<template>
  <div class="upload-file">

    <div
      class="file-block"
      @click="previewFile(file)"
      v-for="(file,index) in formData.fileInfoList"
      :key="file.id"
      :title="file.name"
    >
      <div class="file-icon">
        <img
          src="./file-img/excel.svg"
          v-if="file.extension == '.xls' || file.extension == '.xlsx'"
          height="30"
        />
        <img
          src="./file-img/pdf.svg"
          v-else-if="file.extension == '.pdf'"
          height="30"
        />
        <img
          src="./file-img/ppt.svg"
          v-else-if="file.extension == '.ppt'"
          height="30"
        />
        <img
          src="./file-img/txt.svg"
          v-else-if="file.extension == '.txt'"
          height="30"
        />
        <img
          src="./file-img/word.svg"
          v-else-if="file.extension == '.doc' || file.extension == '.docx'"
          height="30"
        />
        <img
          src="./file-img/default.svg"
          v-else
          height="30"
        />
      </div>
      <div class="file-name">
        <p>{{ file.name }}</p>

      </div>
<!--      <div style=" width: 30px">  <el-link @click.stop="downloadLocalFile(file)">下载</el-link></div>-->

      <div class="file-size">
                        <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
                        >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
                        >
        <span v-else
        >{{ (Number(file.file_size) / 1024 / 1024).toFixed(2) }}M</span
        >
      </div>


    </div>
  </div>
  <van-image-preview v-model:show="state.showResourcePreview"
                     :showIndex="false" :closeable="true"
                     :images="[state.resourceSrc]">
  </van-image-preview>
</template>
<script setup>
import {onMounted, reactive, ref} from "vue";

import store from "@/store";
import axios from "axios";
import {PreviewFile} from "@/api/doc";
import {BlobToImg} from "@/utils/utils";
import {useRoute, useRouter} from "vue-router";
import { Toast, Dialog } from 'vant'

const route = useRoute()
const router = useRouter()
const props = defineProps({
  file_id: {
    type: String,
    default: {}
  }

})
const emit = defineEmits(['onclose'])
const ruleFormRef = ref()
const state = reactive({
  resourceSrc: "",
  showResourcePreview: false,
  imgTypes: ["jpeg", "png", "gif", "bmp", "webp", "tiff", "icon", "svg", "jpg"],
  isImg: false
})
const formData = reactive({
  file_id: '',


  photoPreviewUrl: "",
  showPhotoPreview: false,
  photoInfoList: [],
  dataList: [],
  myHeaders: {
    tenant_id:localStorage.getItem('tenant_id'),
    Authorization:  localStorage.getItem('Token'),
  },
  importUrl: "/oa_portal/oa/portal/Common/UploadFile?sub_system_code=eam",//导入url
  fileInfoList: [],
})
const loading = ref(false)
//保存
const save = () => {
  emit("onclose", false)

}
//取消
const cancel = () => {
  emit("onclose", false)

}
//加载附件列表
const loadFileList = function (ids) {
  if (ids == '' || ids == null) {
    return
  }

  axios.post('/oa_portal/oa/portal/Common/GetFilelist', ids.split(','), {
    headers: {
      "Authorization":  localStorage.getItem('Token'),
      "tenant_id": localStorage.getItem('tenant_id')
    }
  }).then(res => {

    if (res.data.code !== 200) {
      Toast(res.data.msg)
      return
    }
    if (!formData.fileInfoList) {
      formData.fileInfoList = []
    }
    if (!formData.photoInfoList) {
      formData.photoInfoList = []
    }
    res.data.data.forEach((item) => {
      //是否图片

        formData.fileInfoList.push({
          name: item.file_name,
          id: item.id,
          extension: item.extension,
          file_size: item.file_size
        })

    })
  })
}


const previewFile = async (item) => {
  // 单独处理图片
  if (state.imgTypes.indexOf(item.type) !== -1) {
    state.isImg = true
    const res = await PreviewFile(item.id)
    state.resourceSrc = await BlobToImg(res, res.type)
    state.showResourcePreview = true
    return
  }
  // else if(item.type === 'mp4'){
  //   state.isImg = false
  //   const res = await PreviewFile(item.id)
  //   state.resourceSrc = URL.createObjectURL(res)
  //   state.showResourcePreview = true
  //   return
  // }
  // 其他的跳转到预览界面
  await router.push({
    name: 'SpacePreview',
    query: {
      id: item.id,
    }
  })
};
//下载附件到本地
const downloadLocalFile = function (item) {
  axios.post('/oa_portal/oa/portal/Common/DownloadBase64', item.id.split(','), {
    headers: {
      "Authorization":  localStorage.getItem('Token'),
      "tenant_id": localStorage.getItem('tenant_id')
    }
  }).then(res => {

    let fileName = item.name
    let base64 = res.data.data[0].base64
    var raw = window.atob(decodeURIComponent(base64))
    var uInt8Array = new Uint8Array(raw.length)
    for (var i = 0; i < raw.length; i++) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    var blob = new Blob([uInt8Array])
    var downloadUrl = window.URL.createObjectURL(blob)
    var anchor = document.createElement('a')
    anchor.href = downloadUrl
    anchor.download = decodeURI(fileName)
    anchor.click()
  })
}
//预览图片
const previewPhoto = function (item) {
  axios.post('/oa_portal/oa/portal/Common/DownloadBase64', item.id.split(','), {
    headers: {
      "Authorization": store.state.token(),
      "tenant_id": store.state.tenant_id
    }
  }).then(res => {
    if (res.data.code !== 200) {
      Toast(res.data.msg)
      return
    }
    let url = 'data:image/' +
      item.extension.replace('.', '') +
      ';base64,' +
      res.data.data[0].base64
    formData.photoPreviewUrl = url
    formData.showPhotoPreview = true
  })
}
//验证是否图片
const verifySuffix = function (imageName) {
  const suffix = `(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|jpeg)`
  const regular = new RegExp(`.*\.${suffix}`)
  return regular.test(imageName)
}
const onSuccess = (res) => {
  Toast(res.msg)
  if (res.code == 200) {

    res.data.forEach((item) => {
      //是否图片
      if (verifySuffix(item.extension)) {
        formData.photoInfoList.push({
          id: item.id,
          name: item.file_name,
          extension: item.extension,
          file_size: item.file_size
        })
      } else {
        formData.fileInfoList.push({
          name: item.file_name,
          id: item.id,
          extension: item.extension,
          file_size: item.file_size
        })
      }
    })
  }


}
const init = () => {
  formData.file_id = props.file_id
  if (formData.file_id) {
    loadFileList(formData.file_id)
  }
}
onMounted(() => {
  init()

})
</script>
<style scoped lang="scss">
.upload-file {
  width: 100%;


  .icon {
    margin: auto;
    display: none;
  }

  .img-block:hover,
  .file-block:hover {
    background-color: #f5f6f6;
    color: #0089ff;

    .icon {
      margin: auto;
      display: inline-block;
    }
  }

  .img-block,
  .file-block {
    cursor: pointer;
    margin: 2px;
    width: 100%;
    height: 35px;
    padding: 5px 0;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .img-icon,
    .file-icon {
      width: 50px;
    }

    .img-name,
    .file-name {
      width: calc(100% - 100px);
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;

      p {
        line-height: 12px;
      }
    }

    .file-size {
      font-size: 8px;
      width: 50px;
      color: #9e9e9e;
    }


  }
}
</style>
