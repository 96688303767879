<!-- 通用查询网页 -->
<template>
    
  <div class="formApplication">
    <van-form disabled >
      <van-cell-group inset style="margin: 1vh 1.2vh 0vh 1.2vh">
        <span v-for="item in formList" :key="item.column_code">
            <span
                style="
              margin-left: 1vh;
              margin-top: 2vh;
              display: block;
              color: #999;
              font-size: 15px;
            "
            >
            <van-icon class="lable_icon"/>{{ item.column_name }}
          </span>

          <div class="upload-file" v-if="item.component_type === '7'">
            <div
                class="img-block"
                v-for="file in pageConfig.photoInfoList[item.column_code]"
                @click="methods.previewPhoto(file)"
                :key="file.id"
                :title="file.name"
            >
              <div class="img-icon">
                <img src="../../../../assets/file-img/img.svg" height="30"/>
              </div>
              <div class="img-name">
                <span>{{ file.name }}</span>
              </div>
              <div class="file-size">
                <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
                >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
                >
                <span v-else
                >{{
                    (Number(file.file_size) / 1024 / 1024).toFixed(2)
                  }}M</span
                >
              </div>
            </div>
            <div
                class="file-block"
                @click="methods.handlePreView(file.id)"
                v-for="file in pageConfig.fileInfoList[item.column_code]"
                :key="file.id"
                :title="file.name"
            >
              <div class="file-icon">
                <img
                    src="../../../../assets/file-img/excel.svg"
                    v-if="file.extension == '.xls' || file.extension == '.xlsx'"
                    height="30"
                />
                <img
                    src="../../../../assets/file-img/pdf.svg"
                    v-else-if="file.extension == '.pdf'"
                    height="30"
                />
                <img
                    src="../../../../assets/file-img/ppt.svg"
                    v-else-if="file.extension == '.ppt'"
                    height="30"
                />
                <img
                    src="../../../../assets/file-img/txt.svg"
                    v-else-if="file.extension == '.txt'"
                    height="30"
                />
                <img
                    src="../../../../assets/file-img/word.svg"
                    v-else-if="
                    file.extension == '.doc' || file.extension == '.docx'
                  "
                    height="30"
                />
                <img
                    src="../../../../assets/file-img/default.svg"
                    v-else
                    height="30"
                />
              </div>
              <div class="file-name">
                <span>{{ file.name }}</span>
              </div>
              <div class="file-size">
                <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
                >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
                >
                <span v-else
                >{{
                    (Number(file.file_size) / 1024 / 1024).toFixed(2)
                  }}M</span
                >
              </div>
            </div>
          </div>
          <!--表格-->
          <div class="vant-table" v-else-if="item.component_type === '4'">
            <table
                cellspacing="0"
                :style="'width:' + item.th.length * 150 + 'px'"
                class="table"
            >
              <tr>
                <th class="th"></th>
                <th class="th" v-for="(title, _index) in item.th" :key="_index">
                  {{ title }}
                </th>
              </tr>
              <tr
                  v-for="(row, index) in item.list"
                  :key="index"
                  class="list-tr"
              >
                <td style="width: 5%">{{ index + 1 }}</td>
                <td v-for="(title, _index) in item.th" :key="_index">
                  {{ row[title] }}
                </td>
              </tr>
            </table>
          </div>

          <div v-else-if="item.component_type === '9'" style="width: 100%">
            <div
                v-for="row in item.column_value"
                :key="row.line_num"
                class="value-label detail-row"
            >
              <div class="list-title">
                {{ item.column_name + "(" + row.line_num + ")" }}
              </div>
              <div v-for="_column in row.data" :key="_column.column_code">
                <span
                    style="
                    margin-left: 1vh;
                    margin-top: 2vh;
                    display: block;
                    color: #999;
                  "
                >
                  <van-icon class="lable_icon"/>{{ _column.column_name }}
                </span>
                <div v-if="_column.component_type == '7'">
                  <div
                      class="img-block"
                      @click="methods.previewPhoto(file)"
                      v-for="file in pageConfig.photoInfoList[
                      item.column_code +
                        '-' +
                        _column.column_code +
                        '-' +
                        row.line_num
                    ]"
                      :key="file.id"
                  >
                    <div class="img-icon">
                      <img
                          src="../../../../assets/file-img/img.svg"
                          height="30"
                      />
                    </div>
                    <div class="img-name">
                      <span>{{ file.name }}</span>
                    </div>
                    <div class="file-size">
                      <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
                      >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
                      >
                      <span v-else
                      >{{
                          (Number(file.file_size) / 1024 / 1024).toFixed(2)
                        }}M</span
                      >
                    </div>
                  </div>

                  <div
                      class="file-block"
                      @click="methods.downloadLocalFile(file)"
                      v-for="file in pageConfig.fileInfoList[
                      item.column_code +
                        '-' +
                        _column.column_code +
                        '-' +
                        row.line_num
                    ]"
                      :key="file.id"
                      :title="file.name"
                  >
                    <div class="file-icon">
                      <img
                          src="../../../../assets/file-img/excel.svg"
                          v-if="
                          file.extension == '.xls' || file.extension == '.xlsx'
                        "
                          height="30"
                      />
                      <img
                          src="../../../../assets/file-img/pdf.svg"
                          v-else-if="file.extension == '.pdf'"
                          height="30"
                      />
                      <img
                          src="../../../../assets/file-img/ppt.svg"
                          v-else-if="file.extension == '.ppt'"
                          height="30"
                      />
                      <img
                          src="../../../../assets/file-img/txt.svg"
                          v-else-if="file.extension == '.txt'"
                          height="30"
                      />
                      <img
                          src="../../../../assets/file-img/word.svg"
                          v-else-if="
                          file.extension == '.doc' || file.extension == '.docx'
                        "
                          height="30"
                      />
                      <img
                          src="../../../../assets/file-img/default.svg"
                          v-else
                          height="30"
                      />
                    </div>
                    <div class="file-name">
                      <span>{{ file.name }}</span>
                    </div>
                    <div class="file-size">
                      <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
                      >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
                      >
                      <span v-else
                      >{{
                          (Number(file.file_size) / 1024 / 1024).toFixed(2)
                        }}M</span
                      >
                    </div>
                  </div>
                </div>
                <div class="value-label" v-else>
                  <label>{{
                      _column.column_value
                    }}</label>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="item.component_type === '11'" style="width: 100%">
            <div class="rickLink" @click="methods.handlePreRickContent(item)">查看富文本内容</div>
          </div>
          <label v-else style="padding: 10px 15px; font-size: 16px" class="lab">{{
              item.column_value
            }}
      </label>
          <van-divider :style="{ padding: '0 16px' }"/>
        </span>
        <div style="width: 100%;height: 2vh;"></div>
      </van-cell-group>
    </van-form>
    <van-popup v-model:show="pageConfig.showRickPreview"
               :closeable="true" :safe-area-inset-bottom="true"
               teleport="#app"
               :style="{ height: '100%',width: '100%'  }"
               position="right" close-icon-position="top-left">
      <div class="popupBox">
        <div class="popupBox" v-html="pageConfig.richContent"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {reactive, ref, onMounted} from 'vue'
import {GetFormColumnData} from '@/api/oawfs.js'
import {DownloadBase64, GetFileList} from '@/api/oaApi.js'
import {GetMobileFileUrl} from '@/api/mobile.js'
import {Toast, ImagePreview} from 'vant'
import * as dd from 'dingtalk-jsapi'
import {useRouter} from "vue-router";

export default {
  props: ['businessId', 'formCode'],
  setup(props) {
    //页面参数
    const pageConfig = reactive({
      fileInfoList: [],//附件信息列表
      photoInfoList: [],//附件信息列表
      showRickPreview: false, // 预览表格
      showRichText: false, // 预览富文本
      richContent: "", //富文本内容
    })
    //表单列表
    const formList = ref([])
    const router = useRouter()
    //内置函数
    const methods = {
      // 预览富文本数据
      handlePreRickContent: function (item) {
        pageConfig.showRickPreview = true
        pageConfig.richContent = item.column_value
        console.log(pageConfig.richContent, " pageConfig.tableData")
      },
      handlePreView: function (file_id) {
        router.push({name: 'SpacePreview', query: {id: file_id}})
      },
      //获取表单数据
      getFormColumnData: function () {
        let param = {
          formCode: props.formCode,
          businessId: props.businessId,
        }
        GetFormColumnData(param).then((res) => {
          formList.value = res.data
          formList.value.forEach((item) => {
            if (item.component_type == '7') {
              //附件下载
              methods.loadFileList(item.column_value, item.column_code)
            } else if (item.component_type == '4') {
              let list = JSON.parse(item.column_value)
              let thArr = []
              for (var _item in list[0]) {
                thArr.push(_item)
              }
              item.th = thArr
              item.list = list
            }
            //组合列表            
            else if (item.component_type == '9') {
              item.column_value = JSON.parse(item.column_value)
              item.column_value.forEach(row => {
                row.data.forEach(column => {
                  if (column.component_type == '7') {
                    //附件下载
                    methods.loadFileList(column.column_value, item.column_code + "-" + column.column_code + "-" + row.line_num)
                  }
                });
              })
            }
            //json转换显示
            else if (item.edit_type == '2' && item.component_type == '2' && item.data_source) {
              //数据源
              let jarray = JSON.parse(item.data_source)
              if (jarray.find(x => x.value == item.column_value)) {
                item.column_value = jarray.find(x => x.value == item.column_value).text
              }
            }
            //json转换显示
            else if (item.edit_type == '2' && item.component_type == '3' && item.data_source) {
              //数据源
              let jarray = JSON.parse(item.data_source)
              if (item.column_value) {
                let thisvalue = []
                let valueArr = item.column_value.split(',')
                valueArr.forEach(item => {
                  jarray.forEach(element => {
                    if (item == element.value) {
                      thisvalue.push(element.text)
                    }
                  });
                });
                item.column_value = thisvalue.join(',')
              }
            }
          })
        })
      },
      //加载附件列表
      loadFileList: function (ids, columnCode) {
        if (ids == '' || ids == null) {
          return
        }
        GetFileList(ids.split(',')).then(res => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          if (!pageConfig.fileInfoList[columnCode]) {
            pageConfig.fileInfoList[columnCode] = []
          }
          if (!pageConfig.photoInfoList[columnCode]) {
            pageConfig.photoInfoList[columnCode] = []
          }
          res.data.forEach((item) => {
            //是否图片
            if (methods.verifySuffix(item.extension)) {
              pageConfig.photoInfoList[columnCode].push({
                id: item.id,
                name: item.file_name,
                extension: item.extension,
                file_size: item.file_size
              })
            } else {
              pageConfig.fileInfoList[columnCode].push({
                name: item.file_name,
                id: item.id,
                extension: item.extension,
                file_size: item.file_size
              })
            }
          })
        })
      },
      //判断是否PC端
      IsPC: function () {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var flagPc = true;
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flagPc = false;
            break;
          }
        }
        return flagPc;
      },
      //下载附件到本地
      downloadLocalFile: function (item) {
        console.log("当前平台：" + dd.env.platform)
        console.log("IsPC：" + methods.IsPC())
        //移动端钉钉
        if (!methods.IsPC() && dd.env.platform != 'notInDingTalk') {
          Toast('附件暂不支持下载，请打开电脑端下载')
          return
        }
        Toast("开始下载...")
        let ua = navigator.userAgent.toLowerCase()
        //钉钉下载
        if (dd.env.platform != 'notInDingTalk') {
          DownloadBase64(item.id.split(',')).then(res => {
            if (res.code !== 200) {
              Toast(res.msg)
              return
            }
            let fileName = item.name
            let base64 = res.data[0].base64
            var raw = window.atob(decodeURIComponent(base64))
            var uInt8Array = new Uint8Array(raw.length)
            for (var i = 0; i < raw.length; i++) {
              uInt8Array[i] = raw.charCodeAt(i)
            }
            var blob = new Blob([uInt8Array], {type: item.extension.replace('.', '')})
            var downloadUrl = window.URL.createObjectURL(blob)
            var anchor = document.createElement('a')
            //anchor.href = downloadUrl
            anchor.href = downloadUrl
            anchor.download = decodeURI(fileName)
            anchor.click()
          })
        }
        //IOS
        else if (ua.indexOf('Mac') > -1 || ua.indexOf('mac') > -1) {
          window.webkit.messageHandlers.downloadMessage.postMessage(item.id)
        }
        //安卓下载
        else {
          GetMobileFileUrl(item.id).then(res => {
            if (res.code !== 200) {
              Toast(res.msg)
              return
            }
            window.open(res.data)
          })
        }
      },
      //预览图片
      previewPhoto: function (item) {
        DownloadBase64(item.id.split(',')).then(res => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          let url = 'data:image/' +
              item.extension.replace('.', '') +
              ';base64,' +
              res.data[0].base64
          let arr = []
          arr.push(url)
          ImagePreview({images: arr, closeable: true})
        })
      },
      //验证是否图片
      verifySuffix(imageName) {
        const suffix = `(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|jpeg)`
        const regular = new RegExp(`.*\.${suffix}`)
        return regular.test(imageName)
      },
    }
    onMounted(() => {
      //获取数据
      methods.getFormColumnData()
    })
    return {
      pageConfig,
      formList,
      methods,
    }
  },
}
</script>
<style lang="scss">
.rickLink {
  text-indent: 15px;
  color: #007efe;
}

.value-label {
  lebel {
    padding: 10px 15px;
    font-size: 14px
  }
}

.popupBox {
  padding:15px 5px 0 5px;
}

.lable_eye {
  margin-left: 5px;
}

.formApplication {
  .van-field__control {
    //-webkit-text-fill-color: black !important;
  }

  .van-uploader__upload,
  .van-uploader__preview-delete {
    display: none;
  }

  .vant-table {
    overflow-x: auto;

    table {
      padding: 5px;

      .th {
        height: 30px;
        line-height: 30px;
        background-color: #f5f6f6;
        text-align: center;
      }

      tr {
        height: 30px;
        line-height: 30px;
      }

      td {
        text-align: center;
        border-bottom: 1px solid #f5f6f6;
      }

      .link-text {
        color: #0089ff;
      }
    }

    .van-button {
      margin: 0 10px;
    }
  }

  .detail-row {
    width: 100%;
    text-indent: 10px;
    margin: 5px 10px;
  }

  .upload-file {
    width: 100%;

    .img-block:hover,
    .file-block:hover {
      background-color: #f5f6f6;
      color: #0089ff;
    }
  }

  .img-block,
  .file-block {
    cursor: pointer;
    margin: 2px;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .img-icon,
    .file-icon {
      width: 50px;
      margin: 3px 0;
    }

    .img-name,
    .file-name {
      width: calc(100% - 100px);
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 10px;
      line-height: 36px;
    }

    .file-size {
      line-height: 36px;
      font-size: 8px;
      width: 100px;
      color: #9e9e9e;
    }
  }
}
.lab{
  padding: 0 !important;
  display: inline-block;
  width: 98%;
  height: auto;
  word-wrap:break-word;
  margin: 0 5px;
}
</style>
