<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {OrderMeetingStore} from "@/views/Ads/OrderMeeting/store";
import {storeToRefs} from "pinia";
import {getFacilityName, getTimeToGrid} from "@/views/Ads/OrderMeeting/utils";
import moment from "moment";

const orderMeetingStore = OrderMeetingStore()
const {meetingObject} = storeToRefs(orderMeetingStore)

const props = defineProps({
  roomItem: {
    type: Object,
    default: () => {
      return {
        room_id: "",
        room_name: "",
        meetings: [],
        facility: "",
        is_approve: "",
        limit_num: 0,
        meeting_date: ""
      }
    }
  },
  isAdd: {
    type: Boolean,
    default: false
  },
  startGrid: {
    type: Number,
    default: 0
  },
  endGrid: {
    type: Number,
    default: 0
  },
  currentDate: {
    type: String,
    default: ""
  }
})

const state = reactive({
  computerImgUrl: require("@/assets/computer.svg"), // 电脑图片
  facilityMap: "", // 设备名称
  currentTime: 0, // 当前时间序号
  cornerImg: require("@/assets/hook.png"), // 角标图片
})


// 计算属性
const startGridComputed = computed(() => {
  return props.startGrid
})

const endGridComputed = computed(() => {
  return props.endGrid
})

const roomItemComputed = computed(() => {
  return props.roomItem
})

// 获取时间颜色
const getTimeColor = (index) => {
  let isReturnOtherChoice = false
  roomItemComputed.value.meetings.forEach(item => {
    // 获取开始时间的格子
    const start = getTimeToGrid(item.start_time)
    // 获取结束时间的格子
    const end = getTimeToGrid(item.end_time)
    if (index > start && index <= end) isReturnOtherChoice = true
  })
  const choiceDate = moment(moment().year() + '-' + props.currentDate.substring(0, 5)).format("YYYY-MM-DD")
  const nowDate = moment().format("YYYY-MM-DD")
  if (isReturnOtherChoice) return "otherChoice"
  if (index <= state.currentTime && moment(nowDate).isSame(choiceDate)) return "gone"
}

const getRoomItemClass = (item) => {
  return props.isAdd && props.startGrid !== 0 && item.room_id === meetingObject.value.meeting_room_id ? 'active' : 'normal'
}

// 设置当前预约的格子颜色
const getChoiceColor = (index, item) => {
  if (props.isAdd && item.room_id === meetingObject.value.meeting_room_id && props.startGrid !== 0) {
    if (index > startGridComputed.value && index <= endGridComputed.value) return "mineChoice"
  }
}

onMounted(() => {
  state.currentTime = getTimeToGrid(new Date())
})

</script>

<template>
  <div class="roomItem" :class="getRoomItemClass(roomItemComputed)">
    <div class="corner">
      <img :src="state.cornerImg" alt="">
      <div class="mask"></div>
    </div>
    <div class="top">
      <div class="left">
        <div class="title">
          <p>{{ roomItemComputed.room_name }}</p>
          <van-tag v-if="roomItem.is_approve === 'Y'" color="#ECF5FF" text-color="#409EFF">需审批</van-tag>
        </div>
        <div class="info">
          <div class="user">
            <van-icon name="manager"/>
            <span>{{ roomItemComputed.limit_num }}</span></div>
          <div class="instrument">{{ getFacilityName(roomItemComputed.facility) }}</div>
        </div>
      </div>
      <div class="right">
        <div class="img">
          <img :src="state.computerImgUrl" alt="">
        </div>
      </div>
    </div>
    <div class="bottom">
      <ul>
        <li v-for="i in 32" :class="[getTimeColor(i),getChoiceColor(i,roomItemComputed)]"></li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.normal {
  border: 2px solid #fff !important;
}

.corner {
  display: none;
}

.active {
  border: 2px solid #007efe !important;
  position: relative;

  .corner {
    position: absolute;
    display: block;
    left: -1px;
    top: -1px;
    background: #007efe;
    width: 12px;
    height: 12px;
    border-radius: 5px 0 0 0;

    img {
      width: 12px;
      height: 12px;
      position: absolute;
      left: -2px;
      top: -1.5px;
    }

    .mask {
      position: absolute;
      left: 4.7px;
      top: 3.2px;
      width: 12px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
    }
  }
}

p {
  margin: 0;
}

.roomItem {
  margin: 10px 8px 0 8px;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d9d9d9;

  .top {
    display: flex;
    justify-content: space-between;

    .left {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .title {
        display: flex;
        margin-bottom: 5px;
        font-weight: bold;
        position: relative;
        z-index: 10;

        p {
          margin-right: 5px;
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .info {
        display: flex;
        align-items: center;
      }

      .user {
        color: #7d8082;
        width: 60px;

        span {
          padding-left: 5px;
          font-size: 11px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
    }

    .img {
      width: 50px;
      text-align: center;
      border: 1px solid #e1e1e1;
      padding: 7px 0;
      border-radius: 5px;

      img {
        width: 50%;
      }
    }
  }

  .bottom {
    margin-top: 5px;
    padding-bottom: 5px;

    ul {
      display: flex;

      li {
        &:nth-child(1) {
          border-left: 1px solid #e0e0e0;
        }

        @for $i from 1 through 17 {
          &:nth-child(#{(2*$i) - 1})::after {
            content: "#{$i + 6}";
            position: absolute;
            bottom: -13px;
            left: -5px;
            transform: scale(.8);
            transform-origin: 0 0;
            color: #b8babc;
          }
        }

        &:nth-child(32)::after {
          content: "23";
          position: absolute;
          bottom: -13px;
          left: 5px;
          transform: scale(.8);
          transform-origin: 0 0;
          color: #b8babc;
        }

        flex: 1;
        height: 10px;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        position: relative;
      }
    }
  }

  .gone {
    background: #d0d0d0;
  }

  .otherChoice {
    background: #a3a5a8;
  }

  .mineChoice {
    background: #007efe;
  }

}
</style>