<!--审批-->
<template>
  <div>
    <!-- 下拉刷新 -->
    <van-pull-refresh v-model="state.loading" @refresh="methods.onRefresh">
      <div class="Approval">
        <div class="approval-block">
          <van-cell
            :title="state.apply_name + '申请的' + state.form_name"
            style="font-weight: 600"
          >
            <template #label>
              <div style="display: flex">
                <img width="20" height="20" src="../../../assets/logo.svg" />
                <span class="title-span">盛泰光科</span>
              </div>
              <div style="margin-top: 10px">
                <span
                  style="color: #d19c53; font-weight: 400; font-size: 13px"
                  v-if="state.current_status == 1"
                >
                  等待{{ state.approve_names }}
                  <span v-if="state.approve_count > 1">等</span>
                  {{ state.approve_count }}人确认
                </span>
                <span style="color: #57b987" v-if="state.current_status == 2"
                  >审批通过</span
                >
                <span style="color: #f25643" v-if="state.current_status == 3"
                  >审批已拒绝</span
                >
                <span style="color: #d19c53" v-if="state.current_status == 4"
                  >申请人已撤销</span
                >
                <span style="color: #f25643" v-if="state.current_status == 5"
                  >流程异常</span
                >
              </div>
              <div
                style="
                  line-height: 30px;
                  color: #333;
                  font-weight: 400;
                  font-size: 13px;
                "
              >
                表单单号:{{ state.series_code }}
              </div>
            </template>
          </van-cell>
          <div class="pass-seal seal-block" v-if="state.current_status == 2">
            <img src="../../../assets/flow-status/pass.png" width="80" />
          </div>
          <div class="refuse-seal seal-block" v-if="state.current_status == 3">
            <img src="../../../assets/flow-status/reject.png" width="80" />
          </div>
          <div class="revoke-seal seal-block" v-if="state.current_status == 4">
            <img src="../../../assets/flow-status/cancel.png" width="80" />
          </div>
          <div class="revoke-seal seal-block" v-if="state.current_status == 5">
            <img src="../../../assets/flow-status/flow-error.png" width="80" />
          </div>
        </div>

        <!-- 动态加载组件 -->
        <component
          v-if="state.comName"
          :is="state.comName"
          :businessId="state.businessId"
          :formCode="state.formCode"
        />
        <Process :instanceId="state.instanceId"></Process>
        <div class="remark" v-if="state.current_status == 4">
          <van-form disabled>
            <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
              <span
                style="
                  margin-left: 2.5vh;
                  margin-top: 2vh;
                  display: block;
                  color: #8f8f8d;
                "
                >撤销时间</span
              >
              <van-field style="padding-top: 0" v-model="state.end_time" />
              <span
                style="
                  margin-left: 2.5vh;
                  margin-top: 2vh;
                  display: block;
                  color: #8f8f8d;
                "
                >撤销原因</span
              >
              <van-field style="padding-top: 0" v-model="state.abort_reason" />
            </van-cell-group>
          </van-form>
        </div>
      </div>
      <van-tabbar
        @change="methods.tabbarClick1"
        v-if="state.action == 1 && state.current_status == 1"
      >
        <van-tabbar-item style="display: none">占位</van-tabbar-item>
        <van-tabbar-item icon="chat-o">评论</van-tabbar-item>
        <van-tabbar-item icon="share-o">转交</van-tabbar-item>
        <van-tabbar-item icon="revoke">退回</van-tabbar-item>
        <van-tabbar-item>
          <van-button plain type="primary" style="border-radius: 10px"
            >拒绝</van-button
          >
        </van-tabbar-item>
        <van-tabbar-item>
          <van-button type="primary" style="border-radius: 10px"
            >同意</van-button
          >
        </van-tabbar-item>
      </van-tabbar>
      <van-tabbar
        v-if="
          (state.action == 2 || state.action == 4) && state.current_status == 1
        "
      >
        <van-tabbar-item style="display: none">占位</van-tabbar-item>
        <van-tabbar-item icon="chat-o" @click="methods.comment()"
          >评论</van-tabbar-item
        >
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
      </van-tabbar>
      <van-tabbar v-if="state.action == 3 && state.current_status == 1">
        <van-tabbar-item style="display: none">占位</van-tabbar-item>
        <van-tabbar-item icon="chat-o" @click="methods.comment()"
          >评论</van-tabbar-item
        >
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item>
          <van-button
            type="primary"
            @click="methods.urge"
            style="border-radius: 10px"
            >催办</van-button
          >
        </van-tabbar-item>
        <van-tabbar-item>
          <van-button
            plain
            type="primary"
            @click="methods.revoke"
            style="border-radius: 10px"
            >撤销</van-button
          >
        </van-tabbar-item>
      </van-tabbar>
      <van-tabbar v-if="state.current_status == 2">
        <van-tabbar-item style="display: none">占位</van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item></van-tabbar-item>
        <van-tabbar-item> </van-tabbar-item>
        <van-tabbar-item>
          <van-button
            plain
            type="primary"
            @click="methods.revoke"
            style="border-radius: 10px"
            >撤销</van-button
          >
        </van-tabbar-item>
      </van-tabbar>
    </van-pull-refresh>
    <!-- 同意弹框 -->
    <van-popup
      v-model:show="state.showApproval"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <submit-approval
        :formtoId="state.formto_id"
        :instanceId="state.instanceId"
        :action="'Approve'"
        :empId="'*'"
        :statusId="'*'"
        :isdd="state.timestamp ? 1 : 0"
        @onclose="methods.onclose()"
      ></submit-approval>
    </van-popup>
    <!-- 拒绝弹框 -->
    <van-popup
      v-model:show="state.showReject"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <submit-approval
        :formtoId="state.formto_id"
        :instanceId="state.instanceId"
        :action="'Reject'"
        :empId="'*'"
        :statusId="'*'"
        :isdd="state.timestamp ? 1 : 0"
        @onclose="methods.onclose()"
      ></submit-approval>
    </van-popup>
    <!-- 转交弹框 -->
    <van-popup
      v-model:show="state.showTransfer"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <Transfer
        :formtoId="state.formto_id"
        :instanceId="state.instanceId"
        :isdd="state.timestamp ? 1 : 0"
        @onclose="methods.onclose()"
      ></Transfer>
    </van-popup>
    <!-- 评论弹框 -->
    <van-popup
      v-model:show="state.showComment"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <Comment
        :instanceId="state.instanceId"
        @onclose="methods.onclose()"
      ></Comment>
    </van-popup>
    <!-- 退回弹框 -->
    <van-popup
      v-model:show="state.showReturn"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <Return
        :instanceId="state.instanceId"
        :flowCode="state.formCode"
        :formtoId="state.formto_id"
        :isdd="state.timestamp ? 1 : 0"
        @onclose="methods.onclose()"
      ></Return>
    </van-popup>
    <!-- 撤销弹框 -->
    <van-popup
      v-model:show="state.showRevoke"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <submit-approval
        :formtoId="state.formto_id"
        :instanceId="state.instanceId"
        :action="'Revoke'"
        :empId="'*'"
        :statusId="'*'"
        :isdd="state.timestamp ? 1 : 0"
        @onclose="methods.onclose()"
      ></submit-approval>
    </van-popup>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { GetInstance, UrgeNotice, UpdateDingTodoStatus } from "@/api/oawfs.js"; //
import { reactive } from "vue";
import LeaveApplication from "./components/leaveApply/leaveApplication.vue";
import AllotApplication from "./components/allotApply/allotApplication.vue";
import TravelApplication from "./components/travelApply/travelApplication.vue";
import OverTimeApplication from "./components/overTimeApply/overTimeApplication.vue";
import PatchApplication from "./components/patchSwipeApply/patchApplication.vue";
import FormColumn from "./components/formColumn.vue";
import Process from "@/components/Wfs/Process.vue"; //流程组件
import componentsJSon from "./components.json"; //组件的json
import { onMounted } from "@vue/runtime-core";
import * as dd from "dingtalk-jsapi"; //钉钉组件
import { Toast } from "vant";
import moment from "moment";
import ChangeTitle from "@/utils/changeTitle.js";
import SubmitApproval from "./submitApproval/index.vue";
import Transfer from "./submitApproval/transfer.vue";
import Comment from "./submitApproval/comment.vue";
import Return from "./submitApproval/return.vue";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    let state = reactive({
      loading: false, //下拉刷新
      form_name: "", //表单名称
      apply_name: "", //申请人姓名
      approve_names: "", //审批人姓名
      approve_count: "", //审批人个数
      remark: "", //审批备注
      series_code: "", //业务单号
      abort_reason: "", //结束原因
      end_time: "", //撤销时间
      formCode: route.params.formCode, //后端获取的组件code
      businessId: "",
      current_status: null, //1审核中2已通过3已拒绝
      instanceId: route.params.instanceId, //实例id
      formto_id: route.params.formto_id,
      action: route.params.action, //1待处理3发起
      comName: "", //组件的名字
      userinfo: "",
      check_formto_id: "", //选择的id
      formto_list: [],
      showApproval: false, //显示审批弹框
      showTransfer: false, //显示转交弹框
      showReject: false, //显示拒绝弹框
      showComment: false, //显示评论弹框
      showReturn: false, //显示退回弹框
      showRevoke: false, //显示撤销
      timestamp: "",
    });
    const methods = {
      //下拉刷新
      onRefresh: function () {
        state.loading = false;
        window.location.reload();
      },
      //缓存清理
      cache: function (type) {
        if (type == 1) {
          localStorage.setItem("Token", "123");
        } else {
          localStorage.removeItem("Token");
        }
      },
      //获取实例信息
      LoadInstance: function () {
        GetInstance({ id: state.instanceId }).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg);
            return;
          }
          res.data = res.data[0];
          state.businessId = res.data.business_id;
          state.form_name = res.data.form_name; //表单名称
          state.apply_name = res.data.apply_name; //申请人姓名
          state.approve_names = res.data.approve_names; //审批人姓名
          state.approve_count = res.data.approve_count; //审批人个数
          state.current_status = res.data.current_status; //1审核中2已通过3已拒绝
          state.remark = res.data.remark;
          state.series_code = res.data.series_code; //表单单号
          state.abort_reason = res.data.abort_reason;
          state.end_time = methods.convertTime(res.data.end_time);
          //加载组件
          methods.LoadComponent();
        });
      },
      //修改钉钉待办状态
      updateDingTodoStatus: function () {
        UpdateDingTodoStatus({ formto_id: state.formto_id }).then((res) => {
          if (res.code != 200) {
            console.log(res.msg);
          }
        });
      },
      //获取用户信息
      LoadUserInfo: function () {
        dd.util.domainStorage.getItem({
          name: "key", // 存储信息的key值
          onSuccess: function (info) {
            state.userinfo = info.value;
          },
        });
      },
      //加载组件
      LoadComponent: function () {
        let componentName = [];
        componentsJSon.forEach((item) => {
          //把后端获取到的组件id和json里面的对比
          if (item.code == state.formCode && item.look_url != null) {
            //如果id相同就拿出来
            componentName.push(item.cname);
            return;
          }
        });
        if (componentName.length == 0) {
          state.comName = "FormColumn";
          return;
        }
        state.comName = componentName[0]; //给组件名字赋值放到页面上
      },
      //时间转换
      convertTime: function (time) {
        if (time != "") {
          return moment(time).format("YYYY/MM/DD HH:mm:ss");
        }
      },
      //关闭弹框
      onclose: function () {
        state.showApproval = false;
        state.showTransfer = false;
        state.showReject = false;
        state.showComment = false;
        state.showReturn = false;
        state.showRevoke = false;
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      //标签点击
      tabbarClick1: function (index) {
        switch (index) {
          case 1:
            //评论
            methods.comment();
            break;
          case 2:
            //转交
            methods.transfer();
            break;
          case 3:
            //退回
            methods.returns();
            break;
          case 4:
            //拒绝
            methods.reject();
            break;
          case 5:
            //同意
            methods.approve();
            break;
        }
      },
      //拒绝
      reject: function () {
        state.showReject = true;
      },
      //同意
      approve: function () {
        state.showApproval = true;
      },
      //转交
      transfer: function () {
        state.showTransfer = true;
      },
      //回退
      returns: function () {
        state.showReturn = true;
      },
      //评论
      comment: function () {
        state.showComment = true;
      },
      //撤销
      revoke: function () {
        state.showRevoke = true;
      },
      //催办
      urge: function () {
        UrgeNotice({ instance_id: state.instanceId }).then((res) => {
          if (res.code == 200) {
            Toast("催办成功");
          } else {
            Toast("催办失败");
          }
        });
      },
      //获取url参数
      getQueryString: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(decodeURI(r[2]));
        return null;
      },
    };
    onMounted(() => {
      ChangeTitle.setTitle("审批");
      state.timestamp = methods.getQueryString("timestamp");
      //获取实例信息
      methods.LoadInstance();
      //更新钉钉待办状态
      methods.updateDingTodoStatus();
    });
    return {
      methods,
      state,
      LeaveApplication,
      Process,
      AllotApplication,
      TravelApplication,
      OverTimeApplication,
      PatchApplication,
      FormColumn,
    };
  },
  components: {
    LeaveApplication,
    Process,
    AllotApplication,
    TravelApplication,
    OverTimeApplication,
    PatchApplication,
    FormColumn,
    "submit-approval": SubmitApproval,
    Transfer,
    Comment,
    Return,
  },
};
</script>
<style lang="scss">
body {
  background-color: #f6f6f6;
}
.Approval {
  margin-bottom: 60px;
  .approval-block {
    position: relative;
    .seal-block {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }
  .title-span {
    margin-top: 2px;
    margin-left: 6px;
    font-size: 10px;
    font-weight: 400;
  }
  .van-cell__title {
    font-size: 15px;
  }
  .van-tabbar {
    padding-bottom: 15px;
  }
}
</style>
