<template>
<VanbarVue :title="'首页'" :showRight="true" :showLeft="false"></VanbarVue>
    <div class="box">
        <div class="box_item" @click="goto('quotationlist')">
            <img  src="@/assets/defaultMenu.svg" />
            <span>报价</span>
        </div>
        <div class="box_item"  @click="goto('viewOrderlist')">
            <img  src="@/assets/defaultMenu.svg" />
            <span>查看订单</span>
        </div>
    </div>
</template>

<script setup>
import { nextTick, onMounted, reactive,ref,toRefs } from "vue";
import {useRouter,useRoute} from "vue-router";
import VanbarVue from '@/views/Ads/bidding/components/Vanbar.vue';

    const router = useRouter()
    const data=reactive({
    })
    const rules = reactive({
    })
    const {}=toRefs(data)

    onMounted(()=>{
    })
    
    const goto=(name)=>{
        router.push({name,query:{isrouter:true}})
    }

    function getCookie(name) {
    const cookieName = `${name}=`;
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

</script>

<script>
export default {
  name:'',
}
</script>
<style scoped lang='scss'>
.box{
    display: flex;
    padding: 10px;
    .box_item{
        width: 19%;
        margin: 5px ;
    }
}
</style>