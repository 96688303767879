import axios from '../utils/request.js'

export function GetAssetList(data) {
  //获取我的资产
  return axios({
    url: '/ifs_eam/ifs/eam/AssetManage/GetAssetList',
    method: 'POST',
    data: data,
  })
}

export function AddAssetAllotApply(data) {
  //薪资调拨单
  return axios({
    url: '/ifs_eam/ifs/eam/AssetManage/AddAssetAllotApply',
    method: 'POST',
    data: data,
  })
}
//获取调拨信息
export function GetAssetAllotApply(data) {
  return axios({
    url: '/ifs_eam/ifs/eam/AssetManage/GetAssetAllotApply',
    method: 'GET',
    params: data,
  })
}
//获取调拨资产列表
export function GetAllotAsset(data) {
  return axios({
    url: '/ifs_eam/ifs/eam/AssetManage/GetAllotAsset',
    method: 'GET',
    params: data,
  })
}
//通过资产编码查询盘点记录
export function GetRecordByAssetCode(data) {

  return axios({
    url: '/ifs_eam/ifs/eam/AssetsInventory/GetRecordByAssetCode',
    method: 'POST',
    data: data,
  })
}
//通过盘点记录id查询盘点记录
export function GetRecordByAssetCodeNoPrm(data) {
  return axios({
    url: '/ifs_eam/ifs/eam/AssetsInventory/GetRecordByAssetCodeNoPrm',
    method: 'POST',
    data: data,
  })
}
//盘点
export function Inventory(data) {
  return axios({
    url: '/ifs_eam/ifs/eam/AssetsInventory/Inventory',
    method: 'POST',
    data: data,
  })
}
//通过资产id获取资产信息
export function GetAssetById(data) {
  return axios({
    url: '/ifs_eam/ifs/eam/Asset/GetAssetById',
    method: 'POST',
    data: data,
  })
}


