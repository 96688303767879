<template>
  <div class="leaveApplication">
    <van-form disabled>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="apply_emp_info" />申请人
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_emp_info"
          name="申请人"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leaveType1" />请假假别<span
            v-if="pageConfig.leaveType2 != null"
            >1</span
          >
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.leaveType1"
          name="请假假别1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="apply_time" />申请时间
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_time"
          name="申请时间"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="start_time_1" />请假开始时间<span
            v-if="pageConfig.leaveType2 != null"
            >1</span
          >
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.start_time_1"
          name="请假开始时间1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="end_time_1" />请假结束时间<span
            v-if="pageConfig.leaveType2 != null"
            >1</span
          >
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.end_time_1"
          name="请假结束时间1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leave_hours_1" />请假时数<span
            v-if="pageConfig.leaveType2 != null"
            >1</span
          >
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.leave_hours_1"
          name="请假时数1"
          disabled
        />
        <span
          v-if="pageConfig.leaveType2 != null"
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leaveType2" />请假假别2
        </span>
        <van-field
          v-if="pageConfig.leaveType2 != null"
          style="padding-top: 0"
          v-model="pageConfig.leaveType2"
          name="请假假别2"
          disabled
        />
        <span
          v-if="pageConfig.leaveType2 != null"
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="start_time_2" />请假开始时间2
        </span>
        <van-field
          v-if="pageConfig.leaveType2 != null"
          style="padding-top: 0"
          v-model="pageConfig.start_time_2"
          name="请假开始时间2"
          disabled
        />
        <span
          v-if="pageConfig.leaveType2 != null"
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="end_time_2" />请假结束时间2
        </span>
        <van-field
          v-if="pageConfig.leaveType2 != null"
          style="padding-top: 0"
          v-model="pageConfig.end_time_2"
          name="请假结束时间2"
          disabled
        />
        <span
          v-if="pageConfig.leaveType2 != null"
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leave_hours_2" />请假时数2
        </span>
        <van-field
          v-if="pageConfig.leaveType2 != null"
          style="padding-top: 0"
          v-model="pageConfig.leave_hours_2"
          name="请假时数2"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leave_reason" />申请原因
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_reason"
          name="申请原因"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leave_reason" />上传附件
        </span>
        <div class="upload-file">
          <van-uploader multiple v-model="pageConfig.fileList" accept />
        </div>
      </van-cell-group>
    </van-form>
  </div>
</template>
<style lang="scss">
.leaveApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
}
</style>
<script>
import { reactive, toRefs } from '@vue/reactivity'
import { GetLeaveApplyById } from '@/api/ehrTas.js'
import { Toast } from 'vant'
import { onMounted } from '@vue/runtime-core'
import moment from "moment";
import { DownloadBase64 } from '@/api/oaApi.js'
export default {
  props: ['businessId'],
  setup (props) {
    //页面配置
    const pageConfig = reactive({
      apply_emp_name: "",//申请人姓名
      apply_emp_code: "",//申请人工号
      apply_emp_info: "",//申请人信息
      leaveType1: "",//请假假别1
      start_time_1: "",//请假开始时间1
      end_time_1: "",//请假结束时间1
      leave_hours_1: "",//请假时数1
      leaveType2: null,//请假假别2
      start_time_2: "",//请假开始时间2
      end_time_2: "",//请假结束时间2
      leave_hours_2: "",//请假时数2
      apply_reason: "",//申请原因
      fileList: [],//附件列表
      apply_time:'',//申请时间
    })
    //内置方法
    const methods = {
      //获取请假申请
      getLeaveApply: function () {
        //请假申请id
        let param = {
          leaveApplyId: props.businessId
        }
        GetLeaveApplyById(param).then(res => {
          if (res.code != 200) {
            Toast(res.msg)
            return false
          }
          let resData = res.data[0]
          pageConfig.apply_emp_name = resData.emp_name//申请人姓名
          pageConfig.apply_emp_code = resData.emp_code//申请人工号
          pageConfig.apply_emp_info = resData.emp_code+'-'+resData.emp_name//申请人工号
          pageConfig.leaveType1 = resData.leave_name_1//请假类别1
          pageConfig.start_time_1 = methods.convertTime(resData.start_time_1)//开始时间1
          pageConfig.end_time_1 = methods.convertTime(resData.end_time_1)//结束时间1
          pageConfig.leave_hours_1 = resData.leave_hours_1//请假时数1
          pageConfig.leaveType2 = resData.leave_name_2//请假类别2
          pageConfig.start_time_2 = methods.convertTime(resData.start_time_2)//开始时间2
          pageConfig.end_time_2 = methods.convertTime(resData.end_time_2)//结束时间2
          pageConfig.leave_hours_2 = resData.leave_hours_2//请假时数2
          pageConfig.apply_reason = resData.apply_reason//请假原因
          pageConfig.apply_time = methods.convertTime(resData.apply_time)
          //附件下载
          methods.downloadImage(resData.attachment_id)
        })
      },
      //时间转换
      convertTime: function (time) {
        if (time) {
          return moment(time).format('YYYY/MM/DD HH:mm:ss')
        }
      },      
      //下载图片
      downloadImage: function (ids) {
        if (ids == '' || ids == null) {
          return
        }
        DownloadBase64(ids.split(',')).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          res.data.forEach((item) => {
            pageConfig.fileList.push({
              content:
                'data:image/' +
                item.extension.replace('.', '') +
                ';base64,' +
                item.base64,
              url:
                'data:image/' +
                item.extension.replace('.', '') +
                ';base64,' +
                item.base64,
              isImage: true,
            })
          })
        })
      },
    }
    onMounted(() => {
      //获取请假申请记录
      methods.getLeaveApply()
    })
    return {
      pageConfig,
      methods
    }
  },
}
</script>
<style lang="scss">
.leaveApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
  .van-uploader__upload,
  .van-uploader__preview-delete {
    display: none;
  }
  .upload-file {
    background-color: #fff;
    padding: 5px 10px;
    margin: 5px auto;
    overflow-x: auto;
    .van-uploader__wrapper {
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: inherit;
    }
    .van-uploader__wrapper::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>