import axios from '../utils/request.js'
/*保存费用报销单*/
export function SaveReimbursementForm(data) {
  return axios({
    url: '/ifs_exp/ifs/exp/ReimbursementForm/SaveReimbursementForm',
    method: 'POST',
    data,
  })
}
/*保存请款单*/
export function SavePaymentForm(data) {
  return axios({
    url: '/ifs_exp/ifs/exp/PaymentForm/SavePaymentForm',
    method: 'POST',
    data,
  })
}