<template>
  <div id="pdf-view">
    <div id="pdf"></div>
  </div>
</template>
<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import {reactive, toRefs, onMounted} from 'vue'
import {GetVideo, DownLoadFiles} from "../api/oaApi"

export default {
  name: 'PdfView',
  props: ['pdfId'],
  setup(props) {
    const state = reactive({
      pdfh5: null,
    })
    onMounted(() => {
      /*DownLoadFiles({"attachment_id": props.pdfId}).then(res => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(res); // 5.设置a链接href*/
        let url='/oa_portal/oa/portal/Common/DownloadFile/' +  props.pdfId + '?tenant_id='+localStorage.getItem("tenant_id");
        state.pdfh5 = new Pdfh5('#pdf', {
          pdfurl: url,
        })
        state.pdfh5.on('complete', function (status, msg, time) {
          console.log(
              '状态：' +
              status +
              '，信息：' +
              msg +
              '，耗时：' +
              time +
              '毫秒，总页数：' +
              this.totalNum
          )
        })
    })
    return {
      ...toRefs(state),
    }
  },
}
</script>
<style scoped lang="scss">
#pdf-view {
  height: 100vh;
}
</style>
