import { createStore } from "vuex";
import getters from './getters'

export default createStore({
  state:{
    userinfo : '1',//用户信息
    getdict: '',
  },
  mutations:{
    setDict(state, dictfun) {
      state.getdict = dictfun;
    },

  },
  modules: {
    getInfo(state,infoCode){
      state.userinfo = infoCode 
   },
   
  },
  getters
})

