import {GetNewsSection} from "@/api/Ads/news";

// 获取数据并格式化
export async function getDesignList(news_id) {
    const data = {news_id}
    const res = await GetNewsSection(data)
    const designList = res.data.map(item => {
        return {
            section_id: item.section_id,
            columns: item.columns.map(item => {
                return {
                    ...item,
                    left: item.coordinate_x,
                    top: item.coordinate_y,
                    imageUrl: item.file_id ? `/oa_portal/oa/portal/Common/DownloadFile/${item.file_id}?tenant_id=0` : "",
                }
            })
        }
    })
    return designList
}

// 去除数组中空的元素
export const removeEmpty = (arr) => {
    return arr.filter(item => item.columns.length > 0)
}

