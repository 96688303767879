<!--退回页面-->
<template>
  <div class="return">
    <div class="header">
      <p class="title">请选择回退到的节点</p>
    </div>
    <div class="wrapper">
      <van-empty
        style="height: 70vh"
        @click="methods.onclose()"
        v-if="statusList.length == 0"
        description="未找到任何数据"
      />

      <van-cell
        :title="item.status_name"
        :key="item.flow_id"
        v-for="item in statusList"
        @click="methods.openSubmitApproval(item.flow_status_id)"
        is-link
      />
    </div>
    <!-- 同意弹框 -->
    <van-popup
      v-model:show="pageConfig.showApproval"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <submit-approval
        :formtoId="pageConfig.formto_id"
        :instanceId="pageConfig.instance_id"
        :action="'Return'"
        :empId="'*'"
        :statusId="pageConfig.statusid"
        :isdd="pageConfig.isdd"
        @onclose="methods.onclose()"
      ></submit-approval>
    </van-popup>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import { onMounted } from '@vue/runtime-core'
import { GetReturnStatusList } from '@/api/oawfs.js' //emp的接口
import ChangeTitle from '@/utils/changeTitle.js'
import SubmitApproval from './index.vue'
export default {
  props: ['formtoId','flowCode', 'isdd', 'instanceId'],
  components:{'submit-approval':SubmitApproval},
  setup (props, { emit }) {
    //节点列表
    const statusList = ref([])
    const route = useRoute()
    const router = useRouter()
    const pageConfig = reactive({
      inputValue: '',
      searchTimeOut: null,
      statusid:'',
      showApproval:false,//打开审批页面
      instance_id:props.instanceId??route.params.instance_id, //实例id
      flow_code: props.flowCode??route.params.flow_code, //表单code
      formto_id: props.formtoId??route.params.formto_id, //审批人实例id
      isdd:props.isdd??route.params.isdd,//钉钉待办
    })
    const methods = {
      //获取回退节点列表
      loadReturnStatusList: function () {
        let param = {
          form_code:pageConfig.flow_code,
          formto_id:pageConfig.formto_id,
        }
        GetReturnStatusList(param).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          statusList.value = res.data
        })
      },
      //打开提交审批页面
      openSubmitApproval: function (statusid) {
        pageConfig.statusid = statusid
        pageConfig.showApproval = true
      },       
      //关闭弹框
      onclose: function () { 
        pageConfig.showApproval = false
        emit('onclose',null)
      }
    }
    onMounted(() => {
      //获取回退节点
      methods.loadReturnStatusList()
      ChangeTitle.setTitle("退回")
    })
    return {
      statusList,
      pageConfig,
      methods,
    }
  },
}
</script>
<style lang="scss">
.return {
  .header {
    .title {
      line-height: 30px;
      text-indent: 10px;
      margin: 0;
      color: rgb(240, 153, 81);
    }
  }
  .wrapper {
    min-height: 300px;
    margin: 0;
    background-color: #fff;
  }
}
</style>
