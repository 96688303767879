<script setup>
import {reactive} from "vue";
import {useRouter} from "vue-router";
import {GetEmpListByName} from "@/api/oaApi";

const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isAddChildTask: {
    type: Boolean,
    default: false
  },
  isSchedule: {
    type: Boolean,
    default: false
  },
  isAssign: {
    type: Boolean,
    default: false
  },
  isStart: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['handleBegin', 'handleSave', 'handleCancel','handleEditTask','handleAcceptance','handleReturn','handleDown',
  'handleAssign', 'handleSchedule','handleCancelTask','handleActiveTask','handleAssignTask'])

const router = useRouter()
const state = reactive({
  btnPermission: 1, // 按钮权限
})


// 进度
const schedule = () => {

}


</script>

<template>
  <van-tabbar v-if="isEdit || isAddChildTask || isSchedule || isStart" safe-area-inset-bottom="true">
    <van-tabbar-item/>
    <van-tabbar-item/>
    <van-tabbar-item/>
    <van-tabbar-item v-if="!isAddChildTask">
      <van-button plain hairline type="primary" @click="emit('handleCancel')">取消</van-button>
    </van-tabbar-item>
    <van-tabbar-item v-if="isAddChildTask">
      <van-button plain hairline type="primary" @click="()=>router.back()">取消</van-button>
    </van-tabbar-item>
    <van-tabbar-item>
      <van-button type="primary" @click="emit('handleSave')">保存</van-button>
    </van-tabbar-item>
  </van-tabbar>
  <van-tabbar v-else-if="isAssign">
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status !== '5' && item.task_status !== '4'" @click="emit('handleCancelTask')">取消</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status === '5'"  @click="emit('handleActiveTask')">激活</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status === '0'" @click="emit('handleAssignTask')">指派</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status !== '5' && item.task_status !== '4'" @click="emit('handleAssign')">分配</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status === '3'" @click="emit('handleAcceptance')">验收</van-tabbar-item>
    <van-tabbar-item icon="todo-list-o" v-if="item.task_status === '3'" @click="emit('handleReturn')">退回</van-tabbar-item>
    <van-tabbar-item v-if="item.task_status !== '3'"/>
    <van-tabbar-item/>
    <van-tabbar-item v-if="item.task_status !== '5' && item.task_status === '4'"/>
    <van-tabbar-item v-if="item.task_status !== '5' && item.task_status === '4'"/>
    <van-tabbar-item>
      <van-button type="primary" @click="emit('handleEditTask')">修改</van-button>
    </van-tabbar-item>
  </van-tabbar>
  <div v-else>
    <van-tabbar v-if="item.task_status === '1' || item.task_status === '0'">
      <van-tabbar-item icon="todo-list-o" @click="emit('handleAssign')">分配</van-tabbar-item>
      <van-tabbar-item/>
      <van-tabbar-item/>
      <van-tabbar-item/>
      <van-tabbar-item v-if="item.task_status === '1'">
        <van-button type="primary" @click="emit('handleBegin')">开始</van-button>
      </van-tabbar-item>
      <van-tabbar-item v-if="item.task_status === '0'"/>
    </van-tabbar>
    <van-tabbar v-if="item.task_status === '2'">
      <van-tabbar-item icon="todo-list-o" @click="emit('handleAssign')">分配</van-tabbar-item>
      <van-tabbar-item icon="bar-chart-o" @click="emit('handleSchedule')">进度</van-tabbar-item>
      <van-tabbar-item/>
      <van-tabbar-item/>
      <van-tabbar-item>
        <van-button type="primary" @click="emit('handleDown')">完成</van-button>
      </van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<style scoped lang="scss">

.van-tabbar {
  padding-bottom: 10px;
}

.van-tabbar-item {
  color: var(--van-tabbar-item-text-color);
}
</style>