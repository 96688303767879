<!--考勤机列表查询-->
<template>
  <van-pull-refresh v-model="pageConfig.refreshing" @refresh="onRefresh" class="van-pull-refresh">
    <van-list v-model:loading="pageConfig.loading" :finished="pageConfig.finished" finished-text="没有更多了"
              @load="onLoad">
      <van-cell v-for="item in pageConfig.attentionList" :key="item.deviceId" is-link @click="gotoMachine(item)"
                class="my-van-cell">
        <img src="../../../assets/attention.svg">
        <div class="description1">
          <div class="description2">
            <span>{{ item.deviceName }}</span>
          </div>
          <div class="description3">
            <span>考勤机&nbsp;&nbsp;&nbsp;&nbsp; {{ item.empCode }}人</span>
          </div>
        </div>
        <template #right-icon>
          <van-icon name="arrow" class="right-icon"/>
        </template>
      </van-cell>
    </van-list>
  </van-pull-refresh>
</template>
<script>
import {reactive} from '@vue/reactivity'
import ChangeTitle from '@/utils/changeTitle.js'
import {GetCaptureDeviceList} from '@/api/ehrTas.js'
import {useRoute, useRouter} from 'vue-router'
import {AttentionMachineStore} from "@/store/attentionMachineStore";
import {Toast} from "vant";

export default {
  setup() {
    const Router = useRouter(); //获取到值
    const attentionMachineStore = AttentionMachineStore();
    const pageConfig = reactive({
      loading: false,
      finished: false,
      refreshing: false,
      attentionList: [],//考勤机器列表
    })
    const onLoad = () => {
      if (pageConfig.refreshing) {
        pageConfig.attentionList = [];
        Toast('刷新成功');
        pageConfig.refreshing = false;
      }
      //获取考勤机器列表
      GetCaptureDeviceList({}).then((res) => {
        if (res.success) {
          pageConfig.attentionList = res.data
        } else {
          Toast(res.msg)
        }
      })
      pageConfig.finished = true;
      ChangeTitle.setTitle("考勤机列表")
    };
    const onRefresh = () => {
      // 清空列表数据
      pageConfig.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      pageConfig.loading = true;
      onLoad();
    };
    //跳转页面
    const gotoMachine = (item) => {
      attentionMachineStore.setItem(item)
      Router.push({name: "Machine"})
    }
    return {
      onLoad,
      onRefresh,
      pageConfig,
      gotoMachine
    }
  }
}
</script>
<style lang="scss" scoped>
.van-pull-refresh {
  height: 100vh;
}

.my-van-cell {
  height: 70px;
  margin-top: 3px;
}

.right-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
}

img {
  max-width: 15%;
  height: auto;
  margin-left: 10px;
}

.description1 {
  position: absolute;
  left: 70px;
  top: 7px;
  margin-left: 20px;
  font-size: 12px;
  height: 60px;
}

.description3 {
  margin-top: 5px;
}
</style>
