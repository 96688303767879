/**
 *  新闻公告列表api接口
 *  作者：陶璇
 *  时间：2023-7-31
 */

import axios from "@/utils/request";

// 获取新闻列表
export function GetNewsList(data) {
    return axios({
        url: '/oa_ads/oa/ads/NewsList/GetNewsList',
        method: 'post',
        data
    })
}

// 获取新闻设计详情
export function GetNewsSection(data){
    return axios({
        url:'/oa_ads/oa/ads/AdsNewsSection/GetListView',
        method:'POST',
        data:data
    })
}