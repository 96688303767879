<!--疫情情况上传-->
<template>
  <div class="EpidemicSituation">
    <van-form class="form" ref="formRef">
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />工号
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.emp_code"
          :rules="[{ required: true, message: '请输入工号' }]"
          placeholder="请输入工号"
          name="工号"
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />姓名
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.emp_name"
          :rules="[{ required: true, message: '请输入姓名' }]"
          placeholder="请输入姓名"
          name="姓名"
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />采集日期
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.collection_date"
          name="采集日期"
          is-link
          readonly
          placeholder="请选择采集日期"
          :rules="[{ required: true, message: '请选择采集日期' }]"
          @click="pageConfig.showCollectionDate = true"
        />
        <van-popup
          v-model:show="pageConfig.showCollectionDate"
          position="bottom"
          round
        >
          <van-datetime-picker
            v-model="pageConfig.collection_date_picker"
            @confirm="methods.collectionDateSelect"
            @cancel="pageConfig.showCollectionDate = false"
            type="date"
            title="选择采集日期"
          />
        </van-popup>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />健康码类别
        </span>
        <!-- <van-field
          style="padding-top: 0"
          v-model="pageConfig.code_type_name"
          name="健康码类别"
          placeholder="请选择健康码类别"
          :rules="[{ required: true, message: '请选择健康码类别' }]"
          is-link
          readonly
          @click="pageConfig.showCodeTypePop = true"
        />        
        <van-popup
          v-model:show="pageConfig.showCodeTypePop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.healthCodeTypeList"
            @confirm="methods.healthCodeTypeSelect"
            @cancel="pageConfig.showCodeTypePop = false"
          >
          </van-picker>
        </van-popup> -->
        <div style="margin: 10px 20px">
          <van-radio-group
            v-model="pageConfig.code_type"
            direction="horizontal"
          >
            <van-radio
              :name="item.value"
              :key="item.value"
              v-for="item in pageConfig.healthCodeTypeList"
              >{{ item.text }}</van-radio
            >
          </van-radio-group>
        </div>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />健康码图片
        </span>
        <div class="upload-file">
          <van-uploader
            :after-read="methods.uploadHealthFile"
            :rules="[{ required: true, message: '请上传健康码图片' }]"
            v-model="pageConfig.healthCodeList"
            multiple
            :max-count="1"
          />
        </div>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />行程卡图片
        </span>
        <div class="upload-file">
          <van-uploader
            :after-read="methods.uploadTripFile"
            :rules="[{ required: true, message: '请上传行程卡图片' }]"
            multiple
            :max-count="1"
            v-model="pageConfig.TripCardList"
          />
        </div>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="star" />核酸报告图片
        </span>
        <div class="upload-file">
          <van-uploader
            :after-read="methods.uploadNucleicAcidFile"
            :rules="[{ required: true, message: '请上传核酸报告图片' }]"
            multiple
            :max-count="1"
            v-model="pageConfig.nucleicAcidList"
          />
        </div>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" />其他附件
        </span>
        <div class="upload-file">
          <van-uploader
            :after-read="methods.uploadOtherFile"
            multiple
            v-model="pageConfig.otherFileList"
          />
        </div>
      </van-cell-group>
    </van-form>
    <div class="bottom" style="z-index: 100">
      <van-button
        type="primary"
        block
        style="border-radius: 10px; margin-top: 1vh"
        @click="methods.uploadSubmit"
        >提交
      </van-button>
      <van-overlay :show="pageConfig.loadding" style="z-index: 9999999999"
        ><van-loading type="spinner" color="#1989fa" />
      </van-overlay>
      <van-overlay :show="pageConfig.submitSuccess" style="z-index: 9999999999"
        ><SubmitSuccess></SubmitSuccess>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import moment from 'moment'
import { UploadBase64,GetDictList } from '@/api/oaApi.js'
import { useRoute } from 'vue-router'
import { Toast, Dialog } from 'vant'
import { UploadNucleicAcidReport } from '@/api/ehr_emp.js'
import { GetEmpDeptInfo } from '@/api/ehr_emp.js' //emp的接口
import { onMounted } from '@vue/runtime-core'
import SubmitSuccess from '../../../components/submitSuccess.vue'

export default {
  components: { SubmitSuccess },
  setup () {
    const route = useRoute()
    //昨天时间
    const yesterday = new Date()
    yesterday.setTime(yesterday.getTime()-24*60*60*1000);
    //昨天日期
    const yesterdayDate =
      yesterday.getFullYear() +
      '/' +
      (yesterday.getMonth() + 1) +
      '/' +
      yesterday.getDate()
    //表单验证
    const formRef = ref('')
    //页面参数
    const pageConfig = reactive({
      loadding: false,
      submitSuccess:false,
      tenant_id: route.params.tenantId ?? localStorage.getItem("tenant_id"),
      emp_code: '',//工号
      emp_name: '',//姓名
      code_type:'1',//健康码类别
      code_type_name:'',//健康码类别
      collection_date: '',//采集日期
      healthCodeList: [],//健康码图片
      TripCardList: [],//行程卡图片
      nucleicAcidList: [],//核酸报告图片
      otherFileList: [],//其他附件列表
      healthCodeTypeList: [{text:'绿码',value:'1'},{text:'黄码',value:'2'},{text:'红码',value:'3'},{text:'弹框',value:'4'},{text:'无码',value:'5'}],//健康码类别
      showCollectionDate: false,//显示采集日期控件
      collection_date_picker: new Date(yesterdayDate),//采集日期控件值
      collection_start_date_picker:new Date(yesterdayDate),//采集日期控件最小值
      showCodeTypePop:false,//码别弹框
    })
    //内置函数
    const methods = {
      //初始化
      init: function () {
        if (!localStorage.getItem('tenant_id')) {
          localStorage.setItem("tenant_id",pageConfig.tenant_id)
        }
        //默认采集日期昨天
        pageConfig.collection_date = methods.convertDate(yesterdayDate)
        pageConfig.emp_code = localStorage.getItem("emp_code")
        pageConfig.emp_name = localStorage.getItem("emp_name")
        pageConfig.emp_id = localStorage.getItem("emp_id")
        //获取健康码类别
        //methods.getHealthyCodeType()
      },
      //获取健康码类别
      getHealthyCodeType: function () { 
        pageConfig.healthCodeTypeList = []
        GetDictList('emp_health_code_type').then((res) => {
          if (res.code != 200) {
            Toast(res.msg)
            return
          }
          res.data.forEach((item) => {
            pageConfig.healthCodeTypeList.push({
              text: item.dict_label,
              value: item.dict_value,
            })
          })
        })
      },
      //选中健康码类别
      healthCodeTypeSelect: function (item) { 
        pageConfig.code_type = item.value
        pageConfig.code_type_name=item.text
        pageConfig.showCodeTypePop = false
      },
      //采集日期选择
      collectionDateSelect: function (item) {
        pageConfig.collection_date = methods.convertDate(item)
        pageConfig.showCollectionDate = false
      },
      //上传健康码图片
      uploadHealthFile: function (file) {
        pageConfig.loadding=true
        const fileArr = []
        fileArr.push({ base64: file.content.split(',')[1], file_name: file.file.name })
        UploadBase64(fileArr, "emp").then((res) => {
          pageConfig.loadding=false
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          file.id = res.data[0].id
        })
      },
      //上传行程卡图片
      uploadTripFile: function (file) {
        pageConfig.loadding=true
        const fileArr = []
        fileArr.push({ base64: file.content.split(',')[1], file_name: file.file.name })
        UploadBase64(fileArr, "emp").then((res) => {
          pageConfig.loadding=false
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          file.id = res.data[0].id
        })
      },
      //上传核酸报告图片
      uploadNucleicAcidFile: function (file) {
        pageConfig.loadding=true
        const fileArr = []
        fileArr.push({ base64: file.content.split(',')[1], file_name: file.file.name })
        UploadBase64(fileArr, "emp").then((res) => {
          pageConfig.loadding=false
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          file.id = res.data[0].id
        })
      },
      //其他附件
      uploadOtherFile: function (file) {
        pageConfig.loadding=true
        const fileArr = []
        //多文件
        if (file.length && file.length > 1) {
          file.forEach((item) => {
            fileArr.push({ base64: item.content.split(',')[1], file_name: item.file.name })
          })
        } else {
          fileArr.push({ base64: file.content.split(',')[1], file_name: file.file.name })
        }
        UploadBase64(fileArr, "wfs").then((res) => {
          pageConfig.loadding=false
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          //多文件上传
          if (file.length && file.length > 1) {
            res.data.forEach((item) => {
              file.forEach((ifile) => {
                if (item.file_name == ifile.file.name) {
                  ifile.id = item.id
                  return
                }
              })
            })
          } else {
            file.id = res.data[0].id
          }
        })
      },
      //日期转换
      convertDate: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD')
        }
      },
      //提交上传
      uploadSubmit: function () {
        console.log(pageConfig)
        formRef.value.validate().then((res) => {
          if (pageConfig.healthCodeList.length != 1 || !pageConfig.healthCodeList[0].id ) {
            Toast("请上传健康码")
            return
          }
          if (pageConfig.TripCardList.length != 1 || !pageConfig.TripCardList[0].id ) {
            Toast("请上传行程卡")
            return
          }
          if (pageConfig.nucleicAcidList.length != 1 || !pageConfig.nucleicAcidList[0].id) {
            Toast("请上传核酸报告")
            return
          }
          Dialog.confirm({ title: '提交', message: '是否确认提交' }).then(
            () => {
              let fileId = []
              pageConfig.otherFileList.forEach(item => {
                fileId.push(item.id)
              });
              //提交参数
              let param = {
                emp_code: pageConfig.emp_code,//工号
                emp_name: pageConfig.emp_name,//姓名
                collection_date: pageConfig.collection_date,//采集日期
                code_type:pageConfig.code_type,//码别代码
                health_id: pageConfig.healthCodeList[0].id,//健康码id
                trip_id: pageConfig.TripCardList[0].id,//行程卡id
                nucleic_acid_id: pageConfig.nucleicAcidList[0].id,//核酸报告id
                other_attachment_id: fileId.join(','),//其他附件id
                tenant_id: pageConfig.tenant_id,//租户id
              }
              pageConfig.loadding=true
              UploadNucleicAcidReport(param).then(res => {                
              pageConfig.loadding=false
                if (res.code != 200) {
                  Toast(res.msg)
                  return
                }
                Toast("提交成功")
                pageConfig.submitSuccess=true
                //window.location.href = "/SubmitSuccess"
                
              })
            })
        })
      },
    }
    onMounted(() => {
      methods.init()
    })
    return {
      formRef,
      pageConfig,
      methods
    }
  }
}
</script>
<style lang="scss">
.EpidemicSituation {
  position: relative;
  .form {
    padding-bottom: 50px;
    height: calc(100vh - 110px);
    overflow: auto;

    .lable_icon {
      color: red;
      font-size: 4px;
      transform: scale(0.65);
    }
  }

  .van-loading {
    text-align: center;
    position: absolute;
    z-index: 9999999;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    border-radius: 10px;
    left: calc(50% - 40px);
    top: 25vh;
  }
  .property_head {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    line-height: 30px;

    .property_head_text {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      text-align: center;
    }

    .property_head_btn {
      position: absolute;
      top: 2px;
      right: 12px;
      font-size: 12px;
      color: #576b95;
    }

    .property_head_cancle {
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 12px;
      color: #969799;
    }
  }

  .upload-file {
    background-color: #fff;
    padding: 5px 10px;
    margin: 5px auto;
    overflow-x: auto;

    .van-uploader__wrapper {
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: inherit;
    }

    .van-uploader__wrapper::-webkit-scrollbar {
      display: none;
    }
  }

  .bottom {
    height: 50px;
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}
</style>