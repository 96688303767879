// 将时间转换为格子
import moment from "moment";
import {storeToRefs} from "pinia";
import {OrderMeetingStore} from "@/views/Ads/OrderMeeting/store";
const orderMeetingStore = OrderMeetingStore()

const {facilityMap} = storeToRefs(orderMeetingStore)
// 将格子转换为时间
export const getGridToTime = (startGrid,endGrid) => {
    const startTime = moment("07:00","HH:mm")
    const endTime = moment("23:00","HH:mm")
    const startHour = startTime.add(startGrid * 30,"minutes").format("HH:mm")
    const endHour = endTime.add(endGrid * 30,"minutes").format("HH:mm")
    return {
        startHour,
        endHour
    }
}
// 将时间转换为格子
export const getTimeToGrid = (time) => {
    let number = 0
    const hour = moment(time).format("HH")
    const minute = moment(time).format("mm")
    // 时间超过30分钟加2个格子
    if (Number(minute) > 30) number = (Number(hour) - 7) * 2 + 2
    else if (Number(minute) === 0) number = (Number(hour) - 7) * 2
    else number = (Number(hour) - 7) * 2 + 1

    return number
}

// 返回一个start,end之间的数组
export const getArray = (start,end) => {
    const arr = []
    for (let i = start + 1; i <= end; i++) {
        arr.push(i)
    }
    return arr
}

export const getFacilityName = (facility) => {
    let name = ""
    if (facility.length === 0 || facility === null) return "暂无相关设备"
    if(Object.getOwnPropertyNames(facilityMap.value).length === 0) return ""
    facility.split(",").forEach(item => {
        name += facilityMap.value[item] + " / "
    })
    // 去掉最后一个斜杠
    name = name.substring(0, name.length - 2)
    return name === 'undefined' ? '' : name
}