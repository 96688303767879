<!-- 线上学习首页 -->
<template>
  <div class="tms-home">
    <div class="section-block">
      <section>
        <div class="title">{{ pageConfig.menu_name }}</div>
        <div class="content">
          <div
            class="item"
            v-for="item in pageConfig.dataList"
            :key="item.id"
            @click="methods.openLinkRoute(item.path)"
          >
            <div class="item-img">
              <!-- <van-icon name="play-circle-o" /> -->
              <img v-if="item.file_id" :src="compute(item)" />
              <img v-else src="../assets/defaultMenu.svg" />
            </div>
            <div class="item-name">{{ item.menu_name }}</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script >
import router from "@/router";
import { onMounted, reactive, computed } from "vue";
import ChangeTitle from '@/utils/changeTitle.js'
import { useRoute } from 'vue-router';
import { GetEmpMenuListByParent } from '@/api/mobile.js'
import { Toast } from 'vant';
import axios from "axios";
export default {

  setup() {
    const compute = computed(() => {
      return (row) => {
          for (let i = 0; i < pageConfig.fileBase64.length; i++) {
              if ( pageConfig.fileBase64[i].id == row.file_id ) {
                  return  'data:image/svg+xml;base64,'+pageConfig.fileBase64[i].base64
                } 
              }
      
      }
    })
    const route = useRoute()
    const pageConfig = reactive({
      dataList: null,
      fileBase64: '',
      menu_name: null,
    })
    //内置方法

    const methods = {
      DownloadBase64(data) {
        axios
          .post("/oa_portal/oa/portal/Common/DownloadBase64", data, {
            headers: {
              Authorization: localStorage.getItem("Token"),
              tenant_id: localStorage.getItem("tenant_id"),
            },
          })
          .then((res) => {

            pageConfig.fileBase64 = res.data.data;
          });
      },
      //打开路由
      openLinkRoute: function (url) {
        if (url) {
          router.push({ path: url });
        }
      },
      async getMenu () {
        pageConfig.menu_name = route.query.menu_name
        ChangeTitle.setTitle(pageConfig.menu_name)
        const res = await GetEmpMenuListByParent({ parentId: route.query.parent_id })
        if (res.code == 200) {
          pageConfig.dataList = res.data
          let list = [];
          for (const item of pageConfig.dataList) {
            list.push(item.file_id);
          }
          methods.DownloadBase64(list);
        } else {
          Toast(res.msg)
        }
      }
    }
    onMounted(async () => {
      methods.getMenu()
    })
    return {
      methods,
      pageConfig,
      compute
    }
  }
}
</script>
<style scoped lang="scss">
.tms-home {
  .section-block {
    section {
      border-radius: 5px;
      width: 90%;
      margin: 5px auto;
      background-color: #fff;
      padding: 10px 10px 20px 10px;

      .title {
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
      }

      .content {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        justify-content: flex-start;

        .item {
          flex: 1;
          width: 20%;
          max-width: 20%;
          min-width: 20%;
          height: 40px;
          padding: 0 0 10px 0;

          .item-img {
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            margin: auto;
            color: #fff;
            font-size: 18px;
            cursor: pointer;
          }

          .item-name {
            line-height: 22px;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>