import axios from '../utils/request.js'
export function DingLogin(data) {
  return axios({
    url: '/oa_portal/oa/portal/Account/DingLogin',
    method: 'POST',
    data,
  })
}
export function GetTenants() {
  return axios({
    url: '/oa_portal/oa/portal/Tenant/GetTenants',
    method: 'POST',
  })
}
// 获取刷新token
export function RefreshToken(RefreshToken) {
  return axios({
    url: `/oa_portal/oa/portal/Account/RefreshToken/${RefreshToken}`,
  })
}

export function GetEmpListByName(data) {
  return axios({
    url:
      '/oa_portal/oa/portal/common/GetEmployees?pageNo=1&pageSize=20&includeResign=false&name=' +
      data,
    method: 'GET',
  })
}
export function GetEmployees(data) {
  return axios({
    url: '/oa_portal/oa/portal/Common/GetEmployees',
    method: 'Get',
    params: data,
  })
}

// 上传文件
export function UploadFile(form) {
  return axios({
    url: `/oa_portal/oa/portal/Common/UploadFile?sub_system_code=wfs`,
    method: 'POST',
    data: form,
  })
}
// 上传文件base64
export function UploadBase64(data, code) {
  return axios({
    url: `/oa_portal/oa/portal/Common/UploadBase64?sub_system_code=` + code,
    method: 'POST',
    data: data,
  })
}
// 下载文件
export function DownloadFile(data) {
  return axios({
    url: `/oa_portal/oa/portal/Common/DownloadFile/` + data,
    method: 'GET',
  })
}
// 下载文件
export function DownloadBase64(data,waterMark) {
  if(waterMark == undefined || waterMark == null){
    waterMark=''
  }
  return axios({
    //url: `/oa_portal/oa/portal/Common/DownloadBase64?waterMark=`+waterMark,
    url: `/oa_portal/oa/portal/Common/DownloadBase64?waterMark=`+waterMark,
    method: 'POST',
    data: data,
  })
}

export function DownLoadFiles(data) {
  return axios({
    url: `/oa_portal/oa/portal/Common/DownloadFile/` + data.attachment_id,
    method: 'Get',
    data: data,
    params: null,
    responseType: 'blob',
  })
}

//获取课程分类
export function GetVideo(id) {
  return axios({
    url: `/oa_portal/oa/portal/vod/GetVideo/` + id,
    method: 'GET',
  })
}
// 获取文件信息
export function GetFileList(data) {
  return axios({
    url: `/oa_portal/oa/portal/Common/GetFileList`,
    method: 'POST',
    data: data,
  })
}
// 获取数据字典
export function GetDictList(data) {
  return axios({
    url: `/oa_portal/oa/portal/DictData/GetDicts/` + data,
    method: 'GET',
  })
}
// 获取系统参数
export function GetConfigByCode(data) {
  return axios({
    url: `/oa_portal/oa/portal/Config/GetConfigByCode`,
    method: 'GET',
    params: data,
  })
}
// 获取系统参数
export const getSystemConfig = (code) => {
  return axios({
    url: '/oa_portal/oa/portal/Config/GetConfigByCode?cfg_code=' + code,
    method: 'GET',
  })
}

// 获取环境参数数据
export function GetEnvironment() {
  return axios({
    url: `/oa_portal/oa/portal/Account/GetEnvironment`,
    method: 'GET',
  })
}
// 获人员钉钉部门列表
export function GetEmpDingDeptList(data) {
  return axios({
    url: `/oa_portal/oa/portal/ThirdParty/GetEmpDingDeptList`,
    method: 'GET',
    params: data,
  })
}
// 获取常用列表


// 获取部门及部门下的人员
export function GetEmpDeptTree(data) {
  return axios({
    url: `/oa_ads/oa/ads/Car/GetEmpDeptTree`,
    method: 'POST',
    params: data,
  })
}