<template>
    <van-nav-bar
      :title="title"
      :right-text="showRight?'退出登录':''"
      :left-arrow="showLeft?true:false"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
</template>

<script setup>
import { nextTick, onMounted, reactive,ref,toRefs } from "vue";
import { useRouter } from 'vue-router';
 
    const router = useRouter();
    const props=defineProps({
        fileList:{type:Array,default:()=>([])},
        title:"",
        routerName:"",
        showLeft:{default:true,type:Boolean},//显示左边后退路由
        showRight:{default:false,type:Boolean},//显示右边退出登录:供应商首页
    })
    const data=reactive({
    })
    const rules = reactive({
    })
    const {}=toRefs(data)
    onMounted(()=>{
    })
    //左侧按钮
    const onClickLeft=()=>{
      
      if(props.routerName){
        router.push({name:props.routerName,query:{isrouter:true}})
      }else{
        router.go(-1)
      }
    }

    //右侧按钮
    const onClickRight=()=>{
      document.cookie = `phone=''; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        router.push("/Ads/inlet")
    }
</script>

<script>
export default {
  name:'',
}
</script>
<style scoped lang='less'>

</style>