import * as dd from 'dingtalk-jsapi' //钉钉组件

export default {
  /**
   * @desc 更新title名称
   * @param func 函数
   */
  setTitle(title) {
    let ua = navigator.userAgent.toLowerCase()
    try {
      //钉钉
      if (dd.env.platform != 'notInDingTalk') {
        dd.biz.navigation.setTitle({
          title: title, //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {},
          onFail: function (err) {},
        })
      }
      //安卓
      else if (ua.indexOf('android') > -1 || ua.indexOf('Android') > -1) {
        document.title = title
      }
      //ios
      else if (ua.indexOf('Mac') > -1 || ua.indexOf('mac') > -1) {
        window.webkit.messageHandlers.tileMessage.postMessage(title)
      }
    } catch {
      document.title = title
    }
  },
  finishActivity() {
    let ua = navigator.userAgent.toLowerCase()
    //android终端
    let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
    //ios终端
    let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (dd.env.platform != 'notInDingTalk') {
      return 'dingding'
    }
    if (isAndroid) {
      return 'android'
    }
    if (isiOS) {
      return 'ios'
    }
  },
}
