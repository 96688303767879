import axios from '../utils/request.js'
//获取公司列表
export function GetmyCompanies(id){
    return axios({
        url:'/oa_ads/oa/ads/Dining/GetmyCompanies',
        method:'get'
    })   
}
// 获取数据字典
export function GetDictList(data) {
    return axios({
      url: `/oa_portal/oa/portal/DictData/GetDicts/` + data,
      method: 'GET',
    })
  }

//新增
export function Insert(data){
    return axios({
        url:'/oa_ads/oa/ads/Dining/Insert',
        method:'post',
        data
    })   
}
//修改
export function UpdateByID(data){
    return axios({
        url:'/oa_ads/oa/ads/Dining/UpdateByID',
        method:'post',
        data
    })   
}

//查询
export function GetMealCount(data) {
    return axios({
      url: '/oa_ads/oa/ads/Dining/GetMealCount',
      method: 'get',
      params: data
    })
  }
  