<script setup>
import {onMounted, reactive, watchEffect} from "vue";
import moment from "moment";
import FileListComp from "@/components/FileListComp"
import {DownLoadFiles, GetDictList, UploadBase64} from "@/api/oaApi";
import {ref} from "vue-demi";
import StaffPicker from "@/components/StaffPicker";
import {Toast} from "vant";
import {blobToBase64, getFileType, isImage} from "@/utils/utils";


const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  editItem: {
    type: Object,
    default: () => {
    }
  },
  // 是否是编辑状态
  isEdit: {
    type: Boolean,
    default: false
  },
  fileList: {
    type: Array,
    default: () => []
  },
  // 是否是添加子任务
  isAddChildTask: {
    type: Boolean,
    default: false
  },
  // 是否是详情状态
  idDetail: {
    type: Boolean,
    default: false
  },
  // 是否是进度状态
  isSchedule: {
    type: Boolean,
    default: false
  },
  // 是否是修改状态
  isXiuGai: {
    type: Boolean,
    default: false
  },
  // 是否是完成状态
  isFinish: {
    type: Boolean,
    default: false
  },
  // 是否是添加任务
  isAddTask: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['handleSubmit'])

const state = reactive({
  form: {
    task_type_name: "",
    assign_emp_name: "",
    assign_emp_id: "",
    responsible_emp_name: "",
    plant_time: "",
    plant_day: "",
    actual_time: "",
    plan_duration: "",
    actual_duration: "",
    remind_days: "",
    remind_interval: "",
    description: "",
    task_percent: "",
    files: [],
    remark: "",
    actual_start_time: "",
    charge_unit: "", // 专题
    charge_unit_name: "", // 专题名称 -- 用于显示
    priority_level: "", // 优先级
    priority_level_name: "", // 优先级名称 -- 用于显示
  }, // 保存表单数据
  taskTypeList: [], // 任务类型字典
  showTypePicker: false, // 是否显示任务类型选择器
  showPlanningStart: false, // 预计开始
  showPlanningEnd: false, // 预计结束
  showStaffPicker: false, // 显示选择员工
  showActualPicker: false, // 显示实际结束时间
  showTopicPicker: false, // 显示选择专题
  showLevelPicker: false, // 显示选择优先级
  pickerObject: {
    start: "", // 预计开始时间
    end: "", // 预计结束时间
    actual_end_time: "", // 实际结束时间
  },
  currentDate: new Date(),
  fileList: [], // 附件列表 上传时用
  taskLevelList: [
    {
      text: "高",
      value: "H"
    },
    {
      text: "中",
      value: "M"
    },
    {
      text: "低",
      value: "L"
    },
  ], // 优先级列表
})

const formRef = ref(null)

const onSubmit = () => {
  console.log('submit')
}


// 初始化Form数据
const initFormData = () => {
  state.form.plant_time = moment(state.form.plan_start_time).format('YYYY-MM-DD') + "  -  " + moment(state.form.plan_end_time).format('YYYY-MM-DD')
  // 计划天数
  state.form.plant_day = moment(state.form.plan_end_time).diff(moment(state.form.plan_start_time), 'days')
  const actual_start_time = state.form.actual_start_time ? moment(state.form.actual_start_time).format('YYYY-MM-DD') : ""
  let actual_end_time = state.form.actual_end_time ? moment(state.form.actual_end_time).format('YYYY-MM-DD') : ""
  if (props.isFinish) {
    actual_end_time = "请选择任务实际结束日期"
  }
  state.form.actual_time = actual_start_time + "  -  " + actual_end_time


  // 完成百分比
  if (state.form.task_percent <= 1) state.form.task_percent = state.form.task_percent * 100

  // 文件列表
  state.form.files = props.fileList.map(item => {
    return {
      file_id: item.file_id,
      file_name: item.file_name
    }
  })
  getFiles()
}
// 获取任务类型字典
const getDictTaskType = async () => {
  const res1 = await GetDictList("tcs_task_type")
  state.taskTypeList = res1.data.map(item => {
    return {
      text: item.dict_label,
      value: item.dict_value
    }
  })

  const res2 = await GetDictList("tcs_charge_unit")
  state.taskTopicList = res2.data.map(item => {
    return {
      text: item.dict_label,
      value: item.dict_value
    }
  })
}

// 确定选择任务类型
const onConfirmType = ({value}) => {
  state.form.task_type = value
  state.form.task_type_name = state.taskTypeList.find(item => item.value === value).text
  state.showTypePicker = false
}

// 确定选择专题
const onConfirmTopic = ({value}) => {
  console.log(value)
  state.form.charge_unit = value
  state.form.charge_unit_name = state.taskTopicList.find(item => item.value === value).text
  state.showTopicPicker = false
}

// 确定选择优先级
const onConfirmLevel = ({value}) => {
  state.form.priority_level = value
  state.form.priority_level_name = state.taskLevelList.find(item => item.value === value).text
  state.showLevelPicker = false
}

// 任务计划开始时间
const onConfirmPlanningStart = (val) => {
  const time = moment(val).format('YYYY-MM-DD')
  state.form.plan_start_time = time
  state.showPlanningStart = false

  if (state.form.plan_end_time) {
    const day = moment(state.form.plan_end_time).diff(moment(val), 'days')
    state.form.plant_day = day + 1
  }
}

// 任务计划结束时间
const onConfirmPlanningEnd = (val) => {
  const time = moment(val).format('YYYY-MM-DD')
  state.form.plan_end_time = time
  state.showPlanningEnd = false

  if (state.form.plan_start_time) {
    const day = moment(val).diff(moment(state.form.plan_start_time), 'days')
    state.form.plant_day = day + 1
  }
}

// 初始化添加子任务数据
const initAddChildData = () => {
  getDictTaskType()
  state.form.assign_emp_name = localStorage.getItem("emp_code") + "-" + localStorage.getItem("emp_name")
  state.form.assign_emp_id = localStorage.getItem("emp_id")
  //初始化时间
  state.pickerObject.start = state.currentDate
  state.pickerObject.end = state.currentDate
}

// 外部触发校验
const handleSubmitForm = () => {
  formRef.value.submit()
}

// 验证预期提醒
const validateDays = () => {
  if (state.form.plant_day === "" || state.form.remind_days === "") return true
  if (Number(state.form.remind_days) > Number(state.form.plant_day)) {
    return "预期提醒天数不能大于计划天数"
  }
}

// 验证提醒间隔
const validateInterval = () => {
  if (state.form.remind_interval === "") return true
  if (Number(state.form.remind_interval) > Number(state.form.remind_days)) {
    return "提醒间隔天数不能大于提醒天数"
  }
}

// 验证计划工时
const validateDuration = () => {
  if (state.form.plan_duration === "") return "请输入计划工时"
}
// 关闭选择员工
const closeStaffPicker = (e) => {
  state.showStaffPicker = false
  if (e) {
    state.form.responsible_emp_id = e.user_id
    state.form.responsible_emp_name = e.emp_code + "-" + e.emp_name
  }
}
// 实际结束日期
const onConfirmActual = (val) => {
  const time = moment(val).format('YYYY-MM-DD')
  const actual_start_time = state.form.actual_start_time ? moment(state.form.actual_start_time).format('YYYY-MM-DD') : ""
  state.form.actual_time = actual_start_time + "  -  " + time
  state.form.actual_end_time = time
  state.showActualPicker = false
}

const validateActualTime = () => {
  if (!props.isSchedule && (props.isFinish && state.form.actual_end_time === "" || state.form.actual_end_time === null)) return "请选择任务实际结束日期"
  if (props.isSchedule && (state.form.task_percent === 100 && (state.pickerObject.actual_end_time === "" || state.pickerObject.actual_end_time === null))) return "任务进度为100%时请选择任务实际结束日期"
}
const handleShowActualPicker = () => {
  if (state.form.task_percent === 100 || props.isFinish) state.showActualPicker = true
}

const uploadFile = (file) => {
  const fileArr = []
  //多文件
  if (file.length && file.length > 1) {
    file.forEach((item) => {
      fileArr.push({
        base64: item.content.split(',')[1],
        file_name: item.file.name,
      })
    })
  } else {
    fileArr.push({
      base64: file.content.split(',')[1],
      file_name: file.file.name,
    })
  }
  UploadBase64(fileArr, 'wfs').then((res) => {
    console.log(state, "state")

    if (res.code === 200) {
      state.form.files.push({
        file_id: res.data[0].id,
        file_name: res.data[0].file_name,
      })
    } else {
      Toast.fail(res.msg)
    }
  })
}

// 获取附件  目前需求 编辑状态的时候 需要把图片下载下来
const getFiles = async () => {
  state.fileList = []
  for (const item of props.fileList) {
    // 如果是图片就把他下下来
    let content
    if (isImage(item.file_name)) {
      const res = await DownLoadFiles({attachment_id: item.id})
      content = await blobToBase64(res)
    }
    if (content) state.fileList.push({
      file: {
        name: item.file_name,
        id: item.file_id,
        type: getFileType(item.file_name),
      },
      content,
    })
    else {
      state.fileList.push({
        file: {
          name: item.file_name,
          id: item.file_id,
          type: getFileType(item.file_name),
        },
      })
    }
  }

  // 不用下载图片
  // state.fileList = props.fileList.map(item => {
  //   return {
  //     file: {
  //       name: item.file_name,
  //       id: item.file_id,
  //       type: getFileType(item.file_name),
  //     },
  //   }
  // })

}

// 显示专题
const handleShowTopicPicker = () => {
  if (props.idDetail) return
  state.showTopicPicker = true
}

// 显示优先级
const handleShowLevelPicker = () => {
  if (props.idDetail) return
  state.showLevelPicker = true
}

// 删除附件时触发
const deleteFile = (file) => {
  state.form.files = state.form.files.filter(item => item.file_id !== file.file.id)
}

// 初始化修改的数据
const initXiuGaiData = () => {
  // 格式化开始时间
  state.form.plan_start_time = moment(state.form.plan_start_time).format('YYYY-MM-DD')
  state.form.plan_end_time = moment(state.form.plan_end_time).format('YYYY-MM-DD')
}

const handleShowTypePicker = () => {
  if (props.idDetail) return
  state.showTypePicker = true
}

const handleShowStaffPicker = () => {
  if (props.idDetail) return
  state.showStaffPicker = true
}

onMounted(() => {
  if (props.item) state.form = JSON.parse(JSON.stringify(props.item))
  if (props.idDetail) initFormData()
  if (props.isSchedule || props.isXiuGai || props.isFinish) {
    state.form = JSON.parse(JSON.stringify(props.editItem))
    initFormData()
  }
  // 当处于新增子任务时 带出指派人信息
  if (props.isAddChildTask) initAddChildData()

  // 当处于修改时
  if (props.isXiuGai) initXiuGaiData()
})

watchEffect(() => {
  if (state.form.task_percent === 100) state.form.actual_time = moment(state.form.actual_start_time).format('YYYY-MM-DD') + "  -  请选择实际结束日期"
  // else state.form.actual_time = moment(state.form.actual_start_time).format('YYYY-MM-DD') + "  -  " + moment(state.form.actual_end_time).format('YYYY-MM-DD')
})


defineExpose({
  handleSubmitForm
})

</script>

<template>
  <van-form @submit="emit('handleSubmit',state.form)" ref="formRef" :class="[isXiuGai || isEdit ? 'isEdit' : '']">
    <van-cell-group v-if="((isEdit && !isSchedule) || isXiuGai) && !isFinish">
      <van-field
          v-model="state.form.title"
          name="task_type_name"
          label="任务标题"
          :required="isEdit"
          :placeholder="!isEdit ? '' :'请输入任务标题'"
          :readonly="!isEdit"
          :rules="[{ required: true, message: '请输入任务标题' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.task_type_name"
          name="task_type_name"
          label="任务类型"
          :required="isEdit"
          :placeholder="!isEdit ? '' :'请选择任务类型'"
          @click="handleShowTypePicker"
          readonly
          :rules="[{ required: true, message: '请选择任务类型' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.charge_unit_name"
          name="charge_unit_name"
          label="专题"
          :placeholder="!isEdit ? '' :'请选择专题'"
          @click="handleShowTopicPicker"
          readonly
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          :required="isEdit"
          v-model="state.form.assign_emp_name"
          name="assign_emp_name"
          label="指派人"
          readonly
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          :required="isEdit"
          v-model="state.form.responsible_emp_name"
          name="responsible_emp_name"
          label="负责人"
          :placeholder="!isEdit ? '' :'选择或输入负责人'"
          @click="handleShowStaffPicker"
          readonly
          :rules="[{ required: true, message: '请选择或输入负责人' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.priority_level_name"
          name="task_level_name"
          label="优先级"
          :required="isEdit"
          :placeholder="!isEdit ? '' :'请选择优先级'"
          @click="handleShowLevelPicker"
          readonly
          :rules="[{ required: true, message: '请选择优先级' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="!isAddChildTask && !isSchedule && !isXiuGai && !isFinish">
      <van-field
          v-model="state.form.plant_time"
          name="plant_time"
          label="任务规划"
          readonly
      />
    </van-cell-group>
    <van-cell-group v-if="(isAddChildTask && !isSchedule) || isXiuGai && !isFinish">
      <van-field
          v-model="state.form.plan_start_time"
          name="plant_time"
          label="预计开始"
          :required="isEdit"
          placeholder="请选择预计开始时间"
          readonly
          @click="state.showPlanningStart = true"
          :rules="[{ required: true, message: '请选择预计开始时间' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="(isAddChildTask && !isSchedule)|| isXiuGai">
      <van-field
          v-model="state.form.plan_end_time"
          name="plant_time"
          label="截至日期"
          :required="isEdit"
          placeholder="请选择截至日期"
          readonly
          @click="state.showPlanningEnd = true"
          :rules="[{ required: true, message: '请选择截至日期' }]"
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.plant_day"
          name="plant_day"
          label="计划天数"
          readonly
      />
    </van-cell-group>
    <van-cell-group v-if="(!isAddChildTask && !isXiuGai && state.form.task_percent === 100) || isFinish">
      <van-field
          v-model="state.form.actual_time"
          name="actual_time"
          label="任务实际"
          :placeholder="!isEdit ? '' :'请选择时间'"
          readonly
          :required="isFinish || state.form.task_percent === 100"
          :rules="[{ validator: validateActualTime }]"
          @click="handleShowActualPicker"
      />
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.plan_duration"
          name="plan_duration"
          label="计划工时"
          :required="isEdit"
          type="digit"
          :placeholder="!isEdit ? '' :'请输入计划工时'"
          :readonly="!isEdit"
          :rules="[{ validator: validateDuration }]"
      />
    </van-cell-group>
    <van-cell-group v-if="(isAddChildTask && !isSchedule) || isXiuGai">
      <van-field
          v-model="state.form.remind_days"
          name="plan_duration"
          label="预期提醒"
          placeholder="请输入预期提醒"
          type="digit"
          :rules="[{ validator: validateDays }]"
      />
    </van-cell-group>
    <van-cell-group v-if="(isAddChildTask && !isSchedule) || isXiuGai">
      <van-field
          v-model="state.form.remind_interval"
          name="plan_duration"
          label="提醒间隔"
          type="digit"
          placeholder="请输入提醒间隔"
          :rules="[{ validator: validateInterval }]"
      />
    </van-cell-group>
    <van-cell-group v-if="(!isAddChildTask && isSchedule) || isFinish">
      <van-field
          v-model="state.form.actual_duration"
          name="actual_duration"
          label="实际工时"
          type="digit"
          :placeholder="!isEdit ? '' :'请输入实际工时'"
          :readonly="!isEdit"
      />
    </van-cell-group>
    <van-cell-group v-if="!isAddChildTask && isSchedule">
      <van-field
          name="task_percent"
          label="完成百分比"
          :readonly="!isEdit"
      >
        <template #input>
          <div class="slider">
            <van-slider v-model="state.form.task_percent" :readonly="!isEdit">
              <template #button>
                <div class="custom-button">{{ state.form.task_percent }}</div>
              </template>
            </van-slider>
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <van-cell-group v-if="!isSchedule && !isFinish">
      <van-field
          v-model="state.form.description"
          name="description"
          label="任务描述"
          :placeholder="!isEdit ? '' :'请输入任务描述'"
          :readonly="!isEdit"
          autosize
          rows="1"
          type="textarea"
      />
    </van-cell-group>
    <van-cell-group v-if="( isAddTask || isXiuGai || idDetail || isAddChildTask)  && !isSchedule">
      <van-field
          v-model="state.form.remark"
          name="remark"
          label="备注"
          :placeholder="!isEdit ? '' :'请输入备注'"
          :readonly="!isEdit"
          autosize
          rows="1"
          type="textarea"
      />
    </van-cell-group>
    <van-cell-group v-if="(!isAddChildTask && !isSchedule && !isXiuGai) || state.form.task_percent === 100">
      <van-field
          v-model="state.form.complete_remark"
          autosize
          rows="1"
          type="textarea"
          name="complete_remark"
          label="完成简介"
          :readonly="!isEdit"
          :placeholder="!isEdit ? '' :'请输入完成简介'"
      />
    </van-cell-group>
    <van-cell-group v-if="(!isAddChildTask && isSchedule) || idDetail || isXiuGai">
      <van-field
          autosize
          name="附件"
          label="附件"
      >
        <template #input>
          <FileListComp :fileList="fileList" v-if="fileList.length > 0 && (!isAddChildTask && !isSchedule && !isXiuGai)"
                        :isTask="true"/>
          <div class="uploadFile" v-if="isSchedule || isXiuGai">
            <FileListComp :fileList="state.form.fileList" v-if="state.form.fileList" :isTask="true"/>
            <van-uploader @delete="deleteFile"
                          accept :after-read="uploadFile" v-model="state.fileList" upload-icon="plus"/>
          </div>
        </template>
      </van-field>
    </van-cell-group>
  </van-form>

  <!--  选择器-->
  <van-popup
      v-model:show="state.showTypePicker" position="bottom">
    <van-picker
        title="任务类型"
        show-toolbar
        :columns="state.taskTypeList"
        @cancel="state.showTypePicker = false"
        @confirm="onConfirmType"
    />
  </van-popup>

  <van-popup
      v-model:show="state.showTopicPicker" position="bottom">
    <van-picker
        title="专题"
        show-toolbar
        :columns="state.taskTopicList"
        @cancel="state.showTopicPicker = false"
        @confirm="onConfirmTopic"
    />
  </van-popup>

  <van-popup
      v-model:show="state.showLevelPicker" position="bottom">
    <van-picker
        title="优先级"
        show-toolbar
        :columns="state.taskLevelList"
        @cancel="state.showLevelPicker = false"
        @confirm="onConfirmLevel"
    />
  </van-popup>

  <van-popup
      v-model:show="state.showPlanningStart" position="bottom">
    <van-datetime-picker
        v-model="state.pickerObject.start"
        type="date"
        title="预计开始"
        :min-date="state.currentDate"
        @cancel="state.showPlanningStart = false"
        @confirm="onConfirmPlanningStart"
    />
  </van-popup>
  <van-popup
      v-model:show="state.showPlanningEnd" position="bottom">
    <van-datetime-picker
        v-model="state.pickerObject.end"
        type="date"
        title="截至日期"
        :min-date="state.currentDate"
        @cancel="state.showPlanningEnd = false"
        @confirm="onConfirmPlanningEnd"
    />
  </van-popup>
  <van-popup
      v-model:show="state.showActualPicker" position="bottom">
    <van-datetime-picker
        v-model="state.pickerObject.actual_end_time"
        type="date"
        title="结束日期"
        :min-date="state.currentDate"
        @cancel="state.showActualPicker = false"
        @confirm="onConfirmActual"
    />
  </van-popup>
  <StaffPicker :showStaffPicker="state.showStaffPicker" @closeStaffPicker="closeStaffPicker"/>

</template>

<style scoped lang="scss">
:deep(.van-cell) {
  flex-direction: column;
}

.custom-button {
  width: 26px;
  color: #fff !important;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: var(--van-primary-color);
  border-radius: 100px;
  -webkit-text-fill-color: #fff;
}

.slider {
  width: 100%;
  padding: 0 10px;
}

:deep( .van-cell--required ) {
  &:before {
    content: ''
  }

  .van-cell__title.van-field__label {
    span {
      position: relative;

      &:before {
        position: absolute;
        right: -8px;
        color: #ee0a24;
        font-size: 14px;
        content: '*';
      }
    }
  }
}

.uploadFile {
  :deep(.van-uploader__preview-delete-icon) {
    -webkit-text-fill-color: #fff !important;
  }
}

</style>