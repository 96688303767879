/**
 * 首页数据数据存储仓库
 * 作者：陶璇
 * 时间：2023-8-2
 */


import {defineStore} from "pinia";

export const HomeStore = defineStore('HomeStore', {
    state: () => {
        return {
            addedList: [], //收藏列表
            addBase64: [], // 收藏列表的图标
            menuList: [],// 菜单列表
            menuBase64: [],//菜单列表的图标
            bannerList: [],//存轮播图
        }
    },

    actions: {
        // 清除收藏列表 -- 当改变排序 或 添加、删除收藏时
        clearAddedList() {
            this.addedList = []
        },
        // 设置收藏列表
        setAddedList(list) {
            this.addedList = list
        },
        // 设置收藏列表的图标
        setAddBase64(list) {
            this.addBase64 = list
        },
        // 设置菜单列表
        setMenuList(list) {
            this.menuList = list
        },
        // 设置菜单列表的图标
        setMenuBase64(list) {
            this.menuBase64 = list
        },
        // 设置轮播图
        setBannerList(list) {
            this.bannerList = list
        },

    }
})