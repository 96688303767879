import axios from '../utils/request.js'

//获取表单列表
export function GetFlowFormTypeDetail() {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetFlowFormTypeDetail',
    method: 'GET',
  })
}
//获取字典
export function GetDictData(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetDictData',
    method: 'GET',
    params: data,
  })
}
//预览审批流程进度
export function FlowChartPreview(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/FlowChartPreview',
    method: 'GET',
    params: data,
  })
}
//待办、已审批列表
export function DealtWithList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/DealtWithList',
    method: 'POST',
    data: data,
  })
}
//待办、已审批条数
export function DealtWithCount(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/DealtWithCount',
    method: 'GET',
    params: data,
  })
}
//获取实例当前审批人列表
export function GetCurrentApprovalFormtoList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetCurrentApprovalFormtoList',
    method: 'GET',
    params: data,
  })
}
//提交审批表单
export function SubmitApprovalForm(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/SubmitApprovalForm',
    method: 'POST',
    data: data,
  })
}

//获取审批实例
export function GetInstance(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetInstance',
    method: 'GET',
    params: data,
  })
}
//提交审批
export function SubmitApproval(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/SubmitApproval',
    method: 'POST',
    data: data,
  })
}
//评论
export function AddComment(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Comment/AddComment',
    method: 'POST',
    data: data,
  })
}
//获取评论信息
export function GetComment(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Comment/GetComment',
    method: 'GET',
    params: data,
  })
}
//获取回退节点列表
export function GetReturnStatusList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetReturnStatusList',
    method: 'GET',
    params: data,
  })
}
//催办
export function UrgeNotice(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/UrgeNotice',
    method: 'POST',
    data: data,
  })
}
//获取实例最新处理人节点信息
export function GetNewestFormto(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetNewestFormto',
    method: 'GET',
    params: data,
  })
}
//获取表单业务数据
export function GetFormColumnData(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetFormColumnData',
    method: 'GET',
    params: data,
  })
}
//获取自选审批人节点
export function GetCounterSignStatus(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetCounterSignStatus',
    method: 'GET',
    params: data,
  })
}
//获取表单业务数据
export function GetFormData(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetFormData',
    method: 'GET',
    params: data,
  })
}
//获取原始链接地址
export function GetSourceUrl(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/ShortUrl/GetSourceUrl',
    method: 'GET',
    params: data,
  })
}
//获取审批修改表单的数据源
export function GetApprovalUpdateFormDataSource(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetApprovalUpdateFormDataSource',
    method: 'Get',
    params: data,
  })
}
//获取表单子集数据
export function GetFormChildrenColumnData(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetFormChildrenColumnData',
    method: 'Get',
    params: data,
    timeout:99999
  })
}
//修改钉钉待办状态
export function UpdateDingTodoStatus(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/UpdateDingTodoStatus',
    method: 'Post',
    data: data,
  })
}
//   获取表单页面数据
export function GetFormColumn(formId) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetFormColumn?formId=' + formId,
    method: 'GET',
  })
}
//批量审批
export function BatchApproval(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/BatchApproval',
    method: 'POST',
    data: data,
    timeout: 99999,
  })
}
//审批详情
export function GetFormDetailList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetFormDetailList',
    method: 'POST',
    data: data,
  })
}
export function GetFormExcessiveOvertimeDetailList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetFormExcessiveOvertimeDetailList',
    method: 'POST',
    data: data,
  })
}


// 表单数据校验
export function CheckFormColumn(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/FormDataVerification',
    method: 'POST',
    data
  })
}
/*三方接口下拉*/
export function GetOptionData(code,empId) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetOptionData?code=' + code+"&applyEmpId="+empId,
    method: 'GET',
  })
}
//获取所有自选审批人节点
export function GetFormCounterSignStatus(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetFormCounterSignStatus',
    method: 'GET',
    params: data,
  })
}
//获取流程节点
export function GetStatusByFormtoId(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/Approval/GetStatusByFormtoId',
    method: 'Get',
    params: data,
  })
}


// 测试网络并发
export function TestNetwork() {
  return axios({
    url: '/oa_wfs/oa/wfs/TestDemo/TestNetwork',
    method: 'GET',
  })
}

//会计科目列表
export function GetCoaList(companyId) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetCoaList?companyId=' + companyId,
    method: 'Get',
  })
}
//获取成本中心列表
export function GetCostCenterList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetCostCenterList',
    method: 'Get',
    params:data
  })
}
//获取内部订单列表
export function GetInternalOrderList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetInternalOrderList',
    method: 'Get',
    params:data
  })
}
//获取公司信息
export function GetUserCompany(companyId) {
  if(!companyId){
    companyId=''
  }
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetUserCompany?companyId='+companyId,
    method: 'Get',
  })
}
//获取公司列表
export function GetCompanyList() {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetCompanyList',
    method: 'Get',
  })
}
//获取供应商列表
export function GetVendorList(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetVendorList',
    method: 'Get',
    params:data
  })
}
//获取应付账款未清项列表
export function GetAccountPayable(data) {
  return axios({
    url: '/oa_wfs/oa/wfs/FormSetting/GetAccountPayable',
    method: 'Get',
    params:data
  })
}