import axios from '../utils/request.js'

export function GetEmpMenuList() {
    return axios({
        url: `/oa_mobile/oa/mobile/Menu/GetEmpMenuList`,
        method: 'Get',
    })
}

export function GetEmpMenuListByParent(data) {
    return axios({
        url: `/oa_mobile/oa/mobile/Menu/GetEmpMenuListByParent`,
        method: 'Get',
        params: data,
    })
}

//获取移动端文件地址
export function GetMobileFileUrl(fileId) {
    return axios({
        url: '/oa_mobile/oa/mobile/Common/GetMobileFileUrl?fileId=' + fileId,
        method: 'Get',
    })
}

// 获取收藏列表
export function GetFavoriteList() {
    return axios({
        url: '/oa_mobile/oa/mobile/Favorite/GetFavoriteList',
        method: 'post',
        type: "json",
        data: {},
    })
}
// 添加收藏
export function insertFavorite(menu_id) {
    return axios({
        url: '/oa_mobile/oa/mobile/Favorite/InsertFavorite',
        method: 'post',
        data: {
            menu_id
        },
        type: "json"
    })
}

// 改变收藏顺序
export function changeFavoriteSort(data) {
    return axios({
        url: '/oa_mobile/oa/mobile/Favorite/SortFavorite',
        method: 'post',
        data,
        type: "json"
    })
}

// 取消收藏
export function cancelFavorite(id) {
    return axios({
        url: '/oa_mobile/oa/mobile/Favorite/Uncollect',
        method: 'post',
        data: {
            id
        },
        type: "json"
    })
}
// 获取首页数据

export function GetHomeData(data) {

    return axios({
        url: '/oa_mobile/oa/mobile/HomePage/HomePageData',
        method: 'POST',
        data: data,
    })
}