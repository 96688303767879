export const dataItemConfig = {
    title: 'client_name',
    showImg: true,
    imgUrl:require("@/assets/client.svg"),
    data: [{
        label: 'mac地址1',
        name: 'mac_address1'
    },{
        label: 'mac地址2',
        name: 'mac_address2'
    },{
        label: '资源类型',
        name: 'play_mode',
        slot: true
    }]
}
