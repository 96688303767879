<!--
  通用富文本页面
  作者：陶璇
  时间：2023-7-12
-->
<script setup>
import {nextTick, onMounted, reactive} from "vue";
import ChangeTitle from "@/utils/changeTitle";
import {useRoute, useRouter} from "vue-router";
import {onUnmounted} from "@vue/runtime-core";
import FileView from "@/components/fileView.vue";
import {AnnouncementStore} from "@/store/announcementStore";

const announcementStore = AnnouncementStore()
const route = useRoute()
const router = useRouter()
const state = reactive({
  richText: '', // 富文本内容
  fileIds: '',//文件内容
  bgColor: '#fff', // 背景颜色
})

// 从本地获取富文本内容
const getRichText = () => {

  // state.richText = JSON.parse(localStorage.getItem('richText'))
  state.richText = JSON.parse(announcementStore.item.richText)

  state.fileIds = announcementStore.item.fileIds



  nextTick(() => {
    // 设置背景色

    const imgs = document.querySelectorAll('.richBox img')
    imgs.forEach(item => {
      item.parentNode.style.textIndent = '0px'
    })
  })
}
onUnmounted(() => {
  // localStorage.removeItem("richText")
  // localStorage.removeItem("fileIds")
});
onMounted(() => {
  getRichText()

  ChangeTitle.setTitle('详细内容')
  // 获取背景色
  route.query.bgColor && (state.bgColor = route.query.bgColor)
})
</script>

<template>
  <div class="richBox" :style="{backgroundColor:state.bgColor}">
    <div v-html="state.richText"></div>
    <FileView
        :file_id="state.fileIds"
        v-if="state.fileIds"
        :style="{backgroundColor:state.bgColor}"
    />

  </div>

</template>

<style scoped lang="scss">
.richBox {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;

  :deep(img) {
    max-width: calc(100vw - 20px) !important;
  }
}
</style>