import axios from '../utils/request.js'
//获取公司列表
export function GetCompanies(id) {
  return axios({
    url: '/oa_ads/oa/ads/Common/GetCompanies',
    method: 'get',
  })
}
// 获取数据字典
export function GetDictList(data) {
  return axios({
    url: `/oa_portal/oa/portal/DictData/GetDicts/` + data,
    method: 'GET',
  })
}

//新增
export function Insert(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/Add',
    method: 'post',
    data,
  })
}

//查询
export function GetList(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/GetList',
    method: 'post',
    data: data,
  })
}

//获取待点检项
export function GetWaitCheckData(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/GetWaitCheckData',
    method: 'get',
    params: data,
  })
}

//获取已点检项
export function GetCheckedDetails(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/GetCheckedDetails',
    method: 'get',
    params: data,
  })
}

//验证用户权限
export function ValidateUser(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/ValidateUser',
    method: 'get',
    params: data,
  })
}

//查询
export function GetEntity(data) {
  return axios({
    url: '/oa_cks/oa/cks/InstanceCheck/GetReviewInfo',
    method: 'get',
    params: data,
  })
}
