<template>
  <div class="allotApplication">
    <van-form disabled>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >调拨资产</span
        >
        <!-- 资产list -->
        <van-list>
          <van-cell v-for="item in asset_list" :key="item.id">
            <!-- 自定义单元格的标题显示list -->
            <template #title>
              <div style="display: flex; text-align: center">
                <div style="flex: 1">{{ item.asset_name }}</div>
                <div style="flex: 1">{{ item.asset_code }}</div>
                <div style="flex: 1">{{ item.asset_eng_name }}</div>
              </div>
            </template>
          </van-cell>
        </van-list>
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >调拨编号</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.series_num" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >申请人</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.apply_emp" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >申请部门</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.apply_dept" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >申请时间</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.apply_time" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >接收人</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.accept_emp" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >接收部门</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.accept_dept" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >申请原因</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.remark" />
      </van-cell-group>
    </van-form>
  </div>
</template>
<style lang="scss">
.allotApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
}
</style>
<script>
import { GetAssetAllotApply,GetAllotAsset } from '@/api/ifseam.js' //
import { reactive,ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import moment from "moment";
export default {
  props: ['businessId'],
  setup(props) {
    //资产列表
    const asset_list = ref([]) 
    //页面配置
    let pageConfig = reactive({
      series_num:'',//调拨编号
      apply_time:'',//申请时间
      apply_emp:'',//申请人
      accept_emp:'',//接收人
      apply_dept:'',//申请部门
      accept_dept:'',//接收部门
      remark:'',//备注
    })
    let methods = {
      //加载调拨信息
      LoadAssetAllotApply: function () {
        GetAssetAllotApply({ allotId: props.businessId }).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          res.data = res.data[0]
          pageConfig.apply_emp =res.data.apply_emp_name+'（'+res.data.apply_emp_code+'）'
          pageConfig.apply_dept = res.data.apply_dept_name+'（'+res.data.apply_dept_code+'）'
          pageConfig.apply_time = methods.convertTime(res.data.apply_time)
          pageConfig.series_num = res.data.series_num
          pageConfig.remark = res.data.apply_reason //备注
          pageConfig.accept_emp = res.data.accept_emp_name+'（'+res.data.accept_emp_code+'）'
          pageConfig.accept_dept = res.data.accept_dept_name+'（'+res.data.accept_dept_code+'）'
        })
      },
      //资产列表
      LoadAssetList:function(){
        GetAllotAsset({applyid:props.businessId}).then(res=>{
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          asset_list.value = res.data
        })
      },      
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD HH:mm:ss')
        }
      },
    }
    onMounted(()=>{
      //获取资产列表
      methods.LoadAssetList()
      //加载申请记录
      methods.LoadAssetAllotApply()
    })
    return {
      asset_list,
      pageConfig,
      methods,
    }
  },
}
</script>
