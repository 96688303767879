/**
 * 考勤机信息
 * 作者：周俊峰
 * 时间：2023-8-2
 */
import {defineStore} from "pinia";
export const AttentionMachineStore = defineStore('AttentionMachineStore', {
    state: () => {
        return {
            item:{}
        }
    },
    actions: {
        setItem(item) {
            this.item = item
        },
    }
})
