<!--出差查询-->
<template>
  <div class="travelApplication">
    <van-form disabled>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请人
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_emp_info"
          name="申请人"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          出差类型
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.travel_type"
          name="出差类型"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请时间
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_time"
          name="申请时间"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          开始时间
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.start_time"
          name="开始时间"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          结束时间
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.end_time"
          name="结束时间"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          出差时数
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.travel_hours"
          name="出差时数"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="leave_reason" />申请原因
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_reason"
          name="申请原因"
          disabled
        />
      </van-cell-group>
    </van-form>
  </div>
</template>
<style lang="scss">
.travelApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
}
</style>
<script>
import { reactive, toRefs } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { Toast } from 'vant';
import {GetTravelById} from '@/api/ehrTas.js'
import moment from 'moment'
export default {
  props: ['businessId'],
  setup(props) {
    //页面配置
    const pageConfig = reactive({
      apply_emp_name:'',//申请人姓名
      apply_emp_code:'',//申请人工号
      apply_emp_info:'',//申请人信息
      travel_type:'',//出差类型
      start_time:'',//开始时间
      end_time:'',//结束时间
      apply_reason:'',//申请原因
      travel_hours:0,//出差小时
    })
    //内置方法
    const methods = {
      //获取出差申请
      getTravelApply:function(){
        let param = {
          id:props.businessId//出差申请id
        }
        GetTravelById(param).then(res=>{
          if(res.code != 200)
          {
            Toast(res.msg)
            return
          }
          pageConfig.apply_emp_code = res.data[0].apply_emp_code//申请人工号
          pageConfig.apply_emp_name = res.data[0].apply_emp_name//申请人姓名
          pageConfig.apply_emp_info = res.data[0].apply_emp_code+'-'+res.data[0].apply_emp_name
          pageConfig.travel_type = res.data[0].travel_type//出差类型
          pageConfig.start_time = methods.convertTime(res.data[0].start_time) //开始时间
          pageConfig.end_time = methods.convertTime(res.data[0].end_time)//结束时间
          pageConfig.apply_time = methods.convertTime(res.data[0].apply_time)//申请时间
          pageConfig.apply_reason = res.data[0].apply_reason//申请原因
          pageConfig.travel_hours = res.data[0].travel_hours//出差小时
        })
      },      
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD HH:mm:ss')
        }
      },
    }
    onMounted(()=>{
      //获取出差申请
      methods.getTravelApply()
    })
    return{
      pageConfig,
      methods
    }
  }
}
</script>
<style scoped>
.van-field__control {
  -webkit-text-fill-color: black !important;
}
</style>