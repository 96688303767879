<!--提交表单-->
<template>
  <div class="ApplyForm">
    <!-- 头部提交人 -->
    <div class="head">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div
              class="head_content"
              style="display: flex; justify-content: space-between"
            >
              <div class="left" style="display: flex; margin-top: 0.9vh">
                <span style="margin-right: 1vh">填单人</span>
                <!-- <div class="left_man" style="display: flex">
                  <div class="left_man_img">
                    <div
                      style="
                        color: white;
                        font-size: 8px;
                        transform: scale(0.8);
                      "
                    >
                      {{ state.userName }}
                    </div>
                  </div>
                  <span>你好</span>
                </div> -->
                {{ state.empCode }}-{{ state.userName }}
              </div>
              <div class="others-submit">
                <van-button
                 style="font-size: 12px;"
                  type="default"
                  size="mini"
                  @click="methods.otherSubmit"
                  >代他人提交</van-button
                >
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <van-popup
      v-model:show="state.showEmpSelect"
      position="bottom"
      round
      :style="{ height: '80%', overflow: 'hidden' }"
    >
      <emp-select @onSelectEmp="methods.onSelectEmp"></emp-select>
    </van-popup>
    <!-- 动态加载组件 -->
  
    <component
      v-if="state.hasConfig"
      :is="formComponent.get('formCname')"
      :formCode="state.comCode"
      :applyEmp="state.applyEmp"
      ref="roaming"
    />
    <!-- 动态渲染表单 -->
    <FormApply
      v-else-if="!state.hasConfig && state.applyEmp.emp_id"
      :formCode="state.comCode"
      :formId="state.formId"
      :applyEmp="state.applyEmp"
      :verifyCode="state.verifyCode"
      ref="roaming"
    ></FormApply>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { reactive, ref,defineAsyncComponent } from 'vue'
import componentsJSon from './components.json'//组件的json
import EmpSelect from '@/components/Emp/empSelect.vue'
import { onMounted } from '@vue/runtime-core'
import ChangeTitle from '@/utils/changeTitle.js'
import FormApply from './components/formApply.vue'
export default {
  setup () {
    //表单组件
    const formComponent = ref(new Map())
    const roaming = ref(null)
    const route = useRoute()
    let state = reactive({
      comCode: route.params.formCode,//后端获取的组件code
      formId:route.params.formId,//表单id
      formName:route.params.formName,//表单名称
      verifyCode: route.query.verifyCode ?? "",//后端获取的校验码
      userName: "",//当前登陆人
      empCode: "",//当前登录人工号
      showEmpSelect: false,//显示员工选择器
      hasConfig:false,//是否显示动态组件
      applyEmp: {
        emp_code: '',
        emp_name: '',
        emp_id: ''
      },
    })
    const methods = {
      //待人提交
      otherSubmit: function () {
        state.showEmpSelect = true
      },
      //选中员工
      onSelectEmp: function (item) {
        state.showEmpSelect = false
        state.applyEmp = item
        item.id=item.user_id
        roaming.value.methods.changeApplyEmp(item)
      }
    }
    onMounted(() => {
      state.userName = localStorage.getItem('emp_name')
      state.empCode = localStorage.getItem('emp_code')
      state.applyEmp.emp_name = localStorage.getItem('emp_name')
      state.applyEmp.emp_id = localStorage.getItem('emp_id')
      state.applyEmp.emp_code = localStorage.getItem('emp_code')
      let componentName = ''
      componentsJSon.forEach(item => {
        //匹配code
        if (item.code == state.comCode) {
          componentName = item.cname//组件名称
          formComponent.value.set('formCname', defineAsyncComponent(() => import(item.form_url + "")))
          state.hasConfig = true
          return
        }
      });
      ChangeTitle.setTitle(state.formName)
    })
    return {
      methods,
      state,
      roaming,
      formComponent
    }
  },
  components: {
    'emp-select': EmpSelect,
    FormApply
  }
}
</script>
<style lang="scss">
.ApplyForm {
  .head {
    //头部提交人
    .head_content {
      position: relative;

      .left_man_img {
        border-radius: 3px;
        height: 20px;
        background-color: #3394fb;
        text-align: center;
        line-height: 21px;
        margin-right: 5px;
        margin-top: 1px;

        span {
          color: white;
          font-size: 8px;
          transform: scale(0.5);
          
        }
      }

      .others-submit {
        position: absolute;
        right: 12px;
        top: 0px;
      }
    }
  }
}
</style>