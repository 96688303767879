<template>
  <div class="Payment">
    <van-form ref="formRef">
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />申请人
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_emp_info"
          name="申请人"
          disabled
        />
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />部门
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.dept_name"
          name="部门"
          disabled
        />
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />请款公司
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.payment_company_name"
          name="请款公司"
          readonly
          placeholder="请选择请款公司"
          :rules="[{ required: true, message: '请选择请款公司' }]"
          @click="pageConfig.showPaymentCompanyPop = true"
        />
        <van-popup
          v-model:show="pageConfig.showPaymentCompanyPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.companyList"
            @confirm="methods.paymentCompanySelect"
            @cancel="pageConfig.showPaymentCompanyPop = false"
          >
          </van-picker>
        </van-popup>

        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />供应商
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.vendor_name"
          name="供应商"
          readonly
          placeholder="请选择供应商"
          :rules="[{ required: true, message: '请选择供应商' }]"
          @click="pageConfig.showVendorPop = true"
        />
        <van-popup
          v-model:show="pageConfig.showVendorPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.vendorList"
            @confirm="methods.vendorChange"
            @cancel="pageConfig.showVendorPop = false"
          >
            <!-- 部门选择器自定义标题头 -->
            <template #title>
              <van-search
                v-model="pageConfig.vendorCenter"
                placeholder="请输入供应商名称/代码"
                @update:model-value="methods.getVendorList"
              />
            </template>
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />请款币别
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.currency_code"
          name="请款币别"
          readonly
          placeholder="请选择请款币别"
          :rules="[{ required: true, message: '请选择请款币别' }]"
          @click="pageConfig.showCurrencyPop = true"
        />
        <van-popup
          v-model:show="pageConfig.showCurrencyPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.currencyList"
            @confirm="methods.currencySelect"
            @cancel="pageConfig.showCurrencyPop = false"
          >
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />请款金额
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.amount"
          placeholder="请输入请款金额"
          @blur="methods.priceToFixed('amount')"
          name="请款金额"
          :rules="[{ required: true, message: '请输入请款金额' }]"
        />
        <span class="textFontSize">
          <van-icon class="lable_icon" />是否选择未清项
        </span>
        <div style="margin: 20px">
          <van-switch
            v-model="pageConfig.isAccountPayable"
            @change="methods.isAccountPayableChange()"
          />
        </div>
        <div v-if="pageConfig.isAccountPayable">
          <span class="textFontSize">
            <van-icon class="lable_icon" />选择未清项
          </span>
          <div style="margin: 20px">
            <van-button
              type="primary"
              size="small"
              @click="methods.selectAccountPayable"
              >选择应付账款</van-button
            >
          </div>
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" />凭证号
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.document_number"
            :disabled="pageConfig.isAccountPayable"
            name="凭证号"
            placeholder="请输入凭证号"
          />
          <div class="boxContent"></div>
        </div>
        <span class="textFontSize">
          <van-icon class="lable_icon" />电子发票号
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.table_detail.einvoice_number"
          name="电子发票号"
          placeholder="请输入电子发票号"
        />
        <div class="boxContent"></div>
        <div v-if="pageConfig.isAccountPayable">
          <span class="textFontSize">
            <van-icon class="lable_icon" />总账科目代码
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.gl_account_code"
            :disabled="pageConfig.isAccountPayable"
            name="总账科目代码"
            placeholder="请输入总账科目代码"
          />
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" name="star" />总账科目名称
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.gl_account_name"
            :disabled="pageConfig.isAccountPayable"
            name="总账科目名称"
            placeholder="请输入总账科目名称"
          />
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" name="star" />凭证金额
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.document_currency_amount"
            :disabled="pageConfig.isAccountPayable"
            name="凭证金额"
            placeholder="请输入凭证金额"
            :rules="[{ required: true, message: '请输入凭证金额' }]"
          />
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" />本币币别
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.company_currency_code"
            :disabled="pageConfig.isAccountPayable"
            name="本币币别"
            readonly
            placeholder="请选择本币币别"
            :rules="[{ required: true, message: '请选择本币币别' }]"
          />
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" />本币金额
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.local_currency_amount"
            :disabled="pageConfig.isAccountPayable"
            name="本币金额"
            @blur="methods.priceToFixed('local_currency_amount')"
            readonly
            placeholder="请输入本币金额"
          />
          <div class="boxContent"></div>
        </div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />到期日期
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.table_detail.due_date"
          name="到期日期"
          readonly
          :disabled="pageConfig.isAccountPayable"
          placeholder="请选择到期日期"
          :rules="[{ required: true, message: '请选择到期日期' }]"
          @click="()=>{if(!pageConfig.isAccountPayable) {pageConfig.showDueDatePop = true}} "
        />
        <van-popup
          v-model:show="pageConfig.showDueDatePop"
          position="bottom"
          round
        >
          <van-datetime-picker
            v-model="pageConfig.due_date_picker_1"
            @confirm="methods.dueDateSelect"
            type="date"
            title="请选择到期日期"
          />
        </van-popup>
        <div v-if="pageConfig.isAccountPayable">
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" name="star" />分配号
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.table_detail.allocation_number"
            name="分配号"
            readonly
            :disabled="pageConfig.isAccountPayable"
            placeholder="请选择利润中心"
          />
        </div>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />利润中心
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.table_detail.profit_center_name"
          name="利润中心"
          readonly
          placeholder="请选择利润中心"
          :rules="[{ required: true, message: '请选择利润中心' }]"
          @click="()=>{if(!pageConfig.isAccountPayable || pageConfig.table_detail.profit_center_code == '') {pageConfig.showProfitCenterPop = true}} "
        />
        <van-popup
          v-model:show="pageConfig.showProfitCenterPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.profitCenterList"
            @confirm="methods.profitCenterSelect"
            @cancel="pageConfig.showProfitCenterPop = false"
          >
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />付款类型
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.payment_type_name"
          name="付款类型"
          readonly
          :disabled="pageConfig.isAccountPayable"
          placeholder="请选择付款类型"
          :rules="[{ required: true, message: '请选择付款类型' }]"
          @click="()=>{if(!pageConfig.isAccountPayable) {pageConfig.showPaymentTypePop = true}} "
        />
        <van-popup
          v-model:show="pageConfig.showPaymentTypePop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.paymentTypeList"
            @confirm="methods.paymentTypeSelect"
            @cancel="pageConfig.showPaymentTypePop = false"
          >
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />付款阶段
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.payment_phase_name"
          name="付款阶段"
          readonly
          placeholder="请选择付款阶段"
          :rules="[{ required: true, message: '请选择付款阶段' }]"
          @click="pageConfig.showPaymentPhasePop = true"
        />
        <van-popup
          v-model:show="pageConfig.showPaymentPhasePop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.paymentPhaseList"
            @confirm="methods.paymentPhaseSelect"
            @cancel="pageConfig.showPaymentPhasePop = false"
          >
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />付款方式
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.payment_method_name"
          name="付款方式"
          readonly
          placeholder="请选择付款方式"
          :rules="[{ required: true, message: '请选择付款方式' }]"
          @click="pageConfig.showPaymentMethodPop = true"
        />
        <van-popup
          v-model:show="pageConfig.showPaymentMethodPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.paymentMethodList"
            @confirm="methods.paymentMethodSelect"
            @cancel="pageConfig.showPaymentMethodPop = false"
          >
          </van-picker>
        </van-popup>
        <div v-if="pageConfig.payment_method_code == '99'">
          <div class="boxContent"></div>
          <span class="textFontSize">
            <van-icon class="lable_icon" name="star" />付款方式说明
          </span>
          <van-field
            style="padding-top: 0"
            v-model="pageConfig.payment_method_note"
            name="付款方式说明"
            placeholder="请输入付款方式说明"
            :rules="[{ required: true, message: '请输入付款方式说明' }]"
          />
        </div>
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />供应商银行账号
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.beneficiary_bank_account_number"
          name="供应商银行账号"
          placeholder="请输入供应商银行账号"
          :rules="[{ required: true, message: '请输入供应商银行账号' }]"
        />
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />供应商银行名称
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.beneficiary_bank_account_name"
          name="供应商银行名称"
          placeholder="请输入供应商银行名称"
          :rules="[{ required: true, message: '请输入供应商银行名称' }]"
        />
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />请款原因
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_reason"
          name="请款原因"
          placeholder="请输入请款原因"
          :rules="[{ required: true, message: '请输入请款原因' }]"
        />
        <div class="boxContent"></div>
        <span class="textFontSize">
          <van-icon class="lable_icon" name="star" />是否设备款
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.is_equipment_name"
          name="是否设备款"
          readonly
          placeholder="请选择是否设备款"
          :rules="[{ required: true, message: '请选择是否设备款' }]"
          @click="pageConfig.showIsEquipmentPop = true"
        />
        <van-popup
          v-model:show="pageConfig.showIsEquipmentPop"
          position="bottom"
          round
        >
          <van-picker
            :columns="pageConfig.isEquipmentList"
            @confirm="methods.isEquipmentSelect"
            @cancel="pageConfig.showIsEquipmentPop = false"
          >
          </van-picker>
        </van-popup>
        <div class="boxContent"></div>
        <CounterSign
          style="height: auto"
          :applyEmp="pageConfig.applyEmp"
          :required="true"
          :showLabel="item.status_name"
          @empTOListChange="
            (res) => {
              methods.empTOListChange(res, item.status_id)
            }
          "
          v-for="item in pageConfig.counterSignList"
          :key="item.status_id"
        ></CounterSign>
        <div class="boxContent"></div>
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          <van-icon class="lable_icon" name="上传附件" />上传附件
        </span>
        <div class="upload-file">
          <van-uploader
            :after-read="methods.uploadFile"
            multiple
            accept="*"
            v-model="pageConfig.fileList"
          />
        </div>
        <div class="boxContent"></div>
        <span class="textFontSize"> <van-icon class="lable_icon" />备注 </span>
        <van-field
          v-model="pageConfig.remark"
          placeholder="请输入备注..."
          type="textarea"
          rows="3"
          :show-word-limit="true"
          maxlength="200"
          name="备注"
        />
      </van-cell-group>
    </van-form>
    <van-overlay :show="pageConfig.loadding" style="z-index: 9999999999"
      ><van-loading type="spinner" color="#1989fa" />
    </van-overlay>
    <div class="bottom" style="z-index: 100">
      <van-button
        type="primary"
        block
        style="border-radius: 10px; margin-top: 1vh"
        @click="methods.applySubmit"
        >提交
      </van-button>
    </div>
    <!-- 未清项弹框 -->
    <van-popup
      v-model:show="pageConfig.showPaymentPop"
      position="bottom"
      style="height: 100%"
    >
      <PaymentPop
        v-if="pageConfig.showPaymentPop"
        :dataList="pageConfig.accountPayableList"
        @onclose="methods.closePaymentPop"
      ></PaymentPop>
    </van-popup>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive, onMounted, ref } from 'vue'
import { Toast, Dialog } from 'vant'
import PaymentPop from './paymentPop.vue'
import { GetEmpDeptInfo } from '@/api/ehr_emp.js' //emp的接口
import { UploadBase64 } from '@/api/oaApi.js'
import moment from 'moment'
import { SavePaymentForm } from '@/api/ifsexp.js'
import CounterSign from '@/components/Emp/counterSign.vue'
import {
  GetCompanyList,
  GetVendorList,
  GetDictData,
  GetUserCompany,
  GetAccountPayable,
  GetCounterSignStatus,
} from '@/api/oawfs.js' //wfs

export default {
  components: { PaymentPop,CounterSign },
  props: ['applyEmp', 'formCode'],
  setup(props) {
    const router = useRouter()
    //表单验证
    const formRef = ref('')
    //当前时间
    const nowTime = new Date()
    //当前日期
    const nowDate =
      nowTime.getFullYear() +
      '/' +
      (nowTime.getMonth() + 1) +
      '/' +
      nowTime.getDate()
    //页面参数配置
    const pageConfig = reactive({
      loadding: false, //加载效果
      vendorTimeOut: null, //供应商搜索延时器
      companyList: [], //公司列表
      vendorList: [], //供应商列表
      currencyList: [], //请款币别列表
      accountPayableList: [], //应付账款列表
      profitCenterList: [], //利润中心列表
      paymentTypeList: [], //付款类型列表
      isEquipmentList:[{text:'是',value:'Y'},{text:'否',value:'N'}],//是否设备款列表
      showPaymentCompanyPop: false, //请款公司弹窗
      showVendorPop: false, //供应商弹窗
      showCurrencyPop: false, //请款币别弹窗
      showDueDatePop: false, //到期日期弹窗
      showProfitCenterPop: false, //利润中心弹窗
      showPaymentTypePop: false, //付款类型弹窗
      showPaymentPhasePop: false, //付款阶段弹窗
      showPaymentMethodPop: false, //付款方式弹窗
      showIsEquipmentPop: false, //是否设备款弹窗
      due_date_picker_1: nowTime, //到期日期
      applyEmp: props.applyEmp, //申请人
      formCode: props.formCode, //表单代码
      company_id: '', //公司id
      company_code: '', //公司code
      apply_emp_info: '', //申请人信息
      apply_emp_id: '', //申请人id
      apply_emp_code: '', //申请人工号
      dept_name: '', //部门名称
      dept_id: '', //部门id
      payment_company_name: '', //请款公司名称
      payment_company_id: '', //请款公司id
      payment_company_code: '', //请款公司code
      vendor_id: '', //供应商id
      vendor_name: '', //供应商名称
      vendor_code: '', //供应商代码
      vendor_keyword: '', //供应商简称
      vendor_account_group: '', //供应商账户组
      currency_code: '', //请款币别代码
      currency_name: '', //请款币别名称
      amount: '', //请款金额
      isAccountPayable: false, //是否选择应付账款未清项
      showPaymentPop: false, //应付账款弹窗
      table_detail: {
        item_number: '001', //请款单行号
        document_number: null, //凭证号
        einvoice_number: null, //电子发票号
        gl_account_code: null, //总账科目代码
        gl_account_name: null, //总账科目名称
        document_currency_code: null, //凭证币别
        document_currency_amount: null, //凭证金额
        company_currency_code: null, //本币币别
        local_currency_amount: null, //本币金额
        due_date: null, //到期日期
        profit_center_code: null, //利润中心代码
        profit_center_name: null, //利润中心名称
        document_item_number: null, //凭证行号
        sub_memo: null, //说明
        posting_code: null, //记账码
        document_type: null, //凭证类型
        exchange_rate: null, //汇率
        special_gl_flag: null, //特别总账标识
        dc_flag: null, //借贷标识
        payment_amount: null, //请款金额
        payment_start_date: null, //付款起算日期
        payment_terms_code: null, //付款条件
        payment_method_code_item: null, //付款方式
        purchase_document_number: null, //采购凭证号
        tax_code: null, //税码
        tax_name: null, //税名
        document_company_code: null, //公司代码
        document_number: null, //凭证编号
        fiscal_year: null, //会计年度
        posting_date: null, //过账日期
        original_document_number: null, //原始凭证号
        original_fiscal_year: null, //原始凭证号年度
        original_document_currency_amount: null, //原始凭证金额
        original_document_item_number: null, //原始凭证行号
        original_company_code: null, //公司代码
        allocation_number: null, //分配号
      }, //付款明细
      payment_type_name: '', //请款类型名称
      payment_type: '', //请款类型
      payment_method_name: '', //付款方式名称
      payment_method_code: '', //付款方式代码
      payment_method_note: '', //付款方式说明
      beneficiary_bank_account_number: '', //供应商银行账号
      beneficiary_bank_account_name: '', //供应商银行名称
      document_currency_amount_total:0,//凭证金额合计
      apply_reason: '', //请款原因
      is_equipment_name:'',//是否设备款
      is_equipment:'',//是否设备款
      fileList: [], //附件列表
      remark: '', //备注
      counterSignList: [], //自选审批列表
    })

    //内置函数
    const methods = {
      //初始化
      init: function () {
        pageConfig.fill_emp_id = props.applyEmp.emp_id
        pageConfig.apply_emp_id = props.applyEmp.emp_id
        pageConfig.apply_emp_info =
          props.applyEmp.emp_code + '-' + props.applyEmp.emp_name
        pageConfig.apply_emp_code = props.applyEmp.emp_code
        //获取员工部门
        methods.getEmpDept()
        //获取公司信息
        methods.getUserCompany()
        //获取公司列表
        methods.getCompanyList()
        //获取币种列表
        methods.getCurrencyList()
        //获取利润中心列表
        methods.getProfitCenterList()
        //获取请款类型
        methods.getPaymentType()
        //获取付款条件
        methods.getPaymentPhase()
        //获取付款方式
        methods.getPaymentMethod()
        //获取自选审批列表
        methods.getCounterSignStatus()
      },
      //更新申请人
      changeApplyEmp: function (item) {
        pageConfig.apply_emp_id = item.id
        pageConfig.emp_code = item.emp_code
        pageConfig.apply_emp_code = item.emp_code
        pageConfig.apply_emp_info = item.emp_code + '-' + item.emp_name
        pageConfig.company_id = item.company_id
        pageConfig.beneficiary_name = item.emp_name
        //获取员工部门
        methods.getEmpDept()
        //获取公司信息
        methods.getUserCompany(pageConfig.company_id)
      },
      //获取员工部门
      getEmpDept: function () {
        GetEmpDeptInfo({ empId: pageConfig.apply_emp_id }).then((res) => {
          if (res.code == 200 && res.data) {
            pageConfig.dept_name = res.data.dept_code + '-' + res.data.dept_name
            pageConfig.dept_id = res.data.dept_id
          }
        })
      },
      //获取公司
      getUserCompany: function (companyId) {
        GetUserCompany(companyId).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              pageConfig.company_id = res.data.id
              pageConfig.company_code = res.data.company_code
            }
          }
        })
      },
      //获取公司列表
      getCompanyList: function () {
        pageConfig.companyList = []
        GetCompanyList().then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.companyList.push({
                text: item.company_name,
                code: item.company_code,
                value: item.id,
              })
            })
          }
        })
      },
      //请款公司选择
      paymentCompanySelect: function (item) {
        pageConfig.payment_company_name = item.text
        pageConfig.payment_company_id = item.value
        pageConfig.payment_company_code = item.code
        pageConfig.showPaymentCompanyPop = false
        //获取未清项列表
        methods.getAccountPayable()
      },
      //设备款选择
      isEquipmentSelect:function(item){        
        pageConfig.is_equipment_name = item.text
        pageConfig.is_equipment = item.value
        pageConfig.showIsEquipmentPop = false
      },
      //获取自选审批列表
      getCounterSignStatus: function () {
        GetCounterSignStatus({ formCode: 'FI0003' }).then((res) => {
          if (res.code == 200) {
            {
              pageConfig.counterSignList = res.data
            }
          }
        })
      },
      //获取供应商列表
      getVendorList: function (keyword) {
        if (keyword.length < 2) {
          return
        }
        if (pageConfig.vendorTimeOut) {
          clearTimeout(pageConfig.vendorTimeOut)
        }
        pageConfig.vendorTimeOut = setTimeout(() => {
          pageConfig.vendorList = []
          GetVendorList({ keywords: keyword }).then((res) => {
            if (res.code == 200) {
              let list = []
              res.data.forEach((item) => {
                list.push({
                  id: item.id,
                  text: item.vendor_code+'-'+item.vendor_name,
                  label: item.vendor_name,
                  vendor_keyword: item.keywords,
                  vendor_account_group:item.vendor_account_group,
                  value: item.vendor_code,
                })
              })
              pageConfig.vendorList = list
            }
          })
        }, 500)
      },
      //获取币种列表
      getCurrencyList: function () {
        pageConfig.currencyList = []
        GetDictData({ dict_type: 'wfs_currency' }).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.currencyList.push({
                text: item.dict_label,
                value: item.dict_value,
              })
            })
          }
        })
      },
      //获取利润中心列表
      getProfitCenterList: function () {
        pageConfig.profitCenterList = []
        GetDictData({ dict_type: 'org_profit_center' }).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.profitCenterList.push({
                text: item.dict_label,
                value: item.dict_value,
              })
            })
          }
        })
      },
      //获取付款类型
      getPaymentType: function () {
        pageConfig.paymentTypeList = []
        GetDictData({ dict_type: 'exp_payment_type' }).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.paymentTypeList.push({
                text: item.dict_label,
                value: item.dict_value,
              })
            })
          }
        })
      },
      //获取付款阶段
      getPaymentPhase: function () {
        pageConfig.paymentPhaseList = []
        GetDictData({ dict_type: 'exp_payment_phase' }).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.paymentPhaseList.push({
                text: item.dict_label,
                value: item.dict_value,
              })
            })
          }
        })
      },
      //获取付款方式
      getPaymentMethod: function () {
        pageConfig.paymentMethodList = []
        GetDictData({ dict_type: 'exp_payment_method' }).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              pageConfig.paymentMethodList.push({
                text: item.dict_label,
                value: item.dict_value,
              })
            })
          }
        })
      },
      //供应商改变
      vendorChange: function (item) {
        pageConfig.vendor_code = item.value
        pageConfig.vendor_name = item.label
        pageConfig.vendor_id = item.id
        pageConfig.vendor_keyword = item.vendor_keyword
        pageConfig.vendor_account_group = item.vendor_account_group
        pageConfig.showVendorPop = false
        //获取未清项列表
        methods.getAccountPayable()
      },
      //币种改变
      currencySelect: function (item) {
        pageConfig.currency_code = item.value
        pageConfig.currency_name = item.text
        pageConfig.showCurrencyPop = false
        //获取未清项列表
        methods.getAccountPayable()
      },
      //获取未清项列表
      getAccountPayable: function () {
        if (
          !pageConfig.payment_company_code ||
          !pageConfig.currency_code ||
          !pageConfig.vendor_code ||
          !pageConfig.isAccountPayable
        ) {
          return
        }
        pageConfig.accountPayableList = []
        GetAccountPayable({
          companyCode: pageConfig.payment_company_code,
          vendorCode: pageConfig.vendor_code,
        }).then((res) => {
          if (res.code == 200) {
            pageConfig.accountPayableList = res.data.filter(
              (x) => x.document_currency_code == pageConfig.currency_code
            )
          } else {
            Toast(res.msg)
          }
        })
      },
      //选择应付款未清项
      selectAccountPayable: function () {
        if (
          !pageConfig.payment_company_code ||
          !pageConfig.currency_code ||
          !pageConfig.vendor_code ||
          !pageConfig.isAccountPayable
        ) {
          Toast('请选择供应商、请款币别获取应付账款列表')
          return
        }
        pageConfig.showPaymentPop = true
      },
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY-MM-DD')
        }
      },
      //金额保留2位
      priceToFixed: function (columnCode) {
        pageConfig[columnCode] = Number(pageConfig[columnCode]).toFixed(2)
        if (columnCode == 'amount' && !pageConfig.isAccountPayable) {
          pageConfig.table_detail.document_currency_amount =
            pageConfig[columnCode]
        }
      },
      //点击是否选择应付款未清项
      isAccountPayableChange: function () {
        if (pageConfig.isAccountPayable) {
          pageConfig.amount = null
          methods.getAccountPayable()
        } else {
          pageConfig.table_detail.item_number = null //请款单行号
          pageConfig.table_detail.document_number = null //凭证号
          pageConfig.table_detail.einvoice_number = null //电子发票号
          pageConfig.table_detail.gl_account_code = null //总账科目代码
          pageConfig.table_detail.gl_account_name = null //总账科目名称
          pageConfig.table_detail.document_currency_code = null //凭证币别
          pageConfig.table_detail.document_currency_amount = null //凭证金额
          pageConfig.table_detail.company_currency_code = null //本币币别
          pageConfig.table_detail.local_currency_amount = null //本币金额
          pageConfig.table_detail.due_date = null //到期日期
          pageConfig.table_detail.profit_center_code = null //利润中心代码
          pageConfig.table_detail.profit_center_name = null //利润中心名称
          pageConfig.table_detail.document_item_number = null //凭证行号
          pageConfig.table_detail.sub_memo = null //说明
          pageConfig.table_detail.posting_code = null //记账码
          pageConfig.table_detail.document_type = null //凭证类型
          pageConfig.table_detail.exchange_rate = null //汇率
          pageConfig.table_detail.special_gl_flag = null //特别总账标识
          pageConfig.table_detail.dc_flag = null //借贷标识
          pageConfig.table_detail.payment_amount = null //请款金额
          pageConfig.table_detail.payment_start_date = null //付款起算日期
          pageConfig.table_detail.payment_terms_code = null //付款条件
          pageConfig.table_detail.payment_method_code_item = null //付款方式
          pageConfig.table_detail.purchase_document_number = null //采购凭证号
          pageConfig.table_detail.tax_code = null //税码
          pageConfig.table_detail.tax_name = null //税名
          pageConfig.table_detail.document_company_code = null //公司代码
          pageConfig.table_detail.document_number = null //凭证编号
          pageConfig.table_detail.fiscal_year = null //会计年度
          pageConfig.table_detail.posting_date = null //过账日期
          pageConfig.table_detail.original_document_number = null //原始凭证号
          pageConfig.table_detail.original_fiscal_year = null //原始凭证号年度
          pageConfig.table_detail.original_document_currency_amount = null //原始凭证金额
          pageConfig.table_detail.original_document_item_number = null //原始凭证行号
          pageConfig.table_detail.original_company_code = null //公司代码
          pageConfig.payment_type = null //付款方式
          pageConfig.payment_type_name = null //付款方式
          pageConfig.table_detail.allocation_number = null //分配号
          pageConfig.document_currency_amount_total = 0 //凭证总计金额
        }
      },
      //关闭弹框
      closePaymentPop: function (item) {
        if (item) {
          pageConfig.table_detail.item_number = item.item_number //请款单行号
          pageConfig.table_detail.document_number = item.document_number //凭证号
          pageConfig.table_detail.einvoice_number = item.einvoice_number //电子发票号
          pageConfig.table_detail.gl_account_code = item.gl_account_code //总账科目代码
          pageConfig.table_detail.gl_account_name = item.gl_account_name //总账科目名称
          pageConfig.table_detail.allocation_number = item.allocation_number //分配号
          pageConfig.table_detail.document_currency_code =
            item.document_currency_code //凭证币别
          pageConfig.table_detail.document_currency_amount =
            item.document_currency_amount //凭证金额
          pageConfig.table_detail.company_currency_code =
            item.company_currency_code //本币币别
          pageConfig.table_detail.local_currency_amount =
            item.local_currency_amount //本币金额
          pageConfig.table_detail.due_date = methods.convertTime(item.due_date) //到期日期
          pageConfig.table_detail.profit_center_code = item.profit_center_code //利润中心代码
          //利润中心名称
          pageConfig.profitCenterList.forEach((element) => {
            if (element.value == item.profit_center_code) {
              pageConfig.table_detail.profit_center_name = element.text
              return
            }
          })
          pageConfig.table_detail.document_item_number =
            item.document_item_number //凭证行号
          pageConfig.table_detail.sub_memo = item.sub_memo //说明
          pageConfig.table_detail.posting_code = item.posting_code //记账码
          pageConfig.table_detail.document_type = item.document_type //凭证类型
          pageConfig.table_detail.exchange_rate = item.exchange_rate //汇率
          pageConfig.table_detail.special_gl_flag = item.special_gl_flag //特别总账标识
          pageConfig.table_detail.dc_flag = item.dc_flag //借贷标识
          pageConfig.table_detail.payment_amount = item.payment_amount //请款金额
          pageConfig.table_detail.payment_start_date = methods.convertTime(
            item.payment_start_date
          ) //付款起算日期
          pageConfig.table_detail.payment_terms_code = item.payment_terms //付款条件
          pageConfig.table_detail.payment_method_code_item = item.payment_method //付款方式
          pageConfig.table_detail.purchase_document_number =
            item.purchase_document_number //采购凭证号
          pageConfig.table_detail.tax_code = item.tax_code //税码
          pageConfig.table_detail.tax_name = item.tax_name //税名
          pageConfig.table_detail.document_company_code =
            item.document_company_code //公司代码请款金额
          pageConfig.table_detail.document_number = item.document_number //凭证编号
          pageConfig.table_detail.fiscal_year = item.fiscal_year //会计年度
          pageConfig.table_detail.posting_date = methods.convertTime(
            item.posting_date
          ) //过账日期
          pageConfig.table_detail.original_document_number =
            item.original_document_number //原始凭证号
          pageConfig.table_detail.original_fiscal_year =
            item.original_fiscal_year //原始凭证号年度
          pageConfig.table_detail.original_document_currency_amount =
            Number(item.original_document_currency_amount) * -1 //原始凭证金额
          pageConfig.table_detail.original_document_item_number =
            item.original_document_item_number //原始凭证行号
          pageConfig.table_detail.original_company_code =
            item.original_company_code //公司代码
          pageConfig.amount = item.document_currency_amount //请款金额
          pageConfig.payment_type = item.payment_type //付款方式
          //付款方式名称
          pageConfig.paymentTypeList.forEach((element) => {
            if (element.value == item.payment_type) {
              pageConfig.payment_type_name = element.text
              return
            }
          })          
          pageConfig.document_currency_amount_total = item.document_currency_amount_total //凭证总计金额
        }
        pageConfig.showPaymentPop = false
      },
      //到期日期选择
      dueDateSelect: function (item) {
        pageConfig.table_detail.due_date = methods.convertTime(item)
        pageConfig.showDueDatePop = false
      },
      //利润中心选择
      profitCenterSelect: function (item) {
        pageConfig.table_detail.profit_center_name = item.text
        pageConfig.table_detail.profit_center_code = item.value
        pageConfig.showProfitCenterPop = false
      },
      //付款方式选择
      paymentTypeSelect: function (item) {
        pageConfig.payment_type_name = item.text
        pageConfig.payment_type = item.value
        pageConfig.showPaymentTypePop = false
      },
      //付款条件选择
      paymentTermsSelect: function (item) {
        pageConfig.payment_terms_name = item.text
        pageConfig.payment_terms_code = item.value
        pageConfig.showPaymentTermsPop = false
      },
      //付款阶段选择
      paymentPhaseSelect: function (item) {
        pageConfig.payment_phase_name = item.text
        pageConfig.payment_phase_code = item.value
        pageConfig.showPaymentPhasePop = false
      },
      //付款方式选择
      paymentMethodSelect: function (item) {
        pageConfig.payment_method_name = item.text
        pageConfig.payment_method_code = item.value
        pageConfig.showPaymentMethodPop = false
      },
      //自选人
      empTOListChange: function (item, id) {
        if (item) {
          pageConfig.counterSignList.forEach((element) => {
            if (element.status_id == id) {
              element.list = item
              return
            }
          })
        }
      },

      //获取自选审批参数
      getCountersignParam: function () {
        let param = []
        pageConfig.counterSignList.forEach((element) => {
          if (element.list && element.list.length > 0) {
            element.list.forEach((item, index) => {
              param.push({
                status_id: element.status_id,
                is_cc: 'N',
                handler_emp_id: item.user_id,
                sort_num: index + 1,
              })
            })
          }
        })
        return param
      },
      //上传文件
      uploadFile: function (file) {
        const fileArr = []
        //多文件
        if (file.length && file.length > 1) {
          file.forEach((item) => {
            fileArr.push({
              base64: item.content.split(',')[1],
              file_name: item.file.name,
            })
          })
        } else {
          fileArr.push({
            base64: file.content.split(',')[1],
            file_name: file.file.name,
          })
        }
        pageConfig.loadding = true
        UploadBase64(fileArr, 'wfs').then((res) => {
          pageConfig.loadding = false
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          //多文件上传
          if (file.length && file.length > 1) {
            res.data.forEach((item) => {
              file.forEach((ifile) => {
                if (item.file_name == ifile.file.name) {
                  ifile.id = item.id
                  return
                }
              })
            })
          } else {
            file.id = res.data[0].id
          }
        })
      },
      //验证申请
      submitVerify: function () {
        let res = true
        if (pageConfig.amount <= 0) {
          Toast('请款金额必须大于0')
          return false
        }
        if (
          pageConfig.isAccountPayable &&
          Number(pageConfig.amount) >
            Number(pageConfig.table_detail.document_currency_amount)
        ) {
          Toast('请款金额不能大于凭证金额')
          return false
        }
        return res
      },
      //获取提交参数
      getSubmitParam: function () {
        //上传附件
        let fileids = []
        if (pageConfig.fileList.length > 0) {
          pageConfig.fileList.forEach((element) => {
            fileids.push(element.id)
          })
        }
        let detail = []
        pageConfig.table_detail.item_number = '001'
        //手动填写
        if (!pageConfig.isAccountPayable) {
          //通过vendor_account_group判断：如果是Z100，选择预付时会计科目对应到1123100000  预付账款-关系人；非预付时对应到2202100000 应付账款-关系人
          //vendor_account_group不等于Z100时；选择预付时会计科目对应到1123200000 预付账款-非关系人；非预付时对应到2202200000	应付账款-非关系人
          //付款阶段=预付+Z100
          if (pageConfig.vendor_account_group == 'Z100' && pageConfig.payment_phase_code == '1') {
            pageConfig.table_detail.gl_account_code = '1123100000'
            pageConfig.table_detail.gl_account_name = '预付账款-关系人'
          } 
          //付款阶段=非预付+Z100
          else if(pageConfig.vendor_account_group == 'Z100' && pageConfig.payment_phase_code != '1'){
            pageConfig.table_detail.gl_account_code = '2202100000'
            pageConfig.table_detail.gl_account_name = '应付账款-关系人'
          }
          //付款阶段=预付+!Z100
          else if(pageConfig.vendor_account_group != 'Z100' && pageConfig.payment_phase_code == '1'){
            pageConfig.table_detail.gl_account_code = '1123200000'
            pageConfig.table_detail.gl_account_name = '预付账款-非关系人'
          }
          //付款阶段=非预付+!Z100
          else{
            pageConfig.table_detail.gl_account_code = '2202200000'
            pageConfig.table_detail.gl_account_name = '应付账款-非关系人'
          }
          pageConfig.table_detail.document_currency_code =
            pageConfig.currency_code //凭证币别
          pageConfig.table_detail.document_currency_amount = pageConfig.amount //凭证金额

          pageConfig.table_detail.company_currency_code =
            pageConfig.currency_code //本币币别
          pageConfig.table_detail.local_currency_amount = pageConfig.amount //本币金额
          pageConfig.table_detail.due_date = methods.convertTime(
            pageConfig.table_detail.due_date
          ) //到期日期
          pageConfig.table_detail.sub_memo =
            '支付' + pageConfig.vendor_keyword ??
            pageConfig.vendor_name + pageConfig.payment_type_name //行项目文本
          pageConfig.table_detail.sub_memo =
            pageConfig.table_detail.sub_memo.length > 25
              ? pageConfig.table_detail.sub_memo.slice(0, 24)
              : pageConfig.table_detail.sub_memo
        } else {
          pageConfig.table_detail.sub_memo = pageConfig.table_detail.sub_memo
            .replace('购入', '支付')
            .replace('PROC', 'PYM')
        }
        detail.push(pageConfig.table_detail)
        let param = {
          apply_dept_id: pageConfig.dept_id, //申请人部门id
          apply_company_id: pageConfig.company_id, //公司id
          apply_emp_id: pageConfig.apply_emp_id, //申请人id
          company_id: pageConfig.payment_company_id, //付款公司id
          company_code: pageConfig.payment_company_code, //付款公司代码
          fill_emp_id: pageConfig.fill_emp_id, //填单人id
          vendor_code: pageConfig.vendor_code, //供应商编码
          vendor_id: pageConfig.vendor_id, //供应商id
          vendor_name: pageConfig.vendor_name, //供应商名称
          currency_code: pageConfig.currency_code, //请款币种
          amount: pageConfig.amount, //请款金额
          payment_type: pageConfig.payment_type, //付款类型
          payment_phase_code: pageConfig.payment_phase_code, //付款阶段
          payment_method_code: pageConfig.payment_method_code, //付款方式
          payment_method_note: pageConfig.payment_method_note, //付款方式说明
          beneficiary_bank_account_number:
            pageConfig.beneficiary_bank_account_number, //供应商银行账号
          beneficiary_bank_account_name:
            pageConfig.beneficiary_bank_account_name, //供应商银行名称
          apply_reason: pageConfig.apply_reason, //请款原因
          is_equipment: pageConfig.is_equipment, //设备款
          attachment_id: fileids.join(), //附件id
          remark: pageConfig.remark, //备注
          isAccountPayable: pageConfig.isAccountPayable, //是否应付
          document_currency_amount_total:pageConfig.document_currency_amount_total, //凭证总计金额
          detail: detail,
          countersign: methods.getCountersignParam(), //自选
        }
        return param
      },
      //申请提交
      applySubmit: function () {
        formRef.value.validate().then(() => {
          //数据校验
          if (!methods.submitVerify()) {
            return
          }
          Dialog.confirm({ title: '提交', message: '是否确认提交' }).then(
            () => {
              pageConfig.loadding = true
              let param = methods.getSubmitParam()
              SavePaymentForm(param).then((res) => {
                if (res.code != 200) {
                  Dialog.alert({
                    title: '提交失败',
                    message: res.msg,
                  })
                  pageConfig.loadding = false
                  return
                }
                Toast.success('提交成功')
                setTimeout(function () {
                  router.push({ path: '/Todolist/3' })
                }, 1000)
              })
            }
          )
        })
      },
    }
    onMounted(() => {
      methods.init()
    })
    return {
      pageConfig,
      formRef,
      methods,
    }
  },
}
</script>
<style scoped lang="scss">
.bottom {
  height: 50px;
  background-color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;

  .van-button {
    width: 90%;
    margin: 0 auto;
  }
}

.Payment {
  .van-loading {
    text-align: center;
    position: absolute;
    z-index: 9999999;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    border-radius: 10px;
    left: calc(50% - 40px);
    top: 25vh;
  }

  .van-form {
    padding-bottom: 30px;
    height: calc(100vh - 110px);
    overflow: auto;

    .lable_icon {
      color: red;
      transform: scale(0.4);
      font-size: 13px;
      width: 10px;
    }
  }

  .vant-table {
    overflow-x: auto;
    padding: 10px 0;

    table {
      padding: 5px;

      tr {
        line-height: 30px;
      }

      .th {
        height: 30px;
        line-height: 30px;
        background-color: #f5f6f6;
        text-align: center;
      }

      td {
        text-align: center;
        font-size: 10px;
      }

      .link-text {
        color: #0089ff;
      }
    }

    .van-button {
      margin: 0 10px;
    }
  }

  .property_head {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    line-height: 30px;

    .property_head_text {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      text-align: center;
    }

    .property_head_btn {
      position: absolute;
      top: 2px;
      right: 12px;
      font-size: 12px;
      color: #576b95;
    }

    .property_head_cancle {
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 12px;
      color: #969799;
    }
  }
}

.textFontSize {
  margin-left: 1vh;
  margin-top: 2vh;
  display: block;
  color: #000;
  font-size: 13px;
}

.van-cell:after {
  position: relative !important;
}
</style>
