<template>
  <!-- 下拉刷新 -->
  <van-pull-refresh v-model="loading" @refresh="onRefresh">
    <div class="wfs-home">
      <van-row class="box">
        <div @click="GotoRouter_1" class="GotoRouter">
          <van-icon
              name="todo-list-o"
              size="35"
              :badge="todoCount"
              v-if="todoCount > 0"
          />
          <van-icon name="todo-list-o" size="35" v-else/>
          <div>待处理</div>
        </div>
        <div @click="GotoRouter_2" class="GotoRouter">
          <van-icon name="completed" size="35"/>
          <div>已处理</div>
        </div>
        <div @click="GotoRouter_3" class="GotoRouter">
          <van-icon name="back-top" size="35"/>
          <div>已发起</div>
        </div>
        <div @click="GotoRouter_4" class="GotoRouter">
          <van-icon name="guide-o" size="35"/>
          <div>抄送我</div>
        </div>
        <div @click="GotoRouter_5" class="GotoRouter">
          <van-icon name="points" size="35"/>
          <div>批量审批</div>
        </div>
      </van-row>

      <!-- 表单 -->
      <CollapseList v-if="formList.length > 0" :formList="formList" :fileBase64="fileBase64"/>
    </div>
  </van-pull-refresh>
</template>

<script>
import {onMounted, reactive, ref, toRefs, computed, onUpdated} from "vue";
import {GetFlowFormTypeDetail, DealtWithCount} from "@/api/oawfs.js";
import router from "@/router";
import {Toast} from "vant";
import axios from "axios";
import ChangeTitle from '@/utils/changeTitle.js'
import CollapseList from "@/views/Wfs/components/CollapseList.vue";

export default {
  components: {CollapseList},
  setup: function () {
    const imgPng = computed(() => {
      return (row) => {
        for (let i = 0; i < state.fileBase64.length; i++) {
          if (state.fileBase64[i].id == row.file_id) {
            return (state.fileBase64[i].extension.substr(1))
          }
        }
      }
    })
    const collapse = ref(null);
    const activeTab = ref(0)
    const state = reactive({
      loading: false,
      fileBase64: [],
      formList: [],
      compare: "",
      activeNames: ["1"],
      todoCount: 0, //待处理数量
    });
    let methods = {
      show(item) {
        state.compare = item.group_id;
      },
      GotoRouter_1() {
        router.push({path: "/Todolist/1"});
      },
      GotoRouter_2() {
        router.push({path: "/Todolist/2"});
      },
      GotoRouter_3() {
        router.push({path: "/Todolist/3"});
      },
      GotoRouter_4() {
        router.push({path: "/Todolist/4"});
      },
      //批量审批
      GotoRouter_5() {
        router.push({path: "/BatchApprove"});
      },
      // 打开表单申请
      openApplyForm(item) {
        router.push("/FormApply/" + item.form_code + "/" + item.id + "/" + item.form_name);
      },
      // 下拉刷新
      onRefresh() {
        GetFlowFormTypeDetail().then((res) => {
          // console.log(res);
          state.formList = res.data;
          let list = [];
          for (const item of res.data) {
            for (const obj of item.flow_list) {
              list.push(obj.file_id);
            }
          }
          methods.DownloadBase64(list);
        });
        state.loading = false;
      },
      DownloadBase64(data) {
        axios
            .post("/oa_portal/oa/portal/Common/DownloadBase64", data, {
              headers: {
                Authorization: localStorage.getItem("Token"),
                tenant_id: localStorage.getItem("tenant_id"),
              },
            })
            .then((res) => {
              // console.log(res.data.data);
              state.fileBase64 = res.data.data;
            });
      },
      //获取待办数量
      loadDealtWithCount: function () {
        DealtWithCount().then((res) => {
          if (res.code !== 200) {
            Toast(res.msg);
            return;
          }
          state.todoCount = res.data.todoCount; //待处理数量
        });
      },
    };
    onMounted(async () => {
      //加载待办数量
      methods.loadDealtWithCount();
      const res = await GetFlowFormTypeDetail()
      state.formList = res.data;
      let list = [];
      for (const item of res.data) {
        for (const obj of item.flow_list) {
          list.push(obj.file_id);
        }
      }

      methods.DownloadBase64(list);
      ChangeTitle.setTitle("盛泰审批")
    });
    return {
      ...toRefs(state),
      ...methods,
      activeTab,
      imgPng,
      collapse
    };
  },
};
</script>

<style scoped lang="scss">
.wfs-home {
  min-height: 90vh;

  .box {
    margin: 15px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .GotoRouter {
      text-align: center;
    }

    .GotoRouter:hover {
      color: orangered;
    }
  }

  ::v-deep .van-collapse-item__content {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    .collapseItem {
      flex: 1;
      width: 25%;
      max-width: 25%;
      min-width: 25%;
    }
  }
}

</style>
