import axios from '@/utils/request.js'

export function GetvalidYearDepartment() {
  //查询部门
  return axios({
    url: '/ehr_emp/ehr/emp/Employee/GetvalidYearDepartment',
    method: 'GET',
  })
}
//根据工号查询员工列表
export function GetEmpListByCode(data) {
  return axios({
    url: '/ehr_emp/ehr/emp/Employee/GetEmpListByCode',
    method: 'GET',
    params: data,
  })
}

//获取员工所在部门
export function GetEmpDeptInfo(data) {
  return axios({
    url: '/ehr_emp/ehr/emp/Employee/GetEmpDeptInfo',
    method: 'GET',
    params: data,
  })
}

//获取员工离职表单信息
export function GetEmpResignFormInfo(data) {
  return axios({
    url: '/ehr_emp/ehr/emp/Employee/GetEmpResignFormInfo',
    method: 'GET',
    params: data,
  })
}
//根据员工Id获取员工信息
export function GetEmpInfoByEmpId(data) {
  return axios({
    url: '/ehr_emp/ehr/emp/Employee/GetEmpInfoByEmpId',
    method: 'GET',
    params: data,
  })
}
//上传核酸记录
export function UploadNucleicAcidReport(data) {
  return axios({
    url: '/ehr_emp/ehr/emp/Attachment/UploadNucleicAcidReport',
    method: 'Post',
    data: data,
  })
}
