<!-- 自选审批人 -->
<template>
  <div class="CounterSign">
    <span
      style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
    >
      <van-icon class="lable_icon" name="star" v-if="pageConfig.required" />
      <van-icon class="lable_icon" v-else />{{ pageConfig.showLabel }}
    </span>

    <van-cell>
      <template #title>
        <van-tag
          style="margin-right: 3px"
          v-for="item in pageConfig.checkedTOEmpList"
          :key="item.id"
          closeable
          size="medium"
          type="default"
          @close="methods.deleteTOEmpInfo(item)"
        >
          {{ item.emp_code + "-" + item.emp_name }}
        </van-tag>
        <van-button
          icon="plus"
          type="default"
          plain
          @click="methods.showSelectEmpTOSubmit"
          size="small"
          >点击选择</van-button
        >
      </template>
    </van-cell>
    <span
      v-if="false"
      style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
    >
      <van-icon class="lable_icon" />{{ pageConfig.showLabel }}
    </span>

    <van-cell v-if="false">
      <template #title>
        <van-tag
          style="margin-right: 3px"
          v-for="item in pageConfig.checkedCCEmpList"
          :key="item.id"
          closeable
          size="medium"
          type="default"
          @close="methods.deleteCCEmpInfo(item)"
        >
          {{ item.emp_code + "-" + item.emp_name + '-' + item.dept_name}}
        </van-tag>
        <van-button
          icon="plus"
          type="default"
          plain
          @click="methods.showSelectEmpCCSubmit"
          size="small"
          >点击选择</van-button
        >
      </template>
    </van-cell>
    <van-popup
      v-model:show="pageConfig.showEmpTOSelect"
      position="bottom"
      round
      :style="{ height: '80%', overflow: 'hidden' }"
    >
      <emp-select @onSelectEmp="methods.onSelectTOEmp"></emp-select>
    </van-popup>
    <van-popup
      v-model:show="pageConfig.showEmpCCSelect"
      position="bottom"
      round
      :style="{ height: '80%', overflow: 'hidden' }"
    >
      <emp-select @onSelectEmp="methods.onSelectCCEmp"></emp-select>
    </van-popup>
  </div>
</template>

<script>
import EmpSelect from '@/components/Emp/empSelect.vue'
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { Toast } from 'vant';
export default {
  components: { 'emp-select': EmpSelect },
  props: ['showLabel','required','mark','limitNum',"defaultEmpList"],
  setup (props, { emit }) {
    //页面配置
    const pageConfig = reactive({
      showLabel: null,//显示文本
      required:true,//必填标识
      limitNum:props.limitNum,//限制人数
      showEmpTOSelect: false,//显示员工审批下拉框
      showEmpCCSelect: false,//显示员工抄送下拉框
      checkedTOEmpList: [],//选中的审批员工列表
      checkedCCEmpList:[],//选中抄送员工列表
    })
    //内置函数
    const methods = {
      //初始化
      init: function () {
        //显示文本
        pageConfig.showLabel = props.showLabel
        //必填标识
        pageConfig.required = props.required
        //默认值
        if(props.defaultEmpList && props.defaultEmpList.length > 0){
          pageConfig.checkedTOEmpList = props.defaultEmpList
        }
      },
      //显示下拉选择员工
      showSelectEmpTOSubmit: function () {
        pageConfig.showEmpTOSelect = true
      },
      //显示下拉选择员工
      showSelectEmpCCSubmit: function () {
        pageConfig.showEmpCCSelect = true
      },
      //选中审批员工
      onSelectTOEmp: function (item) {
         if (pageConfig.limitNum && pageConfig.checkedTOEmpList.length >= pageConfig.limitNum) {
            Toast("本节点只能选择" + pageConfig.limitNum + "位员工")
            return
        }       
        //是否已经存在
        let has = false
        pageConfig.checkedTOEmpList.find(function (value) {
          if (value.user_id === item.user_id) {
            has = true
            return
          }
        })
        if (has) {
          Toast("已经存在列表，不能重复添加")
          return
        }
        pageConfig.checkedTOEmpList.push(item)
        //关闭弹框
        pageConfig.showEmpTOSelect = false
        //回传员工列表
        emit("empTOListChange", pageConfig.checkedTOEmpList,props.mark);
      },
      //选中抄送员工
      onSelectCCEmp: function (item) {
        if (pageConfig.limitNum && pageConfig.checkedCCEmpList.length >= pageConfig.limitNum) {
            Toast("本节点只能选择" + pageConfig.limitNum + "位员工")
            return
        }       
        //是否已经存在
        let has = false
        pageConfig.checkedCCEmpList.find(function (value) {
          if (value.user_id === item.user_id) {
            has = true
            return
          }
        })
        if (has) {
          Toast("已经存在列表，不能重复添加")
          return
        }
        pageConfig.checkedCCEmpList.push(item)
        //关闭弹框
        pageConfig.showEmpCCSelect = false
        //回传员工列表
        emit("empCCListChange", pageConfig.checkedCCEmpList);
      },
      //移除审批员工
      deleteTOEmpInfo: function (item) { 
        //找到删除的索引
        let index = pageConfig.checkedTOEmpList.findIndex(x => x.id == item.id)
        //移除
        pageConfig.checkedTOEmpList.splice(index, 1)
        //回传员工列表
        emit("empTOListChange", pageConfig.checkedTOEmpList,props.mark);
      },
      //移除抄送员工
      deleteCCEmpInfo: function (item) { 
        //找到删除的索引
        let index = pageConfig.checkedCCEmpList.findIndex(x => x.id == item.id)
        //移除
        pageConfig.checkedCCEmpList.splice(index, 1)
        //回传员工列表
        emit("empCCListChange", pageConfig.checkedCCEmpList);
      },
    }
    onMounted(() => {
      //初始化
      methods.init()
    })
    return {
      pageConfig,
      methods
    }
  }
}
</script>
<style scoped lang="scss">
.CounterSign {
  font-size: 14px;
  .lable_icon {
    color: red;
    font-size: 4px;
    transform: scale(0.65);
  }
  .van-button {
    margin: 3px 0;
    vertical-align: top;
  }
  .van-tag {
    margin: 3px 0;
    padding: 5.5px 3px;
  }
}
</style>