<!--
  新闻公告详情预览页面
  作者：陶璇
  时间：2023-7-31
-->
<script setup>
import {nextTick, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import Hammer from 'hammerjs'

require('./js/turn.js')
import $ from 'jquery'
import {useRoute, useRouter} from "vue-router";
import ChangeTitle from "@/utils/changeTitle";
import {isIOS} from "@/utils/utils";
import {NewsStore} from "@/store/newsStore";
import {storeToRefs} from "pinia";
import {AnnouncementStore} from "@/store/announcementStore";

const route = useRoute()
const router = useRouter()
// dom元素
const previewListRef = ref(null)
const innerViewRef = ref(null)
const flipbookRef = ref(null)
const numberRef = ref(null)
const scrollListRef = ref(null)
const viewportRef = ref(null)
const containerRef = ref(null)
const zoomBtnRef = ref(null)
const announcementStore = AnnouncementStore()
const newsStore = NewsStore()
const {designList, scrollDistance, currentPage} = storeToRefs(newsStore)

const state = reactive({
  showFlip: false, // 是否显示翻页
  maxShowLength: 4,// 最大显示长度
  screenWidth: 0, // 屏幕宽度
  screenHeight: 0, // 屏幕高度
  currentPage: 1, // 当前页
  previewWidth: 0, // 预览图宽度
  previewHeight: 0, // 预览图高度
  timer: null, // 定时器
  zoomValue: 1, // 缩放值
  showZoom: false, // 是否显示缩放
  isZooming: false, // 是否在缩放
  bottomImg:require('@/assets/company.jpg'), // 底部图片
})
const initialDistance = ref(null);
const scale = ref(1);
const onTouchStart = (event) => {
  if (event.touches.length === 2) {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    initialDistance.value = Math.sqrt(
        Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2)
    );

  }
};

const onTouchMove = (event) => {
  if (event.touches.length === 2 && initialDistance.value !== null) {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    const currentDistance = Math.sqrt(
        Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2)
    );
    const newScale = currentDistance / initialDistance.value;
    scale.value = newScale;

    applyScale();
  }
};

const onTouchEnd = () => {
  initialDistance.value = null;
};

const applyScale = () => {
  if (scale.value>1&&scale.value<=3){
    state.zoomValue=scale.value
  }
  if (scale.value<1){
    state.zoomValue=1
  }
  if (scale.value>3){
    state.zoomValue=2
  }
  onChangeZoom()

};
const onTurn = () => {
  nextTick(() => {
    $('#flipbook').turn({
      height: 955, //高度
      width: 650, //宽度
      display: 'single', //单页显示/双页显示  single/double
      elevation: 50,
      duration: 500, //翻页速度(毫秒), 默认600ms
      gradients: true, //翻页时的阴影渐变, 默认true
      acceleration: true, //硬件加速, 默认true, 如果是触摸设备设置为true
      autoCenter: false, //自动居中, 默认true
      page: currentPage.value, //设置当前显示第几页
      pages: designList.value.length, //总页数
      when: {
        // 翻页前触发
        turning: (e, page, view) => {
          newsStore.setCurrentPage(page)
          state.isClickPrev = true
        },
        // 触摸到边角时触发
        start: (e, page) => {
          if (page.next < 1) {
            e.preventDefault();
            $('#flipbook').turn('page', 1).turn('stop');
          }
          if (page.next >= designList.value.length + 1) {
            e.preventDefault();
            $('#flipbook').turn('page', designList.value.length).turn('stop');
          }
        },
      },
    })
  })
}

// 跳转到指定页
const toPage = (index) => {
  if (index < 1 || index > designList.value.length) return
  // 将放大的倍数重置为1 并重置缩放
  state.zoomValue = 1
  state.showZoom = false
  state.isZooming = false
  containerRef.value.style.zoom = 1

  changeScroll(index)
  newsStore.setCurrentPage(index)
  $('#flipbook').turn('page', currentPage.value)
}

// 点击显示富文本
const handleRichText = (item) => {
  if (innerViewRef.value?.style.height === '210px') {
    handleInnerBox()
    return;
  }
  if (item.content?.length < 0 || item.content === null || item.content === undefined) return
  saveRichText(item.content)
  router.push({name: 'RichText',query:{bgColor:item.bg_color}})
}

// 保存富文本内容至本地
const saveRichText = (content) => {
  // // 先清空
  // localStorage.removeItem('richText')
  // // 再设置
  // localStorage.setItem('richText', JSON.stringify(content))
  announcementStore.setItem({richText:JSON.stringify(content),fileIds:""})

}


// 初始化事件
const initEvent = async () => {
  if (designList.value.length === 0) await newsStore.getDesignList()
  onTurn()
}

// 点击页码，显示预览小窗
const handleNumber = () => {
  if (innerViewRef.value.style.height === '210px') {
    numberRef.value.style.zIndex = '5'
    innerViewRef.value.style.height = '0px'
  } else {
    numberRef.value.style.zIndex = '9999'
    innerViewRef.value.style.height = '210px'
  }
}

// 点击让预览小窗消失
const handleInnerBox = () => {
  if (innerViewRef.value?.style?.height === '210px') {
    innerViewRef.value.style.height = '0px'
    numberRef.value.style.zIndex = '5'
  }
}

// 改变滚动条位置
const changeScroll = (page) => {
  // 如果点击的页是当前页，不做处理
  if (page === currentPage.value) return
  // 判断当前点击的页是否在页面的中心
  const targetIndex = page
  let centerIndex = state.maxShowLength
  let scrollValue = 0
  // 判断当前点击是向右滚动还是左滚动
  if (scrollDistance.value > 0) {
    centerIndex = scrollDistance.value / 100 + centerIndex
  }

  // 记录当前滚动条滚动的距离
  const currentScrollDistance = scrollListRef.value.scrollLeft
  // 记录将要滚动的距离
  const scrollDistanceTemp = targetIndex < centerIndex ? scrollDistance.value - 100 : scrollDistance.value + 100
  newsStore.setScrollDistance(scrollDistanceTemp < 0 ? 0 : scrollDistanceTemp)
  // 清楚定时器
  clearInterval(state.timer)

  // 使用定时器，让滚动条滚动到指定位置
  state.timer = setInterval(() => {
    if (Math.abs(scrollValue) >= Math.abs(scrollDistance.value - currentScrollDistance)) clearInterval(state.timer)
    else {
      scrollListRef.value.scrollLeft = targetIndex < centerIndex ? scrollListRef.value.scrollLeft - 5 : scrollListRef.value.scrollLeft + 5
      scrollValue = targetIndex < centerIndex ? scrollValue - 5 : scrollValue + 5
    }
  }, 10)
}

// 初始化屏幕参数
const initData = () => {
  state.screenWidth = document.body.clientWidth
  state.screenHeight = document.body.clientHeight

  state.previewWidth = (state.screenWidth - 30) / 4
  state.previewHeight = 180

  // 对ios用户进行特殊处理
  if (isIOS()) {
    state.screenHeight -= 10
    // viewportRef.value.style.height = `${state.screenHeight}px`
  }
  // 计算缩放比例
  const scaleX = state.screenWidth / 650;
  const scaleY = state.screenHeight / 680;
  // 设置翻页容器的按照屏幕宽高比例缩放
  flipbookRef.value.style.transform = `scale(${scaleX})`
  containerRef.value.style.height = `${955 * scaleX}px`

  // 计算预览图的缩放比例
  const previewScaleX = state.previewWidth / 650
  const previewScaleY = state.previewHeight / 1800
  // 设置预览容器的按照屏幕宽高比例缩放
  previewListRef.value.style.transform = `scale(${previewScaleX},${previewScaleY})`
}

// 向左滑动
const swipeLeft = () => {
  if (state.isZooming) return
  toPage(currentPage.value + 1)
}

// 向右滑动
const swipeRight = () => {
  if (state.isZooming) return
  toPage(currentPage.value - 1)
}


// 放大数值改变
const onChangeZoom = () => {

  // containerRef.scaleTo(state.zoomValue, {
  //   // 变换原点。可以是数字或百分比字符串，如"50%"
  //   originX: "50%",
  //   originY: "50%",
  //   // 变换原点相对于容器还是内容？
  //   relativeTo: 'content',
  //   // 如果值与当前值不同，则触发'change'事件
  //   allowChangeEvent: false,
  // });
  console.log(  containerRef.value.style)
  containerRef.value.style.zoom = state.zoomValue
  //containerRef.value.style.transform = `scale(${state.zoomValue})`
  containerRef.value.style.transformOrigin='center';
  //containerRef.value.style.transform = `scale(${state.zoomValue})`
  state.isZooming = state.zoomValue !== 1
}

const handleZoom = () => {
  state.showZoom = !state.showZoom
}

onMounted(() => {
  ChangeTitle.setTitle('新闻详情')

  newsStore.setNewsId(route.query.news_id)
  initData()
  initEvent()
})

onBeforeUnmount(() => {
  newsStore.clearDesignList()
})
</script>

<template>

  <div class="flipbook-viewport zoomable" ref="viewportRef" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <div class="container" ref="containerRef" pinch-zoom="on">
      <div id="flipbook" ref="flipbookRef">
        <div v-for="(item,index) in designList.length" class="box">
          <div class="innerBox" @click="handleInnerBox" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
            <div v-for="(item2,index2) in designList[index].columns"
                 :key="index*index2"
                 class="designElement"
                 @click="handleRichText(item2)"
                 :style="{
                            transform:`translate(${item2.left}px, ${item2.top}px)`,
                            width:item2.width+'px',
                            height:item2.height+'px',
                           }">
              <img v-if="item2?.imageUrl" :src="item2?.imageUrl" alt="">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="bottom" :class="[isIOS() ? 'iosBottom' : '']">
      <!--        <div class="prev" @click="()=>toPage(currentPage.value-1)" v-if="currentPage.value > 1">上一页</div>-->
      <!--        <div class="prev" v-else></div>-->
      <div class="number" ref="numberRef">
        <p @click="handleNumber">{{ currentPage }}</p>
        <div class="scrollList" ref="scrollListRef">
          <div class="innerViewList" ref="innerViewRef"
               :style="{width:(state.previewWidth + 15) * designList.length + 'px'}">
            <div class="previewList" :style="{width:750 * designList.length + 'px'} " ref="previewListRef">
              <div class="item" :class="[currentPage === index + 1 ? 'currentItem' : '']"
                   @click="()=>toPage(index+1)"
                   style="width: 650px;height: 955px" v-for="(item,index) in designList.length">
                <div v-for="(item2,index2) in designList[index].columns"
                     :key="index*index2"
                     class="designElement"
                     :style="{
                            transform:`translate(${item2.left}px, ${item2.top}px)`,
                            width:item2.width+'px',
                            height:item2.height+'px',
                           }">
                  <img v-if="item2?.imageUrl" :src="item2?.imageUrl" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--        <div class="next" @click="()=>toPage(currentPage.value+1)" v-if="currentPage.value < designList.value.length">-->
      <!--          下一页-->
      <!--        </div>-->
      <!--        <div class="next" v-else></div>-->
    </div>

    <div class="zoomBtn" ref="zoomBtnRef">
      <transition name="slider">
        <div class="slider" v-if="state.showZoom">
          <van-slider v-model="state.zoomValue" vertical @change="onChangeZoom" :min="1" :max="3" :step="0.2"
                      :reverse="true"/>
        </div>
      </transition>
      <p @click="handleZoom">放大</p>
    </div>
    <div class="bottomImg">
      <img :src="state.bottomImg" alt="">
    </div>
  </div>
</template>

<style scoped lang="scss">

.bottomImg{
  img{
    width: 100%;
  }
}
.innerViewList {
  height: 0px;
  transition: all .3s;
}

.scrollList {
  overflow-x: scroll;
  background-color: rgba(0, 0, 0, 0.5);
}

.previewList {
  display: flex;
  justify-content: space-between;
  transform-origin: left top;
  padding: 50px;

  .item {
    margin-right: 100px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgb(204, 204, 204) 0px 0px 80px;
    overflow: hidden;
  }

  .currentItem {
    box-shadow: 0 0 80px red;
  }
}

#flipbook {
  transform-origin: top left;
}

.flipbook-viewport {
  width: 100vw;
  overflow-x: scroll;
  overflow-y: scroll;

  .bottom {
    position: fixed;
    bottom: 0px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .prev, .next {
      width: 35px;
      padding: revert;
      z-index: 99;
    }

    .number {
      position: absolute;
      z-index: 5;
      color: #fff;
      width: 100%;
      left: 0;

      p {
        text-align: center;
        line-height: 15px;
        margin: 0 auto;
        padding: 0;
        width: 40px;
      }
    }

    .iosNumber {
      bottom: 25px;
    }

    .number::before {
      content: "";
      display: inline-block;
      position: absolute;
      z-index: -9;
      left: 50%;
      transform: translateX(-50%);
      width: 30px; /* 梯形的底部宽度 */
      height: 0; /* 梯形的高度 */
      border-bottom: 15px solid rgba(0, 0, 0, .5); /* 梯形的底边 */
      border-left: 20px solid transparent; /* 梯形的左边 */
      border-right: 20px solid transparent; /* 梯形的右边 */
    }
  }

  .iosBottom {
    bottom: 17px;
  }

  .box {
    background-color: #fff;
    position: relative;
  }

  .innerBox {
    width: 100%;
    height: 100%;
    //padding: 8px 20px;
    box-sizing: border-box;
  }

  .designElement {
    z-index: 99;
    position: absolute;
    box-sizing: border-box;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes move-in {
  0% {
    height: 0px;
  }
  100% {
    height: 100px;
  }
}

@keyframes move-out {
  0% {
    height: 100px;
  }
  100% {
    height: 0px;
  }
}

.slider-enter-active {
  animation: move-in 0.3s ease;
}

.slider-leave-active {
  animation: move-out 0.3s ease;
}

.slider-enter, .slider-leave-to {
  height: 0px;
}

.container {
  position: relative;
}

.zoomBtn {
  position: fixed;
  right: 10px;
  bottom: 50px;
  padding: 0 5px;

  .slider {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100px;
    border-radius: 7px;
    background-color: #fff;
  }

  p {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
