
import {defineStore} from "pinia";
export const AnnouncementStore = defineStore('announcementStore', {
    state: () => {
        return {
            item:{}
        }
    },
    actions: {
        setItem(item) {
            this.item = item
        },
    }
})
