<!--首页-->
<template>
  <van-pull-refresh
    ref="refreshRef"
    v-model="pageConfig.loading"
    @refresh="methods.refreshPush()"
    success-text="刷新成功"
    loading-text="加载中"
  >
    <div class="home" ref="homeRef">
      <div class="banner">
        <van-swipe :autoplay="3000" lazy-render>
          <van-swipe-item v-for="item in pageConfig.bannerImageUrl" :key="item">
            <van-image width="100%" :src="item" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="section-block">
        <!--        收藏模块-->
        <section>
          <div class="title">收藏</div>
          <div class="content">
            <div class="item" v-for="obj in pageConfig.addedList" :key="obj.id">
              <div class="item-img" @click="methods.openLinkRoute(obj)">
                <img v-if="obj.file_id" :src="compute1(obj)" class="img_1" />
                <img v-else src="../assets/defaultMenu.svg" />
              </div>
              <div class="item-name" @click="methods.openLinkRoute(obj)">
                {{ obj.menu_name }}
              </div>
            </div>
            <div
              class="item"
              :class="[pageConfig.addedList.length == 0 ? 'justAdd' : '']"
            >
              <div class="plus-icon item-img" @click="methods.handleCollect">
                <van-icon name="plus" color="#fff" />
              </div>
              <div class="item-name" @click="methods.handleCollect">
                添加收藏
              </div>
            </div>
          </div>
        </section>

        <section v-for="item in pageConfig.list" :key="item.id">
          <div class="title">{{ item.menu_name }}</div>
          <div class="content">
            <div class="item" v-for="obj in item.children" :key="obj.id">
              <div class="item-img" @click="methods.openLinkRoute(obj)">
                <img v-if="obj.file_id" :src="compute(obj)" class="img_1" />
                <img v-else src="../assets/defaultMenu.svg" />
              </div>
              <div class="item-name" @click="methods.openLinkRoute(obj)">
                {{ obj.menu_name }}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>

import router from "@/router";
import {onMounted, reactive, computed, ref} from "vue";
import ChangeTitle from '@/utils/changeTitle.js'
import {froms} from '@/utils/froms.js'
import {GetEnvironment} from '@/api/oaApi.js'
import {GetEmpMenuList, GetFavoriteList} from '@/api/mobile.js'
import axios from "axios";
import {nextTick} from "@vue/runtime-core";

export default {
  setup() {
    const compute = computed(() => {
      return (row) => {
        for (let i = 0; i < pageConfig.fileBase64.length; i++) {
          if (pageConfig.fileBase64[i].id == row.file_id) {
            return 'data:image/svg+xml;base64,' + pageConfig.fileBase64[i].base64
          }
        }
      }
    })

    const compute1 = computed(() => {
      return (row) => {
        for (let i = 0; i < pageConfig.addBase64.length; i++) {
          if (pageConfig.addBase64[i].id == row.file_id) {
            return 'data:image/svg+xml;base64,' + pageConfig.addBase64[i].base64
          }
        }
      }
    })

    //页面参数配置
    const pageConfig = reactive({
      loading: false,
      fileBase64: "",
      list: null,//存树
      markLabel: '',//标记文本
      tenant_id: localStorage.getItem("tenant_id"),
      bannerImageUrl: [process.env.BASE_URL + "banner/banner1.png", process.env.BASE_URL + "banner/banner2.png"],
      addedList: [], // 收藏列表
      addBase64: [],
    })

    const refreshRef = ref()
    const homeRef = ref()

    //内置函数
    const methods = {
      onTouchStart(e){
        e.preventDefault()
      },
      // 获取收藏列表
      async getAddedList() {
        const res = await GetFavoriteList()
        pageConfig.addedList = res.data
        let list = [];
        for (const item of pageConfig.addedList) {
          list.push(item.file_id);
        }
        methods.DownloadBase64(list, 1);
      },
      // 点击收藏
      handleCollect() {
        router.push({path: '/collect'})
      },
      DownloadBase64(data, flag) {
        axios
            .post("/oa_portal/oa/portal/Common/DownloadBase64", data, {
              headers: {
                Authorization: localStorage.getItem("Token"),
                tenant_id: localStorage.getItem("tenant_id"),
              },
            })
            .then((res) => {
              // console.log(res.data.data);
              if (flag === 1) {
                pageConfig.addBase64 = res.data.data;
              } else {
                pageConfig.fileBase64 = res.data.data;
              }
            });
      },
      openApproval: function () {
        methods.openLinkRoute('/WFS')
        // let empArr = ['STD6343','STD6048','STD6275','STD6444','STD6146','STD5865','STD5965','STD5090','STD5841']
        // let empCode = localStorage.getItem('emp_code')
        // if(empArr.indexOf(empCode)>=0)
        // {
        //   methods.openLinkRoute('/WFS')
        // }else{
        //   Toast('敬请期待...')
        // }
      },
      //打开路由
      openLinkRoute: function (url) {
        if (url.path) {
          router.push({path: url.path});
        } else {
          router.push({path: '/subMenu', query: {parent_id: url.id, menu_name: url.menu_name}});
        }
        // console.log(123);
      },
      //环境参数
      getEnvironment: function () {
        // console.log("获取环境参数")
        GetEnvironment().then(res => {
          // console.log("获取环境参数结果")
          // console.log(res)
          if (res.code == 200) {
            pageConfig.markLabel = res.data
          }
        })
      },
      async refreshPush() {
        methods.getEnvironment()
        ChangeTitle.setTitle("首页")
        const res = await GetEmpMenuList()
        await methods.getAddedList()
        if (res.code == 200) {
          pageConfig.list = froms(res.data, '')
          pageConfig.list.sort(sortBy('sort_num', 1))
          for (const iterator of pageConfig.list) {
            iterator.children.sort(sortBy('sort_num', 1))
          }

          function sortBy(attr, rev) {
            if (rev == undefined) {
              rev = 1
            } else {
              (rev) ? 1 : -1;
            }
            return function (a, b) {
              a = a[attr];
              b = b[attr];
              if (a < b) {
                return rev * -1
              }
              if (a > b) {
                return rev * 1
              }
              return 0;
            }
          }

          let list = [];
          for (const item of pageConfig.list) {
            for (const obj of item.children) {
              list.push(obj.file_id);
            }
          }

          methods.DownloadBase64(list);
          pageConfig.loading = false
        } else {
          pageConfig.loading = false
        }
      }
    }

    // 动态设置高度
    const setHeight = () => {
      const isIosSafari = navigator.userAgent.match(/iP(ad|od|hone)/i) && navigator.userAgent.match(/WebKit/i);
      console.log(isIosSafari,"isIosSafari")
      if (isIosSafari) {
        const height = homeRef.value.clientHeight + 80 + 'px'
        refreshRef.value.$el.style.height = height
      }

    }
    onMounted(async () => {
      methods.getEnvironment()
      ChangeTitle.setTitle("首页")
      const res = await GetEmpMenuList()
      await methods.getAddedList()
      pageConfig.list = froms(res.data, '')
      pageConfig.list.sort(sortBy('sort_num', 1))
      for (const iterator of pageConfig.list) {
        iterator.children.sort(sortBy('sort_num', 1))
      }

      function sortBy(attr, rev) {
        if (rev == undefined) {
          rev = 1
        } else {
          (rev) ? 1 : -1;
        }
        return function (a, b) {
          a = a[attr];
          b = b[attr];
          if (a < b) {
            return rev * -1
          }
          if (a > b) {
            return rev * 1
          }
          return 0;
        }
      }

      let list = [];
      for (const item of pageConfig.list) {
        for (const obj of item.children) {
          list.push(obj.file_id);
        }
      }
      methods.DownloadBase64(list);
      // await nextTick(() => {
      //   setHeight()
      // })

    })
    return {
      pageConfig,
      methods,
      compute,
      compute1,
      refreshRef,
      homeRef,
    }
  }
}
</script>

<style lang="scss">
* {
  -webkit-touch-callout: none; /* 禁止长按菜单 */
  -webkit-user-select: none; /* 禁止选择文字 */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.van-pull-refresh {
  overflow: visible !important;
  //overflow-y: scroll !important;
}

img {
  pointer-events: none;
}

body {
  -webkit-touch-callout: none;
}
-webkit-scrollbar-vertical {
  display: none;
}

.home {
  min-height: calc(100vh - 5px);
  padding-top: 5px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE 10+ */

  .banner {
    width: 96%;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;

    img {
      border-radius: 4px;
    }
  }

  .section-block {
    //height: 100px;
    //position: relative;
    .plus-icon {
      background: #dadada;
    }

    .test-mark-block {
      width: 100%;
      top: 10px;
      text-align: center;
      position: absolute;
      font-size: 14px;
      color: #f00;
      font-weight: bold;
    }

    section {
      border-radius: 5px;
      width: 97%;
      margin: 5px auto;
      background-color: #fff;
      padding: 0 8px;
      box-sizing: border-box;
      overflow: hidden;

      .title {
        font-size: 14px;
        font-weight: bold;
        height: 28px;
        line-height: 28px;
        margin-top: 1px;
      }

      .content {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;

        .justAdd {
          margin-bottom: 10px !important;
        }

        .item {
          flex: 1;
          width: 20%;
          max-width: 20%;
          word-wrap: break-word;
          min-width: 20%;
          margin-bottom: 5px;

          .item-img {
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            margin: auto;
            color: #fff;
            font-size: 18px;
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .item-name {
            line-height: 22px;
            color: #414141;
          }
        }
      }
    }
  }
}
</style>
