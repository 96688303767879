<!--转交页面-->
<template>
  <div class="transfer">
    <div class="header">
      <van-search
        shape="round"
        @input="methods.loadEmpList"
        v-model="pageConfig.inputValue"
        placeholder="搜索"
        input-align="left"
      />
    </div>
    <div class="wrapper">
      <p class="title">请选择用户进行转交</p>
      <van-cell
        :title="item.emp_code + '-' + item.emp_name"
        :key="item.user_id"
        v-for="item in userList"
        v-model="pageConfig.empid"
        @click="methods.openSubmitApproval(item.user_id)"
        is-link
      />
    </div>
    <!-- 同意弹框 -->
    <van-popup
      v-model:show="pageConfig.showApproval"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <submit-approval
        :formtoId="pageConfig.formto_id"
        :instanceId="pageConfig.instance_id"
        :action="'Forward'"
        :empId="pageConfig.empid"
        :statusId="'*'"
        :isdd="pageConfig.isdd"
        @onclose="methods.onclose()"
      ></submit-approval>
    </van-popup>
  </div>
</template>
<script>
import { reactive,ref } from '@vue/reactivity'
import { useRoute,useRouter } from 'vue-router'
import { Toast } from 'vant'
import { onMounted } from '@vue/runtime-core'
import {GetEmpListByName} from '@/api/oaApi.js'//emp的接口
import ChangeTitle from '@/utils/changeTitle.js'
import SubmitApproval from './index.vue'

export default {
  props: ['formtoId', 'isdd', 'instanceId'],
  components:{'submit-approval':SubmitApproval},
  setup (props, { emit }) {
    //用户列表
    const userList = ref([])
    const route = useRoute()
    const router = useRouter()
    const pageConfig = reactive({
      inputValue:"",
      searchTimeOut:null,
      empid: "",
      showApproval:false,//打开审批页面
      instance_id:props.instanceId??route.params.instance_id,//实例id
      formto_id:props.formtoId??route.params.formto_id,//审批人实例id
      isdd:props.isdd??route.params.isdd,//钉钉待办
    })

    const methods= {
      //获取员工列表
      loadEmpList:function(){
        clearTimeout(pageConfig.searchTimeOut)        
         pageConfig.searchTimeOut=setTimeout(() => {
            GetEmpListByName(pageConfig.inputValue).then(res=>{//emp的查询员工接口查询员工
              if(res.code !== 200)
              {
                Toast(res.msg)
                return
              }
              userList.value=res.rows
            })
         },1000);         
      },
      //打开提交审批页面
      openSubmitApproval: function (empid) {
        pageConfig.empid=empid
        pageConfig.showApproval = true
      },        
      //关闭弹框
      onclose: function () { 
        pageConfig.showApproval = false
        emit('onclose',null)
      }
    }
    onMounted(()=>{
      ChangeTitle.setTitle("转交")
    })
    return{
      userList,
      pageConfig,
      methods
    }
  }
}
</script>
<style lang="scss">
.transfer {
  .wrapper {
    .title {
      line-height: 20px;
      text-indent: 10px;
      margin: 0;
      color: rgb(240, 153, 81);
    }
    min-height: 300px;
    background-color: #fff;
  }
}
</style>