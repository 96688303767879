<!-- 学习预览 -->
<template>
  <div class="video-preview" id="div1">
    <div style="width:100%;margin-top:5px">
      <preview-pdf v-if="state.file_type=='.pdf'"
                   style="height: 100vh"
                   :pdfId="state.fileId"
      ></preview-pdf>
      <div ref="videoRef" v-if="state.file_type!='.pdf'" style="width:100%;margin-top:5px"></div>
    </div>
  </div>
</template>
<script>
import {onBeforeMount, onMounted, onUnmounted, reactive, ref, nextTick} from '@vue/runtime-core'
import {useRoute, useRouter} from 'vue-router'
import PreviewPdf from '@/components/previewPdf.vue' //PDF预览组件
import {insertStudyRecord, UpdateStudyTime} from "@/api/ehrTms.js";
import {GetVideo} from "@/api/oaApi.js"
import {Toast} from 'vant';
import img01 from '../../../assets/logo/horizontallogo.svg'
import DPlayer from "dplayer";
import Hls from "hls.js";
import {onBeforeUnmount} from "vue";
import router from "@/router";
import * as dd from 'dingtalk-jsapi'
import ChangeTitle from "@/utils/changeTitle";

export default {
  components: {"preview-pdf": PreviewPdf},
  setup() {
    const videoRef = ref();
    const router = useRouter()
    const route = useRoute()
    //页面参数配置
    const pageConfig = reactive({
      enroll_time: "",
      studyId: '',//本次学习id
      totalTime: 0,//总时长
      videUrl: '',//学习地址
      pdfUrl: null,//pdf地址
      mp4Url: null,//mp4地址
      imgUrl: '',//封面
      timeInterval: '',//定时器
      stydySeconds: 0,//学习时长
      onfocus: true, //在网页中
      video: '',//播放对象
      classId: '',//课程id
      videoLoad: false,//加载
    })
    const state = reactive({
      delay: "",
      fileId: "",
      instance: null,//视频实例
      video: {},//视频
      playState: false,//学习状态
      pageUrl: "",//pdf地址
      file_type: "",//文件类型
      studyTime: 0,//学习时长
      studyRecordId: "",//学习记录ID
      startTimer: "",//开始学习定时器
      subTimer: "",//更新学习时长定时器,
      pdfFoucs: false,
    });
    //内置函数
    const methods = {
      //加载视频
      loadVideo(videoInfo) {
        let player = {
          container: videoRef.value,
          autoplay: false,
          theme: "#0093ff",
          loop: false,
          volume: 0.7,
          lang: 'zh-cn', // 语言，'en', 'zh-cn', 'zh-tw'
          hotkey: true, // 是否支持热键，调节音量，播放，暂停等
          preload: "auto",
          logo: img01,
          video: {
            pic: videoInfo.pic, // 封面
            url: videoInfo.url,
            defaultQuality: 0, // 默认是HD
            type: "customHls",
            customType: {
              customHls: function (video, player) {
                const hls = new Hls();
                hls.loadSource(video.src);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                  player.video.play();
                });
              },
            },
          },
        };
        state.instance = new DPlayer(player);
        state.instance.fullScreen.cancel('web');
        state.playState = !state.instance.video.paused
      },
      //初始化
      init: function () {
        //判断文件类型
        if (route.params.fileType != '.pdf') {
          setTimeout(() => {
            GetVideo(route.params.fileId).then((res) => {
              state.video = res.data.video;
              methods.loadVideo(state.video);
              state.instance.seek(0)
              // 以下为隐藏一些作者的信息和视频播放源 如不需要可删除
              document.querySelector(".dplayer-menu").remove(); //隐藏右键菜单
              document.querySelector(".dplayer-mask").remove();
              document.querySelector(".dplayer-info-panel-item-url").remove(); //隐藏播放源
            })
          }, 5);
        } else {
          state.playState = true
        }
        methods.startStudy()
        return
        pageConfig.videoLoad = true
        pageConfig.pdfUrl = null
        pageConfig.videUrl = decodeURIComponent(route.params.videUrl)
        pageConfig.classId = route.params.classId
        //pdf
        if (pageConfig.videUrl.toLowerCase().endsWith('pdf')) {
          pageConfig.pdfUrl = pageConfig.videUrl
          //开始学习
          methods.courseStart()
          //播放保存学习记录
          methods.saveRecords()
        } else {
          pageConfig.imgUrl = decodeURIComponent(route.params.imgUrl)
          //上次播放时间
          let lastPlayTime = localStorage.getItem('videoPlayTime_' + pageConfig.classId)
          if (lastPlayTime) {
            Toast("欢迎回来，为你从上次位置继续播放")
          }
          pageConfig.mp4Url = pageConfig.videUrl
          pageConfig.video = document.getElementById("video");
          pageConfig.video.addEventListener('loadedmetadata', function () {
            pageConfig.totalTime = video.duration
            //开始学习
            methods.courseStart()
          });
          pageConfig.video.addEventListener('play', function () {
            if (lastPlayTime) {
              pageConfig.video.currentTime = lastPlayTime
            }
            //播放保存学习记录
            methods.saveRecords()
          });
          pageConfig.video.addEventListener('pause', function () {
            //暂停
            methods.stopStudy()
          });
          pageConfig.video.addEventListener('ended', function () {
            //结束
            methods.stopStudy()
            localStorage.removeItem('videoPlayTime_' + pageConfig.classId)
          });
          if (lastPlayTime && !methods.isIOS()) {
            pageConfig.video.currentTime = lastPlayTime
          }
        }
      },
      //开始学习
      startStudy: function () {
        var d = new Date();
        var year = d.getFullYear(); //获取年
        let month = (1 + d.getMonth()).toString().padStart(2, '0'); // 月份从0开始，所以加1，并使用padStart补零
        let days = d.getDate().toString().padStart(2, '0'); // 使用padStart补零
        let hour = d.getHours().toString().padStart(2, '0'); // 使用padStart补零
        let minute = d.getMinutes().toString().padStart(2, '0'); // 使用padStart补零
        let second = d.getSeconds().toString().padStart(2, '0'); // 使用padStart补零
        let queryParamsSub = {
          class_id: route.params.classId,
          start_time: year + '-' + month + '-' + days + ' ' + hour + ':' + minute + ':' + second,
          learning_duration: 0,
        }
        //插入一条学习记录
        insertStudyRecord(queryParamsSub).then(res => {
          if (res.code == 200) {
            //返回当前学习记录的ID
            state.studyRecordId = res.data
            methods.studyTimer()
            methods.subStudyTimeTimer()
          }
        })
      },
      studyTimer: function () {
        state.startTimer = setInterval(() => {
          //切换标签页（不在当前学习页面），暂停学习计时
          if ('visible' != window.document.visibilityState) {
            if (route.params.fileType != '.pdf') {
              state.instance.video.pause()
            } else {
              state.playState = false
            }
            return
          }
          //如果是视频，监视视频播放状态
          if (route.params.fileType != '.pdf') {
            state.playState = !state.instance.video.paused   //播放
          }
          //判断是否是学习状态
          if (state.playState) {
            state.studyTime++
          }
        }, 1000);
      },
      subStudyTimeTimer: function () {
        state.subTimer = setInterval(() => {
          //学习时长为0，不提交学习时长
          if (state.studyTime != 0) {
            let queryParams = {
              learning_duration: state.studyTime,
              id: state.studyRecordId
            }
            //更新学习时长
            UpdateStudyTime(queryParams).then(res => {
            })
            state.studyTime = 0
          }
        }, 5000)
      },
      //开始学习
      // courseStart:function(){
      //   let param ={
      //     courseId:pageConfig.courseId,
      //     duration:pageConfig.totalTime,//视频时长
      //   }
      //   CourseStart(param).then(res=>{
      //     if(res.code != 200)
      //     {
      //       Toast(res.msg)
      //     }
      //     pageConfig.studyId=res.data
      //   })
      // },
      //保存学习记录
      // saveRecords:function(){
      //   //定时器
      //   pageConfig.timeInterval = localStorage.getItem('studyTimeInterval')
      //   if (pageConfig.timeInterval) {
      //     window.clearInterval(pageConfig.timeInterval)
      //   }
      //   pageConfig.timeInterval=setInterval(() => {
      //     pageConfig.stydySeconds=Number(pageConfig.stydySeconds)+5//学习时长
      //     let param ={
      //     courseId:pageConfig.courseId,//课程id
      //     id:pageConfig.studyId,//学习id
      //     std_duration:pageConfig.stydySeconds,//学习时长
      //   }
      //   SaveRecords(param).then(res=>{})
      //   }, 5000);
      //   localStorage.setItem('studyTimeInterval',pageConfig.timeInterval)
      // },
      // //暂停学习
      // stopStudy:function(){
      //   window.clearInterval(pageConfig.timeInterval)
      // },
      //进入网页
      focusPage: function () {
        if (state.file_type == '.pdf') {
          state.playState = true
        }
        //pageConfig.onfocus = true
        //pdf直接开始
        // if(pageConfig.videUrl.toLowerCase().endsWith('pdf'))
        // {
        //   //播放保存学习记录
        //     methods.saveRecords()
        // }
      },
      //关闭网页
      blurPage: function () {
        if (state.file_type == '.pdf') {
          state.playState = false
        } else {
          state.instance.video.pause()
        }
        // //网页未关闭
        // if(pageConfig.onfocus)
        // {
        //   window.clearInterval(pageConfig.timeInterval)
        //   pageConfig.onfocus = false
        //   //视频
        //   // if(!pageConfig.videUrl.toLowerCase().endsWith('pdf'))
        //   // {
        //   //   //记录当前视频时间
        //   //   localStorage.setItem('videoPlayTime_'+pageConfig.courseId,pageConfig.video.currentTime)
        //   //   //播放暂停
        //   //   pageConfig.video.pause();
        //   // }
        // }
      },
      //是否苹果系统
      isIOS: function () {
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return isiOS;
      }
    }
    onUnmounted(() => {
      clearInterval(state.subTimer)
      clearInterval(state.startTimer)
      state.subTimer = ""
      state.startTimer = ""
      // 如果需要更新学习时间，则执行
      if (state.studyTime > 0) {
        const queryParams = {
          learning_duration: state.studyTime,
          id: state.studyRecordId,
        };
        UpdateStudyTime(queryParams)
      }
    })
    onBeforeMount(() => {
      state.fileId = route.params.fileId
    })
    // 在three页面内 navigateBack，将返回one页面
    onMounted(() => {
      ChangeTitle.setTitle("课程详情")
      state.file_type = route.params.fileType
      //初始化
      methods.init()
      // 离开console
      window.onblur = function () {
        methods.blurPage()
      }
      //继续答题
      window.onfocus = function () {
        methods.focusPage()
      }
      // 监听 visibility change 事件
      document.addEventListener('visibilitychange', function () {
        var isHidden = document.hidden
        if (isHidden) {
          methods.blurPage()
        } else {
          methods.focusPage()
        }
      })
      window.addEventListener("popstate", function (e) {
        pageConfig.videoLoad = false
        pageConfig.pdfUrl = null
        methods.blurPage()
      })
    })
    return {
      methods,
      PreviewPdf,
      pageConfig,
      state,
      videoRef,
      img01,
    }
  }
}
</script>
<style scoped lang="scss">
.video-preview {
  video {
    width: 100%;
    display: block;
  }
}

// #iframeId {
// pointer-events: none;
// }
</style>
