<!--员工选择页面-->
<template>
  <div class="transfer">
    <div class="header">
      <van-search
        shape="round"
        @input="methods.loadEmpList"
        v-model="pageConfig.inputValue"
        placeholder="搜索"
        input-align="left"
      />
    </div>
    <div class="wrapper">
      <p class="title">请点击选择一名员工</p>
      <van-cell
        :title="item.emp_code + '-' + item.emp_name + '-' + item.dept_name"
        :key="item.id"
        v-for="item in userList"
        v-model="pageConfig.user_id"
        @click="methods.selectEmp(item)"
        is-link
      />
    </div>
  </div>
</template>
<script>
import { reactive,ref } from '@vue/reactivity'
import { useRoute,useRouter } from 'vue-router'
import { Toast } from 'vant'
import { onMounted } from '@vue/runtime-core'
import {GetEmpListByName} from '@/api/oaApi.js'//emp的接口
export default {
  setup(props,{ emit }) {
    //用户列表
    const userList = ref([])
    const route = useRoute()
    const router = useRouter()
    const pageConfig = reactive({
      inputValue:"",
      searchTimeOut:null,
      empid:"",
      instance_id:route.params.instance_id,//实例id
      formto_id:route.params.formto_id,//审批人实例id
    })

    const methods= {
      //获取员工列表
      loadEmpList:function(){
        clearTimeout(pageConfig.searchTimeOut)        
         pageConfig.searchTimeOut=setTimeout(() => {
            GetEmpListByName(pageConfig.inputValue).then(res=>{//emp的查询员工接口查询员工
              if(res.code !== 200)
              {
                Toast(res.msg)
                return
              }
              console.log(res.rows)
              userList.value=res.rows
            })
         },1000);         
      },
      //选择员工
      selectEmp:function(item){
        console.log(item)
        emit("onSelectEmp", item);
      }       
    }
    onMounted(()=>{

    })
    return{
      userList,
      pageConfig,
      methods
    }
  }
}
</script>
<style lang="scss">
.transfer {
  .wrapper {
    .title {
      line-height: 20px;
      text-indent: 10px;
      margin: 0;
      color: rgb(240, 153, 81);
    }
    min-height: 300px;
    background-color: #fff;
  }
}
</style>