<script setup>
import {computed, onBeforeUpdate, reactive, ref, watch, watchEffect} from "vue";
import router from "@/router";
import {nextTick, onBeforeMount, onMounted} from "@vue/runtime-core";

const props = defineProps({
  fromIndex: {
    type: Number,
    default: 0,
  },
  fromItem: {
    type: Object,
    default: {}
  },
  fileBase64: {
    type: Array,
    default: []
  },
  // 已选中打开列表
  openList: {
    type: Array,
    default: []
  }
})

const emit = defineEmits(["changeOpenList"])

const state = reactive({
  defaultImgSrc: require("@/assets/defaultForm.svg"),
})
// 打开表单申请
const openApplyForm = (item) => {
  router.push({
    path: "/FormApply/" + item.form_code + "/" + item.id + "/" + item.form_name,
    query: {
      verifyCode: item.verify_api_code,
    }
  });
}

const compute = computed(() => {
  return (row) => {
    for (let i = 0; i < props.fileBase64.length; i++) {
      if (props.fileBase64[i].id == row.file_id) {
        if (props.fileBase64[i].extension.indexOf('svg') != -1) {
          return 'data:image/svg+xml;base64,' + props.fileBase64[i].base64
        }
        return 'data:image/' + props.fileBase64[i].extension.substr(1) + ';base64,' + props.fileBase64[i].base64
      }
    }
  }
})

const handleClickItem = (index) => {
  emit("changeOpenList", index)
}

</script>

<template>
  <div class="collapse-item">
    <div class="title" @click="handleClickItem(fromIndex)">
      <div class="name">{{ fromItem.group_name }}</div>
      <div class="icon">
        <van-icon name="arrow-up"
                  :class="[openList.indexOf(fromIndex) === -1 ? 'changeDirection' : 'changeDirection2']"/>
      </div>
    </div>
    <div class="cont" v-show="openList.includes(fromIndex)">
      <div
          class="collapseItem"
          v-for="obj in fromItem.flow_list"
          @click="openApplyForm(obj)"
          :key="obj.id"
      >
        <img
            :src="compute(obj)"
            width="30"
            height="30"
            alt="加载失败"
            v-if="compute(obj)"
        />

        <img
            :src="state.defaultImgSrc"
            width="30"
            height="30"
            v-else
        />
        <div>{{ obj.form_name }}</div>
        <!-- <div>{{ obj.flow_eng_name }}</div> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.collapse-item {
  margin-bottom: 4px;

  .title {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 11px;
    font-weight: 600;
    background: #fff;
  }

  .cont {
    transition: all .3s;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: #fff;
    padding: 7px;
  }
}

.collapseItem {
  flex: 1;
  width: 25%;
  max-width: 25%;
  min-width: 25%;

  &:nth-child(n+5) {
    margin-top: 5px;
  }
}

.changeDirection {
  transition: all .3s;
  transform: rotate(180deg);
}

.changeDirection2 {
  transition: all .3s;
  transform: rotate(0deg);
}

.closeItem {
  height: 0;
}

</style>