/**
 * 新闻数据存储仓库
 * 作者：陶璇
 * 时间：2023-8-7
 */


import {defineStore} from "pinia";
import {getDesignList, removeEmpty} from "@/views/Ads/NewsList/js/utils";

export const NewsStore = defineStore('NewsStore', {
    state: () => {
        return {
            designList: [], //设计列表
            currentPage: 1, // 当前页
            scrollDistance: 0, // 滚动条滚动距离
            news_id: '',// 新闻ids
        }
    },

    actions: {
        // 设置当前页
        setCurrentPage(page) {
            this.currentPage = page
        },
        // 设置滚动条距离
        setScrollDistance(distance) {
            this.scrollDistance = distance
        },
        // 设置新闻id
        setNewsId(id) {
            this.news_id = id
        },
        // 清空设计列表
        clearDesignList() {
            this.designList = []
        },
        // 获取设计列表
        async getDesignList() {
            if (this.news_id !== '' || this.news_id !== undefined) {
                this.designList = await getDesignList(this.news_id)
            }
            this.designList = removeEmpty(this.designList)
        }
    }
})