import { RefreshToken } from '@/api/oaApi.js'
import { Toast } from 'vant'
let i = 0
export async function fns() {
  let tokens = localStorage.getItem('RefreshTokenInfo')
 
  if (tokens && i == 0) {
    i = 1
    const res = await RefreshToken(tokens)
      if (res && res.code == 200 && res.data.refreshToken != tokens) {
        localStorage.setItem('RefreshTokenInfo', res.data.refreshToken)
        localStorage.setItem('Token', res.data.token)
      }
      i = 0
  }
}
