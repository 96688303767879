import {getSystemConfig} from "@/api/oaApi";

export function formatTime(time) {
    const dateObj = new Date(time);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hour = String(dateObj.getHours()).padStart(2, '0');
    const minute = String(dateObj.getMinutes()).padStart(2, '0');
    const second = String(dateObj.getSeconds()).padStart(2, '0');
    const formattedTime = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    return formattedTime;
}

/**
 * 二进制流转换为base64 格式。
 * @param data 二进制流
 * @param type 文件类型
 * @returns {Promise<unknown>}
 */
export function getBase64(data, type) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], {type}); //类型一定要写！！！
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getBase64Url(data, type) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], {type}); //类型一定要写！！！
        const blobUrl = window.URL.createObjectURL(blob);
        resolve(blobUrl)
    });
}

export function BlobToArrayBuffer(data, type) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], {type})
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(blob)
    })
}

export function BlobToText(data, type) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], {type})
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(blob)
    })
}

export function BlobToImg(data, type) {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], {type})
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob)
    })
}

// Bolb转Base64
export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            resolve(e.target.result)
        }
        fileReader.readAsDataURL(blob)
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'))
        }
    })
}


// 分离文件名和后缀
export function splitFileName(fileName) {
    const index = fileName.lastIndexOf('.')
    const name = fileName.substring(0, index)
    const suffix = fileName.substring(index + 1)
    return {name, suffix}
}

// 通过文件名 获取文件类型 如果是图片则返回图片类型
export function getFileType(fileName) {
    const index = fileName.lastIndexOf('.')
    const suffix = fileName.substring(index + 1)
    const fileType = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'webp']
    if (fileType.includes(suffix)) {
        return 'image/' + suffix
    } else {
        return suffix
    }
}

// 通过文件名 判断是否为图片类型
export function isImage(fileName) {
    const index = fileName.lastIndexOf('.')
    const suffix = fileName.substring(index + 1)
    const fileType = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'webp']
    return fileType.includes(suffix)
}

// 防止接口超时 15s 取消加载状态
export function timeoutPromise(promise, ms) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject("接口超时，请稍后重试！")
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    });
}

// 获取服务器地址前缀
const getPrefixUrl = async () => {
    const res = await getSystemConfig('portal_web_url')
    if (res.code === 200) prefixUrl = res.data.cfg_value
}
let prefixUrl = ""

// 获取文件在线url链接
export function getFileUrlByFileId(id) {
    if (prefixUrl === "") getPrefixUrl()
    return prefixUrl + '/oa_portal/oa/portal/Common/DownloadFile/' + id + '?tenant_id=0'
}

// 为ios用户添加底部安全区域
export function addSafeArea() {
    const userAgent = navigator.userAgent
    const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isIOS) {
        const safeArea = document.createElement('div')
        safeArea.style.height = '34px'
        document.body.appendChild(safeArea)
    }
}

// 判断是不是ios用户
export function isIOS() {
    const userAgent = navigator.userAgent
    return !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}