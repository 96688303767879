<script setup>
import {computed, reactive} from "vue";
import axios from "axios";
import {onMounted} from "vue";
import AppItemList from "@/views/Collect/components/AppItemList.vue";
import ChangeTitle from "@/utils/changeTitle";
import {cancelFavorite, GetEmpMenuList, GetFavoriteList, insertFavorite} from "@/api/mobile";
import {froms} from "@/utils/froms";
import router from "@/router";
import {Toast} from "vant";
import {HomeStore} from "@/store/homeStore";

const homeStore = HomeStore();


const state = reactive({
  // 全部应用列表
  formList: [],
  fileBase64: "",
  loading: false,
  // 已添加应用列表
  addedList:[],
  addBase64: "",

  defaultImgSrc: require("@/assets/defaultMenu.svg"),
})


// 获取全部应用
const queryData = async () => {
  const res = await GetEmpMenuList()
  state.formList = froms(res.data, '')
  state.formList.sort(sortBy('sort_num', 1))
  for (const iterator of state.formList) {
    iterator.children.sort(sortBy('sort_num', 1))
  }
  function sortBy(attr, rev) {
    if (rev == undefined) {
      rev = 1
    } else {
      (rev) ? 1 : -1;
    }
    return function (a, b) {
      a = a[attr];
      b = b[attr];
      if (a < b) {
        return rev * -1
      }
      if (a > b) {
        return rev * 1
      }
      return 0;
    }
  }

  let list = [];
  for (const item of state.formList) {
    for (const obj of item.children) {
      list.push(obj.file_id);
    }
  }
  DownloadBase64(list);
}

// 获取已添加应用
const queryAddedList = async () => {
  const res = await GetFavoriteList()
  state.addedList = res.data
  let list = [];
  for (const item of state.addedList) {
    list.push(item.file_id);
  }
  DownloadBase64(list,1);
}

const DownloadBase64 = (data,flag) => {
  axios
      .post("/oa_portal/oa/portal/Common/DownloadBase64", data, {
        headers: {
          Authorization: localStorage.getItem("Token"),
          tenant_id: localStorage.getItem("tenant_id"),
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        if(flag === 1){
          state.addBase64 = res.data.data;
        }else{
          state.fileBase64 = res.data.data;
        }
      });
}

const compute = computed(() => {
  return (row) => {
    for (let i = 0; i < state.addBase64.length; i++) {
      if (state.addBase64[i].id == row.file_id) {
        if (state.addBase64[i].extension.indexOf('svg') != -1) {
          return 'data:image/svg+xml;base64,' + state.addBase64[i].base64
        }
        return 'data:image/' + state.addBase64[i].extension.substr(1) + ';base64,' + state.addBase64[i].base64
      }
    }
  }
})

// 跳转排序页面
const handleEdit = () => {
  // 跳转排序页面
  router.push({
    path: "/collect/edit"
  });
}

// 添加收藏
const insert = async (id) => {
  const res = await insertFavorite(id);
  if(res.code == 200){
    await queryData()
    await queryAddedList()
    Toast.success("添加成功")
    homeStore.clearAddedList()
  }else{
    Toast.fail(res.msg)
  }
}

// 取消收藏
const cansel = async (id) => {
  const res = await cancelFavorite(id);
  if(res.code == 200) {
    await queryData()
    await queryAddedList()
    Toast.success("取消成功")
    homeStore.clearAddedList()
  }else{
    Toast.fail("取消失败")
  }
}

onMounted(async () => {
  ChangeTitle.setTitle("我的收藏")
  await queryData()
  await queryAddedList()
})
</script>

<template>
  <div class="cont">
    <div class="title">
      <div class="name">已添加应用 <span>{{state.addedList.length}}</span></div>
      <div class="operate" @click="handleEdit">排序</div>
    </div>
    <div class="icons">
      <div class="img" v-for="item in state.addedList">
        <img v-if="compute(item)" :src="compute(item)" />
        <img v-else :src="state.defaultImgSrc">
      </div>
    </div>
  </div>
  <AppItemList v-if="state.formList.length > 0"
               :formList="state.formList"
               :fileBase64="state.fileBase64"
               @canselFavorite="cansel"
               @insertFavorite="insert"/>
</template>

<style scoped lang="scss">
.cont {
  background: #fff;
  padding: 10px;
  margin-bottom: 7px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .name {
      font-weight: 600;
      font-size: 14px;
    }

    .operate {
      color: #409eff;
      font-size: 12px;
    }
  }

  .icons {
    display: flex;
    .img{
      width: 26px;
      margin-right: 2px;
    }
    img {
      width: 100%;
    }
  }
}
</style>