import axios from '../utils/request.js'

//请假申请
export function AddLeaveApply(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/LeaveApply/LeaveApply',
    method: 'POST',
    data: data,
  })
}
//获取请假类别
export function GetLeaveType(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Leave/GetLeaveByEmpId',
    method: 'GET',
    params: data,
  })
}
//获取员工请假记录
export function GetEmpLeaveApply(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/LeaveApply/GetEmpLeaveApply',
    method: 'GET',
    params: data,
  })
}
//获取请假时数
export function GetLeaveHours(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/LeaveApply/GetLeaveHours',
    method: 'GET',
    params: data,
  })
}
//通过id获取请假记录
export function GetLeaveApplyById(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/LeaveApply/GetLeaveApplyByLeaveApplyId',
    method: 'GET',
    params: data,
  })
}
//获取员工假日和剩下假日信息
export function GetEmpRemainingLeaveHours(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/PersonalLeave/GetRestLeaveHoursH5',
    method: 'GET',
    params: data,
  })
}

//获取加班小时数
export function CalcOverTimeHours(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/CalcOverTimeHours',
    method: 'POST',
    data: data,
  })
}
//加班申请提交
export function AddOvertimeApply(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/Add',
    method: 'POST',
    data: data,
  })
}
//通过id获取加班记录
export function GetOvertimeById(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/GetOvertimeById',
    method: 'GET',
    params: data,
  })
}

//签卡申请提交
export function SwipePatchApply(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/SwipePatch/SwipePatchApply',
    method: 'POST',
    data: data,
  })
}
//获取签卡原因
export function GetPatchReasonList() {
  return axios({
    url: '/ehr_tas/ehr/tas/SwipePatch/GetPatchReasonList',
    method: 'GET',
  })
}

//获取签卡申请
export function GetSwipePatchApplyById(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/SwipePatch/GetSwipePatchApplyById',
    method: 'GET',
    params: data,
  })
}

//提交出差申请
export function TravelApply(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Travel/Add',
    method: 'POST',
    data: data,
  })
}

//获取出差信息
export function GetTravelById(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Travel/GetTravelById',
    method: 'GET',
    params: data,
  })
}
//获取出差小时数
export function CalcTravelHours(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Travel/CalcTravelHours',
    method: 'POST',
    data: data,
  })
}
//查询出勤统计
export function GetAttendanceFileCensus(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/AttendanceFile/GetAttendanceFileCensus',
    method: 'Get',
    params: data,
  })
}
//修改出勤统计状态
export function UpdateAttendanceCensus(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/AttendanceFile/UpdateAttendanceCensus',
    method: 'Get',
    params: data,
  })
}

export function GetAttendanceFileByEmpId(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/AttendanceFile/GetAttendanceFileByEmpId',
    method: 'Get',
    params: data,
  })
}
//获取员工考勤信息
export function GetPersonAttendance(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/AttendanceFile/GetPersonAttendance',
    method: 'Get',
    params: data,
  })
}
//获取员工加班记录
export function GetOvertimePersonal(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/GetOvertimePersonal?page=1&pageSize=999',
    method: 'Post',
    data: data,
  })
}
//获取员工调休假
export function GetEmpOverduePersonalOverTime(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/GetEmpOverduePersonalOverTime',
    method: 'Get',
    params: data,
  })
}
//获取员工调年假
export function GetEmpOverduePersonalYearLeave(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/Overtime/GetEmpOverduePersonalYearLeave',
    method: 'Get',
    params: data,
  })
}
//获取考勤机列表
export function GetCaptureDeviceList(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/GetCaptureDeviceList',
    method: 'Post',
    data: data,
  })
}
//查询采集员工信息
export function GetCaptureEmpList(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/GetCaptureEmpList',
    method: 'Post',
    data: data,
  })
}
//通过员工id查询人员明细
export function GetCaptureEmp(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/GetCaptureEmp',
    method: 'Post',
    data: data,
  })
}
//人脸在线采集
export function CaptureFace(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/CaptureFace',
    method: 'Post',
    data: data,
  })
}
//虹膜在线采集
export function CaptureIris(data) {
  return axios({
    url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/CaptureIris',
    method: 'Post',
    data: data,
  })
}

//上传图片后更新数据库
export function UpdateFaceImage(data) {
  return axios({
      url: '/ehr_tas/ehr/tas/mobile/EhrTasMobile/UpdateFaceImage',
      method: 'Post',
      data:data
  })
}
