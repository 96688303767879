import axios from '@/utils/request.js'

// 获取任务列表
export function GetTaskPageList(data) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/GetPageList',
        method: 'post',
        data
    })
}

// 获取任务附件
export function GetFiles(task_id) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSAttachment/GetFiles',
        method: 'post',
        data: {task_id}
    })
}

// 获取任务详情
export function GetTaskDetail(id) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/GetDetailById',
        method: 'post',
        data: {id}
    })
}

// 更新任务
export function UpdateTask(data) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/Update',
        method: 'post',
        data
    })
}

// 查询子任务
export function GetChildTaskList(id) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/gettasklist?parent_id=' + id,
        method: 'get',
    })
}

// 新增子任务
export function AddChildTask(data) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/Inserttask',
        method: 'post',
        data: [data]
    })
}

// 新增任务
export function AddTask(data) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/Insert',
        method: 'post',
        data
    })
}

// 退回任务
export function taskBack(data) {
    return axios({
        url: '/oa_tcs/oa/tcs/TCSTask/taskBack',
        method: 'post',
        data
    })
}

// 获取参会人接口
export function GetMeetingPersonnel(meeting_id) {
    return axios({
        url: '/oa_ads/oa/ads/MeetingPersonnel/GetList',
        method: 'post',
        data: {meeting_id}
    })
}