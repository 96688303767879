<template>
  <div class="outbox">
    <div>
         <!-- <img class="logoClass"  src="@/assets/logo/favicon.ico"> -->
         <img class="logoClass"  src="@/assets/logo/horizontallogo.svg">
    </div>
        <!-- <p class="pClass">供应商入口</p> -->
    <div class="box">
      <van-form v-model="submitForm" :label-width="70" @submit="commitBtn">
        <van-field v-model="submitForm.phone" type="tel" label="手机号" />
        <van-field
          v-model="submitForm.code"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button size="small" type="primary" @click="sentBtn">
              <van-count-down :time="time" v-if="time" @finish="onFinish">
                <template #default="timeData">
                  <!-- <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span> -->
                  <span class="block">{{ timeData.minutes }}</span>
                  <span class="colon">:</span>
                  <span class="block">{{ timeData.seconds }}</span>
                </template>
              </van-count-down>
              <span v-else>发送验证码</span>
            </van-button>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button block type="primary" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { sendCode, PhoneCodeLogin } from "@/api/Ads/bidding";
import { Toast } from "vant";
import {debounce} from "@/utils/debounce";

const router = useRouter();
const time = ref();
const data = reactive({
  submitForm: {
    phone: "",
    code: "",
  },
});
const rules = reactive({});
const { submitForm } = toRefs(data);

onMounted(() => {});
//发送验证码
const sentBtn = debounce(() => {
  if (time.value) {
    return;
  }
  sendCode({ phone: data.submitForm.phone }).then((res) => {
    if (res.success) {
      Toast.success("验证码已发送");
      time.value = 90 * 2 * 1000;
    } else {
      Toast.fail(res.msg);
    }
  });
},1000);
//提交按钮
const commitBtn = () => {
  PhoneCodeLogin(data.submitForm).then((res) => {
    if (res.success) {
      localStorage.setItem("phone", data.submitForm.phone);
      setCookie("phone", data.submitForm.phone, 168);
      router.push({
        name: "vendorMenu",
        query: {
        },
      });
    } else {
      Toast.fail(res.msg);
    }
  });
};

//倒计时结束
const onFinish = () => {
  time.value = null;
};
//cookie存储数据
function setCookie(name, value, hours) {
  const now = new Date();
  // const expirationTime = now.getTime() + hours *  1000; // 计算过期时间
  const expirationTime = now.getTime() + hours * 60 * 60 * 1000; // 计算过期时间
  now.setTime(expirationTime);
  document.cookie = `${name}=${value}; expires=${now.toUTCString()}; path=/`;
}
</script>

<script>
export default {
  name: "",
};
</script>
<style scoped lang="scss">
.outbox {
  height: 100vh;
  display: flex;
  flex-direction: column; /* 垂直排列 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  .box {
    height: 400px;
    width: 300px;
  }
}
.logoClass{
  margin-bottom: 20px;
}
.pClass{
  font-size: 14px;
  font-weight: bold;
}
</style>
