import {defineStore} from 'pinia'
import {GetDirectoryPageList, GetTreeDataList} from "@/api/doc";

export const spaceStore = defineStore('tab', {
    state: () => {
        return {
            // 所有这些属性都将自动推断出它们的类型
            treeData: [1, 2, 3], // 所有的空间树列表
            currentLevel: 0, //当前空间层级
            currentFolder: [], // 当前空间的文件夹
            tabLineList: [], // tab列表
            page: 1,
            pageSize: 10,
            total: 0,
            fileList: [], // 对应目录下的文件资源
            maxSize: 0, // 当前屏幕下 最大可显示的文件数量
            finished: false, // 是否加载完
            loading: false, // 加载更多
        }
    },
    actions: {
        // 获取树列表
        fetchTreeData() {
            return new Promise(async (resolve, reject) => {
                    GetTreeDataList().then(res => {
                        this.treeData = res.data
                        console.log(this.treeData,"this.treeData")
                        resolve(res)
                    }).catch(err => {
                        reject(err)

                    })
                }
            )
        },
        getCurrentFolder() {
            console.log(this.currentLevel,"this.currentLevel")
            // 获取第一层数据
            if (this.currentLevel !== 0) {
                const list = this.currentFolder.filter(item => item.id === this.currentLevel)[0]
                this.currentFolder = list.children ? list.children : []
            } else {
                this.currentFolder = this.treeData
                // 删除所有tab数据
                this.tabLineList = []
            }
        },
        // 点击文件夹
        async handleFolder(item) {
            console.log(item,"item")
            // 修改tabList
            this.tabLineList.push(item)
            this.currentLevel = item.id
            this.getCurrentFolder()
            this.resetPage()
            await this.getFolderFile(item)
            // this.getFolderFile(this.tabLineList[this.tabLineList.length - 1])
        },
        resetPage(){
            this.page = 1
            this.pageSize = this.maxSize
            this.fileList = []
        },
        // 点击返回上一级
        handleBack(){
            if (this.tabLineList.length === 0) return
            const item = this.tabLineList[this.tabLineList.length - 2]
            // 如果不存在，则父级为首页
            if (!item) {
                this.currentLevel = 0
                this.getCurrentFolder()
            } else {
                this.currentLevel = item.id
                this.currentFolder = item.children
            }
            this.tabLineList.pop()
            this.resetPage()
        },
        // 进入到目标菜单
        async handleTarget(item, index) {
            this.tabLineList.splice(index + 1, this.tabLineList.length - index)
            this.currentFolder = item.children
            this.resetPage()
            await this.getFolderFile(this.tabLineList[this.tabLineList.length - 1])
        },
        // 获取目录下的文件资源
        async getFolderFile(item) {
            if (item?.space_id) {
                const params = {
                    id: item.id,
                    space_id: item.space_id,
                    page: this.page,
                    pageSize: this.pageSize
                }
                this.loading = true
                const res = await GetDirectoryPageList(params)
                this.loading = false
                if (res.code === 200) {
                    this.fileList = res.rows
                    console.log(this.fileList,"this.fileList")
                    this.total = res.total
                    this.finished = this.fileList.length >= this.total
                }
            }
        },
        updatePageSize(pageSize){
            this.pageSize += pageSize
        },
        updateMaxSize(maxSize){
            this.maxSize = maxSize
        },
        // 初始化数据
        initData(){
            this.treeData = []
            this.currentLevel = 0
            this.currentFolder = []
            this.tabLineList = []
            this.page = 1
            this.pageSize = 10
            this.total = 0
            this.fileList = []
            this.maxSize = 0
            this.finished = false
            this.loading = false
        }
    }
})