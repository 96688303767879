import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './permission'
import {PullRefresh, Lazyload} from 'vant'
import { Dialog } from 'vant';
import Vue3TouchEvents from "vue3-touch-events";
import pinia from "@/store/store";
import 'highlight.js/styles/pojoaque.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import hljs from "highlight.js";
import "./assets/style/css.css"
document.querySelectorAll("pre code").forEach((block) => {
  hljs.highlightBlock(block);
});
createApp(App)
    .use(Vue3TouchEvents)
    .use(store)
    .use(Vant)
    .use(router)
    .use(PullRefresh)
    .use(Lazyload)
    .use(Dialog)
    .use(pinia)
    .use(hljsVuePlugin)
    .mount('#app')
