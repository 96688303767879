<script setup>

import {reactive, ref} from "vue";
import moment from "moment";


const state = reactive({
  form: {
    id: '',
    actual_start_time: '', // 实际开始时间
    plan_duration: '', // 计划工时
    task_status: 2, // 任务状态
  },
  showPicker: false, // 是否显示时间选择器
})

const formRef = ref(null)
const nowDate = ref(new Date())
const emit = defineEmits(['handleSubmit'])
// 验证通过，保存表单
const handleSubmitStart = () => {
  formRef.value.submit()
}

// 保存时间
const saveTime = (val) => {
  state.showPicker = false
  state.form.actual_start_time = moment(val).format('YYYY-MM-DD')
}


defineExpose({
  handleSubmitStart
})
</script>

<template>
  <van-form @submit="emit('handleSubmit', state.form)" ref="formRef">
    <van-cell-group>
      <van-field
          name="plan_duration"
          label="计划工时"
          placeholder="请输入计划工时"
          v-model="state.form.plan_duration"
          type="digit"
          :rules="[{ required: true, message: '请输入计划工时' }]">
      </van-field>
    </van-cell-group>
    <van-cell-group>
      <van-field
          v-model="state.form.actual_start_time"
          name="plan_duration"
          label="实际开始时间"
          placeholder="请选择时间"
          @click="state.showPicker = true"
          :rules="[{ required: true, message: '请选择时间' }]"
          readonly/>
      <van-popup v-model:show="state.showPicker" position="bottom">
        <van-datetime-picker @confirm="saveTime" @cancel="state.showPicker = false" type="date" v-model="nowDate"/>
      </van-popup>
    </van-cell-group>
  </van-form>
</template>

<style scoped lang="scss">
:deep(.van-cell) {
  flex-direction: column;
}
</style>