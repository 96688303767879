import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: "home",
        component: () => import('../views/home.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/DingDing/login.vue'),
    },
    {
        path: '/loginFail',
        name: 'loginFail',
        component: () => import('../views/DingDing/loginFail.vue'),
    },
    //OA审批
    {
        path: '/WFS',
        name: 'WFS',
        component: () => import('../views/Wfs/index.vue'),
    },
    //审批
    {
        path: '/Approval/:formCode/:instanceId/:formto_id/:action',
        name: 'Approval',
        component: () => import('../views/Wfs/Approval/index.vue'),
    },
    //转交
    {
        path: '/transfer/:formto_id/:instance_id/:isdd',
        name: 'Transfer',
        component: () =>
            import('../views/Wfs/Approval/submitApproval/transfer.vue'),
    },
    //回退
    {
        path: '/return/:flow_code/:formto_id/:instance_id/:isdd',
        name: 'Return',
        component: () => import('../views/Wfs/Approval/submitApproval/return.vue'),
    },
    //评论
    {
        path: '/comment/:instance_id',
        name: 'Comment',
        component: () => import('../views/Wfs/Approval/submitApproval/comment.vue'),
        meta: {
            title: '评论',
        },
    },
    //评论明细
    {
        path: '/CommentDetail/:comment_id',
        name: 'CommentDetail',
        component: () =>
            import('../views/Wfs/Approval/submitApproval/commentDetailed.vue'),
    },
    //下载附件
    {
        path: '/DownloadFile/:file_id',
        name: 'DownloadFile',
        component: () =>
            import('../views/Wfs/Approval/submitApproval/downloadFile.vue'),
    },
    {
        path: '/submitApproval/:formto_id/:action/:empid/:statusid/:instance_id/:isdd',
        name: 'SubmitApproval',
        component: () => import('../views/Wfs/Approval/submitApproval/index.vue'),
    },
    {
        path: '/FormApply/:formCode/:formId/:formName',
        name: '表单申请',
        component: () => import('../views/Wfs/Approval/applyForm.vue'),
        meta: {
            title: '表单申请',
        },
    },
    {
        path: '/Todolist/:code',
        name: 'Todolist',
        component: () => import('../views/Wfs/Todolist/index.vue'),
        meta: {
            title: '待办列表',
        },
    },
    {
        path: '/BatchApprove',
        name: 'BatchApprove',
        component: () => import('../views/Wfs/Todolist/batchApprove/index.vue'),
        meta: {
            title: '批量审批',
        },
    },
    {
        path: '/TodolistSearch/:code',
        name: 'TodolistSearch',
        component: () => import('../views/Wfs/Todolist/Search/index.vue'),
        meta: {
            title: '待办搜索列表',
        },
    },
    {
        path: '/Process',
        name: 'Process',
        component: () => import('../components/Wfs/Process.vue'),
    },
       //待我考试
       {
        path: '/TmsPendingExamList',
        name: 'PendingExamList',
        component: () => import('../views/Tms/Exam/pendingExamList.vue'),
    },
    //考试记录
    {
        path: '/TmsMyExamRecord',
        name: 'TmsMyExamRecord',
        component: () => import('../views/Tms/Exam/examRecord.vue'),
    },
    //考试
    {
        path: '/TmsExam/:examId/:paperId/:type/:examRecordId',
        name: 'TmsExam',
        component: () => import('../views/Tms/Exam/exam.vue'),
    },
    //考试所有记录
    {
        path: '/MyAllExamRecord/:examId',
        name: 'MyAllExamRecord',
        component: () => import('../views/Tms/Exam/myAllExamRecord.vue'),
    },
    //整体预览
    {
        path: '/FullPreview/:score/:empId/:examRecordId',
        name: 'FullPreview',
        component: () => import('../views/Tms/Exam/fullPreview.vue'),
    },
    //考试详情
    {
        path: '/TmsMyAllExamRecord/:empId/:examRecordId/:questionNumber',
        name: 'TmsExamDetails',
        component: () => import('../views/Tms/Exam/examDetails.vue'),
    },
    //课程列表
    {
        path: '/TmsCourseList',
        name: 'TmsCourseList',
        component: () => import('../views/Tms/Study/courseList.vue'),
    },

    //课程搜索列表
    {
        path: '/TmsSearchCourseList',
        name: 'TmsSearchCourseList',
        component: () => import('../views/Tms/Study/courseSearch.vue'),
    },
    //预览课程新版
    {
        path: '/TmsCoursePreview/:classId/:fileId/:fileType',
        name: 'TmsCoursePreview',
        component: () => import('../views/Tms/Study/preview.vue'),
    },
    //预览PDF
    {
        path: '/PDF',
        name: 'PDF',
        component: () => import('@/components/previewPdf.vue'),
    },
    //测试表单
    {
        path: '/Test',
        name: 'Test',
        component: () =>
            import('../views/Wfs/Approval/components/testApply/testForm.vue'),
    },
    //访客信息
    {
        path: '/Wfs/visitor/:businessId/:phone',
        name: 'WfsVisitor',
        component: () =>
            import('../views/Wfs/Approval/components/visitorApply/visitInfo.vue'),
    },
    //扫码审批
    {
        path: '/Wfs/toApproval/:businessId/:formCode/:datetime',
        name: 'ToApproval',
        component: () =>
            import('../views/Wfs/Approval/components/visitorApply/toApproval.vue'),
    },
    //短链接跳转
    {
        path: '/s/:code',
        name: 'ShortUrl',
        component: () => import('../views/shortUrl.vue'),
    },
    //出勤确认
    {
        path: '/Tas/AttendanceCensus',
        name: 'AttendanceCensus',
        component: () => import('../views/Tas/AttendanceCensus/index.vue'),
    },
    //出勤查询
    {
        path: '/Tas/AttendanceInfo',
        name: 'AttendanceInfo',
        component: () => import('../views/Tas/AttendanceInfo/index.vue'),
    },
    //加班记录查询
    {
        path: '/Tas/OverTimeList',
        name: 'OverTimeList',
        component: () => import('../views/Tas/OverTimeList/index.vue'),
    },
    //假时查询
    {
        path: '/Tas/LeaveTimeQuery',
        name: 'LeaveTimeQuery',
        component: () => import('../views/Tas/LeaveTimeQuery/index.vue'),
    },
    //智能考勤(考勤机列表)
    {
        path: '/Tas/SmartAttendance',
        name: 'SmartAttendance',
        component: () => import('../views/Tas/SmartAttendance/index.vue'),
    },
    //智能考勤(智能考勤机)
    {
        path: '/Tas/SmartAttendance/Machine',
        name: 'Machine',
        component: () => import('../views/Tas/SmartAttendance/machine.vue'),
        props: true,
    },
    //智能考勤(虹膜/人脸采集人员列表)
    {
        path: '/Tas/SmartAttendance/collectEmp',
        name: 'collectEmp',
        component: () => import('../views/Tas/SmartAttendance/collectEmp.vue'),
        props: true,
    },
    //智能考勤(员工详细信息)
    {
        path: '/Tas/SmartAttendance/collectEmpDetail',
        name: 'collectEmpDetail',
        component: () => import('../views/Tas/SmartAttendance/collectEmpDetail.vue'),
        props: true,
    },
    //智能考勤(虹膜/人脸采集)
    {
        path: '/Tas/SmartAttendance/Capture',
        name: 'Capture',
        component: () => import('../views/Tas/SmartAttendance/Capture.vue'),
        props: true,
    },
    //工资查询
    {
        path: '/Sal/Payroll',
        name: 'Payroll',
        component: () => import('../views/Sal/Payroll/index.vue'),
    },
    //疫情情况上传
    {
        path: '/EpidemicSituation/Upload/:tenantId',
        name: 'EpidemicSituation',
        component: () => import('../views/Other/EpidemicSituation/index.vue'),
    },
    //提交成功
    {
        path: '/SubmitSuccess',
        name: 'SubmitSuccess',
        component: () => import('@/components/submitSuccess.vue'),
    },
    {
        path: '/externalTransfer',
        name: 'externalTransfer',
        component: () => import('../views/Other/externalTransfer.vue'),
    },

    //餐厅系统
    {
        path: '/Ads/DingingRoom',
        name: 'DiningRoom',
        component: () => import('../views/Ads/DiningRoom/index.vue'),
    },
    //会议室系统
    {
        path: '/Ads/MeetingRoom',
        name: 'MeetingRoom',
        component: () => import('../views/Ads/MeetingRoom/index.vue'),
    },
    //用车系统
    {
        path: '/Ads/Car',
        name: 'Car',
        component: () => import('../views/Ads/Car/index.vue'),
    },
    //用车系统
    {
        path: '/Ads/CarInfo',
        name: 'CarInfo',
        component: () => import('../views/Ads/Car/CarInfo.vue'),
    },
    //宿舍-水电费
    {
        path: '/Dor/WaterElectricityCost',
        name: 'WaterElectricityCost',
        component: () => import('../views/Dor/WaterElectricityCost/index.vue'),
    },
    // 多级目录
    {
        path: '/subMenu',
        name: 'subMenu',
        component: () => import('../views/subMenu.vue'),
    },
    // 点检
    {
        path: '/cks/check',
        name: 'check',
        component: () => import('../views/Cks/check/index.vue'),
    },
    // 点检详情
    {
        path: '/cks/checkItem/:checkStatus/:instanceId:/:checkId/:isSubmit',
        name: 'checkItem',
        component: () => import('../views/Cks/check/itemIndex.vue'),
    },
    // 复检详情
    {
        path: '/cks/reviewItem/:checkStatus/:instanceId/:checkId',
        name: 'reviewItem',
        component: () => import('../views/Cks/check/reviewItem.vue'),
    },
    // 点检列表
    {
        path: '/cks/checkList',
        name: 'checkList',
        component: () => import('../views/Cks/check/checkIndex.vue'),
    },
    // 扫码复检列表
    {
        path: '/cks/reviewList/:instanceId',
        name: 'reviewByPhone',
        component: () => import('../views/Cks/check/reviewByPhone.vue'),
    },
    // 复检列表
    {
        path: '/cks/reviewList',
        name: 'reviewList',
        component: () => import('../views/Cks/check/reviewIndex.vue'),
    },
    //点检中转
    {
        path: '/cks/Transit/:instanceId',
        name: 'Transit',
        component: () => import('../views/Cks/check/TransitIndex.vue'),
    },
    // 收藏列表
    {
        path: '/collect',
        name: 'Collect',
        component: () => import('../views/Collect/index.vue'),
    },
    // 收藏列表排序
    {
        path: '/collect/Edit',
        name: 'CollectEdit',
        component: () => import('../views/Collect/EditApp.vue'),
    },
    // 文档控件
    {
        path: '/Doc/Space',
        name: 'Space',
        component: () => import('../views/Doc/Space/index.vue'),
    },
    // 文档预览
    {
        path: '/Doc/Space/Preview',
        name: 'SpacePreview',
        component: () => import('../views/Doc/Space/PreViewFile.vue'),
    },
    // 我的任务
    {
        path: '/Tmg/MyTask',
        name: 'MyTask',
        component: () => import('../views/Tmg/MyTask/index.vue'),
    },
    // 我的指派
    {
        path: '/Tmg/MyAssign',
        name: 'MyAssign',
        component: () => import('../views/Tmg/MyAssign/index.vue'),
    },
    // 我的任务搜索
    {
        path: '/Tmg/MyTask/Search',
        name: 'MyTaskSearch',
        component: () => import('../views/Tmg/MyTask/searchTask.vue'),
    },
    // 我的任务详情
    {
        path: '/Tmg/MyTask/Detail',
        name: 'MyTaskDetail',
        component: () => import('../views/Tmg/MyTask/Details.vue'),
    },
    // 子任务列表
    {
        path: '/Tmg/MyTask/subtasks',
        name: 'MyTaskSubtasks',
        component: () => import('../views/Tmg/MyTask/subtasks.vue'),
    },
    
    // 供应商登录菜单列表
    {
        path: '/Ads/vendorMenu',
        name: 'vendorMenu',
        component: () => import('../views/Ads/bidding/viewOrder/vendorMenu.vue'),
    },
    // 下单历史记录
    {
        path: '/Ads/orderList',
        name: 'orderList',
        component: () => import('../views/Ads/bidding/orderList/index.vue'),
    },
    // 下单
    {
        path: '/Ads/placeOrder',
        name: 'placeOrder',
        component: () => import('../views/Ads/bidding/placeOrder/index.vue'),
    },
    // 添加采购详细
    {
        path: '/Ads/purchaseDetail',
        name: 'purchaseDetail',
        component: () => import('../views/Ads/bidding/purchaseDetail/index.vue'),
    },
    {
        path: '/Ads/purchaseDetaillist',
        name: 'purchaseDetail',
        component: () => import('../views/Ads/bidding/purchaseDetail/list.vue'),
    },
    // 比价
    {
        path: '/Ads/priceParity',
        name: 'priceParity',
        component: () => import('../views/Ads/bidding/priceParity/index.vue'),
    },
    {
        path: '/Ads/priceParitylist',
        name: 'priceParitylist',
        component: () => import('../views/Ads/bidding/priceParity/list.vue'),
    },
    // 报价
    {
        path: '/Ads/quotation',
        name: 'quotation',
        component: () => import('../views/Ads/bidding/quotation/index.vue'),
    },
    {
        path: '/Ads/quotationlist',
        name: 'quotationlist',
        component: () => import('../views/Ads/bidding/quotation/list.vue'),
    },
    // 收货
    {
        path: '/Ads/takeGoods',
        name: 'takeGoods',
        component: () => import('../views/Ads/bidding/takeGoods/index.vue'),
    },
    {
        path: '/Ads/takeGoodslist',
        name: 'takeGoodslist',
        component: () => import('../views/Ads/bidding/takeGoods/list.vue'),
    },
    // 供应商查看订单
    {
        path: '/Ads/viewOrder',
        name: 'viewOrder',
        component: () => import('../views/Ads/bidding/viewOrder/index.vue'),
    },
    {
        path: '/Ads/viewOrderlist',
        name: 'viewOrderlist',
        component: () => import('../views/Ads/bidding/viewOrder/list.vue'),
    },
    {
        path: '/Ads/inlet',
        name: 'inlet',
        component: () => import('../views/Ads/bidding/viewOrder/inlet.vue'),
    },
    // 预约会议
    {
        path: '/Ads/OrderMeeting',
        name: 'OrderMeeting',
        component: () => import('../views/Ads/OrderMeeting/index.vue'),
    },
    // 预约会议新增页面
    {
        path: '/Ads/OrderMeeting/edit',
        name: 'OrderMeetingEdit',
        component: () => import('../views/Ads/OrderMeeting/edit.vue'),
    },
    // 二次选会议室页面
    {
        path: '/Ads/OrderMeeting/choiceMeetingRoom',
        name: 'choiceMeetingRoom',
        component: () => import('../views/Ads/OrderMeeting/choiceRoom.vue'),
    },
    // 我的会议页面
    {
        path: '/Ads/MyMeeting',
        name: 'MyMeeting',
        component: () => import('../views/Ads/MyMeeting/index.vue'),
    },
    // 我的会议页面
    {
        path: '/Ads/MyMeeting/search',
        name: 'MyMeetingSearch',
        component: () => import('../views/Ads/MyMeeting/searchMyMeeting.vue'),
    },
    // 参会人员页面
    {
        path: '/Ads/MyMeeting/participants',
        name: 'MyMeetingParticipants',
        component: () => import('../views/Ads/MyMeeting/participants.vue'),
    },
    // 会议室审批页面
    {
        path: '/meetingapprove',
        name: 'Meetingapprove',
        component: () => import('../views/Ads/meetingapprove/index.vue'),
    },
    // 会议室审批搜索页面
    {
        path: '/meetingapprove/search',
        name: 'MeetingapproveSearch',
        component: () => import('../views/Ads/meetingapprove/searchApprove.vue'),
    },
    // 会议室审批详情页面
    {
        path: '/meetingapprove/detail',
        name: 'MeetingapproveDetail',
        component: () => import('../views/Ads/meetingapprove/detail.vue'),
    },
    // 按部门选人页面
    {
        path: '/departmentSelection',
        name: 'DepartmentSelection',
        component: () => import('@/components/DepartmentSelection/index.vue'),
    },
    // 新闻公告页面
    {
        path: '/oa/newslist',
        name: 'newslist',
        component: () => import('../views/Ads/NewsList/index.vue'),
    },
    // 新闻公告搜索页面
    {
        path: '/oa/newslist/search',
        name: 'newslistSearch',
        component: () => import('../views/Ads/NewsList/searchNews.vue'),
    },
    // 新闻公告详情预览页面
    {
        path: '/oa/newslist/newsPreview',
        name: 'newsPreview',
        component: () => import('../views/Ads/NewsList/newsPreview.vue'),
    },
    // 通用富文本显示页面
    {
        path: '/common/richText',
        name: 'RichText',
        component: () => import('../components/richTextPage.vue'),
    },
    // 智能办公
    {
        path: '/oa/chatGPT',
        name: 'chatGPT',
        component: () => import('../views/Oa/chatGPT/index.vue'),
    },
    // 会话列表
    {
        path: '/oa/chatList/:modelId',
        name: 'chatList',
        component: () => import('../views/Oa/chatGPT/chatList.vue'),
    },
    // 助手列表
    {
        path: '/oa/assistantList/:modelId',
        name: 'assistantList',
        component: () => import('../views/Oa/chatGPT/assistantList.vue'),
    },

    // 经营管理交互达成率
    {
        path: '/kanban/operation',
        name: 'operation',
        component: () => import('../views/Kanban/Operation/index.vue'),
    },
    // 公告列表
    {
        path: '/pub/manage/issueList',
        name: 'pubIssueList',
        component: () => import('../views/Pub/manage/issueList/index.vue'),
    },
    // 公告搜索
    {
        path: '/pub/manage/IssueSearch',
        name: 'IssueSearch',
        component: () => import('../views/Pub/manage/issueList/searchIssueList.vue'),
    },
    // 公告详情
    {
        path: '/pub/manage/IssueDetail',
        name: 'IssueDetail',
        component: () => import('../views/Pub/manage/issueList/detail.vue'),
    },
    // 经营管理生产计划
    {
        path: '/kanban/productionPlan',
        name: 'productionPlan',
        component: () => import('../views/Kanban/ProductionPlan/index.vue'),
    },
    // 制造首页扫码
    {
        path: '/isc/homePage',
        name: 'homePage',
        component: () => import('../views/Isc/homePage/index.vue'),
    },
    // 停机报告
    {
        path: '/isc/downtimeReport',
        name: 'downtimeReport',
        component: () => import('../views/Isc/downtimeReport/index.vue'),
    },
    // 资产盘点手动
    {
        path: '/eam/manualInventory',
        name: 'manualInventory',
        component: () => import('../views/Eam/manualInventory/index.vue'),
    },

    // 资产盘点
    {
        path: '/eam/reinspection',
        name: 'reinspection',
        component: () => import('../views/Eam/reinspection/index.vue'),
    },
    // 资产盘点成功
    {
        path: '/eam/success',
        name: 'inventorySuccess',
        component: () => import('../views/Eam/details/success.vue'),
    },
    // 资产盘点详情
    {
        path: '/eam/details',
        name: 'inventoryDetails',
        component: () => import('../views/Eam/details/index.vue'),
    },
    // 公告详情
    {
        path: '/oa/remoteControl',
        name: 'RemoteControl',
        component: () => import('../views/Oa/remoteControl/index.vue'),
    },
    // 公告详情
    {
        path: '/oa/remoteControl/details',
        name: 'RemoteDetails',
        component: () => import('../views/Oa/remoteControl/remoteDetails.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
