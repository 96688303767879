import axios from "@/utils/request";

/**
 * 遥控终端相关api
 */

/**
 * 获取终端列表
 * @returns {*}
 * @constructor
 */
export function GetClient(){
    return axios({
        url:'/oa_kanban/oa/kanban/ClientAuthority/GetClient',
        method:'POST',
    })
}

/**
 * 获取终端对应的定播资源列表
 * @returns {*}
 * @constructor
 */
export function IdGetResource(id){
    return axios({
        url:'/oa_kanban/oa/kanban/Client/IdGetResource',
        method:'POST',
        data:{id}
    })
}

/**
 * 修改定播、轮播
 * @param id
 * @returns {AxiosPromise}
 * @constructor
 */
export function UpdatePlay(data){
    return axios({
        url:'/oa_kanban/oa/kanban/Client/UpdatePlay',
        method:'POST',
        data
    })
}
