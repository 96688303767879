<!--访客信息-->
<template>
  <div class="visitor-info">
    <div class="row">
      <div class="title">有效期：</div>
      <div class="content">
        {{ pageConfig.visitStartTime }} ~ {{ pageConfig.visitEndTime }}
      </div>
    </div>
    <div class="row">
      <div class="title">来访单位：</div>
      <div class="content">
        {{ pageConfig.visitOrgName }}
      </div>
    </div>
    <div class="row">
      <div class="title">访客：</div>
      <div class="content">
        {{ pageConfig.visitorName }}
      </div>
    </div>
    <div class="qr-code-div">
      <p>
        <span style="color: red; font-weight: bold">* </span
        >请将此码出示给园区保安或前台
      </p>
      <vue-qr
        class="qrcode"
        :logoSrc="require('../../../../../assets/logo.svg')"
        :text="pageConfig.qrCodeUrl"
        :size="250"
      ></vue-qr>
    </div>
    <div class="row">
      <div class="title">接待人：</div>
      <div class="content">
        {{ pageConfig.receptionist }}
      </div>
    </div>
    <div class="row">
      <div class="title">接待人电话：</div>
      <div class="content">
        {{ pageConfig.receptionist_phone }}
      </div>
    </div>
    <div class="row">
      <div class="title">到访园区：</div>
      <div class="content">
        {{ pageConfig.visitOffice }}
      </div>
    </div>
    <div class="row">
      <div class="title">园区地址：</div>
      <div class="content">
        {{ pageConfig.companyAddress }}
      </div>
    </div>
    <div class="row">
      <div class="title">官网地址：</div>
      <div class="content">
        <a :href="pageConfig.officialWebsiteUrl">{{
          pageConfig.officialWebsiteUrl
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive,ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { GetFormData } from '@/api/oawfs.js'
import { onMounted } from '@vue/runtime-core'
import moment from 'moment'
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
   components: {VueQr},
  setup () {
    const route = useRoute()
    //页面配置
    const pageConfig = reactive({
      qrInterval: null,//定时器
      businessId: '',//业务id
      phone: '',//来访人手机
      visitStartTime: null,//来访开始日期
      visitEndTime: null,//来访结束日期
      visitorName: '',//访客姓名
      visitOrgName:'',//来访单位
      receptionist: '',//接待人
      receptionist_phone:'',//接待人电话
      visitOfficeCode: '',//到访园区代码
      visitOffice: '',//到访园区
      companyAddress: '',//公司地址
      formCode:'GA0001',//表单代码
      qrCodeUrl:process.env.VUE_APP_H5_URL + '/Wfs/toApproval/' + route.params.businessId + '/GA0001/'+new Date(),//扫码跳转审批
      officialWebsiteUrl: 'https://www.shinetech-optics.com/',//官网地址
    })
    //内置函数
    const methods = {
      //初始化
      init: function () {
        pageConfig.businessId = route.params.businessId
        pageConfig.phone = route.params.phone
        //获取绑定来访表单数据
        methods.bindVisitFormData()
        //刷新二维码
        methods.refreshQrCode()
        //离开页面
        methods.onblur()
      },
      //离开页面
      onblur: function () { 
        //离开
        window.onblur = function () {
          window.clearInterval(pageConfig.qrInterval)
        }
        // 监听 visibility change 事件
        document.addEventListener('visibilitychange', function () {
          var isHidden = document.hidden
          if (isHidden) {
          window.clearInterval(pageConfig.qrInterval)
          }
        })
        window.addEventListener('popstate', function (e) {
          window.clearInterval(pageConfig.qrInterval)
        })
      },
      //刷新二维码
      refreshQrCode: function () { 
        pageConfig.qrInterval = setInterval(() => {
          pageConfig.qrCodeUrl = process.env.VUE_APP_H5_URL + '/Wfs/toApproval/' + route.params.businessId + '/GA0001/' + new Date().getTime()
          console.log(pageConfig.qrCodeUrl)
        }, 15000);        
      },
      //获取绑定来访表单数据
      bindVisitFormData: function () {
        GetFormData({ businessId: pageConfig.businessId, formCode: pageConfig.formCode }).then(res => {
          if (res.data) {
            res.data.forEach(item => {
              //来访开始日期
              if (item.column_code == "visit_start_time") {
                pageConfig.visitStartTime = methods.convertTime(item.column_value)
              }
              //来访结束日期
              if (item.column_code == "visit_end_time") {
                pageConfig.visitEndTime = methods.convertTime(item.column_value)
              }
              //到访园区
              if (item.column_code == "office_name") {
                pageConfig.visitOffice = item.column_value
              }              
              //公司地址
              if (item.column_code == "company_address") {
                pageConfig.companyAddress = item.column_value
              }
              //来访单位
              if (item.column_code == "visit_org_name") {
                pageConfig.visitOrgName = item.column_value
              }
              //接待人=>申请人
              if (item.column_code == "apply_emp_info") {
                pageConfig.receptionist = item.column_value.split('-')[1]
              }
              //接待人电话=>申请人
              if (item.column_code == "apply_emp_phone") {
                pageConfig.receptionist_phone = item.column_value
              }
              //厂区代码
              if (item.column_code == "office_code") {
                pageConfig.visitOfficeCode = item.column_value
              }
              
              //访客信息
              if (item.column_code == "contacts_list" && pageConfig.phone) {
                let jsonArr = JSON.parse(item.column_value)
                jsonArr.forEach(element => {
                  if (element.手机号 == pageConfig.phone) {
                    pageConfig.visitorName = element.姓名
                    return
                  }
                });
              }
            });
          }
        })
      },
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD HH:mm')
        }
      },
    }
    onMounted(() => {
      //初始化
      methods.init()
    })
    return {
      methods,
      pageConfig
    }
  }
}
</script>
<style lang="scss">
body {
}
.visitor-info {
  background-color: #fff;
  margin: 10px auto;
  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 30px;
    line-height: 30px;

    .title {
      width: 80px;
      text-align: right;
    }

    .content {
      width: calc(100% - 80px);
      text-align: left;
    }
  }
  .qr-code-div {
    width: 100%;
    margin: auto;
    text-align: center;
  }
}
</style>
