<script setup>


import {reactive, ref} from "vue";
import CollapseItem from "@/views/Wfs/components/CollapseItem.vue";
import {onMounted} from "@vue/runtime-core";

const props = defineProps({
  formList: {
    type: Array,
    default: []
  },
  fileBase64: {
    type: Array,
    default: []
  }
})
const state = reactive({
  openList: [],
  isOpenAll:true,
  activeTab:0
})

// 改变下拉框
const changeOpenList = (index) => {
  if (state.openList.indexOf(index) === -1) {
    state.openList.push(index)
  } else {
    state.openList.splice(state.openList.indexOf(index), 1); // 如果存在，删除该数字
  }
}
// 改变tab
const changeTab = (index) => {
  if (state.openList.indexOf(index) === -1){
    state.openList.push(index)
  }
}

onMounted(() => {
  if (state.isOpenAll) state.openList = Array.from({length: props.formList.length}, (_, i) => i);
})

</script>

<template>
  <van-tabs
      color="#000"
      @change="changeTab"
      v-model:active="state.activeTab" scrollspy sticky>
    <van-tab
        :line-width="20"
        :title="item.group_name"
        :name="index"
        v-for="(item,index) in formList"
        :key="index">
      <CollapseItem
          class="item" :fromItem="item"
          :fileBase64="fileBase64"
          :fromIndex="index"
          :openList="state.openList"
          @changeOpenList="changeOpenList"/>
    </van-tab>
  </van-tabs>
</template>

<style scoped lang="scss">
 ::v-deep .van-tab--active{
   font-weight: 700;
 }
 .item{

 }
</style>