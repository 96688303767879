<!-- 表单申请（动态） -->
<template>
  <div class="FormApply">
    <van-overlay :show="pageConfig.showLoadding" style="z-index: 9999999999">
      <van-loading type="spinner" color="#1989fa" />
    </van-overlay>
    <!-- 中间表单 -->
    <van-form class="form" ref="formRef">
      <van-cell-group inset style="margin: 1vh 0vh 0 0">
        <span class="column-title">
          <van-icon class="lable_icon" name="star" />申请人
        </span>
        <van-field style="padding-top: 0" v-model="pageConfig.columnObject.apply_emp_info" name="申请人" disabled />
        <div class="boxContent"></div>
        <span class="column-title">
          <van-icon class="lable_icon" name="star" />部门
        </span>
        <van-field style="padding-top: 0" v-model="pageConfig.columnObject.dept_name" name="部门" disabled />
        <!-- 动态渲染 -->
        <div v-for="item in pageConfig.columnList" :key="item.id" v-show="item.edit_type != '2'">
          <div class="boxContent"></div>
          <span v-if="item.component_type != '5'" class="column-title">
            <div v-if="item.required == 'Y' && item.edit_type != '2'">
              <van-icon class="lable_icon" name="star" />{{ item.column_name }}
            </div>
            <div v-else>
              <van-icon class="lable_icon" />{{ item.column_name }}
            </div>
          </span>
          <!-- 输入框 -->
          <div v-if="item.component_type == '1'">
            <van-field v-if="item.max_column_length < 200" :maxlength="item.max_column_length" style="padding-top: 0"
              :placeholder="'请输入' + item.column_name" v-model="pageConfig.columnObject[item.column_code]"
              :name="item.column_name" :type="item.column_type == 'number' ? 'digit' : 'text'"
              @blur="methods.inputBlur(item)" />
            <van-field v-else :maxlength="item.max_column_length" style="padding-top: 0"
              :placeholder="'请输入' + item.column_name" v-model="pageConfig.columnObject[item.column_code]"
              :name="item.column_name" type="textarea" rows="3" :show-word-limit="true" />
          </div>
          <!-- 下拉框 -->
          <div v-else-if="item.component_type == '2'">
            <van-field style="padding-top: 0" v-model="pageConfig.columnObject[item.column_code + '_name']"
              :name="item.column_name" :placeholder="'请选择' + item.column_name" :rules="[
      {
        required:
          item.required == 'Y' && item.edit_type != '2'
            ? true
            : false,
        message: '请选择' + item.column_name,
      },
    ]" is-link readonly @click="pageConfig.showSelectPop[item.column_code] = true" />
            <!-- 弹出框 -->
            <van-popup v-model:show="pageConfig.showSelectPop[item.column_code]" position="bottom" round>
              <van-picker :columns="pageConfig.selectList[item.column_code]" @confirm="(res) => {
        methods.selectChange(res, item.column_code)
      }
      " @cancel="pageConfig.showSelectPop[item.column_code] = false">
              </van-picker>
            </van-popup>
          </div>
          <!-- 多选下拉 -->
          <div v-else-if="item.component_type == '3'">
            <van-field style="padding-top: 0" v-model="pageConfig.columnObject[item.column_code + '_name']" :rules="[
      {
        required:
          item.required == 'Y' && item.edit_type != '2'
            ? true
            : false,
        message: '请选择' + item.column_name,
      },
    ]" :name="item.column_name" :placeholder="'请选择' + item.column_name" is-link readonly
              @click="pageConfig.showSelectPop[item.column_code] = true" />
            <!-- 验证类型弹出框 -->
            <van-popup v-model:show="pageConfig.showSelectPop[item.column_code]" position="bottom" style="height: 60%"
              round>
              <MultipleSelect :optionList="pageConfig.selectList[item.column_code]" @onComfirm="(res) => {
        methods.multipleSelectComfirm(res, item.column_code)
      }
      " @onCancel="pageConfig.showSelectPop[item.column_code] = false"></MultipleSelect>
            </van-popup>
          </div>
          <!-- 日期控件 -->
          <div v-else-if="item.component_type == '8'">
            <van-field style="padding-top: 0" v-model="pageConfig.columnObject[item.column_code]"
              :name="item.column_name" is-link readonly :placeholder="'请选择' + item.column_name" :rules="[
      {
        required:
          item.required == 'Y' && item.edit_type != '2'
            ? true
            : false,
        message: '请选择' + item.column_name,
      },
    ]" @click="methods.handleClickDatePicker(item.column_code)" />
            <van-popup v-model:show="pageConfig.showSelectPop[item.column_code]" position="bottom" round>
              <van-datetime-picker v-if="item.column_type == 'date'" v-model="pageConfig.datepicker[item.column_code]"
                @cancel="pageConfig.showSelectPop[item.column_code] = false" @confirm="(res) => {
        methods.dateSelect(res, item.column_code)
      }
      " type="date" :title="'选择' + item.column_name" />
              <van-datetime-picker v-else v-model="pageConfig.datepicker[item.column_code]" @confirm="(res) => {
        methods.datetimeSelect(res, item.column_code)
      }
      " type="datetime" :title="'选择' + item.column_name" />
            </van-popup>
          </div>
          <!-- 表格控件 -->
          <div v-else-if="item.component_type == '4'">
            <div class="vant-table">
              <table cellspacing="0" :style="'width:' + (item.children.length * 150 + 100) + 'px'" class="table">
                <tr>
                  <th class="th" width="50px">序号</th>
                  <th class="th" width="50px">操作</th>
                  <th v-for="col in item.children" class="th" width="150px" :key="col.id">
                    {{ col.column_name }}
                  </th>
                </tr>
                <tr v-for="(row, _index) in pageConfig.columnObject[
      item.column_code
    ]" :key="row.id">
                  <td align="center">{{ _index + 1 }}</td>
                  <td style="text-align: center">
                    <span class="link-text" style="color: #f56c6c"
                      @click="methods.deleteTableRow(item.column_code, row.id)">删除</span>
                  </td>
                  <td v-for="col in item.children" style="text-align: center" :key="col.id">
                    <!-- 表格-日期 -->
                    <div v-if="col.component_type == '8'">
                      <van-field style="padding-top: 0" v-model="row[col.column_code]" :name="col.column_name" is-link
                        readonly :placeholder="'请选择' + col.column_name" :rules="[
      {
        required: col.required == 'Y' ? true : false,
        message: '请选择' + col.column_name,
      },
    ]" @click="
      methods.handleTableDatePicker(
        col.column_code,
        col.column_type
      )
      " />
                      <van-popup v-model:show="pageConfig.showSelectPop[col.column_code]" position="bottom" round>
                        <van-datetime-picker v-if="col.column_type == 'date'"
                          v-model="pageConfig.datepicker[col.column_code]" @cancel="
      pageConfig.showSelectPop[col.column_code] = false
      " @confirm="(res) => {
        methods.tableDateSelect(res, row, col.column_code)
      }
      " type="date" :title="'选择' + col.column_name" />
                        <van-datetime-picker v-else v-model="pageConfig.datepicker[col.column_code]" @confirm="(res) => {
        methods.tableDatetimeSelect(
          res,
          row,
          col.column_code
        )
      }
      " type="datetime" :title="'选择' + col.column_name" />
                      </van-popup>
                    </div>
                    <!-- 表格-下拉框 -->
                    <div v-else-if="col.component_type == '2'">
                      <van-field style="padding-top: 0" v-model="row[col.column_code + '_name']"
                        :placeholder="'请选择' + col.column_name" :name="item.column_name" is-link readonly @click="
      pageConfig.showSelectPop[col.column_code] = true
      " />
                      <!-- 弹出框 -->
                      <van-popup v-model:show="pageConfig.showSelectPop[col.column_code]" position="bottom" round>
                        <van-picker :columns="pageConfig.selectList[col.column_code]" @confirm="(res) => {
        methods.tableSelectChange(
          row,
          col.column_code,
          res
        )
      }
      " @cancel="
      pageConfig.showSelectPop[col.column_code] = false
      ">
                        </van-picker>
                      </van-popup>
                    </div>
                    <div v-else>
                      <van-field :maxlength="col.max_column_length" style="padding-top: 0"
                        :placeholder="'请输入' + col.column_name" v-model="row[col.column_code]" :name="col.column_name"
                        :type="col.column_type == 'number' ? 'digit' : 'text'"
                        @blur="methods.tableInputBlur(row, col)" />
                    </div>
                  </td>
                </tr>
              </table>
              <van-button icon="plus" size="small" @click="methods.addTableRow(item.column_code, item.children)"
                type="default">添加
              </van-button>
            </div>
          </div>
          <!-- 组合列表 -->
          <div v-else-if="item.component_type == '9'">
            <div class="detail-list">
              <div style="margin-left: 20px" v-for="(row, index) in pageConfig.columnObject[
      item.column_code
    ]" :key="row.id">
                <div class="list-title">
                  <div class="title-name">
                    {{ item.column_name }}({{ index + 1 }})
                  </div>
                  <div class="list-delete">
                    <a href="javascript:void();" @click="methods.deleteTableRow(item.column_code, row.id)">删除</a>
                  </div>
                </div>
                <div v-for="col in item.children" :key="col.id">
                  <span class="column-title">
                    <div v-if="col.required == 'Y'">
                      <van-icon class="lable_icon" name="star" />{{
      col.column_name
    }}
                    </div>
                    <div v-else>
                      <van-icon class="lable_icon" />{{ col.column_name }}
                    </div>
                  </span>
                  <!-- (组合)日期控件 -->
                  <div v-if="col.component_type == '8'">
                    <van-field style="padding-top: 0" v-model="row[col.column_code]" :name="col.column_name" is-link
                      readonly :placeholder="'请选择' + col.column_name" :rules="[
      {
        required: col.required == 'Y' ? true : false,
        message: '请选择' + col.column_name,
      },
    ]" @click="pageConfig.showSelectPop[col.column_code] = true" />
                    <van-popup v-model:show="pageConfig.showSelectPop[col.column_code]" position="bottom" round>
                      <van-datetime-picker v-if="col.column_type == 'date'"
                        v-model="pageConfig.datepicker[col.column_code]" @confirm="(res) => {
        methods.tableDateSelect(res, row, col.column_code)
      }
      " type="date" :title="'选择' + col.column_name" />
                      <van-datetime-picker v-else v-model="pageConfig.datepicker[col.column_code]" @confirm="(res) => {
        methods.tableDatetimeSelect(
          res,
          row,
          col.column_code
        )
      }
      " type="datetime" :title="'选择' + col.column_name" />
                    </van-popup>
                  </div>
                  <!-- (组合)下拉框 -->
                  <div v-else-if="col.component_type == '2'">
                    <van-field style="padding-top: 0" v-model="row[col.column_code + '_name']" :name="col.column_name"
                      :placeholder="'请选择' + col.column_name" :rules="[
      {
        required: col.required == 'Y' ? true : false,
        message: '请选择' + col.column_name,
      },
    ]" is-link readonly @click="pageConfig.showSelectPop[col.column_code] = true" />
                    <!-- 弹出框 -->
                    <van-popup v-model:show="pageConfig.showSelectPop[col.column_code]" position="bottom" round>
                      <van-picker :columns="pageConfig.selectList[col.column_code]" @confirm="(res) => {
        methods.combinationSelectChange(
          row,
          col.column_code,
          res
        )
      }
      " @cancel="
      pageConfig.showSelectPop[col.column_code] = false
      ">
                      </van-picker>
                    </van-popup>
                  </div>
                  <!-- (组合)多选下拉 -->
                  <div v-else-if="col.component_type == '3'">
                    <van-field style="padding-top: 0" v-model="row[col.column_code + '_name']" :rules="[
      {
        required: col.required == 'Y' ? true : false,
        message: '请选择' + col.column_name,
      },
    ]" :name="col.column_name" :placeholder="'请选择' + col.column_name" is-link readonly
                      @click="pageConfig.showSelectPop[col.column_code] = true" />
                    <!-- 验证类型弹出框 -->
                    <van-popup v-model:show="pageConfig.showSelectPop[col.column_code]" position="bottom"
                      style="height: 60%" round>
                      <MultipleSelect :optionList="pageConfig.selectList[col.column_code]" @onComfirm="(list) => {
        methods.combinationSelectMultipleChange(
          row,
          col.column_code,
          list
        )
      }
      " @onCancel="
      pageConfig.showSelectPop[col.column_code] = false
      "></MultipleSelect>
                    </van-popup>
                  </div>
                  <!-- (组合)上传附件 -->
                  <div v-else-if="col.component_type == '7'">
                    <div class="upload-file">
                      <van-uploader :after-read="methods.uploadFile" multiple
                        v-model="pageConfig.fileList[col.column_code]" />
                    </div>
                  </div>
                  <!-- (组合)输入框 -->
                  <div v-else>
                    <van-field v-if="col.max_column_length < 200" :maxlength="col.max_column_length"
                      style="padding-top: 0" :placeholder="'请输入' + col.column_name" v-model="row[col.column_code]"
                      :name="col.column_name" :type="col.column_type == 'number' ? 'digit' : 'text'"
                      @blur="methods.tableInputBlur(row, col)" />
                    <van-field v-else :maxlength="col.max_column_length" style="padding-top: 0"
                      :placeholder="'请输入' + col.column_name" v-model="row[col.column_code]" :name="col.column_name"
                      type="textarea" rows="3" />
                  </div>
                </div>
              </div>
              <van-button icon="plus" class="add-button" size="small"
                @click="methods.addTableRow(item.column_code, item.children)" type="default">增加明细
              </van-button>
            </div>
          </div>
          <!-- 自选签核 -->
          <div v-else-if="item.component_type == '5'">
            <CounterSign style="height: auto" :limitNum="item.countersign_num" :required="item.required == 'Y' && item.edit_type != '2' ? true : false
      " :applyEmp="pageConfig.applyEmp" :showLabel="item.column_name" @empTOListChange="(res) => {
        methods.countersignChange(res, item.column_code)
      }
      "></CounterSign>
          </div>
          <!-- 附件 -->
          <div v-else-if="item.component_type == '7'">
            <div class="upload-file">
              <van-uploader :after-read="methods.uploadFile" multiple v-model="pageConfig.fileList[item.column_code]" />
            </div>
          </div>
          <!-- 示例附件 -->
          <div v-else-if="item.component_type == '12'">
            <div class="file-block" @click="methods.downloadFile(file)" v-for="file in item.fileList" :key="file.id"
              :title="file.name">
              <div class="file-icon">
                <img src="../../../../assets/file-img/excel.svg"
                  v-if="file.extension == '.xls' || file.extension == '.xlsx'" height="30" />
                <img src="../../../../assets/file-img/img.svg" v-else-if="file.extension == '.png' ||
      file.extension == '.jpg' ||
      file.extension == '.jpeg' ||
      file.extension == '.gif' ||
      file.extension == '.bmp' ||
      file.extension == '.svg'
      " height="30" />
                <img src="../../../../assets/file-img/pdf.svg" v-else-if="file.extension == '.pdf'" height="30" />
                <img src="../../../../assets/file-img/ppt.svg" v-else-if="file.extension == '.ppt'" height="30" />
                <img src="../../../../assets/file-img/txt.svg" v-else-if="file.extension == '.txt'" height="30" />
                <img src="../../../../assets/file-img/word.svg" v-else-if="file.extension == '.doc' || file.extension == '.docx'
      " height="30" />
                <img src="../../../../assets/file-img/default.svg" v-else height="30" />
              </div>
              <div class="file-name">
                <span>{{ file.name }}</span>
              </div>
              <div class="file-size">
                <span v-if="Number(file.file_size) / 1024 / 1024 < 1">{{ (Number(file.file_size) / 1024).toFixed(2)
                  }}K</span>
                <span v-else>{{
      (Number(file.file_size) / 1024 / 1024).toFixed(2)
    }}M</span>
              </div>
            </div>
          </div>
          <!-- 文本 -->
          <div v-else-if="item.component_type == '10'">
            <div class="label-block">
              <pre style="
                  font-family: '-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif';
                  font-size: 12px;
                ">{{ item.remark }}</pre>
            </div>
          </div>
        </div>
      </van-cell-group>
    </van-form>
    <div class="bottom" style="z-index: 100">
      <van-button type="primary" block style="border-radius: 10px; margin-top: 1vh" @click="methods.submitForm">提交
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  SubmitApprovalForm,
  GetFormColumn,
  CheckFormColumn,
  GetOptionData,
} from '@/api/oawfs.js'
import { GetEmpDeptInfo } from '@/api/ehr_emp.js' //emp的接口
import { Toast, Dialog, ImagePreview } from 'vant'
import { GetDictList, UploadBase64, GetFileList } from '@/api/oaApi.js'
import MultipleSelect from '@/components/multipleSelect.vue'
import CounterSign from '@/components/Emp/counterSign.vue'
import moment from 'moment'

export default {
  props: ['applyEmp', 'formCode', 'formId', 'verifyCode'],
  components: { MultipleSelect, CounterSign },
  setup (props) {
    const router = useRouter()
    //表单验证
    const formRef = ref('')
    //页面参数配置
    const pageConfig = reactive({
      formCode: props.formCode, //表单代码
      formId: props.formId, //表单id
      showSelectPop: {}, //下拉框弹框
      datepicker: {}, //日期控件默认值
      selectList: {}, //下拉框绑定数据源
      columnList: [], //表单字段列表
      columnSourceList: [], //(源数据)表单字段列表
      countersignList: {}, //{column_code:list{[{status_id: '',is_cc: 'N',handler_emp_id: '',}],is_cc:'',status_id:''}}
      fileList: {}, //附件对象
      showLoadding: false, //加载效果
      columnObject: {
        apply_emp_id: '', //申请人id
        apply_emp_code: '', //申请人工号
        apply_emp_name: '', //申请人姓名
        apply_emp_info: '', //申请人信息
        fill_emp_id: '', //填单人id
        fill_emp_info: '', //填单人id
        dept_name: '', //部门代码-部门名称
        dept_code: '', //部门代码
        dept_id: '', //部门id
      }, //表单数据模型
    })
    //内置函数
    const methods = {
      // 选择表格中的日期选择器
      handleTableDatePicker: function (code, type) {
        pageConfig.showSelectPop[code] = true
        if (type === 'date') {
          pageConfig.datepicker[code] = new Date()
        }
      },
      // 选择日期选择器
      handleClickDatePicker: function (code) {
        pageConfig.showSelectPop[code] = true
        pageConfig.datepicker[code] = new Date()
      },
      // 单选改变
      tableSelectChange: function (row, columnCode, data) {
        row[columnCode] = data.value
        row[columnCode + '_name'] = data.text
        pageConfig.showSelectPop[columnCode] = false
      },
      //初始化
      init: function () {
        //填单人
        pageConfig.columnObject.fill_emp_id = localStorage.getItem('emp_id') //填单人id
        pageConfig.columnObject.fill_emp_info =
          localStorage.getItem('emp_code') +
          '-' +
          localStorage.getItem('emp_name') //填单人信息
        //申请人
        pageConfig.columnObject.apply_emp_id = props.applyEmp.emp_id
        pageConfig.columnObject.apply_emp_code = props.applyEmp.emp_code
        pageConfig.columnObject.apply_emp_info =
          props.applyEmp.emp_code + '-' + props.applyEmp.emp_name
        pageConfig.columnObject.apply_emp_name = props.applyEmp.emp_name
        //获取员工部门
        methods.getEmpDept()
        //获取表单字段列表
        methods.getFormColumnList()
      },
      //获取员工部门
      getEmpDept: function () {
        GetEmpDeptInfo({ empId: pageConfig.columnObject.apply_emp_id }).then(
          (res) => {
            if (res.code == 200 && res.data) {
              pageConfig.columnObject.dept_name =
                res.data.dept_code + '-' + res.data.dept_name
              pageConfig.columnObject.dept_id = res.data.dept_id
            }
          }
        )
      },
      //更新申请人
      changeApplyEmp: function (item) {
        pageConfig.columnObject.apply_emp_id = item.id
        pageConfig.columnObject.apply_emp_code = item.emp_code
        pageConfig.columnObject.apply_emp_info =
          item.emp_code + '-' + item.emp_name
        //获取员工部门
        methods.getEmpDept()
      },
      //获取表单字段列表
      getFormColumnList: function () {
        GetFormColumn(pageConfig.formId).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          pageConfig.columnSourceList = JSON.parse(JSON.stringify(res.data))
          pageConfig.columnList = res.data
          //赋值表单数据模型
          methods.setColumnObject()
        })
      },
      //设置下拉框数据源
      setSelectDataSource: function (_row) {
        //字典数据
        if (_row.option_source == 1) {
          //获取字典
          methods.getDictDataSetSelect(_row.column_code, _row.dict_type)
        } else {
          //获取字典
          methods.getApiDataSetSelect(_row.column_code, _row.option_api_code)
        }
      },
      //获取字段数据
      getDictDataSetSelect: function (columnCode, dictType) {
        pageConfig.selectList[columnCode] = []
        GetDictList(dictType).then((res) => {
          if (res.code != 200) {
            Toast(res.msg)
            return
          }
          pageConfig.selectList[columnCode] = res.data.map((item) => {
            return { text: item.dict_label, value: item.dict_value }
          })
          //重新组合显示的组件
          methods.filterColumnList(columnCode)
        })
      },
      //获取三方接口设置下拉数据源
      getApiDataSetSelect: function (columnCode, code) {
        pageConfig.selectList[columnCode] = []
        GetOptionData(code).then((res) => {
          if (res.code != 200) {
            Toast(res.msg)
            return
          }
          pageConfig.selectList[columnCode] = res.data.map((item) => {
            return { text: item.dict_label, value: item.dict_value }
          })
          //重新组合显示的组件
          methods.filterColumnList(columnCode)
        })
      },
      //赋值表单数据模型
      setColumnObject: function () {
        pageConfig.columnList.forEach((item) => {
          pageConfig.columnObject[item.column_code] = ''
          //组合列表或者表格
          if (item.component_type == '9' || item.component_type == '4') {
            pageConfig.columnObject[item.column_code] = []
            let row = { id: '1' }
            item.children.forEach((_item) => {
              row[_item.column_code] = ''
              //子集下拉框
              if (_item.component_type == '2' || _item.component_type == '3') {
                //设置下拉框数据源
                methods.setSelectDataSource(_item) //设置下拉框数据源
              }
            })
            pageConfig.columnObject[item.column_code].push(row)
          }
          //下拉框
          else if (item.component_type == '2' || item.component_type == '3') {
            //设置下拉框数据源
            methods.setSelectDataSource(item)
          }
          //自选审批
          else if (item.component_type == '5') {
            pageConfig.countersignList[item.column_code] = {
              list: [],
              is_cc: item.status_type == '1' ? 'N' : 'Y',
              status_id: item.status_id,
              requeried: item.required == 'Y' ? true : false,
              column_name: item.column_name,
            }
          }
          //实例附件
          else if (item.component_type == '12') {
            if (item.column_value) {
              GetFileList(item.column_value.split(',')).then((res) => {
                if (res.code !== 200) {
                  Toast(res.msg)
                  return
                }
                item.fileList = []
                res.data.forEach((file) => {
                  item.fileList.push({
                    id: file.id,
                    name: file.file_name,
                    extension: file.extension,
                    file_size: file.file_size,
                    url:
                      '/oa_portal/oa/portal/Common/DownloadFile/' +
                      file.id +
                      '?tenant_id=' +
                      localStorage.getItem('tenant_id'),
                  })
                })
              })
            }
          }
        })
      },
      //输入框失去焦点
      inputBlur: function (item) {
        if (item.column_type == 'number') {
          let vaule = pageConfig.columnObject[item.column_code] ?? 0
          pageConfig.columnObject[item.column_code] = Number(vaule).toFixed(
            item.column_scale
          )
        }
      },
      //表格输入框失去焦点
      tableInputBlur: function (row, item) {
        if (item.column_type == 'number') {
          let vaule = row[item.column_code] ?? 0
          row[item.column_code] = Number(vaule).toFixed(item.column_scale)
        }
      },
      //下载附件
      downloadFile: function (file) {
        //图片
        if (methods.verifySuffix(file.extension)) {
          let arr = []
          arr.push(file.url)
          ImagePreview({ images: arr, closeable: true })
        } else {
          router.push({ name: 'SpacePreview', query: { id: file.id } })
        }
      },
      //验证是否图片
      verifySuffix (imageName) {
        const suffix = `(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|jpeg)`
        const regular = new RegExp(`.*\.${suffix}`)
        return regular.test(imageName)
      },
      //过滤表单栏位（动态）
      filterColumnList: function (columnCode) {
        //找到所有与当前对象相关的列
        let list = pageConfig.columnSourceList.filter(
          (x) => x.show_conditions && x.show_conditions.indexOf(columnCode) >= 0
        )
        //删除相关的栏位
        list.forEach((item) => {
          pageConfig.columnList.forEach((element, index) => {
            if (item.column_code == element.column_code) {
              pageConfig.columnList.splice(index, 1)
            }
          })
        })
        list.forEach((item) => {
          if (!item.show_conditions || eval(item.show_conditions)) {
            pageConfig.columnList.push(item)
          }
        })
        //排序
        pageConfig.columnList = pageConfig.columnList.sort(
          methods.sortsFun('sort_num')
        )
      },
      //排序
      sortsFun: function (column) {
        return function (a, b) {
          var v1 = a[column]
          var v2 = b[column]
          return v1 - v2
        }
      },
      //下拉框选中
      selectChange: function (item, columnCode) {
        pageConfig.columnObject[columnCode] = item.value
        pageConfig.columnObject[columnCode + '_name'] = item.text
        pageConfig.showSelectPop[columnCode] = false
        //重新组合显示的组件
        methods.filterColumnList(columnCode)
      },
      //多选框
      multipleSelectComfirm: function (list, columnCode) {
        //制程信息
        let code = [],
          name = []
        list.forEach((element) => {
          code.push(element.value)
          name.push(element.text)
        })
        pageConfig.columnObject[columnCode] = code.join(',')
        pageConfig.columnObject[columnCode + '_name'] = name.join(',')
        pageConfig.showSelectPop[columnCode] = false
      },
      //组合单选下拉框选择
      combinationSelectChange: function (row, columnCode, data) {
        row[columnCode] = data.value
        row[columnCode + '_name'] = data.text
        pageConfig.showSelectPop[columnCode] = false
      },
      //组合多选下拉选择
      combinationSelectMultipleChange: function (row, columnCode, list) {
        //制程信息
        let code = [],
          name = []
        list.forEach((element) => {
          code.push(element.value)
          name.push(element.label)
        })
        row[columnCode] = code.join(',')
        row[columnCode + '_name'] = name.join(',')
        pageConfig.showSelectPop[columnCode] = false
      },
      //日期选择
      dateSelect: function (item, columnCode) {
        pageConfig.columnObject[columnCode] = methods.convertDate(item)
        pageConfig.showSelectPop[columnCode] = false
      },
      //时间选择
      datetimeSelect: function (item, columnCode) {
        pageConfig.columnObject[columnCode] = methods.convertDate(item, true)
        pageConfig.showSelectPop[columnCode] = false
      },
      //表格-日期选择
      tableDateSelect: function (item, row, columnCode) {
        row[columnCode] = methods.convertDate(item)
        pageConfig.showSelectPop[columnCode] = false
        console.log(row)
      },
      //表格-时间选择
      tableDatetimeSelect: function (item, row, columnCode) {
        row[columnCode] = methods.convertDate(item, true)
        pageConfig.showSelectPop[columnCode] = false
      },
      //日期转换
      convertDate: function (date, istime) {
        if (date != '') {
          if (istime) {
            return moment(date).format('YYYY/MM/DD HH:mm:ss')
          }
          return moment(date).format('YYYY/MM/DD')
        }
      },
      //获取guid
      guid: function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
              v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
          }
        )
      },
      //新增表格行
      addTableRow: function (columnCode, childrenList) {
        let row = { id: methods.guid() }
        childrenList.forEach((item) => {
          row[item.column_code] = ''
        })
        pageConfig.columnObject[columnCode].push(row)
        console.log(pageConfig.columnObject)
      },
      //删除表格行
      deleteTableRow: function (columnCode, id) {
        if (pageConfig.columnObject[columnCode].length <= 1) {
          Toast('至少填写一行数据')
          return
        }
        Dialog.confirm({
          title: '提示',
          message: '是否删除该行数据',
        }).then((res) => {
          //找到删除的索引
          let index = pageConfig.columnObject[columnCode].findIndex(
            (x) => x.id == id
          )
          //移除
          pageConfig.columnObject[columnCode].splice(index, 1)
        })
      },
      //自选签核选中
      countersignChange: function (item, columnCode) {
        pageConfig.countersignList[columnCode].list = item
      },
      //上传附件
      uploadFile: function (file) {
        const fileArr = []
        //多文件
        if (file.length && file.length > 1) {
          file.forEach((item) => {
            fileArr.push({
              base64: item.content.split(',')[1],
              file_name: item.file.name,
            })
          })
        } else {
          fileArr.push({
            base64: file.content.split(',')[1],
            file_name: file.file.name,
          })
        }
        UploadBase64(fileArr, 'wfs').then((res) => {
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            return
          }
          //多文件上传
          if (file.length && file.length > 1) {
            res.data.forEach((item) => {
              file.forEach((ifile) => {
                if (item.file_name == ifile.file.name) {
                  ifile.id = item.id
                  return
                }
              })
            })
          } else {
            file.id = res.data[0].id
          }
        })
      },
      //获取自选审批参数
      getCountersignParam: function () {
        let list = []
        pageConfig.columnList.forEach((item) => {
          if (item.component_type == '5') {
            //自选
            let coutersign = pageConfig.countersignList[item.column_code]
            coutersign.list.forEach((element, index) => {
              list.push({
                status_id: coutersign.status_id,
                is_cc: coutersign.is_cc,
                handler_emp_id: element.user_id,
                sort_num: index + 1,
              })
            })
          }
        })
        return list
      },
      //获取提交参数
      getSubmitParam: function () {
        //防止影响原对象
        let formData = JSON.parse(JSON.stringify(pageConfig.columnObject))
        pageConfig.columnList.forEach((column) => {
          //自选审批
          if (column.component_type == '5') {
            //删除自选节点
            delete formData[column.column_code]
          }
          //附件
          else if (column.component_type == '7') {
            if (pageConfig.fileList[column.column_code]) {
              let fileId = []
              pageConfig.fileList[column.column_code].forEach((_row) => {
                fileId.push(_row.id)
              })
              formData[column.column_code] = fileId.join()
            }
          }
          //下拉框
          else if (
            column.component_type == '2' ||
            column.component_type == '3'
          ) {
            if (!formData[column.column_code + '_name']) {
              formData[column.column_code + '_name'] = ''
            }
          }
          //表格或是组合列表
          else if (
            column.component_type == '4' ||
            column.component_type == '9'
          ) {
            let _list = []
            //对应表格的数据
            let table = pageConfig.columnObject[column.column_code]
            table.forEach((row, index) => {
              column.children.forEach((col) => {
                let column_value = row[col.column_code]
                //附件
                if (col.component_type == '7') {
                  if (pageConfig.fileList[col.column_code]) {
                    let fileId = []
                    pageConfig.fileList[col.column_code].forEach((_row) => {
                      fileId.push(_row.id)
                    })
                    column_value = fileId.join()
                  }
                }
                //下拉框
                else if (
                  col.component_type == '3' ||
                  col.component_type == '2'
                ) {
                  _list.push({
                    line_num: index + 1,
                    column_code: col.column_code + '_name',
                    column_value: row[col.column_code + '_name'],
                  })
                }
                _list.push({
                  line_num: index + 1,
                  column_code: col.column_code,
                  column_value: column_value,
                })
              })
            })
            formData[column.column_code] = JSON.stringify(_list)
          }
        })
        let param = {
          form_code: props.formCode,
          apply_emp_id: pageConfig.columnObject.apply_emp_id, //申请人id
          apply_dept_id: pageConfig.columnObject.dept_id, //申请人部门id
          apply_reason: '', //申请原因
          countersign_list: methods.getCountersignParam(), //获取自选审批参数
          form_data: formData,
        }
        return param
      },
      //验证表单
      verifyForm: function () {
        let res = true
        console.log(pageConfig.columnList)
        pageConfig.columnList.forEach((column) => {
          if (column.component_type == '5') {
           let item = pageConfig.countersignList[column.column_code]
           if (item.requeried && item.list.length == 0) {
            res = false
            Toast(item.column_name + '必填，请选择自选人员')
            return
          }
          }
        })
        return res
      },
      //提交表单
      submitForm: function () {
        //校验表单
        if(!methods.verifyForm())
        {
          return
        }
        formRef.value.validate().then((res) => {
          Dialog.confirm({ title: '提交', message: '是否确认提交' }).then(
            async () => {
              //提交参数
              let param = methods.getSubmitParam()
              pageConfig.showLoadding = true

              // 如果存在校验码，则对表单进行校验
              if (props.verifyCode) {
                const verifyRes = await CheckFormColumn({
                  code: props.verifyCode,
                  ...param,
                })
                pageConfig.showLoadding = false
                // 验证失败 提示强制提交
                if (!verifyRes.success) {
                  // 不允许提交
                  if (verifyRes.level === 1) Toast(verifyRes.msg)
                  else {
                    Dialog.confirm({
                      title: '警告',
                      message: `验证失败，信息：${verifyRes.msg}，是否继续提交？`,
                    }).then(() => {
                      methods.submitFormSuccess(param)
                    })
                  }
                } else {
                  // 验证成功直接提交
                  methods.submitFormSuccess(param)
                }
              } else {
                // 验证成功直接提交
                methods.submitFormSuccess(param)
              }
            }
          )
        })
      },
      submitFormSuccess: function (param) {
        pageConfig.showLoadding = false
        SubmitApprovalForm(param).then((res) => {
          if (res.code != 200) {
            pageConfig.showLoadding = false
            Dialog.alert({
              title: '申请失败',
              message: res.msg,
            })
            return
          }
          Toast.success('提交成功')
          setTimeout(function () {
            router.push({ path: '/Todolist/3' })
          }, 1000)
        })
      },
    }
    onMounted(() => {
      //初始化
      methods.init()
    })
    return {
      pageConfig,
      methods,
      formRef,
    }
  },
}
</script>
<style lang="scss">
.lable_icon {
  color: red;
  transform: scale(0.4);
  font-size: 13px;
  width: 10px;
}

.FormApply {
  .van-loading {
    text-align: center;
    position: absolute;
    z-index: 9999999;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    border-radius: 10px;
    left: calc(50% - 40px);
    top: 25vh;
  }

  .form {
    padding-bottom: 50px;
    height: calc(100vh - 110px);
    overflow: auto;

    .lable_icon {
      color: red;
      transform: scale(0.4);
      font-size: 13px;
      width: 10px;
    }

    .vant-table {
      width: 100%;
      overflow-x: auto;
      padding: 10px 0;

      table {
        padding: 5px;

        tr {
          line-height: 30px;
        }

        .th {
          height: 30px;
          line-height: 30px;
          background-color: #f5f6f6;
          text-align: center;
        }

        td {
          text-align: center;
          border-bottom: 1px solid #f5f6f6;
        }

        .link-text {
          color: rgb(245, 108, 108);
        }
      }

      .van-button {
        margin: 0 10px;
      }
    }
  }

  .img-block,
  .file-block {
    cursor: pointer;
    margin: 2px;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .img-icon,
    .file-icon {
      width: 50px;
      margin: 3px 0;
    }

    .img-name,
    .file-name {
      width: calc(100% - 100px);
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 10px;
      line-height: 36px;
    }

    .file-size {
      line-height: 36px;
      font-size: 8px;
      width: 100px;
      color: #9e9e9e;
    }
  }

  .column-title {
    margin-left: 1vh;
    margin-top: 2vh;
    display: block;
    color: #000;
    font-size: 14px;
  }

  .list-title {
    position: relative;
    line-height: 30px;

    .title-name {
      width: 50%;
    }

    .list-delete {
      position: absolute;
      right: 40px;
      top: 0;

      a {
        text-decoration: none;
        color: #f04a4a;
      }
    }
  }

  .upload-file {
    background-color: #fff;
    padding: 5px 10px;
    margin: 5px auto;
    overflow-x: auto;

    .van-uploader__wrapper {
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: inherit;
    }

    .van-uploader__wrapper::-webkit-scrollbar {
      display: none;
    }
  }

  .add-button {
    margin: 10px 20px;
  }

  .bottom {
    height: 50px;
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;

    .van-button {
      width: 90%;
      margin: 0 auto;
    }
  }

  .label-block {
    text-indent: 20px;
    padding: 5px 10px;
  }
}

// 2024/1/4 修改样式
.van-cell-group--inset {
  border-radius: 0;
}

.boxContent {
  width: 100%;
  height: 10px;
  background-color: #f5f6f6;
}

.van-field__body {
  font-size: 12px;
  margin-left: 5px;
}

.textFontSize {
  margin-left: 1vh;
  margin-top: 2vh;
  display: block;
  color: #000;
  font-size: 13px;
}

.van-cell:after {
  position: relative !important;
}
</style>
