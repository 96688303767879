<script setup>
import {onMounted, reactive, ref} from "vue";
import ChangeTitle from "@/utils/changeTitle";
import {AddChildTask, AddTask, GetFiles, GetTaskDetail, GetTaskPageList, taskBack, UpdateTask} from "@/api/Tmg/myTask";
import {useRoute, useRouter} from "vue-router";
import {splitFileName} from "@/utils/utils";
import {Toast} from "vant";

import PermissionButton from "@/views/Tmg/MyTask/comp/PermissionButton.vue";
import StartForm from "@/views/Tmg/MyTask/comp/StartForm.vue";
import moment from "moment";
import FormColumnList from "@/views/Tmg/MyTask/comp/FormColumnList.vue";
const route = useRoute()
const router = useRouter()
const state = reactive({
  queryParams: {
    id: "",
    page: 1,
    pageSize: 10
  },
  detailObj: {}, // 详情对象
  fileList: [], // 文件列表
  btn_status: ['1', '2', '0','4'], // 只有为已指派 和 进行中时 才会显示按钮
  isStart: false, // 是否开始
  isEdit: false, // 是否编辑
  isAddChildTask: false, // 是否添加子任务
  isSchedule: false, // 是否是进度页面
  isFinish: false, // 是否是完成页面
})

const startFormRef = ref(null)

const formColumnList = ref(null)

// 获取任务详情
const getTaskDetail = async () => {
  const res = await GetTaskDetail(state.queryParams.id)
  state.detailObj = res.rows[0]
  state.loading = false
  await getFiles()
}

const onRefresh = () => {
  state.loading = true
  if (!state.isAddChildTask) getTaskDetail()
};

// 获取附件文件列表
const getFiles = async () => {
  const res = await GetFiles(state.detailObj.id)
  if (!res.data) return
  state.fileList = res.data.map(item => {
    const {name, suffix} = splitFileName(item.file_name)
    return {
      ...item,
      id: item.file_id,
      name,
      type: suffix
    }
  })
}

// 点击开始
const handleBegin = () => {
  if (state.detailObj.actual_start_time !== null && state.detailObj.actual_start_time !== "") {
    const data = {
      actual_start_time: moment(state.detailObj.actual_start_time).format("YYYY-MM-DD HH:mm:ss"),
      plan_duration: state.detailObj.plan_duration,
      task_status: 2
    }
    handleSubmit(data,1)
    return
  }else{
    state.isStart = true
  }
  // state.isStart = true
  // state.isEdit = true
}

// 保存操作
const handleSave = () => {
  console.log("保存")
  // state.isEdit = false
  if (state.isStart) startFormRef.value.handleSubmitStart()

  if (state.isAddChildTask || state.isSchedule || state.isXiuGai || state.isFinish) formColumnList.value.handleSubmitForm()

}

// 提交操作
const handleSubmit = async (val,num) => {
  // 是否是开始
  if (state.isStart || num === 1) {
    const data = {...val, id: state.detailObj.id}
    const res = await UpdateTask(data)
    if (res.code === 200) {
      Toast.success("操作成功")
      handleCancel()
      await getTaskDetail()
    } else {
      Toast.error(res.msg)
    }
  }

  // 是否是新增子任务
  if (state.isAddChildTask) {
    // 处理数据
    let data = {
      assign_emp_id: val.assign_emp_id,
      plan_duration: val.plan_duration,
      plan_end_time: moment(val.plan_end_time).format("YYYY-MM-DD HH:mm:ss"),
      plan_start_time: moment(val.plan_start_time).format("YYYY-MM-DD HH:mm:ss"),
      remind_days: val.remind_days === "" ? 1 : Number(val.remind_days),
      remind_interval: val.remind_interval === "" ? 1 : Number(val.remind_interval),
      responsible_emp_id: val.responsible_emp_id,
      description: val.description,
      remark: val.remark,
      task_type: val.task_type,
      title: val.title,
      charge_unit: val.charge_unit,
      priority_level: val.priority_level,
    }
    let res
    if (state.queryParams.id !== null || state.queryParams.id !== "") {
      data.parent_id = state.queryParams.id
      data.task_status = "0"
      data.files = []
      res = await AddTask(data)
    } else {
      res = await AddChildTask(data)
    }
    if (res.code === 200) Toast.success("添加成功")
    else Toast.error(res.msg)
    setTimeout(() => {
      router.back()
    }, 1000)
  }

  // 进度
  if (state.isSchedule) {
    const data = {
      id: state.detailObj.id,
      actual_duration: val.actual_duration,
      complete_remark: val.complete_remark,
      task_percent: val.task_percent / 100,
      remark: val.remark,
    }
    if (val.task_percent === 100) {
      data.actual_end_time = val.actual_end_time
      data.task_status = 3
    }
    if (val.files.length > 0) {
      data.files = val.files
    }
    const res = await UpdateTask(data)
    if (res.code === 200) Toast.success("修改成功")
    else Toast.fail(res.msg)
    setTimeout(() => {
      handleCancel()
    }, 1000)
  }

  // 修改
  if(state.isXiuGai){
    const data = {
      id: state.detailObj.id,
      title: val.title,
      actual_duration: val.actual_duration,
      actual_end_time: val.actual_end_time,
      assign_emp_id: val.assign_emp_id,
      complete_remark: val.complete_remark,
      description: val.description,
      files: val.files,
      plan_duration: val.plan_duration,
      plan_end_time: val.plan_end_time,
      plan_start_time: val.plan_start_time,
      remark: val.remark,
      remind_days: val.remind_days,
      remind_interval: val.remind_interval,
      responsible_emp_id: val.responsible_emp_id,
      task_percent: val.task_percent,
      task_type: val.task_type,
    }
    const res = await UpdateTask(data)
    if (res.code === 200) Toast.success("修改成功")
    else Toast.fail(res.msg)
    setTimeout(() => {
      handleCancel()
    }, 1000)
  }

  console.log("123123",state.isFinish)
  // 完成
  if(state.isFinish){
    console.log("执行了finish")
    const data = {
      actual_duration: val.actual_duration,
      actual_end_time: val.actual_end_time,
      complete_remark: val.complete_remark,
      id: state.detailObj.id,
      remark: val.remark,
      task_status: 3,
    }
    const res = await UpdateTask(data)
    if (res.code === 200) Toast.success("修改成功")
    else Toast.fail(res.msg)
    setTimeout(() => {
      handleCancel()
    }, 1000)
  }

  // 每次操作完 获取最新数据
  await getTaskDetail()
}


// 取消操作
const handleCancel = () => {
  state.isEdit = false
  state.isStart = false
  state.isSchedule = false
  state.isAddChildTask = false
  state.isXiuGai = false
  state.isFinish = false
  ChangeTitle.setTitle("任务详情")
  console.log(state.detailObj, "detailObj")
}

// 分配子任务
const handleAssign = () => {
  router.push({name: "MyTaskSubtasks", query: {id: state.detailObj.id}})
}

const handleSchedule = () => {
  ChangeTitle.setTitle("进度")
  state.isSchedule = true
}

// 取消任务
const handleCancelTask = async () => {
  const data = {
    id: state.detailObj.id,
    task_percent: 0,
    task_status: 5
  }

  const res = await UpdateTask(data)
  if (res.code === 200) Toast.success("取消成功")
  await getTaskDetail()
}

// 激活任务
const handleActiveTask = async () => {
  const data = {
    id: state.detailObj.id,
    task_status: 0,
    task_percent: 0,
  }
  const res = await UpdateTask(data)
  if (res.code === 200) Toast.success("激活成功")
  await getTaskDetail()
}

// 指派任务
const handleAssignTask = async () => {
  const data = {
    id: state.detailObj.id,
    task_status: 1,
    task_percent: 0,
  }
  const res = await UpdateTask(data)
  if (res.code === 200) Toast.success("指派成功")
  await getTaskDetail()
}

// 修改任务
const handleEditTask = async () => {
  state.isEdit = true
  state.isXiuGai = true
  ChangeTitle.setTitle("修改任务")
  // 尝试获取父任务
  if (state.detailObj.parent_id !== null) {
    const res = await GetTaskDetail(state.detailObj.parent_id)
    if(res.code === 200) state.detailObj.father_task_name = res.rows[0].title
    console.log( state.detailObj.father_task_name," state.detailObj.father_task_name")
  }
}

// 验收
const handleAcceptance = async () => {
  const data = {
    id: state.detailObj.id,
    task_status: 4,
    task_percent: 0,
  }
  const res = await UpdateTask(data)
  if (res.code === 200) Toast.success("验收成功")
  await getTaskDetail()
}

// 退回
const handleReturn = async () => {
  const data = {
    id: state.detailObj.id,
    task_status: 1,
    task_percent: 1,
  }
  const res = await taskBack(data)
  if (res.code === 200) Toast.success("退回成功")
  await getTaskDetail()
}

// 完成任务
const handleDown = () => {
  state.isFinish = true
  state.isEdit = true
}

onMounted(() => {
  const {id, isAddChildTask, isAssign,isAddTask} = route.query
  state.queryParams.id = id
  state.isAddChildTask = Boolean(isAddChildTask)
  state.isAssign = Boolean(isAssign)
  state.isAddTask = Boolean(isAddTask)
  if (state.isAddTask) {
    state.isAddChildTask = true
    state.isEdit = true
    ChangeTitle.setTitle("新增任务")
  }else{
    if (state.isAddChildTask) ChangeTitle.setTitle("新增子任务")
    else ChangeTitle.setTitle("任务详情")
    if (!state.isAddChildTask) getTaskDetail()
  }
})
</script>

<template>
  <!-- 下拉刷新 -->
  <!--  <van-pull-refresh v-model="state.loading" @refresh="onRefresh">-->
  <div class="headerBox" v-if="!state.isAddChildTask && !state.isXiuGai">
    <p class="title">{{ state.detailObj.title }}</p>
    <p>指派人：{{ state.detailObj.assign_emp_name }}</p>
  </div>
  <div class="headerBox" v-if="state.isXiuGai && state.detailObj.father_task_name">
    <p>父任务：{{ state.detailObj.father_task_name }}</p>
    <p>由谁创建：{{ state.detailObj.creat_name }}</p>
  </div>
  <div :class="[state.btn_status.includes(state.detailObj.task_status) || state.isAddChildTask ? 'contentBox' : '']">
    <div class="columnBox">

      <FormColumnList :item="state.detailObj"
                      :idDetail="true"
                      v-if="state.detailObj.title && !state.isStart && !state.isSchedule && !state.isXiuGai && !state.isFinish"
                      :fileList="state.fileList"/>

      <FormColumnList ref="formColumnList" v-if="state.isAddChildTask || state.isSchedule || state.isXiuGai || state.isFinish"
                      @handleSubmit="handleSubmit" :editItem="state.detailObj"
                      :fileList="state.fileList"
                      :isXiuGai="state.isXiuGai"
                      :isFinish="state.isFinish"
                      :isAddTask="state.isAddTask"
                      :isAddChildTask="state.isAddChildTask" :isSchedule="state.isSchedule" :isEdit="true"/>

      <StartForm v-if="state.isStart" ref="startFormRef" @handleSubmit="handleSubmit"/>
      <PermissionButton
          :item="state.detailObj" v-if="state.detailObj.title || state.isAddChildTask"
          :isAddChildTask="state.isAddChildTask"
          :isSchedule="state.isSchedule"
          :isEdit="state.isEdit"
          :isAssign="state.isAssign"
          :isStart="state.isStart"
          :isAddTask="state.isAddTask"
          @handleDown="handleDown"
          @handleReturn="handleReturn"
          @handleAcceptance="handleAcceptance"
          @handleEditTask="handleEditTask"
          @handleCancelTask="handleCancelTask"
          @handleActiveTask="handleActiveTask"
          @handleAssignTask="handleAssignTask"
          @handleSchedule="handleSchedule"
          @handleAssign="handleAssign"
          @handleSave="handleSave"
          @handleCancel="handleCancel"
          @handleBegin="handleBegin"/>
    </div>
  </div>
  <!--  </van-pull-refresh>-->

</template>

<style scoped lang="scss">
.headerBox {
  background: #fff;
  padding: 10px 15px;

  p {
    margin: 5px 0;
  }
}

.contentBox {
  padding-bottom: 55px;
}

.columnBox {
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
}
</style>