<!--下拉多选-->
<template>
  <div class="multipleSelect">
    <!-- 弹出框的表头和两个按钮 -->
    <div class="property_head">
      <span class="property_head_text">选择涉及制程</span>
      <div class="property_head_btn" @click="methods.comfirm">确认</div>
      <div class="property_head_cancle" @click="methods.cancel">取消</div>
    </div>
    <!-- <van-checkbox-group v-model="pageConfig.optionsChecked">
            <van-cell-group inset>
              <van-cell
                v-for="item in pageConfig.optionList"
                clickable
                :key="item"
                :title="item.text"
              >
                <template #right-icon>
                  <van-checkbox :name="item" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group> -->
    <div class="checkBox">
      <van-checkbox-group v-model="pageConfig.optionsChecked">
        <van-checkbox v-for="(item, index) in pageConfig.optionList" :key="item" :name="item.text">
          <div class="vanCheckbox">
            {{ item.text }}
          </div>
        </van-checkbox>
      </van-checkbox-group>
    </div>
  </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
export default {
  props: ['optionList'],
  setup(props, { emit }) {
    //页面参数
    const pageConfig = reactive({
      optionList: props.optionList,//选项列表
      optionsChecked: [],//选择的值
    })
    //内置函数
    const methods = {
      //确认
      comfirm: function () {
        let list = []
        for (let i = 0; i < pageConfig.optionsChecked.length; i++) {
         list.push( {text:pageConfig.optionsChecked[i],value:i+1})
        }
        emit("onComfirm", list);
      },
      //取消
      cancel: function () {
        let list = []
        for (let i = 0; i < pageConfig.optionsChecked.length; i++) {
          list.push({text:pageConfig.optionsChecked[i],value:i+1})
        }
        emit("onCancel", list);
      },
    }
    return {
      pageConfig,
      methods
    }
  }
}
</script>
<style lang="scss">
.multipleSelect {
  .property_head {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    line-height: 30px;

    .property_head_text {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      text-align: center;
    }

    .property_head_btn {
      position: absolute;
      top: 2px;
      right: 12px;
      font-size: 12px;
      color: #576b95;
    }

    .property_head_cancle {
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 12px;
      color: #969799;
    }
  }
}
.checkBox{
  padding: 0 20px;border-top: 1px solid #f5f6f6;padding-top: 10px;
  .vanCheckbox {
    line-height: 25px;
    padding-left: 8px;
    font-size: 14px;
  }
}
</style>