<!--会议签到页面-->
<template>


  <div style="text-align: center;margin-top:5%;" v-if="showData">
    <img src="@/assets/sign-success.png" style="width: 50%; height: 50%;">
    <p style="margin-top: 10px;font-size: 24px;font-weight: bold">签到成功</p>
    <van-form>


      <van-cell-group inset>
        <van-field
            v-model="meeting_data.meeting_name"
            label="会议主题:"
            disabled
        />
        <van-field
            v-model="meeting_data.meeting_code"
            label="会议代码:"
            disabled
        />
        <van-field
            v-model="meeting_data.room_name"
            label="会议室:"
            disabled
        />
        <van-field
            v-model="meeting_data.host_name"
            label="主持人:"
            disabled
        />
        <van-field
            v-model="start_time"
            label="开始时间:"
            disabled
        />     <van-field
          v-model="end_time"
          label="结束时间:"
          disabled
      />
      </van-cell-group>

    </van-form>

  </div>

  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <div class="block">

        <van-loading type="spinner" size="44px" class="loading">
        </van-loading>
      </div>
    </div>

  </van-overlay>


</template>
<script>
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import moment from "moment";
import {GetList, Sign} from "@/api/meeting_room";
import {useRouter} from "vue-router";
import {GetMealCount} from "@/api/diningroom";
import {Toast,Dialog } from "vant";
import * as dd from 'dingtalk-jsapi'
export default {
  setup() {

    //页面配置参数
    const state = reactive({
      show: true,
      showData: false,
      showPop:computed(res=>{
      return !state.show
      }),
      meeting_data: {},
    });


    const start_time = computed(() => {
      return state.meeting_data  ?  moment(state.meeting_data.start_time).format('YYYY-MM-DD HH:mm:ss'):""
    })
    const end_time= computed(() => {
      return state.meeting_data  ? moment(state.meeting_data.end_time).format('YYYY-MM-DD HH:mm:ss'):""
    })

    //内置函数
    const methods = reactive({
      //初始化
      init: function () {
        const router = useRouter();
        const paramValue = router.currentRoute.value.query
        // console.log(paramValue, "paramValue")



        Sign({meeting_id: paramValue.meeting_id, code: paramValue.code}).then(res => {
          if (res.code === 200) {
            GetList({meeting_id: paramValue.meeting_id}).then(res => {
              if (res.code === 200) {
                state.meeting_data = res.rows[0]
                console.log(state.meeting_data,"meeting_data")
                state.show = false
                state.showData = true
              } else {
                state.show = false
                Dialog({

                 message:res.msg,

                }).then(() => {
               //  router.replace("/home")
                  dd.biz.navigation.goBack({
                    onSuccess: function (result) { },
                    onFail: function (err) { },
                  })
                });
           //     Toast(res.msg)
              }
            })


          } else {
            state.show = false
            Dialog({
            message:res.msg,

            }).then(() => {
            //  router.replace("/home")
              dd.biz.navigation.goBack({
                onSuccess: function (result) { },
                onFail: function (err) { },
              })
            });

          }


        })
      }
    });
    onMounted(() => {
      methods.init()

    });
    return {

      ...toRefs(state),
      methods,
      start_time,
      end_time
    };
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading {
  margin: 0 auto;
}
:deep(.van-field--disabled .van-field__label){
  color: #000 !important;
}
:deep(.van-field__body .van-field__control:disabled){
  color: #000 !important;
  -webkit-text-fill-color:#000 !important;
}
</style>
