<script setup>
import {computed, reactive, ref} from "vue";
const props = defineProps({
  formList: {
    type: Array,
    default: []
  },
  fileBase64: {
    type: String,
    default: ""
  }
})

const emit = defineEmits(["insertFavorite","canselFavorite"])
const activeTab = ref()
const state = reactive({
  defaultImgSrc: require("@/assets/defaultMenu.svg"),
})


const compute = computed(() => {
  return (row) => {
    for (let i = 0; i < props.fileBase64.length; i++) {
      if (props.fileBase64[i].id == row.file_id) {
        if (props.fileBase64[i].extension.indexOf('svg') != -1) {
          return 'data:image/svg+xml;base64,' + props.fileBase64[i].base64
        }
        return 'data:image/' + props.fileBase64[i].extension.substr(1) + ';base64,' + props.fileBase64[i].base64
      }
    }
  }
})


// 点击收藏
const handleInsert = (id) => {
  emit("insertFavorite", id)
}

// 取消收藏
const handleCansel = (id) => {
  emit("canselFavorite", id)
}

</script>

<template>

  <div class="list-cont">
      <div className="title">
        <div className="name">全部应用</div>
      </div>
      <van-tabs
          :line-width="20"
          color="#000" v-model:active="activeTab" scrollspy sticky>
        <van-tab :title="item.menu_name" :name="index" v-for="(item,index) in formList">
          <div class="app-item">
            <div class="title">
              <div class="name">{{item.menu_name}}</div>
            </div>
            <div class="icon-cont" v-for="(child,index) in item.children">
              <div class="left">

                <div class="icon">
                  <img
                      :src="compute(child)"
                      width="40"
                      height="40"
                      alt="加载失败"
                      v-if="compute(child)"
                  />

                  <img
                      :src="state.defaultImgSrc"
                      width="30"
                      height="30"
                      v-else
                  />
                </div>
                <div class="name">
                  {{ child.menu_name }}
                </div>
              </div>
              <div class="right">
                <van-button v-if="child.is_favorite === 'false'" type="default"  size="mini" @click="handleInsert(child.id)">添加</van-button>
                <van-button v-else type="danger"  size="mini" @click="handleCansel(child.favorite_id)">取消</van-button>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
  </div>
</template>

<style scoped lang="scss">
  .list-cont{
    ::v-deep .van-button--mini{
      padding: 2px 5px !important;
    }
    ::v-deep .van-button__text{
      font-size: 12px;
    }
    ::v-deep .van-tabs__nav{
      padding-left: 0 !important;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 10px 10px 0 10px;
      .name {
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
  .app-item{
    padding: 4px 8px;
    background: #fff;
    .title{
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px;
      padding: 0;
    }
    .icon-cont{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom:5px;
      .left{
        display: flex;
        align-items: center;
        .icon{
          margin-right: 6px;
         img{
           width: 30px;
           height: 30px;
         }
        }
      }
    }
  }
</style>