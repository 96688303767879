export const icons = new Map([
        ['pptx', require("@/assets/file-img/ppt.svg")],
        ['ppt', require("@/assets/file-img/ppt.svg")],
        ['docx',  require("@/assets/file-img/word.svg")],
        ['doc',  require("@/assets/file-img/word.svg")],
        ['xlsx',  require("@/assets/file-img/excel.svg")],
        ['xls',  require("@/assets/file-img/excel.svg")],
        ['pdf',  require("@/assets/file-img/pdf.svg")],
        ['txt',  require("@/assets/file-img/txt.svg")],
        ['jpg',  require("@/assets/file-img/img.svg")],
        ['png',  require("@/assets/file-img/img.svg")],
        ['jpeg',  require("@/assets/file-img/img.svg")],
        ['gif',  require("@/assets/file-img/img.svg")],
        ['svg',  require("@/assets/file-img/img.svg")],
        ['bmp',  require("@/assets/file-img/img.svg")],
        ['webp',  require("@/assets/file-img/img.svg")],
        ['tiff',  require("@/assets/file-img/img.svg")],
        ['icon',  require("@/assets/file-img/img.svg")],
        ['mp4',  require("@/assets/file-img/video.svg")],
        ['文件夹',  require("@/assets/file-img/folder.svg")],
    ]
);