import {defineStore} from "pinia";
import {getLoginUser} from "@/api/user";

export const UserStore = defineStore('UserStore', {
    state: () => {
        return {
            userInfo:{},//存放用户信息
        }
    },

    actions: {
        // 获取用户信息
        async getUserInfo(){
            const res = await getLoginUser()
            if(res.code === 200){
                this.userInfo = res.data
            }
        }
    }
})