<template>
  <div class="reviewItem">
    <van-loading type="spinner" v-show="state.showLoadding" color="#1989fa" />
    <van-loading type="spinner" v-show="state.PhotoLoadding" color="#1989fa" />
    <!-- 中间表单 -->
    <van-form class="form" ref="formRef">
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 2vh; margin-top: 2vh; display: block; color: #969799"
        >
          <van-icon class="lable_icon"/>名称
        </span>
        <van-cell :title="state.waitCheckList.instance_name"  />
        <span
          style="margin-left: 2vh; margin-top: 2vh; display: block; color: #969799"
        >
          <van-icon class="lable_icon"/>编号
        </span>
        <van-cell :title="state.waitCheckList.instance_code"  />
        <span
          style="margin-left: 2vh; margin-top: 2vh; display: block; color: #969799"
        >
          <van-icon class="lable_icon"/>型号
        </span>
        <van-cell :title="state.waitCheckList.instance_spec"  />
        <span
          style="margin-left: 2vh; margin-top: 2vh; display: block; color: #969799"
        >
          <van-icon class="lable_icon"/>位置
        </span>
        <van-cell :title="state.waitCheckList.instance_location"  />
        
      </van-cell-group>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh" v-for="(item) in state.resCheckList" :key="item.id">
        <van-cell style="font-size:14px" :value="item.check_item_name" />
        <van-cell is-link :arrow-direction="item.arrow" @click.stop="methods.Getfileinfo(item)">
          <template #title>
            <span style="font-size: 14px; margin-left: 1vh; display: block; color: #000">
              <van-icon class="lable_icon" />点检结果
            </span>
          </template>
          <template #value>
            <van-field name="radio" label="" :rules="[{ required: true, message: '点检结果为必选' }]">
              <template #input>
                <van-radio-group class="radio_group" v-model="item.check_result" direction="horizontal"
                  :disabled="true">
                  <van-radio name="Y" @click.stop="methods.radioClick(item, 1)">正常</van-radio>
                  <van-radio name="N" checked-color="#ee0a24" @click.stop="methods.radioClick(item, 2)"> 异常</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </template>
        </van-cell>
        <span v-if="(item.show)" style="font-size: 14px; margin-left: 3vh; margin-top: 2vh; display: block; color: #000">
          <van-icon class="lable_icon" />点检备注
        </span>
        <van-field v-if="(item.show)" style=" margin-left: 1vh; padding-top: 0" :disabled="true" v-model="item.check_remark" name="点检备注"
          maxlength="400" type="textarea" rows="3" />

        <span v-if="item.show" style="font-size: 14px; margin-left: 3vh; margin-top: 2vh; display: block; color: #000">
          <van-icon class="lable_icon" />附件
        </span>
        <div class="upload-file" v-if="item.show">
          <div class="img-block" v-for="file in state.fileinfoList[item.check_item_name]"
            @click="methods.previewPhoto(file)" :key="file.id" :title="file.name">
            <div class="img-icon">
              <img src="../../../assets/file-img/img.svg" height="30" />
            </div>
            <div class="img-name">
              <span>{{ file.name }}</span>
            </div>
            <div class="file-size">
              <span v-if="Number(file.file_size) / 1024 / 1024 < 1">{{ (Number(file.file_size) / 1024).toFixed(2)
              }}K</span>
              <span v-else>{{
                (Number(file.file_size) / 1024 / 1024).toFixed(2)
              }}M</span>
            </div>
          </div>
        </div>
      </van-cell-group>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <van-cell :value="state.waitCheckList.instance_name" />
        <van-cell is-link :arrow-direction="state.arrow" @click.stop="methods.reviewRadioClick(3)">
          <template #title>
            <span style="width: 100px; display: block; color: #000">
              <van-icon class="lable_icon" name="star" />复检结果
            </span>
          </template>
          <template #value>
            <van-field name="radio" label="" :rules="[{ required: true, message: '点检结果为必选' }]">
              <template #input>
                <van-radio-group class="radio_group" v-model="state.review_result" direction="horizontal"
                  :disabled="!(state.checkStatus == 2)">
                  <van-radio name="Y" @click.stop="methods.reviewRadioClick(1)">正常</van-radio>
                  <van-radio name="N" checked-color="#ee0a24" @click.stop="methods.reviewRadioClick(2)"> 异常</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </template>
        </van-cell>

        <span v-if="state.show" style="font-size: 14px; margin-left: 3vh; margin-top: 2vh; display: block; color: #000">
          <van-icon class="lable_icon" name="star" v-if="state.review_result=='N'" />
          <van-icon class="lable_icon" v-if="state.review_result!='N'" />复检备注
        </span>
        <van-field v-if="state.show" style="margin-left: 1vh; padding-top: 0" :disabled="!(state.checkStatus == 2)"
          v-model="state.review_remark" name="复检备注" maxlength="400" type="textarea" rows="3" />
        <span v-if="state.show && state.checkStatus == 2"
          style="font-size: 14px; margin-left: 3vh; margin-top: 2vh; display: block; color: #000">
          <van-icon class="lable_icon" name="star" v-if="state.review_result=='N'" />
          <van-icon class="lable_icon" v-if="state.review_result!='N'" />上传附件
        </span>
        <div class="upload-file" v-if="state.show && state.checkStatus == 2">
          <van-uploader :after-read="methods.uploadFile" multiple v-model="state.fileList" />
        </div>
        <span v-if="state.show && !(state.checkStatus == 2)"
          style="font-size: 14px; margin-left: 3vh; margin-top: 2vh; display: block; color: #000">
          <van-icon class="lable_icon" />附件
        </span>
        <div class="upload-file" v-if="state.show && !(state.checkStatus == 2)">         
          <div class="img-block" v-for="file in state.reviewfileinfoList" @click="methods.previewPhoto(file)"
            :key="file.id" :title="file.name">
            <div class="img-icon">
              <img src="../../../assets/file-img/img.svg" height="30" />
            </div>
            <div class="img-name">
              <span>{{ file.name }}</span>
            </div>
            <div class="file-size">
              <span v-if="Number(file.file_size) / 1024 / 1024 < 1">{{ (Number(file.file_size) / 1024).toFixed(2)
              }}K</span>
              <span v-else>{{
                (Number(file.file_size) / 1024 / 1024).toFixed(2)
              }}M</span>
            </div>
          </div>
        </div>
      </van-cell-group>
    </van-form>
    <div class="bottom" style="z-index: 100" v-if="state.checkStatus == 2">
      <van-button type="primary" block style="border-radius: 10px; margin-top: 1vh"
        @click="methods.checkSubmit">提交</van-button>
    </div>
  </div>
  <van-overlay :show="state.showoverlay">
  <div class="wrapper" @click.stop>
    <van-loading size="24px">上传中...</van-loading>
  </div>
</van-overlay>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { GetDictList, UploadBase64, DownloadBase64, GetFileList } from '@/api/oaApi.js'
import { ref, onMounted, reactive } from 'vue'
import store from '@/store'
import {
  GetList,
  GetWaitCheckData,
  GetEntity,
  GetCheckedDetails,
  Insert,
} from '@/api/oacks.js'
import { Toast, Dialog, List, ImagePreview } from 'vant'
import moment from 'moment'
import ChangeTitle from '@/utils/changeTitle.js'
export default {
  setup() {
    //表单验证
    const formRef = ref('')
    const route = useRoute()
    const router = useRouter()
    let state = reactive({
      loading: false, //下拉刷新
      showLoadding: false,
      showoverlay: false,

      PhotoLoadding:false,

      instanceId: route.params.instanceId, //实例id
      checkId: route.params.checkId,
      checkStatus: route.params.checkStatus,
      review_file_id: "",
      review_remark: "",
      review_result: "",
      review_status: "",
      show: false,
      arrow: "right",

      fileList: [],
      waitCheckList: [],
      resCheckList: [],//待点检项
      check_file_id: "",

      fileinfoList: [],

      reviewfileinfoList: [],
    })
    const methods = {
      //下拉刷新
      onRefresh: function () {
        state.loading = false
        window.location.reload()
      },
      //缓存清理
      cache: function (type) {
        if (type == 1) {
          localStorage.setItem("Token", "123")
        } else {
          localStorage.removeItem("Token")
        }
      },
      //获取实例信息
      LoadInstance: function () {
        state.showLoadding = true
        let param = {
          checkId:state.checkId,
        }
        GetEntity(param).then((res) => {
          if (res.code !== 200) {
            Toast.error(res.msg)
            return
          }
            ChangeTitle.setTitle("复检" + res.data[0].instance_name)
            state.waitCheckList = res.data[0]
            state.review_file_id = res.data[0].review_file_id,
            state.review_remark = res.data[0].review_remark,
            state.review_result = res.data[0].review_result,
            state.review_status = res.data[0].review_status,
            state.fileList = state.review_file_id == "" || state.review_file_id == null ? [] : state.review_file_id.split(',')
            methods.Getreviewfileinfo(state.fileList)
        })
        GetCheckedDetails({ id: state.checkId }).then((res) => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          res.data.map(item => {
            state.check_file_id = item.check_file_id
            const ids = state.check_file_id == "" ? [] : state.check_file_id.split(',')
            state.resCheckList.push({
              check_file_id: ids,
              check_item_eng_name: item.check_item_eng_name,
              check_item_id: item.check_item_id,
              check_item_name: item.check_item_name,
              check_remark: item.check_remark,
              check_result: item.check_result,
              arrow: "right",
              show: false,
            })
          })
        })

        state.showLoadding = false
      },

      //单选框点击
      reviewRadioClick: function (val) {
        if (val == 2) {
          state.show = true
        }else if(val == 3){
          state.show =!state.show
        }
      },

      //复检图片
      Getreviewfileinfo: function (ids) {
        GetFileList(ids).then(res => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          res.data.forEach((item) => {
            state.reviewfileinfoList.push({
              id: item.id,
              name: item.file_name,
              extension: item.extension,
              file_size: item.file_size
            })
          })
        })
      },

      //单选框点击
      radioClick: function (rows, val) {
        if (val == 2) {
          rows.show = true
        }
      },
      //获取图片
      Getfileinfo: function (rows) {
        //console.log(rows)
        rows.arrow = rows.arrow == "down" ? "right" : "down"
        rows.show = !rows.show
        let file_id = rows.check_file_id+""
        const ids = file_id == "" ? [] : file_id.split(',')
        GetFileList(ids).then(res => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          if (!state.fileinfoList[rows.check_item_name]) {
            state.fileinfoList[rows.check_item_name] = []
          }
          res.data.forEach((item) => {
            state.fileinfoList[rows.check_item_name].push({
              id: item.id,
              name: item.file_name,
              extension: item.extension,
              file_size: item.file_size
            })
          })
        })
      },

      //获取用户信息
      LoadUserInfo: function () {
        dd.util.domainStorage.getItem({
          name: 'key', // 存储信息的key值
          onSuccess: function (info) {
            state.userinfo = info.value
          },
        })
      },

      //上传附件
      uploadFile: function (file) {
        state.showoverlay = true
        const fileArr = []
        //多文件
        if (file.length && file.length > 1) {
          file.forEach((item) => {
            fileArr.push({
              base64: item.content.split(',')[1],
              file_name: item.file.name,
            })
          })
        } else {
          fileArr.push({
            base64: file.content.split(',')[1],
            file_name: file.file.name,
          })
        }
        UploadBase64(fileArr, 'wfs').then((res) => {
          if (res.code !== 200) {
            file.status = 'failed'
            file.message = '上传失败'
            state.showoverlay = false
            return
          }
          //多文件上传
          if (file.length && file.length > 1) {
            res.data.forEach((item) => {
              file.forEach((ifile) => {
                if (item.file_name == ifile.file.name) {
                  ifile.id = item.id
                  return
                }
              })
            })
          } else {
            file.id = res.data[0].id
          }
          state.showoverlay = false
        })
      },

      //预览图片
      previewPhoto: function (item) {
        state.PhotoLoadding = true;
        DownloadBase64(item.id.split(','),'{name}{time}').then(res => { 
          state.PhotoLoadding = false;
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          let url = 'data:image/' +
            item.extension.replace('.', '') +
            ';base64,' +
            res.data[0].base64
          let arr = []
          arr.push(url)
          ImagePreview({ images: arr, closeable: true })
        })
      },

      checkSubmit: function () {
        formRef.value.validate().then(() => {
          Dialog.confirm({ title: '提交', message: '是否确认提交' }).then(
            () => {
              let fileIdList = []
              state.fileList.forEach((item) => {
                fileIdList.push(item.id)
              })
              let param = {
                instance_id: state.instanceId,// 实例id
                check_id: state.checkId,
                review_result: state.review_result, //复检结果
                review_remark: state.review_remark,
                review_file_id: fileIdList.join(','), //复检附件id
              }
              if (state.review_result == "") {
                  Dialog.alert({
                    title: '复检未完成',
                    message: "复检未完成",
                  })
                  return
                }
              //console.log(param);
              Insert(param).then((res) => {
                if (res.code != 200) {
                  Dialog.alert({
                    title: '复检失败',
                    message: res.msg,
                  })
                  return
                }
                Toast.success('复检成功')
                setTimeout(function () {
                  router.push({ name: 'reviewList' })
                }, 1000)
              })
            }
          )
        })
      },
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD HH:mm:ss')
        }
      },
      //关闭弹框
      onclose: function () {
        state.showApproval = false

        setTimeout(() => {
          window.location.reload()
        }, 500);
      },

      //获取url参数 
      getQueryString: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(decodeURI(r[2]));
        return null;
      },
    }
    onMounted(() => {
      //获取实例信息
      methods.LoadInstance()
    })
    return {
      formRef,
      methods,
      state,
    }
  },
  components: {
  },
}
</script>
<style lang="scss">
body {
  background-color: #f6f6f6;
}
.wrapper {
    // display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.reviewItem {
  .form {
    padding-bottom: 50px;
    height: calc(100vh - 110px);
    overflow: auto;

    .lable_icon {
      color: red;
      font-size: 10px;
      transform: scale(0.65);
    }

    .radio_group {
      font-size: 10px;
      width: 180px;
    }
  }
  .van-loading {
      text-align: center;
      position: absolute;
      z-index: 9999999;
      width: 80px;
      height: 80px;
      line-height: 80px;
      background-color: #fff;
      border-radius: 10px;
      left: calc(50% - 40px);
      top: 25vh;
    }

  .property_head {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    line-height: 30px;

    .property_head_text {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      text-align: center;
    }

    .property_head_btn {
      position: absolute;
      top: 2px;
      right: 12px;
      font-size: 12px;
      color: #576b95;
    }

    .property_head_cancle {
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 12px;
      color: #969799;
    }
  }

  .upload-file {
    background-color: #fff;
    padding: 5px 10px;
    margin: 5px auto;
    overflow-x: auto;

    .van-uploader__wrapper {
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: inherit;
    }

    .van-uploader__wrapper::-webkit-scrollbar {
      display: none;
    }

    .img-block,
    .file-block {
      cursor: pointer;
      margin: 2px;
      width: 95%;
      height: 30px;
      padding: 5px 0;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .img-icon,
      .file-icon {
        width: 50px;
        margin: 3px 0;
      }

      .img-name,
      .file-name {
        width: calc(100% - 100px);
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 10px;
        line-height: 36px;
      }

      .file-size {
        line-height: 36px;
        font-size: 8px;
        width: 100px;
        color: #9e9e9e;
      }
    }
  }

  .bottom {
    height: 50px;
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
  }

}
</style>
