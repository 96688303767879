<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import ChangeTitle from "@/utils/changeTitle";
import axios from "axios";
import {changeFavoriteSort, GetFavoriteList} from "@/api/mobile";
import draggable from "vuedraggable";
import {HomeStore} from "@/store/homeStore";

const homeStore = HomeStore();

const state = reactive({
  fileBase64: [],
  defaultImgSrc: require("@/assets/defaultMenu.svg"),
  dragImg: require("@/assets/drag.svg"),
  addedList: [],
  form_list: [{title: "肯德基"}, {title: "麦当劳"}],
})


// 获取已添加应用
const queryData = async () => {
  const res = await GetFavoriteList()
  state.addedList = res.data
  let list = [];
  for (const item of state.addedList) {
    list.push(item.file_id);
  }
  DownloadBase64(list);
}

const DownloadBase64 = (data, flag) => {
  axios
      .post("/oa_portal/oa/portal/Common/DownloadBase64", data, {
        headers: {
          Authorization: localStorage.getItem("Token"),
          tenant_id: localStorage.getItem("tenant_id"),
        },
      })
      .then((res) => {
        state.fileBase64 = res.data.data;
      });
}

const compute = computed(() => {
  return (row) => {
    for (let i = 0; i < state.fileBase64.length; i++) {
      if (state.fileBase64[i].id == row.file_id) {
        if (state.fileBase64[i].extension.indexOf('svg') != -1) {
          return 'data:image/svg+xml;base64,' + state.fileBase64[i].base64
        }
        return 'data:image/' + state.fileBase64[i].extension.substr(1) + ';base64,' + state.fileBase64[i].base64
      }
    }
  }
})
//开始拖拽事件
const onStart = (event) => {
};
//结束拖拽事件
const onEnd = async () => {
  state.addedList = state.addedList.map((item, index) => {
    return {
      ...item,
      sort_num: index + 1
    }
  })
  const res = await changeFavoriteSort(state.addedList)
  if (res.code == 200) {
    await queryData()
    homeStore.clearAddedList()
  }
};

onMounted(() => {
  ChangeTitle.setTitle("排序")
  queryData()
})

</script>

<template>
  <div class="edit-cont">
    <draggable
        :list="state.addedList"
        :force-fallback="true"
        chosen-class="chosenClass"
        ghost-class="ghost"
        animation="200"
        handle=".drag-handle"
        @start="onStart"
        @end="onEnd"
    >
      <template #item="{ element, index }">
        <van-cell>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="img">
              <img v-if="compute(element)" :src="compute(element)"/>
              <img v-else :src="state.defaultImgSrc" alt="">
              <div class="name">{{ element.menu_name }}</div>
            </div>
          </template>
          <template #value>
            <div class="value">
              <div class="imgBox">
                <div class="drag-handle"></div>
                <img :src="state.dragImg" alt="">
              </div>
            </div>
          </template>
        </van-cell>
      </template>
    </draggable>
  </div>
</template>

<style scoped lang="scss">
.edit-cont {
  .name {
    font-size: 11px;
  }

  .img {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 6px;
    }
  }

  .imgBox {
    position: relative;

    .drag-handle {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .value {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 25px;
    }
  }
}


</style>
