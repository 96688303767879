<template>
  <div class="AccountPayable">
    <div class="wrapper">
      <div class="vant-table">
        <van-radio-group
          v-model="pageConfig.checkedRow"
        >
          <table cellspacing="0" class="table">
            <tr>
              <th class="th" width="20"></th>
              <th class="th" width="55">凭证编号</th>
              <th class="th" width="55">凭证行号</th>
              <th class="th" width="120">凭证类型</th>
              <th class="th" width="100">电子发票号</th>
              <th class="th" width="100">总账科目</th>
              <th class="th" width="130">凭证货币金额</th>
              <th class="th" width="100">凭证货币</th>
              <th class="th" width="100">本币金额</th>
              <th class="th" width="100">本币货币</th>
              <th class="th" width="100">到期日期</th>
              <th class="th" width="100">分配号</th>
              <th class="th" width="100">利润中心</th>
              <th class="th" width="130">公司代码</th>
              <th class="th" width="120">原始凭证号</th>
              <th class="th" width="160">原始凭证号年度</th>
              <th class="th" width="160">原始凭证金额</th>
              <th class="th" width="180">原始凭证号行项目</th>
              <th class="th" width="100">付款类型</th>
            </tr>
            <tr
              v-for="(item, index) in pageConfig.dataList"
              :key="index"
            >
              <td align="center">
                <van-radio :name="item" ></van-radio>
              </td>
              <td>
                {{ item.document_number }}
              </td>
              <td>
                {{ item.document_item_number }}
              </td>
              <td>
                {{ item.document_type }}
              </td>
              <td>
                {{ item.einvoice_number }}
              </td>
              <td>
                {{ item.gl_account_code }}
              </td>
              <td>
                {{ item.document_currency_amount }}
              </td>
              <td>
                {{ item.document_currency_code }}
              </td>
              <td>
                {{ item.local_currency_amount }}
              </td>
              <td>
                {{ item.company_currency_code }}
              </td>
              <td>
                {{ item.due_date }}
              </td>
              <td>
                {{ item.allocation_number }}
              </td>
              <td>
                {{ item.profit_center_code }}
              </td>
              <td>
                {{ item.original_company_code }}
              </td>
              <td>
                {{ item.original_document_number }}
              </td>
              <td>
                {{ item.original_fiscal_year }}
              </td>
              <td>
                {{ item.original_document_currency_amount }}
              </td>
              <td>
                {{ item.original_document_item_number }}
              </td> 
              <td>
                {{ item.payment_type }}
              </td>
            </tr>
          </table>
        </van-radio-group>
      </div>
      
    </div>
    <div class="footer-bottom">
        <div class="page-botton">
          <van-button
            type="primary"
            size="normal"
            @click="methods.savePaymentSelect()"
            v-if="pageConfig.allowSave"
            >确定</van-button
          >
          <van-button
            size="normal"
            @click="methods.onclose()"
            plain
            hairline
            type="primary"
            >取消</van-button
          >
        </div>
      </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  name: 'AccountPayable',
  props: ['dataList'],
  setup(props, { emit }) {
    //页面参数配置
    const pageConfig = reactive({
      showDetailList:[],
      checkedRow:null,
      dataList: [],
      allowSave: true,//允许提交
      document_currency_amount_total:0,//凭证金额合计
    })
    //内置函数
    const methods = {
      init:function(){
        if (props.dataList) {
          pageConfig.dataList = JSON.parse(JSON.stringify(props.dataList))
          pageConfig.dataList.forEach(element => {
            if(element.document_currency_amount<0 && pageConfig.allowSave){
              pageConfig.allowSave = false
              Toast("请联系财务做清账处理后再行请款！")
              return
            }
            pageConfig.document_currency_amount_total += Number(element.document_currency_amount)
          });
        }
      },
      //格式化日期
      formatDate:function(number) {
        const year = number.toString().slice(0, 4);
        const month = number.toString().slice(4, 6);
        const day = number.toString().slice(6, 8);
        return `${year}-${month}-${day}`;
      },
      //取消
      onclose:function(){
        emit("onclose",null)
      },
      //保存
      savePaymentSelect:function(){
        if(pageConfig.checkedRow.document_currency_amount<=0){
          Toast("凭证金额小于0，不能请款！")
          return
        }
        pageConfig.checkedRow.due_date = methods.formatDate(pageConfig.checkedRow.due_date)
        pageConfig.checkedRow.payment_start_date = methods.formatDate(pageConfig.checkedRow.payment_start_date)
        pageConfig.checkedRow.posting_date = methods.formatDate(pageConfig.checkedRow.posting_date)
        pageConfig.checkedRow.document_currency_amount_total = pageConfig.document_currency_amount_total
        emit("onclose",pageConfig.checkedRow)
      },
    }
    onMounted(() => {
      methods.init()
    })
    return{
      pageConfig,
      methods
    }
  }
}
</script>




<style scoped lang="scss">
.AccountPayable{
  .wrapper {
    width: 100%;
    background-color: #fff;
    overflow: auto;
    .vant-table {
      padding: 10px 0;
      width: 1550px;
      height: calc(100vh - 80px);

      table {
        width: 100%;
        padding: 5px;

        tr {
          line-height: 30px;
        }

        .th {
          height: 30px;
          line-height: 30px;
          background-color: #f5f6f6;
          text-align: center;
        }

        td {
          text-align: center;
          border-bottom: 1px solid #f5f6f6;
        }

        .link-text {
          color: #0089ff;
        }
      }

      .van-button {
        margin: 0 10px;
      }
    }
  }

    .footer-bottom {
      padding: 0px 10px;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .page-botton {
        width: 100%;
        .van-button {
          float: right;
          margin-left: 5px;
        }
      }
  }
}
</style>