import axios from "@/utils/request";

export function Sign(data){
    return axios({
        url:'/oa_ads/oa/ads/MeetingPersonnel/Sign',
        method:'post',
        data
    })
}
export function GetList(data){
    return axios({
        url:'/oa_ads/oa/ads/Meeting/GetList',
        method:'post',
        data
    })
}
