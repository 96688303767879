<script setup>
import {onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {BlobToImg, formatTime} from "@/utils/utils";
import {useWindowSize} from '@vant/use';
import {icons} from "@/views/Doc/Space/data";
import {debounce} from "@/utils/controlBtn";
import {useRouter} from "vue-router";
import {spaceStore} from "@/store/space";
import {storeToRefs} from "pinia";
import {Toast} from "vant";
import ChangeTitle from "@/utils/changeTitle";
import {PreviewFile} from "@/api/doc";
import FileListComp from "@/components/FileListComp.vue"

const router = useRouter()
const space_store = spaceStore()

const {
  currentFolder,
  tabLineList,
  loading,
  finished,
  fileList,
  pageSize,
  maxSize,
  currentLevel
} = storeToRefs(space_store)

const state = reactive({
  resourceSrc: "",
  showResourcePreview: false,
  imgTypes: ["jpeg", "png", "gif", "bmp", "webp", "tiff", "icon", "svg", "jpg"],
  isImg: false
})

// 点击文件夹
const handleFolder = (item) => {
  space_store.handleFolder(item)
}

// 点击返回上一级
const handleBack = () => {
  space_store.handleBack()
}

// 进入到目标菜单
const handleTarget = (item, index) => {
  if (item.id === currentLevel.value) return
  space_store.handleTarget(item, index)
}


// 滚动到底部时触发方法
const handleScroll = (e) => {
  const fun = debounce(() => {
    const {scrollHeight, scrollTop, clientHeight} = e.target
    if (scrollTop + clientHeight >= scrollHeight) {
      if (finished.value || loading.value) return
      space_store.updatePageSize(pageSize.value += 10)
      space_store.getFolderFile(tabLineList.value[tabLineList.value.length - 1])
    }
  }, 300)
  fun()
}

// 点击文件
const handleFile = async (item) => {
  if (item.type === "文件夹") {
    const child = currentFolder.value.filter(folder => folder.id === item.id)[0]
    handleFolder(child)
  } else {
    await previewFile(item)
  }
}

const previewFile = async (item) => {
  // 单独处理图片
  if (state.imgTypes.indexOf(item.type) !== -1) {
    state.isImg = true
    const res = await PreviewFile(item.id)
    state.resourceSrc = await BlobToImg(res, res.type)
    state.showResourcePreview = true
    return
  }
  // else if(item.type === 'mp4'){
  //   state.isImg = false
  //   const res = await PreviewFile(item.id)
  //   state.resourceSrc = URL.createObjectURL(res)
  //   state.showResourcePreview = true
  //   return
  // }
  // 其他的跳转到预览界面
  await router.push({
    name: 'SpacePreview',
    query: {
      id: item.id,
    }
  })
};

const init = async () => {
  const {width, height} = useWindowSize();
  if (maxSize.value === 0) {
    space_store.updateMaxSize(Math.ceil(height.value / 37) - 2)
    space_store.updatePageSize(maxSize.value)
    // 获取树列表
    const res = await space_store.fetchTreeData()
    if (res.code !== 200) Toast.fail("获取文件失败")
    space_store.getCurrentFolder()
  } else {
    await space_store.getFolderFile(tabLineList.value[tabLineList.value.length - 1])
  }
  window.addEventListener('scroll', handleScroll, true);
}

onMounted(async () => {
  ChangeTitle.setTitle("文档空间")
  await init()
})

onBeforeUnmount(() => {
  // 停止监听
  window.removeEventListener('scroll', handleScroll);
})

</script>

<template>
  <div class="spaceBox">
    <div class="tabLine" v-if="tabLineList.length > 0">
      <div class="tab" @click="handleBack">返回上一级</div>
      <div class="tab" v-for="(item,index) in tabLineList" @click="handleTarget(item,index)">
        <span>/</span>{{ item.label }}
      </div>
    </div>
    <div class="noSpace" v-if="currentFolder.length === 0 && fileList.length === 0">
      <van-empty description="暂无数据"/>
    </div>
    <div v-else class="hasSpace">
      <!--      首页文件夹-->
      <div class="dataFolder" v-if="fileList.length === 0 && loading === false">
        <div class="folder" v-for="item in currentFolder" @click="handleFolder(item)">
          <div class="img">
            <img :src="icons.get('文件夹')" alt="">
          </div>
          <div class="text">
            <div class="name">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <!--    文件（包含文件夹）-->
      <FileListComp :fileList="fileList" v-if="fileList.length > 0"/>
      <div v-if="loading" class="loading">
        <van-loading size="24px">加载中...</van-loading>
      </div>
      <div class="noMore" v-if="finished && fileList.length > 0">没有更多了</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pdfPreview {
  width: 450px;
  height: 550px;
  position: fixed;
  top: 0;
  left: 0;
}

.loading {
  text-align: center;
}

.noMore {
  text-align: center;
  color: #969799;
  padding: 0 20px;
}

.fileImg {
  img {
    width: 25px !important;
  }
}

.tabLine {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  margin-bottom: 10px;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .tab {
    white-space: nowrap;

    span {
      padding: 0 5px;
    }
  }
}

.spaceBox {
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  background: #fff;
  min-height: calc(100vh - 10px);
  box-sizing: border-box;
}

.noSpace {
  display: flex;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 33%;
  }
}

.hasSpace {
  width: 100%;
}

.dataFolder {
  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
  //display: grid;
  //justify-content: space-between;
  //grid-template-columns: repeat(auto-fill, 18%);
  overflow-y: scroll;
  width: 100%;

  &:after {
    content: "";
    flex: auto;
  }

  .img {
    display: flex;
    align-items: center;
  }

  .folder {
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 25px;
    }

    .text {
      flex: 1;
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 10px;
    }
  }

  .file {
    display: flex;
    margin-bottom: 15px;

    img {
      width: 25px;
    }

    .text {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      padding-left: 10px;

      .name {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    .bottom {
      color: #8f8f8d;
      display: flex;
      margin-right: 5px;
      font-size: 9px;

      .time {
        margin-right: 5px;
      }
    }
  }
}

</style>
