

<template>
  <div class="patchApplication">
    <van-form disabled>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请人
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_emp_info"
          name="申请人"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          补卡原因
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_reason_name"
          name="补卡原因"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请时间
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_time"
          name="申请时间"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          出勤日期
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.attendance_date"
          name="出勤日期"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          签卡时段1
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.patch_time_1"
          name="签卡时段1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          签卡时段2
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.patch_time_2"
          name="签卡时段2"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          签卡时段3
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.patch_time_3"
          name="签卡时段3"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          签卡时段4
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.patch_time_4"
          name="签卡时段4"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          备注
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.remark"
          name="备注"
          disabled
        />
      </van-cell-group>
    </van-form>
  </div>
</template>
<style lang="scss">
.patchApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
}
</style>
<script>
import { onMounted } from '@vue/runtime-core';
import { reactive, ref } from '@vue/reactivity'
import { GetSwipePatchApplyById }  from '@/api/ehrTas.js'
import { Toast } from 'vant';
import moment from 'moment'
export default {
  props: ['businessId'],
  setup(props) {
    //页面配置
    const pageConfig = reactive({
      apply_emp_name:'',//申请人
      apply_emp_code:'',//申请人工号
      apply_emp_info:'',//申请人信息
      attendance_date:'',//出勤日期
      apply_reason_name:'',//补卡原因
      apply_time:'',//申请时间
      patch_time_1:'',//签卡时间1
      patch_time_2:'',//签卡时间2
      patch_time_3:'',//签卡时间3
      patch_time_4:'',//签卡时间4
      remark:'',//备注
    })
    //内置函数
    const methods= {
      //获取签卡申请记录
      getSwipePatchApply:function(){
        let param = {
          id:props.businessId
        }
        GetSwipePatchApplyById(param).then(res=>{
          if(res.code != 200)
          {
            Toast(res.msg)
            return
          }
          pageConfig.apply_emp_name=res.data[0].apply_emp_name//申请人姓名
          pageConfig.apply_emp_code=res.data[0].apply_emp_code//申请人工号
          pageConfig.apply_emp_info=res.data[0].apply_emp_code+'-'+res.data[0].apply_emp_name//申请人工号
          pageConfig.attendance_date=methods.convertTime(res.data[0].attendance_date)//出勤日期
          pageConfig.patch_time_1=methods.convertTime(res.data[0].patch_time_1,true)//签卡时间1
          pageConfig.patch_time_2=methods.convertTime(res.data[0].patch_time_2,true)//签卡时间2
          pageConfig.patch_time_3=methods.convertTime(res.data[0].patch_time_3,true)//签卡时间3
          pageConfig.patch_time_4=methods.convertTime(res.data[0].patch_time_4,true)//签卡时间4
          pageConfig.apply_reason_name = res.data[0].patch_reason_name//签卡原因
          pageConfig.remark=res.data[0].remark//备注
          pageConfig.apply_time = methods.convertTime(res.data[0].apply_time,true)
        })
      },      
      //时间转换
      convertTime: function (time,istime) {
        if (time) {
          if(istime)
          {
            return moment(time).format('YYYY/MM/DD HH:mm:00')
          }else
          {
            return moment(time).format('YYYY/MM/DD')
          }          
        }
      },  
    }
    onMounted(()=>{
      //获取签卡申请记录
      methods.getSwipePatchApply()
    })
    return{
       pageConfig
    }
  }
}
</script>
