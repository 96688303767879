<!--
  公用员工选择器
  作者：陶璇
  时间：2023-7-12
-->
<script setup>
import {onMounted, reactive, watch, watchEffect} from "vue";
import {GetEmpListByName} from "@/api/oaApi";

const props = defineProps({
  showStaffPicker: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['closeStaffPicker','handleFocusStaffPicker','blurStaffPicker'])

const state = reactive(({
  showPicker: false, // 是否显示
  receivingManList: [], // 搜索人的列表
  codeName: "", // 员工号
  receivingMan: {}, // 已选择的员工
  timer: null, // 定时器
}))

// 点击确定
const rectManConfirm = (e) => {
  console.log(e,"e")
  if(!e) {
    emit("closeStaffPicker")//关闭选择器
    return
  }
  state.receivingMan = e//首先给表单绑定赋值
  state.receivingManLable = e.emp_code + '-' + e.emp_name//然后给表单显示赋值
  emit("closeStaffPicker",e)//关闭选择器
}
//搜索框内容变化触发
const searchUpdate = (e) => {
  if (state.timer) clearTimeout(state.timer)
  if(e === '' || e === null || e === undefined) {
    state.receivingManList = []
    return
  }
  // 定时器防抖 在300毫秒内 输入内容不再变化就执行搜索
  state.timer = setTimeout(() => {
    GetEmpListByName(e).then(res => {//emp的查询员工接口查询员工
      state.receivingManList = res.rows
      state.receivingManList = fuzzyQuery(state.receivingManList, e)//搜索框内容变化后调用筛选赋值给选择器绑定
    })
  }, 250)
}
watchEffect(() => {
  state.showPicker = props.showStaffPicker
})

const fuzzyQuery = (list, keyWord) => {//搜索框筛选函数
  const reg = new RegExp(keyWord, 'i');
  const arr = [];
  for (let i = 0; i < list.length; i++) {
    if (reg.test(list[i].emp_code + '-' + list[i].emp_name)) {
      arr.push(list[i]);
    }
  }
  return arr;
}
</script>

<template>
  <van-popup v-model:show="state.showPicker" position="bottom" round
             @click-overlay="emit('closeStaffPicker')">
    <van-picker
        :columns="state.receivingManList"
        @confirm="rectManConfirm"
        @cancel="emit('closeStaffPicker')"
    >
      <!-- 接收人选择器自定义标题头 -->
      <template #title>
        <van-search
            v-model="state.codeName"
            placeholder="请输入工号或者姓名"
            @update:model-value="searchUpdate"
            @focus="emit('handleFocusStaffPicker')"
            @blur="emit('blurStaffPicker')"
        />
      </template>
      <!-- 自定义选择器内容 -->
      <template #option="option">
        <div>{{ option.emp_code + "-" + option.emp_name }}</div>
      </template>
    </van-picker>
  </van-popup>
</template>

<style scoped lang="scss">
:deep(.van-field__body) {
  position: relative;
}

:deep(.van-field__clear) {
  position: absolute;
  right: 0;
}
</style>