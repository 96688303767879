<!--
 Copyright 蒋云辰
 Describe 流程图组件
 Date     2022/3/5
-->

<template>
  <!-- 任务流程 -->
  <div class="task-process-list">
    <div class="title">流程</div>
    <van-loading
      type="spinner"
      v-if="pageConfig.showLoadding"
      color="#1989fa"
    />
    <section class="task-process" v-for="(item, i) in taskProcess" :key="i">
      <div class="flow-status">
        <div class="flow-status-head">
          <div class="user-head-block">
            <!-- 结束节点 -->
            <div v-if="item.status_code == 1000">结束</div>
            <!-- 单个人 -->
            <div
              class="single-user"
              v-else-if="
                item.details.length == 1 && item.details[0].approval_emp_id
              "
            >
              {{
                item.details[0].approval_emp_name.substring(
                  item.details[0].approval_emp_name.length - 2
                )
              }}
            </div>
            <!-- 多用户 -->
            <div class="user-group" v-else>
              <van-icon name="friends" />
            </div>
            <div class="status-icon" v-if="item.status_code != 1000">
              <!-- 已通过 -->
              <van-icon
                name="checked"
                color="#57B987"
                v-if="item.result == 'Approve'"
              />
              <!-- 已拒绝 -->
              <van-icon
                name="clear"
                color="#F25643"
                v-else-if="item.result == 'Reject'"
              />
              <!-- 转交 -->
              <van-icon
                name="share"
                color="#F25643"
                v-else-if="item.result == 'Forward'"
              />
              <!-- 已退回 -->
              <van-icon
                name="revoke"
                style="
                  background-color: #f25643;
                  border-radius: 10px;
                  text-align: center;
                  line-height: 13px;
                  width: 13px;
                  height: 13px;
                  position: absolute;
                  bottom: 2px;
                  right: 2px;
                  font-size: 8px;
                "
                v-else-if="item.result == 'Return'"
              />
              <!-- 待处理 -->
              <van-icon color="#F09951" name="more" v-else />
            </div>
          </div>
          <div style="height: 100%" v-if="i != taskProcess.length - 1">
            <div class="connect-line" v-if="item.result != ''"></div>
            <div class="connect-dashed-line" v-else></div>
          </div>
        </div>
        <div class="flow-status-content">
          <div class="flow-status-content-title">
            <div class="status-title">{{ item.status_name }}</div>
            <div class="approve-time" v-if="item.result != ''">
              {{ process.convertTime(item.details[0].approval_time) }}
            </div>
          </div>
          <div
            class="flow-status-content-action"
            v-if="item.status_code != 1000 && item.status_type == 1"
          >
            <!-- 单人审批 -->
            <label v-if="item.details.length == 1 && item.result != ''"
              ><!-- 需要显示执行动作{{ item.details[0].remark }}-->
              {{ item.details[0].actual_approval_emp_name
              }}<span v-if="item.details[0].actual_approval_emp_name">代理</span
              >{{ item.details[0].approval_emp_name
              }}{{
                item.result == "Approve"
                  ? "同意"
                  : item.result == "Reject"
                  ? "拒绝"
                  : item.result == "Forward"
                  ? "转交"
                  : "回退"
              }}
            </label>
            <label v-else
              ><label v-if="item.status_code != 1000"
                ><span v-if="item.result == ''">{{
                  item.details.length > 0 ? item.details[0].remark : "预计流程"
                }}</span
                >审批中：{{ item.details.length }}人{{
                  item.approve_type == 2 ? "或签" : "会签"
                }}</label
              ></label
            >
          </div>
          <div
            class="flow-status-content-approver"
            v-if="item.details.length > 1"
          >
            <div
              class="approver-block"
              v-for="detail in item.details"
              :key="detail.approval_emp_id"
            >
              {{
                detail.approval_emp_name.substring(
                  detail.approval_emp_name.length - 2
                )
              }}
              <div class="status-icon">
                <!-- 已通过 -->
                <van-icon
                  name="checked"
                  color="#57B987"
                  v-if="detail.action == 'Approve'"
                />
                <!-- 已拒绝 -->
                <van-icon name="clear" v-else-if="detail.action == 'Reject'" />
                <!-- 已退回 -->
                <van-icon name="revoke" v-else-if="detail.action == 'Return'" />
                <!-- 转交 -->
                <van-icon name="share" v-else-if="detail.action == 'Forward'" />
                <!-- 待处理 -->
                <van-icon color="#F09951" name="more" v-else />
              </div>
            </div>
          </div>
          <!--审批备注 -->
          <div
            class="flow-status-content-remark"
            v-if="
              item.details.length > 0 &&
              item.details[0].remark &&
              item.status_code != 0 &&
              item.status_code != 1000 &&
              item.result != '' &&
              item.status_type == 1
            "
          >
            <div class="remark-text">
              <p>
                备注:{{ item.details.length > 0 ? item.details[0].remark : "" }}
              </p>
            </div>
          </div>
          <!--评论 -->
          <div class="flow-status-content-comment">
            <div
              class="comment-text"
              v-for="comment in item.comments"
              :key="comment.id"
              @click="process.openCommentDetail(comment)"
            >
              <p>
                {{ comment.comment_emp_name + ":" + comment.content }}
              </p>
              <img
                v-if="comment.img_id_list || comment.file_id_list"
                src="@/assets/paperclip.png"
                height="18"
              />
            </div>
          </div>
          <!-- 抄送 -->
          <div
            class="flow-status-content-cclist"
            v-if="item.cclist && item.cclist.length > 0"
          >
            <div>抄送人：</div>
            <div class="cc-block">
              <div class="cc-item" v-for="cc in item.cclist" :key="cc.emp_id">
                {{
                  cc.emp_name
                    ? cc.emp_name.substring(cc.emp_name.length - 2)
                    : "??"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { FlowChartPreview } from '@/api/oawfs.js'
import { Toast } from 'vant'
import moment from 'moment'
import { useRouter } from 'vue-router'
export default {
  props: ['instanceId'],
  setup(props) {
    const router = useRouter()
    //流程图
    const taskProcess = ref([])
    const pageConfig = reactive({
      showLoadding: true, //加载效果
    })
    const process = {
      //加载获取流程数据
      loadProcessList: function () {
        const param = {
          ran: '2',
          instanceId: props.instanceId,
        }
        //获取流程图
        FlowChartPreview(param).then((res) => {
          pageConfig.showLoadding = false
          if (!res.success) {
            Toast(res.msg)
            return
          }
          taskProcess.value = res.data
        })
      },
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('MM-DD HH:mm')
        }
      },
      //打开评论明细
      openCommentDetail: function (item) {
        router.push({
          name: 'CommentDetail',
          params: {
            comment_id: item.id,
          },
        })
      },
    }
    onMounted(() => {
      //加载获取流程数据
      process.loadProcessList()
    })
    return {
      pageConfig,
      process,
      taskProcess,
    }
  },
}
</script>
<style lang="scss">
.task-process-list {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  margin: 5px auto;
  .title {
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    text-indent: 5px;
  }

  .van-loading {
    text-align: center;
    position: absolute;
    z-index: 9999999;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    border-radius: 10px;
    left: calc(50% - 40px);
    top: 25vh;
  }
  section {
    width: 100%;
    .flow-status {
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      .flow-status-head {
        width: 50px;
        .user-head-block {
          width: 45px;
          margin: 5px 5px 0 5px;
          height: 45px;
          font-size: 14px;
          border-radius: 5px;
          color: #fff;
          line-height: 45px;
          text-align: center;
          position: relative;
          background-color: #409eff;
          .user-group {
            font-size: 20px;
          }
          .status-icon {
            height: 13px;
            width: 13px;
            text-align: center;
            border-radius: 10px;
            position: absolute;
            bottom: -1px;
            right: -1px;
            background-color: #fff;
            .van-icon {
              height: 10px;
              width: 10px;
              font-size: 12px;
              position: absolute;
              bottom: 2px;
              right: 3px;
            }
          }
        }
        .connect-line {
          width: 0px;
          border-left: 2px solid #dce3eb;
          height: calc(100% - 38px);
          min-height: 10px;
          margin: 2px auto;
        }
        .connect-dashed-line {
          width: 0px;
          border-left: 2px dashed #dce3eb;
          height: calc(100% - 35px);
          min-height: 10px;
          margin: 2px auto;
        }
      }
      .flow-status-content {
        width: calc(100% - 50px);
        padding: 5px;
        .flow-status-content-title {
          .status-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            font-size: 14px;
            line-height: 24px;
            color: #333333;
            display: inline-block;
            width: calc(100% - 80px);
            vertical-align: middle;
          }

          .approve-time {
            display: inline-block;
            vertical-align: middle;
            width: 80px;
            color: #ccc;
            font-size: 10px;
            text-align: right;
          }
        }
        .flow-status-content-approver {
          display: -webkit-flex;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .approver-block {
            width: 30px;
            height: 30px;
            border-radius: 3.5px;
            color: #fff;
            text-align: center;
            line-height: 30px;
            margin: 5px;
            margin-left: 0px;
            background-color: #409eff;
            position: relative;
            font-size:10px;
            .status-icon {
              height: 10px;
              width: 10px;
              text-align: center;
              border-radius: 5px;
              position: absolute;
              bottom: -1px;
              right: -1px;
              background-color: #fff;
              .van-icon {
                height: 8px;
                width: 8px;
                font-size: 8px;
                position: absolute;
                bottom: 1.2px;
                right: 1.2px;
              }
            }
          }
        }
        .flow-status-content-action {
          line-height: 18px;
          color: #999999;
        }
        .flow-status-content-comment {
          .comment-text {
            position: relative;
            padding: 1px 10px 1px 10px;
            margin: 5px auto;
            background-color: #efefef;
            img {
              position: absolute;
              right: 5px;
              bottom: 10px;
            }
          }
        }
        .flow-status-content-cclist {
          color: #999999;
          font-size: 12px;
          .cc-block {
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .cc-item {
              font-size: 10px;
              width: 30px;
              height: 30px;
              border-radius: 3.5px;
              color: #fff;
              text-align: center;
              line-height: 30px;
              margin: 5px;
              margin-left: 0px;
              background-color: #409eff;
            }
          }
        }
        .flow-status-content-remark {
          .remark-text {
            padding: 1px 5px;
            margin: 5px auto;
            background-color: #efefef;
          }
        }
      }
    }
  }
}
</style>
