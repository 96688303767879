
<template>
<div style="height: 40%; position: relative;">
<div style="width: 150px;  position: absolute; bottom: 0;left: 50%;transform: translateX(-50%);">
<img src="@/assets/signSuccess.svg" style="width: 150px;margin-bottom: 30px"><br>
 <p class="showDetails" @click="methods.showDetails">查看资产详情</p>
</div>


</div>
  <div style="height: 60%; position: relative;">
    <van-button
        @click="methods.logout"
        round
        block
        type="primary"
        style="padding: 16px;  position: absolute;
            bottom: 10%;"
        native-type="submit"
    >退出
    </van-button
    >

  </div>
</template>
<script>
import {onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {GetRecordByAssetCodeNoPrm, Inventory} from "@/api/ifseam";
import {Toast} from "vant";
import * as dd from "dingtalk-jsapi";

export default {
  setup() {
    const router = useRouter()
    const  formRef=ref(null);

    const formData=reactive({
      asset_id:"",
      back_type:""
    })
    const  methods=reactive(
        {
          showDetails:()=>{
            router.push({name: 'inventoryDetails', query: {asset_id: formData.asset_id,back_type:formData.back_type}})
          },
          logout:()=>{
            if (formData.back_type=="1"){
              router.push("/home")
            }else {
              dd.biz.navigation.goBack({
                onSuccess: function (result) { },
                onFail: function (err) { },
              })
            }
          },

          init: function () {
            const router = useRouter();
            const paramValue = router.currentRoute.value.query
            if (paramValue.asset_id) {
              formData.asset_id=paramValue.asset_id;
              formData.back_type=paramValue.back_type;
            }else{
              if (paramValue.back_type=="1"){
                router.push("/home")
              }else {
                dd.biz.navigation.goBack({
                  onSuccess: function (result) { },
                  onFail: function (err) { },
                })
              }


            }
          }
        }
    )
    onMounted(() => {
      methods.init()
    });
    return{formData,methods,formRef}
  }

};
</script>


<style scoped lang="scss">
.showDetails{
  font-size: 12px;
  margin-left: 15px;
  color: #2C99FF;
}
.showDetails:hover{
  font-size: 12px;
  margin-left: 15px;
  color: #0074E8;
}
</style>