import axios from '../utils/request.js'
//获取我的考试
export function GetAllForMyExam(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/GetAllForMyExam',
    method: 'GET',
    params: data,
  })
}
//获取我的考试记录
export function GetMyExamInfoByEmpId(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/GetMyExamInfoByEmpId',
    method: 'GET',
    params: data,
  })
}
//获取答卷
export function GetQuestion(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/GetQuestions',
    method: 'GET',
    params: data,
  })
}
//保存答案
export function ExamSaveAnswer(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/ExamSaveAnswer',
    method: 'POST',
    data: data,
  })
}
//用户考试的题目信息存入数据库
export function ExamSaveQuestion(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/ExamSaveQuestion',
    method: 'POST',
    data: data,
  })
}
//交卷
export function SubmitExam(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/SubmitExam',
    method: 'GET',
    params: data,
  })
}
//记录考试时长
export function RecordTime(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/RecordTime',
    method: 'POST',
    data: data,
  })
}
//重新开始考试计算考试时间
export function StartExamTime(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/StartExamTime',
    method: 'POST',
    data: data,
  })
}
//开始考试获取问题id*
export function StartExam(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/StartExam',
    method: 'Get',
    params: data,
  })
}

//获取课程列表*
export function CourseList(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/PerStudy/GetvideoList',
    method: 'POST',
    data: data,
  })
}
export function GetCompanies(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Common/GetAllCompanies',
    method: 'POST',
    data: data,
  })
}

//开始学习
export function CourseStart(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/LearningRecords/start',
    method: 'POST',
    data: data,
  })
}
//保存学习记录
export function SaveRecords(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/LearningRecords/saveRecords',
    method: 'POST',
    data: data,
  })
}
//获取课程分类
export function CourseCategoryList(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/CourseCategory/GetTreeList',
    method: 'Get',
    params: data,
  })
}

//插入学习记录
export function insertStudyRecord(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/PerStudy/Insert',
    method: 'Post',
    data: data,
  })
}

//更新学习记录时长
export function UpdateStudyTime(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/PerStudy/Update',
    method: 'Post',
    data: data,
  })
}

export function GetClassCategory(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/OpenClass/GetClassCategory',
    method: 'Post',
    data: null,
  })
}
export function subRegister(data){
  return axios({
      url:'/ehr_tms/ehr/tms/PerStudy/SubRegister',
      method:'Post',
      data:data
  })
}
//判断最小学习时长是否大于自己的学习记录的时长，如果大不能考试
export function CompareStudyTime(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/CompareStudyTime',
    method: 'post',
    data: data
  })
}
//获取我的所有考试记录
export function GetMyAllExamRecord(data) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/GetMyAllExamRecord',
    method: 'Post',
    data: data
  })
}
//获取考试详情，用于查看考试错题
export function GetExamDetails(query) {
  return axios({
    url: '/ehr_tms/ehr/tms/Exam/GetExamDetails',
    method: 'Get',
    params: query
  })
}