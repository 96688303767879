<!--单个考勤机查询-->
<template>
  <van-cell-group class="myMachineCell">
    <div class="smartAttendanceTop">
      <div class="header-item1">{{ attentionMachineItem.deviceName }}（{{ attentionMachineItem.empCode }}）人</div>
      <div class="header-item2">IP地址：{{ attentionMachineItem.ipAddress }}</div>
      <div class="header-item3">SN码：{{ attentionMachineItem.serialNo }}</div>
    </div>
    <van-cell @click="gotoCollect(attentionMachineItem)">
      <div class="smartAttendanceBottom">
        <img src="../../../assets/iris.svg" alt="">
        <span>虹膜/人脸采集</span>
      </div>
      <template #right-icon>
        <van-icon name="arrow" class="right-icon"/>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script setup>
import {onMounted} from '@vue/runtime-core'
import ChangeTitle from '@/utils/changeTitle.js'
import {useRoute, useRouter} from 'vue-router'
import {ref, watch} from "vue";
import {AttentionMachineStore} from "@/store/attentionMachineStore";

const attentionMachineStore = AttentionMachineStore()
const attentionMachineItem = ref(attentionMachineStore.item)
const refreshing = ref(false)
const Router = useRouter(); //获取到值
//转到虹膜/人脸采集页面
const gotoCollect = (item) => {
  Router.push({path: "collectEmp", query: {deviceId: item.deviceId, deviceName: item.deviceName}})
}
onMounted(() => {
  ChangeTitle.setTitle("考勤机列表")
})

</script>
<style lang="scss" scoped>
.myMachineCell {
  height: 100vh;
  background-color: #fff;
}

img {
  max-width: 15%;
  height: auto;
  margin-right: 30px;
}

.right-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-40%);
  margin-top: 3px;
}

.smartAttendanceTop {
  height: 80px;
  padding: 10px 0 10px 0;
  text-align: center;
  background-color: #fff;
  font-size: 12px;
  line-height: 30px;

  .header-item1 {
    color: black;
  }

  .header-item2 {
    color: #9e9e9e;
  }

  .header-item3 {
    color: #7d8082;
  }

}

.smartAttendanceBottom {
  border: 1px solid #adacac;
  border-radius: 5px;
  height: 40px;
  margin: 10px 5px 0 5px;
  display: flex;
  font-size: 12px;
  color: #333333;
  align-items: center;

}
</style>
