import axios from '@/utils/request.js'

// 预约会议室
export function GetMeetingRoomList(data) {
    return axios({
        url: '/oa_ads/oa/ads/Meeting/GetMeetingRoomList',
        method: 'post',
        data
    })
}

// 预约会议室
export function AddRecord(data) {
    return axios({
        url: '/oa_ads/oa/ads/Meeting/AddRecord',
        method: 'post',
        data
    })
}

// 插入会议室参会人员
export function AddMeetingPersonnel(data) {
    return axios({
        url: '/oa_ads/oa/ads/MeetingPersonnel/insert',
        method: 'post',
        data
    })
}

// 我的预约列表
export function GetMeetingList(data) {
    return axios({
        url: '/oa_ads/oa/ads/Meeting/GetList',
        method: 'post',
        data
    })
}

// 获取参会人员
export function GetParticipants(meeting_id) {
    return axios({
        url: '/oa_ads/oa/ads/MeetingPersonnel/GetParticipants',
        method: 'post',
        data: {meeting_id}
    })
}

// 获取公司列表
export function GetCompanies() {
    return axios({
        url: '/oa_ads/oa/ads/MeetingRoom/GetCompanies',
        method: 'get',
    })
}

// 获取我的预约列表
export function GetMyMeetingList(data) {
    return axios({
        url: '/oa_ads/oa/ads/Meeting/GetList',
        method: 'post',
        data
    })
}

// 修改会议信息
export function UpdateMeeting(data) {
    return axios({
        url: '/oa_ads/oa/ads/Meeting/EditRecord',
        method: 'post',
        data
    })
}

// 获取用户部门信息
export function GetDeptName(id) {
    return axios({
        url: '/oa_ads/oa/ads/Car/GetDeptName?deptId=' + id,
        method: 'Get',
    })
}

// 根据部门id获取部门下的人员
export function GetEmpDept(dept_id) {
    return axios({
        url: '/oa_ads/oa/ads/Car/GetEmpDept',
        method: 'Post',
        data: {dept_id}
    })
}

// 根据部门名称搜索部门
export function GetDeptNameList(name) {
    return axios({
        url: '/oa_ads/oa/ads/Car/GetDep?key=' + name,
        method: 'Get',
    })
}

// 根据员工工号或姓名搜索
export function GetEmpNameList(key) {
    return axios({
        url: '/oa_ads/oa/ads/Car/GetEmployeesDept',
        method: 'post',
        data: {
            key,
            dept_ids: [""]
        }
    })
}

// 根据key查询员工或部门
export function GetEmployeesDeptByKey(key) {
    return axios({
        url: '/oa_ads/oa/ads/Car/GetEmployeesDeptByKey',
        method: 'post',
        data: {key}
    })
}