import router from './router'
import * as dd from 'dingtalk-jsapi'
import {spaceStore} from "@/store/space";
import {UserStore} from "@/store/userStore"; //钉钉组件
import { Toast } from 'vant';
// 全局路由守卫
const whiteList = ['/login','/WfsVisitor','/ShortUrl','/externalTransfer','/Ads/vendorMenu','/Ads/inlet','/Ads/viewOrder','/Ads/viewOrderlist',"/Ads/quotation","/Ads/quotationlist"] //定义白名单
let firstLoad = process.env.VUE_APP_FIRSTLOAD=="true"?true:false
let scrollTop = 0


function getCookie(name) {
    const cookieName = `${name}=`;
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }
router.beforeEach((to, from, next) => {
    if (from.path === "/home" && to.path === "/Doc/Space") {
        spaceStore().initData()
    }
    //获取用户信息
    if(Object.keys(UserStore().userInfo).length === 0){
        UserStore().getUserInfo()
    }
    //扫码点检
    if((to.name == 'Transit' || to.name == 'home') && firstLoad){
        firstLoad=false
        localStorage.clear()
    }
    if (localStorage.getItem('tenant_id')){
        if (to.path == '/login') {
            next('/')
        } else {
            next()
        }
    } else {
        //白名单
        if (whiteList.indexOf(to.path) > -1) {
            // '/Ads/inlet',
            const adsList=['/Ads/vendorMenu','/Ads/viewOrder','/Ads/viewOrderlist',"/Ads/quotation","/Ads/quotationlist"]
            const phone = getCookie('phone');
            if(adsList.indexOf(to.path) > -1){
                //验证竞价供应商是否登录
                if(!phone){
                    Toast.fail("请登录")
                    next('/Ads/inlet')
                }else{
                    const adsrouterList=['/Ads/viewOrder','/Ads/viewOrderlist',"/Ads/quotation","/Ads/quotationlist"]
                    if(adsrouterList.indexOf(to.path) > -1){
                        if(to.query.isrouter){
                            next()
                        }else{
                            next({name: 'vendorMenu'})
                        }
                    }
                }
            }
            if(to.path=="/Ads/inlet"&&phone){
                next({name: 'vendorMenu'})
            }
            next()
        } else {
            //拦截路由之前记录需要进入的地址
            sessionStorage.setItem('NextRoutePath', to.fullPath)
            next('/login')
        }
    }
})

// router.beforeLeave = (to, from, next) => {
//     scrollTop = document.documentElement.scrollTop || document.body.scrollTop
//     console.log(scrollTop,"scrollTop")
//     next()
// }
//
// router.beforeEnter = (to, from, next) => {
//     next(vm => {
//         document.body.scrollTop = vm.scrollTop
//     })
// }

export default router
