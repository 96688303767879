<template>
  <div style="overflow-y: auto; height: 100vh" id="EnvironmentMark">
    <router-view />
  </div>
</template>

<script>
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
import { GetEnvironment } from '@/api/oaApi.js'
import {
  onMounted,
  reactive,
  ref,
  watch,
  toRefs,
  onUnmounted,
  onBeforeMount,
} from 'vue'
import waterMark from '@/store/waterMark.js'
import eruda from '@/utils/eruda.js'
import { useRouter } from 'vue-router'
import * as dd from 'dingtalk-jsapi'
export default {
  setup() {
    const r = useRouter()
    const pageConfig = reactive({
      flg: '',
    })
    //内置函数
    const methods = {
      //环境参数水印
      waterMarkEnvironment: function () {
        GetEnvironment().then((res) => {
          if (res.code == 200) {
            if (res.data) {
              eruda.init()
              //设置水印
              waterMark.set(
                res.data,
                document.getElementById('EnvironmentMark')
              )
            }
          }
        })
      },
      //监听退出登录
      loginout: function () {
        document.addEventListener('visibilitychange', function () {
          if (document.visibilityState === 'hidden') {
            localStorage.clear()
          }
        })
      },
    }

    // dd.ready(function() {
    //   document.addEventListener('pause', function() {
    //     // 在这里处理你的业务逻辑
    //     const controller = new AbortController();
    //     controller.abort()
    //   });
    // },false)

    onMounted(() => {
      methods.waterMarkEnvironment()
      //methods.loginout()
    })

    return {
      methods,
      ...toRefs(pageConfig),
    }
  },
}
</script>
<style lang="scss">
.van-divider {
  display: none !important;
}
* {
  -webkit-touch-callout: none;
  /* 禁止长按菜单 */
  -webkit-user-select: none;
  /* 禁止选择文字 */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  overflow: hidden;
  background-color: #f0f0f0;
}

img[src=''],
img:not([src]) {
  opacity: 0;
}

#EnvironmentMark {
  max-width: 400px;
  margin: 0 auto;
}

:deep(.van-cell--required) {
  &:before {
    content: '';
  }

  .van-cell__title.van-field__label {
    span {
      position: relative;
      &:before {
        position: absolute;
        right: -8px;
        color: #ee0a24;
        font-size: 14px;
        content: '*';
      }
    }
  }
}
</style>
