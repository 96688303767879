<script setup>
import {reactive,onMounted} from "vue";
import {useRouter} from "vue-router";
import ChangeTitle from "@/utils/changeTitle";
import {GetClient} from "@/api/Kanban/remoteControl";
import {dataItemConfig} from "./data";
import DataList from "./comp/DataList.vue";

const router = useRouter()


const state = reactive({
  dataList: [], // 数据列表
})



// 点击事件
const handleClick = (item) => {
  router.push({name:'RemoteDetails',query:{id:item.id,play_mode:item.play_mode,name:item.client_name}})
}

// 获取终端列表
const getClientList = async () => {
  const res = await GetClient()
  state.dataList = res.data
}

onMounted(() => {
  ChangeTitle.setTitle('遥控终端')
  getClientList()
})
</script>

<template>
  <div class="outBox">
    <div class="title">终端总数：({{state.dataList.length}})</div>
    <DataList
        :dataList="state.dataList"
        :dataItemConfig="dataItemConfig"
        @handleDetail="handleClick"/>
  </div>
</template>

<style scoped lang="scss">
.outBox{
  min-height: calc(100vh - 10px);
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  .title{
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
}
.dataBox {
  display: flex;
  flex-wrap: wrap;
}
</style>