
<template>
  <div class="timeOverApplication">
    <van-form disabled>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请人
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_emp_info"
          name="申请人"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班日期
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.attendance_date"
          name="加班日期"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请日期
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_time"
          name="申请日期"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班开始时段1
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_start_time_1"
          name="加班开始时段1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班结束时段1
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_end_time_1"
          name="加班结束时段1"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班开始时段2
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_start_time_2"
          name="加班开始时段2"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班结束时段2
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_end_time_2"
          name="加班结束时段2"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班开始时段3
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_start_time_3"
          name="加班开始时段3"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班结束时段3
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_end_time_3"
          name="加班结束时段3"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请加班时数
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_overtime_hours"
          name="申请加班时数"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          加班类型
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.overtime_type"
          name="加班类型"
          disabled
        />
        <span
          style="margin-left: 1vh; margin-top: 2vh; display: block; color: #000"
        >
          申请原因
        </span>
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.apply_reason"
          name="加班类型"
          disabled
        />
      </van-cell-group>
    </van-form>
  </div>
</template>
<style lang="scss">
.timeOverApplication {
  .van-field__control {
    -webkit-text-fill-color: black !important;
  }
}
</style>
<script>
import { reactive } from '@vue/reactivity'
import { GetOvertimeById } from '@/api/ehrTas.js'
import { Toast } from 'vant'
import { onMounted } from '@vue/runtime-core'
import moment from 'moment'
export default {  
    props: ['businessId'],
    setup(props) {
        //页面配置
        const pageConfig=reactive({
            apply_emp_name:'',//申请人姓名
            apply_emp_code:'',//申请人工号
            apply_emp_info:'',//申请人信息
            attendance_date:'',//加班日期
            apply_start_time_1:'',//加班开始时段1
            apply_end_time_1:'',//加班结束时段1
            apply_start_time_2:'',//申请开始加班时段2
            apply_end_time_2:'',//申请结束加班时段2
            apply_start_time_3:'',//申请开始加班时段3
            apply_end_time_3:'',//申请结束加班时段3
            apply_overtime_hours:0,//申请加班时数
            overtime_type:'',//转加班OT，转调休LV
            apply_reason:'',//申请原因
            apply_time:'',//申请时间
        })
        //内置函数
        const methods = {
          //通过id获取加班记录
            getOvertimeById:function()
            {
              GetOvertimeById({id:props.businessId}).then(res=>{
                if(res.code != 200)
                {
                  Toast(res.msg)
                  return
                }
                pageConfig.apply_emp_name=res.data[0].apply_emp_name//申请人姓名
                pageConfig.apply_emp_code=res.data[0].apply_emp_code//申请人工号
                pageConfig.apply_emp_info=res.data[0].apply_emp_code+'-'+res.data[0].apply_emp_name//申请人信息
                pageConfig.attendance_date=methods.convertTime(res.data[0].attendance_date)//加班日期
                pageConfig.apply_start_time_1=methods.convertTime(res.data[0].apply_start_time_1,true)//加班开始时段1
                pageConfig.apply_end_time_1=methods.convertTime(res.data[0].apply_end_time_1,true)//加班结束时段1
                pageConfig.apply_start_time_2=methods.convertTime(res.data[0].apply_start_time_2,true)//加班开始时段2
                pageConfig.apply_end_time_2=methods.convertTime(res.data[0].apply_end_time_2,true)//加班结束时段2
                pageConfig.apply_start_time_3=methods.convertTime(res.data[0].apply_start_time_3,true)//加班开始时段3
                pageConfig.apply_end_time_3=methods.convertTime(res.data[0].apply_end_time_3,true)//加班结束时段3
                pageConfig.apply_overtime_hours=res.data[0].apply_overtime_hours//申请加班时数
                pageConfig.overtime_type=res.data[0].overtime_type
                pageConfig.apply_reason = res.data[0].apply_reason
                pageConfig.apply_time = methods.convertTime(res.data[0].apply_time,true)

              })
            },            
            //时间转换
            convertTime: function (time, istime) {
              if (time) {
                if (istime) {
                  return moment(time).format('YYYY/MM/DD HH:mm:00')
                } else {
                  return moment(time).format('YYYY/MM/DD')
                }
              }
            },
        }
        onMounted(()=>{
          //获取加班记录
          methods.getOvertimeById()
        })
        return{
            pageConfig,
            methods
        }
    }
}
</script>
