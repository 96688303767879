'use strict'

let watermark = {}

let setWatermark = (str, el) => {
  try {
    let id = '1.23452384164.123412415'
    if (document.getElementById(id) !== null) {
      el.removeChild(document.getElementById(id))
    }
    let can = document.createElement('canvas')
    let cans = can.getContext('2d')
    can.width = 150
    can.height = 120
    cans.rotate((-20 * Math.PI) / 180)
    cans.font = '18px Vedana'
    cans.fillStyle = 'rgba(120, 120, 120, 0.20)'
    cans.textAlign = 'left'
    cans.textBaseline = 'Middle'
    cans.fillText(str, '', 100)

    let div = document.createElement('div')
    div.id = id
    div.style.pointerEvents = 'none'
    div.style.top = '0px'
    div.style.left = '0px'
    div.style.position = 'fixed'
    div.style.zIndex = '2000'
    div.style.width = document.documentElement.clientWidth + 'px'
    div.style.height = document.documentElement.clientHeight - 100 + 'px'
    div.style.background =
      'url(' + can.toDataURL('image/png') + ') left top repeat'
    el.appendChild(div)
    return id
  } catch (e) {
    console.log(e)
  }
}

// 该方法只允许调用一次
watermark.set = (str, el) => {
  let id = setWatermark(str, el)
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str, el)
    }
  }, 500)
  window.onresize = () => {
    setWatermark(str, el)
  }
}

export default watermark
