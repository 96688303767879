import axios from "@/utils/request";

// 获取树列表
export function GetTreeDataList() {
    return axios({
        url: '/oa_doc/oa/doc/FileStore/GetTreeDataList',
        method: 'post',
        data: {
            type:""
        },
    })
}
// 获取空间文件列表
export function GetDirectoryPageList(data) {
    return axios({
        url: '/oa_doc/oa/doc/FileStore/GetDirectoryPageList',
        method: 'post',
        data
    })
}

// 获取空间文件列表
export function PreviewFile(id) {
    return axios({
        url: '/oa_doc/oa/doc/File/PreviewFile',
        method: 'post',
        data: {id},
        responseType: 'blob',
        timeout: 999999,
    })
}

