import axios from '../utils/request.js'
//钉钉应用id
//正式dingyidh51qodvmzrkic 测试ding1reitutps4ttbxk4
const AppKey = 'dingyidh51qodvmzrkic' //'ding1reitutps4ttbxk4'
//钉钉秘钥
//正式L13hWfKNK1jkhD_zfwzy1B4BghBDEbQ0mblp0Kb_cPcMgy566aMabK_XC7YfGV4B 测试2BaG47obDP6ftaY3kyoCkDy9I44a0RtLj4sNZaPoDua768DzMsJEtt5Va8BgrybM
const AppSecret =
  'L13hWfKNK1jkhD_zfwzy1B4BghBDEbQ0mblp0Kb_cPcMgy566aMabK_XC7YfGV4B' //'2BaG47obDP6ftaY3kyoCkDy9I44a0RtLj4sNZaPoDua768DzMsJEtt5Va8BgrybM'
/*获取token*/
export function access_token() {
  return axios({
    url: '/dingding_api/gettoken?appkey=' + AppKey + '&appsecret=' + AppSecret,
    method: 'GET',
  })
}
// userid
export function getuserId(data, code) {
  return axios({
    url:
      '/dingding_api/topapi/v2/user/getuserinfo?access_token=' +
      data +
      '&code=' +
      code,
    method: 'POST',
  })
}
// 获取用户信息
export function getuserInfo(access_token, data, language) {
  return axios({
    url:
      '/dingding_api/topapi/v2/user/get?access_token=' +
      access_token +
      '&userid=' +
      data +
      '&language=' +
      language,
    method: 'POST',
  })
}


export function getLoginUser(){
  return axios({
    url:'/oa_portal/oa/portal/Account/getLoginUser',
    method:'GET'
  })
}