<script setup>
import {icons} from "@/views/Doc/Space/data";
import {BlobToImg, formatTime} from "@/utils/utils";
import {PreviewFile} from "@/api/doc";
import router from "@/router";
import {reactive} from "vue";
import {spaceStore} from "@/store/space";
import {storeToRefs} from "pinia";
import {DownLoadFiles} from "@/api/oaApi";

const props = defineProps({
  fileList: {
    type: Array,
    default: () => {
      return []
    }
  },
  isTask: {
    type: Boolean,
    default: false
  }
})

const space_store = spaceStore()
const {
  currentFolder,
  loading,
  finished,
  pageSize,
} = storeToRefs(space_store)

const state = reactive({
  resourceSrc: "",
  showResourcePreview: false,
  imgTypes: ["jpeg", "png", "gif", "bmp", "webp", "tiff", "icon", "svg", "jpg"],
  isImg: false
})

const handleFile = async (item) => {
  if (item.type === "文件夹") {
    const child = currentFolder.value.filter(folder => folder.id === item.id)[0]
    handleFolder(child)
  } else {
    await previewFile(item)
  }
}

// 点击文件夹
const handleFolder = (item) => {
  space_store.handleFolder(item)
}

const previewFile = async (item) => {
  // 单独处理图片
  if (state.imgTypes.indexOf(item.type) !== -1) {
    state.isImg = true
    let res
    if(props.isTask){
      res = await DownLoadFiles({attachment_id: item.id})
    }else{
      res = await PreviewFile(item.id)
    }
    // const res = await PreviewFile(item.id)
    state.resourceSrc = await BlobToImg(res, res.type)
    state.showResourcePreview = true
    return
  }
  // 其他的跳转到预览界面
  await router.push({
    name: 'SpacePreview',
    query: {
      id: item.id,
      isTask: props.isTask
    }
  })
}

</script>

<template>
  <div class="dataFolder">
    <div class="file" v-for="item in fileList" @click="handleFile(item)">
      <div class="img" :class="[icons.get(item.type) ? 'fileImg' : '']">
        <img :src="icons.get(item.type)" alt="">
      </div>
      <div class="text">
        <div class="name">{{ item.name }}</div>
        <div class="bottom">
          <div class="time">{{ formatTime(item.update_time) }}</div>
          <div class="size" v-if="item.size">{{ item.size ? item.size : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
  <van-image-preview v-model:show="state.showResourcePreview"
                     :showIndex="false" :closeable="true"
                     :images="[state.resourceSrc]">
  </van-image-preview>
</template>

<style scoped lang="scss">
.dataFolder {
  overflow-y: scroll;
  width: 100%;

  &:after {
    content: "";
    flex: auto;
  }

  .img {
    display: flex;
    align-items: center;
  }

  .folder {
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 25px;
    }

    .text {
      flex: 1;
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 10px;
    }
  }

  .file {
    display: flex;
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
    img {
      width: 25px;
    }

    .text {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      padding-left: 10px;

      .name {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    .bottom {
      color: #8f8f8d;
      display: flex;
      margin-right: 5px;
      font-size: 9px;

      .time {
        margin-right: 5px;
      }
    }
  }
}
</style>