import Cookies from 'js-cookie'

const timeKey = 'hrsaas-timestamp-key' //设置token超时问题的key

// 获取时间戳
export function getTimeStamp(){
    return Cookies.get(timeKey)
}
// 设置时间戳
export function setTimeStamp(){
    return Cookies.set(timeKey,Date.now())
}