import axios from 'axios'
import { Toast } from 'vant'
import { getTimeStamp, setTimeStamp } from '@/utils/auth.js'
import router from '@/router'
import * as dd from 'dingtalk-jsapi' //
import { fns } from '@/utils/RefreshToken.js'
import axiosRetry from 'axios-retry'
import { RefreshToken } from '@/api/oaApi.js'
// import {RefreshToken,LogOut} from '@/api/user/login.js'
const TimeOut = 3600 //定义超时时间
const service = axios.create({
  baseURL: '', //设置基准地址
  timeout: 600000, //超时时间
})
// 请求拦截器

// console.log(Token);
service.interceptors.request.use(
  (config) => {
    setTimeStamp()
    if (localStorage.getItem('Token')) {
      //每次请求前 注入token
      if (isCheckTimeOut()) {
        //如果为true  表示token过期
        Toast.fail('登录超时,正在返回登录页')
        // 删除
        dd.util.domainStorage.removeItem({
          name: 'infoCode', // 存储信息的key值
        })
        localStorage.clear()
        router.push('/login') //调到登录页
        return
      }
      config.headers['Authorization'] = localStorage.getItem('Token')
      config.headers['language'] = 'zh'
    }
    config.headers['tenant_id'] = localStorage.getItem('tenant_id')
    if (config.method === 'post') {
      // 检测是否包含文件类型, 若包含则进行 formData 封装
      let hasFile = false
      if (config.type === 'json') {
        config.headers['Content-Type'] = 'application/json'
        return config
      }
      if (config.data) {
        Object.keys(config.data).forEach((key) => {
          if (typeof config.data[key] === 'object') {
            const item = config.data[key]
            if (
              item instanceof FileList ||
              item instanceof File ||
              item instanceof Blob
            ) {
              alert('检测包含文件类型')
              hasFile = true
            }
          }
        }) // 检测到存在文件使用 FormData 提交数据
      }
      if (hasFile) {
        const formData = new FormData()
        Object.keys(config.data).forEach((key) => {
          formData.append(key, config.data[key])
        })
        config.data = formData
      }
    }
    return config
  },
  (error) => {
    Toast('请求失败')
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  async (response) => {

    if (response.data && response.data.code == 401) {
    
      await fns()
      if (!response.config.reload) {
        response.config.reload = true
        return service(response.config)
      }
      return response.data
    }

    if (response.data && response.data.code == 402) {
      //拦截路由之前记录需要进入的地址
      sessionStorage.setItem('NextRoutePath', window.location.pathname)
      localStorage.clear()
      router.push('/login') //调到登录页
      return response.data
    }
    if (response) {
      if (response.data && response.status === 200) {
        return response.data
      }
      Toast.error('网络请求错误')
      return
    } else {
      return Promise.reject(new Error(''))
    }
  },
  (error) => {
    if (String(error).indexOf('timeout') >= 0) {
      Toast('网络请求超时')
    } else {
      Toast('请求接口失败')
    }
    console.log(error)
    return Promise.reject(error)
  }
)
// 设置超时逻辑
function isCheckTimeOut() {
  let currentTime = Date.now() //当前时间
  let TimeStamp = getTimeStamp() //缓存时间
  // console.info(currentTime, TimeStamp)
  return (currentTime - TimeStamp) / 1000 > TimeOut
}
export default service
