import {defineStore} from "pinia";
import {GetDictList} from "@/api/oacks";
import {AddMeetingPersonnel, AddRecord, GetCompanies, GetMeetingRoomList, UpdateMeeting} from "@/api/Ads/orderMeeting";
import {GetEmpDeptTree} from "@/api/oaApi";

export const OrderMeetingStore = defineStore('orderMeeting', {
    state: () => {
        return {
            companies: [],// 公司列表
            facilityList: [],// 设施设备列表
            meetingRoomList: [],// 会议室列表
            facilityMap: {},// 设施设备map
            meetingObject: { // 会议对象
                current_time_index: [], // 当前时间格子号
                start_time: '', // 开始时间
                end_time: '', // 结束时间
                meeting_room_id: '', // 会议室id
                meeting_name: '', // 会议标题
                host_emp_id: '', // 主持人id
                current_status: '', // 是否需要审批 1 需要 2 不需要
                remark: '', // 备注
                meeting_id: '', // 会议id  需要新增成功之后 才有
                must_people: [],// 必须参会人员
                optional_people: [],// 必须参会人员
                is_external	: "N",// 外部人员参会
            },
            meetingRoomName: '', // 会议室名称
            hostEmpName: '', // 会议主持人名称
            must_participate_infos: [], // 必须参与人信息
            unnecessary_participate_infos: [], // 非必须参与人信息
            departmentPersonnelList: [], // 部门人员列表
        }
    },
    actions: {
        resetStartAndEndTime() {
            this.meetingObject.start_time = ''
            this.meetingObject.end_time = ''
        },
        // 获取部门人员的书列表
        getDepartmentPersonnelList() {
            return new Promise(async (resolve, reject) => {
                const res = await GetEmpDeptTree()
                this.departmentPersonnelList = res.data
                if (res.code === 200) {
                    resolve(res.data)
                } else {
                    reject(res.message)
                }
            })
        },
        // 撤销申请
        cancelApply() {
            return new Promise(async (resolve, reject) => {
                const data = {
                    id: this.meetingObject.meeting_id,
                    current_status: 4
                }
                const res = await UpdateMeeting(data)
                if (res.code === 200) {
                    resolve('撤销成功')
                    router.back()
                } else {
                    reject('撤销失败')
                }
            })
        },
        // 修改会议预约
        updateRecord() {
            return new Promise(async (resolve, reject) => {
                const data = {
                    current_status: this.meetingObject.current_status,
                    end_time: this.meetingObject.end_time,
                    host_emp_id: this.meetingObject.host_emp_id,
                    id: this.meetingObject.meeting_id,
                    meeting_code: this.meetingObject.meeting_code,
                    meeting_name: this.meetingObject.meeting_name,
                    meeting_room_id: this.meetingObject.meeting_room_id,
                    must_people: this.meetingObject.must_people,
                    optional_people: this.meetingObject.optional_people,
                    remark: this.meetingObject.remark,
                    start_time: this.meetingObject.start_time,
                    is_external	: this.meetingObject.is_external,
                }
                UpdateMeeting(data).then(res => {
                    if (res.code === 200) {
                        resolve('修改成功')
                    } else {
                        reject('修改失败')
                    }
                })
            })
        },
        // 新增会议预约
        addRecord() {
            return new Promise(async (resolve, reject) => {
                // 去除其中 current_time_index 和 meeting_id字段
                let meetingObject = JSON.parse(JSON.stringify(this.meetingObject))
                delete meetingObject.current_time_index
                delete meetingObject.meeting_id
                const res1 = await AddRecord(meetingObject)
                if (res1.code === 200) {
                    resolve('会议预约成功')
                    // this.must_participate.meeting_id = res1.data.meeting_id
                    // this.unnecessary_participate.meeting_id = res1.data.meeting_id
                    // const res2 = await AddMeetingPersonnel(this.must_participate)
                    // const res3 = await AddMeetingPersonnel(this.unnecessary_participate)
                    // if (res2.code === 200 && res3.code === 200) {
                    //     resolve('会议预约成功')
                    // }else{
                    //     reject('会议预约失败')
                    // }
                } else {
                    reject('新增会议预约失败')
                }

            })
        },
        // 清空草稿数据
        clearDraftData() {
            this.must_participate = {}
            this.must_participate_infos = []
            this.unnecessary_participate = {}
            this.unnecessary_participate_infos = []
            this.meetingObject.host_emp_id = ''
            this.meetingObject.meeting_id = ''
            this.meetingObject.meeting_name = ''
            this.meetingObject.remark = ''
            this.meetingObject.is_external = ''
            this.hostEmpName = ''
        },
        // 判断是否存在草稿
        isExistDraft() {
            return new Promise(async (resolve, reject) => {
                if (this.meetingObject.host_emp_id || this.meetingObject.meeting_name
                    || this.meetingObject.remark || this.must_participate_infos.length > 0
                    || this.unnecessary_participate_infos.length > 0
                    || this.hostEmpName) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        },
        //
        // 获取公司列表
        getCompanies() {
            return new Promise(async (resolve, reject) => {
                GetCompanies().then(res => {
                    this.companies = res.data
                    console.log(res.data, " res.data")
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取设施设备
        getFacilityList() {
            return new Promise(async (resolve, reject) => {
                GetDictList("ads_meetingroom_facility").then(res => {
                    this.facilityList = res.data
                    // 将设施设备转换为map
                    this.facilityMap = {}
                    res.data.forEach(item => {
                        this.facilityMap[item.dict_value] = item.dict_label
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取会议室列表
        getMeetingRoomList(data) {
            return new Promise(async (resolve, reject) => {
                GetMeetingRoomList(data).then(res => {
                    this.meetingRoomList = res.data
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
})
