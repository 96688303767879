
import axios from "@/utils/request";

// export function  GetUserInfo(data){
//     return axios({
//         url:'/oa_ads/oa/ads/UserAuthRecord/GetUserInfo',
//         method:'get',
//         params: data
//     })
// }
export function  GetUserInfoByOrderInsertEmpId(data){
    return axios({
        url:'/oa_ads/oa/ads/UserAuthRecord/GetUserInfoByOrderInsertEmpId',
        method:'post',
        data
    })
}
export function  HavePermission(data){
    return axios({
        url:'/oa_ads/oa/ads/UserAuthRecord/HavePermission',
        method:'post',
        data
    })
}
export function  GetUserInfo(data){
    return axios({
        url:'/oa_ads/oa/ads/UserAuthRecord/GetUserInfo',
        method:'post',
        data
    })
}
export function  GetCompany(data){
    return axios({
        url:'/oa_ads/oa/ads/UserAuthRecord/GetCompany',
        method:'get',
        params: data
    })
}
export function  GetDept(data){
    return axios({
        url:'/oa_ads/oa/ads/UserAuthRecord/GetDept',
        method:'get',
        params: data
    })
}
export function  GetAllCategory(data){
    return axios({
        url:'/oa_ads/oa/ads/Category/GetAllCategory',
        method:'post',
        data
    })
}
export function  GetAllPurpose(data){
    return axios({
        url:'/oa_ads/oa/ads/Purpose/GetAllPurpose',
        method:'post',
        data
    })
}
export function  GetAllProject(data){
    return axios({
        url:'/oa_ads/oa/ads/Project/GetAllProject',
        method:'post',
        data
    })
}
export function  GetAllVendor(data){
    return axios({
        url:'/oa_ads/oa/ads/Vendor/GetAllvendor',
        method:'post',
        data
    })
}
export function  GetAllItem(data){
    return axios({
        url:'/oa_ads/oa/ads/Item/GetAllItem',
        method:'post',
        data
    })
}
export function  OrderInsert(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/Insert',
        method:'post',
        data
    })
}
export function  GetAllOrder(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetAllOrder',
        method:'post',
        data
    })
}
export function  GetAllQuotation(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetAllQuotation',
        method:'post',
        data
    })
}
export function  VendorOffer(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/VendorOffer',
        method:'post',
        data
    })
}
export function  GetComparePricesOrder(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetComparePricesOrder',
        method:'post',
        data
    })
}
export function  GetComparePrices(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetComparePrices',
        method:'post',
        data
    })
}
export function  ReceiptOfGoods(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/ReceiptOfGoods',
        method:'post',
        data
    })
}
export function  UpdateById(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/UpdateById',
        method:'post',
        data
    })
}
export function  GetSuppliersViewOrders(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetSuppliersViewOrders',
        method:'post',
        data
    })
}
export function  SuppliersViewOrders(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/SuppliersViewOrders',
        method:'post',
        data
    })
}
export function  GetReceiptOfGoods(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetReceiptOfGoods',
        method:'post',
        data
    })
}
export function  GetTakeDeliveryOfGoods(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetTakeDeliveryOfGoods',
        method:'post',
        data
    })
}
export function  TakeDeliveryOfGoods(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/TakeDeliveryOfGoods',
        method:'post',
        data
    })
}
export function  sendCode(data){
    return axios({
        url:'/oa_ads/oa/ads/Vendor/sendCode',
        method:'post',
        data
    })
}
export function  PhoneCodeLogin(data){
    return axios({
        url:'/oa_ads/oa/ads/Vendor/PhoneCodeLogin',
        method:'post',
        data
    })
}
export function  GetOrderHisList(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/GetOrderHisList',
        method:'post',
        data
    })
}
export function  OnceAgenData(data){
    return axios({
        url:'/oa_ads/oa/ads/Order/OnceAgenData',
        method:'post',
        data
    })
}
export function  GetCategoryByAuth(data){
    return axios({
        url:'/oa_ads/oa/ads/Category/GetCategoryByAuth',
        method:'post',
        data
    })
}
export function  CheckVendorIsAvailable(data){
    return axios({
        url:'/oa_ads/oa/ads/Vendor/CheckVendorIsAvailable',
        method:'post',
        data
    })
}