import {defineStore} from "pinia";
import {GetEmpDeptTree} from "@/api/oaApi";

export const DTSelectionStore = defineStore('DTSelection', {
    state: () => {
        return {
            tabTitle: [], // tab标题
            chooseCompanyIndex: -1,
            // 选中的tab
            currentLevel: 0, // 当前层级
            departmentPersonnelList: [], // 部门人员列表
            choosePersonnelList: [], // 选中的人员列表
            chooseDepartmentList: [], // 选中的部门列表
            realChoosePersonnelList: [], // 真实选中的人员列表
            selectionType: '', // 区分人员选择类型
            otherChoosePersonnelList: [], // 选中的其他人员列表
        }
    },
    actions: {
        setOtherChoosePersonnelList(list) {
            this.otherChoosePersonnelList = list
        },
        setRealChoosePersonnelList() {
            this.realChoosePersonnelList = JSON.parse(JSON.stringify(this.choosePersonnelList))
        },
        // 设置区分的类型
        setSelectionType(type) {
            this.selectionType = type
        },
        // 设置已选中人员列表
        setChoosePersonnelList(list) {
            this.choosePersonnelList = list
        },
        // 获取部门人员的书列表
        getDepartmentPersonnelList() {
            return new Promise(async (resolve, reject) => {
                const res = await GetEmpDeptTree()
                this.departmentPersonnelList = res.data
                console.log(res, "departmentPersonnelList")
                if (res.code === 200) {
                    resolve(res.data)
                } else {
                    reject(res.message)
                }
            })
        },
        // 已全选部门的方式 添加人员列表 没有就添加 有就不管
        addPersonnelListByDepartment(item) {
            const index = this.choosePersonnelList.findIndex(v => v.emp_id === item.emp_id)
            if (index === -1) this.choosePersonnelList.push(item)
        },
        // 改变选择的人员列表
        changePersonnelList(item) {
            const index = this.choosePersonnelList.findIndex(v => v.emp_id === item.emp_id)
            if (index > -1) {
                this.choosePersonnelList.splice(index, 1)
            } else {
                // 是否被其他选择器选中
                if(this.otherChoosePersonnelList.some(v => v.emp_id === item.emp_id)) return
                this.choosePersonnelList.push(item)
            }
        },
        // 改变选择的部门列表
        changeDepartmentList(item) {
            const index = this.chooseDepartmentList.findIndex(v => v.id === item.id)
            if (index > -1) {
                this.chooseDepartmentList.splice(index, 1)
                const currentPersonnelList = item.children.emps.filter(element => !this.otherChoosePersonnelList.some((item) => item.emp_id === element.emp_id))
                currentPersonnelList.forEach(item2 => {
                    this.changePersonnelList(item2)
                })
            } else {
                this.chooseDepartmentList.push(item)
            }
        },
        addDepartmentList(item) {
            const index = this.chooseDepartmentList.findIndex(v => v.id === item.id)
            if (index === -1) this.chooseDepartmentList.push(item)
        },
        // 移除部门下对应的人员
        removePersonnelListByDepartment(item) {
            console.log("item", item)
            item.children.emps.forEach(item2 => {
                this.changePersonnelList(item2)
            })
        }
    }
})