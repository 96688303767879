<!-- 评论明细查看 -->
<template>
  <div class="comment-view-block">
    <van-form>
      <van-cell-group inset style="margin: 1vh 1.2vh 0 1.2vh">
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >评论时间</span
        >
        <van-field style="padding-top: 0" v-model="pageConfig.comment_time" />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >评论人</span
        >
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.comment_emp_name"
        />
        <span
          style="
            margin-left: 2.5vh;
            margin-top: 2vh;
            display: block;
            color: #8f8f8d;
          "
          >评论内容</span
        >
        <van-field
          style="padding-top: 0"
          v-model="pageConfig.content"
          type="textarea"
          autosize
        />
      </van-cell-group>
    </van-form>

    <div class="upload-block">
      <p>上传图片</p>
      <div class="upload-photo">
        <div
          class="img-block"
          v-for="file in pageConfig.img_base64_list"
          @click="methods.previewPhoto(file)"
          :key="file.id"
          :title="file.name"
        >
          <div class="img-icon">
            <img src="../../../../assets/file-img/img.svg" height="30" />
          </div>
          <div class="img-name">
            <span>{{ file.name }}</span>
          </div>
          <div class="file-size">
            <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
              >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
            >
            <span v-else
              >{{ (Number(file.file_size) / 1024 / 1024).toFixed(2) }}M</span
            >

          </div>
        </div>
      </div>
      <p>上传附件</p>
      <div class="upload-file">
        <div
          class="file-block"
          @click="methods.handlePreView(file.id)"
          v-for="file in pageConfig.file_base64_list"
          :key="file.id"
          :title="file.name"
        >
          <div class="file-icon">
            <img
              src="../../../../assets/file-img/excel.svg"
              v-if="file.extension == '.xls' || file.extension == '.xlsx'"
              height="30"
            />
            <img
              src="../../../../assets/file-img/pdf.svg"
              v-else-if="file.extension == '.pdf'"
              height="30"
            />
            <img
              src="../../../../assets/file-img/ppt.svg"
              v-else-if="file.extension == '.ppt'"
              height="30"
            />
            <img
              src="../../../../assets/file-img/txt.svg"
              v-else-if="file.extension == '.txt'"
              height="30"
            />
            <img
              src="../../../../assets/file-img/word.svg"
              v-else-if="file.extension == '.doc' || file.extension == '.docx'"
              height="30"
            />
            <img
              src="../../../../assets/file-img/default.svg"
              v-else
              height="30"
            />
          </div>
          <div class="file-name">
            <span>{{ file.name }}</span>
          </div>
          <div class="file-size">
            <span v-if="Number(file.file_size) / 1024 / 1024 < 1"
              >{{ (Number(file.file_size) / 1024).toFixed(2) }}K</span
            >
            <span v-else
              >{{ (Number(file.file_size) / 1024 / 1024).toFixed(2) }}M</span
            >

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetComment } from '@/api/oawfs.js'
import { onMounted } from '@vue/runtime-core'
import {useRoute, useRouter} from 'vue-router'
import { reactive } from '@vue/reactivity'
import { DownloadBase64, GetFileList, DownloadFile } from '@/api/oaApi.js'
import moment from "moment";
import * as dd from "dingtalk-jsapi";
import ChangeTitle from '@/utils/changeTitle.js'
import { Toast,ImagePreview } from 'vant'
import { GetMobileFileUrl } from '@/api/mobile.js'
export default {
  setup () {

    const route = useRoute()
    const pageConfig = reactive({
      comment_time: '', //评论时间
      content: '', //评论内容
      comment_emp_name: '', //评论人
      file_id_list: '', //附件id列表
      img_id_list: '', //图片id列表
      img_base64_list: [], //图片base64列表
      file_base64_list: [], //附件base64列表
    })
    const router = useRouter()

    const methods = {
      handlePreView: function (file_id) {
        router.push({name: 'SpacePreview', query: {id: file_id}})
      },
      //加载评论信息
      loadComment: function () {
        let param = {
          id: route.params.comment_id,
        }
        GetComment(param).then((res) => {
          if (res.code !== 200) {
            Toast('获取评论信息失败')
            return
          }
          pageConfig.comment_time = methods.convertTime(res.data.comment_time)
          pageConfig.content = res.data.content
          pageConfig.comment_emp_name = res.data.comment_emp_name
          //下载图片
          methods.loadFileList(res.data.img_id_list,'img_base64_list')
          //下载附件
          methods.loadFileList(res.data.file_id_list,'file_base64_list')
        })
      },//加载附件列表
      loadFileList: function (ids, remark) {
        if (ids == '' || ids == null) {
          return
        }
        GetFileList(ids.split(',')).then(res => { 
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }       
          res.data.forEach((item) => {
              pageConfig[remark].push({ name: item.file_name, id: item.id, extension: item.extension,file_size:item.file_size })
          })
        })
      },      
      //判断是否PC端
      IsPC: function () {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
        var flagPc = true;
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flagPc = false;
            break;
          }
        }
        return flagPc;
      },
      //下载附件到本地
      downloadLocalFile: function (item) {         
        //移动端钉钉
        if (!methods.IsPC() && dd.env.platform != 'notInDingTalk') {
          Toast('附件暂不支持下载，请打开电脑端下载')
          return
        }
        //钉钉下载
        if (dd.env.platform != 'notInDingTalk') {
          DownloadBase64(item.id.split(',')).then(res => { 
            if (res.code !== 200) {
              Toast(res.msg)
              return
            }
            let fileName = item.name
            let base64 = res.data[0].base64
            var raw = window.atob(decodeURIComponent(base64))
            var uInt8Array = new Uint8Array(raw.length)
            for (var i = 0; i < raw.length; i++) {
              uInt8Array[i] = raw.charCodeAt(i)
            }
            var blob = new Blob([uInt8Array], { type: item.extension.replace('.', '') })
            var downloadUrl = window.URL.createObjectURL(blob)
            var anchor = document.createElement('a')
            anchor.href = downloadUrl
            anchor.download = decodeURI(fileName)
            anchor.click()
          })
        }      
        //IOS
        else if (ua.indexOf('Mac') > -1 || ua.indexOf('mac') > -1)
        {
          window.webkit.messageHandlers.downloadMessage.postMessage(item.id)
        }
        //安卓下载
        else {
          GetMobileFileUrl(item.id).then(res => {
            if (res.code !== 200) {
              Toast(res.msg)
              return
            }
            window.open(res.data)
          })
        }
        
      },
      //预览图片
      previewPhoto: function (item) {
        DownloadBase64(item.id.split(',')).then(res => {
          if (res.code !== 200) {
            Toast(res.msg)
            return
          }
          let url = 'data:image/' +
            item.extension.replace('.', '') +
            ';base64,' +
            res.data[0].base64
          let arr = []
            arr.push(url)
          ImagePreview({ images:arr,closeable:true })
        })
      }, 
      //时间转换
      convertTime: function (time) {
        if (time != '') {
          return moment(time).format('YYYY/MM/DD HH:mm:ss')
        }
      },
    }
    onMounted(() => {
      //加载评论信息
      methods.loadComment()
      ChangeTitle.setTitle("评论信息")
    })
    return {
      methods,
      pageConfig,
    }
  },
}
</script>
<style lang="scss">
.comment-view-block {
  width: 100%;
  .van-uploader__upload,
  .van-uploader__preview-delete {
    display: none;
  }

  .van-field__control {
    text-indent: 5px;
    color: #666666 !important;
  }

  .upload-block {
    margin: 1vh 1.2vh 0px;
    border-radius: 5px;
    background-color: #fff;
    padding: 2px 0;

    p {
      text-indent: 10px;
      color: #898989;
    }

    .upload-photo,
    .upload-file {
      background-color: #fff;
      padding: 5px 10px;
      margin: 5px auto;
      overflow-x: auto;

      .van-uploader__wrapper {
        overflow-x: scroll;
        white-space: nowrap;
        flex-wrap: inherit;
      }

      .van-uploader__wrapper::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .img-block,
  .file-block {
    cursor: pointer;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    line-height: 30px;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .img-icon,
    .file-icon {
      width: 50px;
      margin: 3px 0;
    }
    .img-name,
    .file-name {
      width: calc(100% - 100px);
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 10px;
      line-height: 36px;
    }
    .file-size {
      line-height: 36px;
      font-size: 8px;
      width: 100px;
      color: #9e9e9e;
    }
  }
}
</style>
