<script setup>
import {reactive} from "vue";
import ImgListItem from "@/components/NormalPage/ImgListItem.vue";
import {modeStatus} from "@/views/Oa/remoteControl/data";

const emit = defineEmits(['handleDetail'])
const props = defineProps({
  dataList: {
    type: Array,
    default: []
  },
  dataItemConfig: {
    type: Object,
    default: () => {
    }
  },
})

const state = reactive({
  color: '', // 任务状态颜色
  text: '', // 任务状态文字
})


</script>

<template>
  <div v-for="(item,index) in dataList" :key="index">
    <ImgListItem
        :dataItemConfig="dataItemConfig"
        :dataItem="item"
        @click="emit('handleDetail',item)">
      <template #play_mode="{dataItem}">
        <span :style="{color: dataItem.play_mode == '2' ? '#e6a23c' : ''}"> {{ dataItem.play_mode == 1 ? '轮播' : '定播' }}</span>
      </template>
    </ImgListItem>
  </div>
</template>

<style scoped lang="scss">

</style>